import styled from "@emotion/styled";

export const StreakContainer = styled.div(({ theme }) => {
  return {
    width: "20px",
    height: "28px",
    background: "#F2DAFF",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});

export const ProgressInnerValue = styled.div(({ theme }) => {
  return {
    background: `linear-gradient(92.58deg, ${theme.palette.secondary.progressGradient2} 0%, ${theme.palette.secondary.progressGradient1} 100%)`,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  };
});
