import React, { Fragment, useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import JsxParser from "react-jsx-parser";

export const PowerTipsTab = ({ ...props }) => {
  const [tab, setTab] = React.useState(props.tab);
  useEffect(() => {
    setTab(props.tab);
  }, [props.tab]);

  return (
    <Fragment>
      {tab && tab.name && (
        <>
          <Typography
            variant="title_medium"
            sx={{ textTransform: "capitalize", my: 2 }}
          >
            {tab.name}
          </Typography>
          <Divider />
        </>
      )}
      <Box
        sx={{
          boxSizing: "border-box",
          flexGrow: 1,
          overflow: "scroll",
          my: 2,
        }}
      >
        <JsxParser
          components={{ Typography, Box }}
          jsx={props.tab.html_content}
        />
      </Box>
    </Fragment>
  );
};
