import React, { useEffect } from "react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import { connect } from "react-redux";

import { TabGroup, TabComponent } from "./styles";
import StayOnTrack from "./StayOnTrack";
import QuickStatView from "./QuickStatView";
import WorkspaceUpdates from "./WorkspaceUpdates";
import NetworkUpdates from "./NetworkUpdates";
import RunwayCalculator from "./Runway Calculator";
import FeaturedPartner from "./FeaturedPartner";
import PowerUp from "./PowerUp";
import { BlueDashboardIcon } from "../../common/icons";
import { getCurrentDate } from "../../utils/date";
import WelcomeStartupOSModal from "../../common/modal/WelcomeStartupOSModal";
import * as actions from "../../modules/actions";
import { STARTUPOS_TRANSPARENT, FABICON } from "../../constants/image";
import theme from "../../constants/Theme";
import services from "../../services";
import { toastContainer } from "../../utils/toast";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const initialState = {
  demoDate: "",
  runawayDetails: [
    {
      cash: 0,
      burnRate: 0,
      type: "ACTUAL",
    },
    {
      cash: 0,
      burnRate: 0,
      type: "WHAT_IF",
    },
  ],
  streakDetails: [
    {
      userId: 0,
      daysMaintained: 1,
      expiresOn: "2022-04-23",
      streakType: {
        period: 0,
        streakName: "POWER_UP",
      },
    },
    {
      userId: 0,
      daysMaintained: 0,
      expiresOn: "2022-04-23",
      streakType: {
        period: 0,
        streakName: "LOGIN",
      },
    },
    {
      userId: 0,
      daysMaintained: 1,
      streakType: {
        period: 0,
        streakName: "TODAYS_TOP",
      },
    },
  ],
  lastVisitedPowerUp: null,
  totalPowerUpsCompleted: 0,
  circleTotalUnits: 0,
  startUpScore: 250,
  radarValue: 0,
  unitDetails: {
    powerUpsUnits: 11,
    circleTotalUnits: 13,
    startUpMinScore: 0,
    startUpMaxScore: 850,
    newUserSignupScore: 250,
    powerupMaxScore: 500,
    demoDateRange: 28,
  },
};

const Workspace = ({ showModal, setAuthData }) => {
  const [value, setValue] = React.useState(0);
  const [dashboardData, setDashboardData] = React.useState(initialState);
  const [stayOnTrackData, setStayOnTrackData] = React.useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNext = event => {
    setAuthData({ showModal: false, showTour: true });
  };

  useEffect(() => {
    services
      .getDashboardStats()
      .then(res => {
        const data = res.data;
        const resData = data.data;
        setDashboardData(resData);
      })
      .catch(err => {
        console.log(err);
        toastContainer(err?.error_description, "error");
      });

    services
      .getTodaysTop()
      .then(res => {
        const data = res.data;
        const resData = data.data;

        setStayOnTrackData(resData);
      })
      .catch(err => {
        console.log(err);
        toastContainer(err?.error_description, "error");
      });
  }, []);

  return (
    <>
      <WelcomeStartupOSModal
        isOpen={showModal}
        onClose={() => setAuthData({ showModal: false })}
        onNext={handleNext}
      />

      <Stack
        sx={{
          minHeight: "100%",
          borderRadius: "28px",
          display: { xs: "none", md: "block" },
          background:
            "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
        }}
      >
        <Grid
          container
          sx={{ p: 4, maxWidth: theme.breakpoints.values.xl, margin: "auto" }}
        >
          <Grid item md={8} lg={8} sx={{ pr: 2 }}>
            <Grid
              backgroundColor="#fff"
              container
              borderRadius="28px"
              item
              xs={12}
              sm={12}
              sx={{ pr: 2, pl: 2 }}
              data-tut="reactour__quickStat"
            >
              <QuickStatView data={dashboardData} />
            </Grid>
            <Grid container>
              <Grid
                item
                md={!!dashboardData?.lastVisitedPowerUp ? 6 : 12}
                lg={6}
                sx={{ pr: 2, mt: 3 }}
                data-tut="reactour__runwayCalculator"
              >
                <RunwayCalculator data={dashboardData?.runawayDetails} />
              </Grid>
              {dashboardData?.lastVisitedPowerUp && (
                <Grid
                  item
                  md={6}
                  lg={6}
                  sx={{ mt: 3, height: "100%" }}
                  data-tut="reactour__powerup"
                >
                  <PowerUp data={dashboardData?.lastVisitedPowerUp} />
                </Grid>
              )}
            </Grid>
            <Grid
              backgroundColor="#FCFCFC"
              container
              borderRadius="28px"
              sx={{ pr: 2, pl: 2, mt: 3 }}
            >
              <Grid item md={12} lg={12} data-tut="reactour__partner">
                <FeaturedPartner />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            md={4}
            lg={4}
            backgroundColor={theme.palette.secondary.white}
            borderRadius="28px"
            height="100%"
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              data-tut="reactour__stayOnTrack"
            >
              <StayOnTrack
                data={dashboardData}
                stayOnTrackData={stayOnTrackData}
              />
            </Grid>
          </Grid>
        </Grid>
      </Stack>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          minHeight: "100%",
          display: { xs: "block", md: "none" },
          background:
            "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
        }}
      >
        <Box sx={{ pt: 3 }} data-tut="reactour__responsive-quickStat">
          <Box
            sx={{ p: 3, display: "flex", alignItems: "center", ml: 3, mr: 3 }}
            backgroundColor="#FCFCFC"
            borderRadius="28px"
          >
            <BlueDashboardIcon />
            <Typography
              variant="h2"
              sx={{ fontSize: "22px !important", ml: 1 }}
            >
              Dashboard
            </Typography>
            <Typography
              sx={{
                marginLeft: "auto",
                fontFamily: "PoppinsRegular !important",
              }}
              variant="h6"
            >
              {getCurrentDate()}
            </Typography>
          </Box>
          <Box
            sx={{ pl: 3.1, pr: 3.1, pt: 3 }}
            justifyContent="center"
            alignItems="center"
          >
            <TabGroup value={value} onChange={handleChange}>
              <TabComponent
                type="workspace"
                label="Workspace Updates"
                {...a11yProps(0)}
              />
              <TabComponent
                type="network"
                label="Network Updates"
                {...a11yProps(1)}
              />
            </TabGroup>
          </Box>
          <Box>
            <WorkspaceUpdates
              value={value}
              index={0}
              data={dashboardData}
              stayOnTrackData={stayOnTrackData}
            ></WorkspaceUpdates>
            <NetworkUpdates
              value={value}
              index={1}
              data={dashboardData}
            ></NetworkUpdates>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

const mapStoreToProps = ({ auth }) => ({
  showModal: auth.showModal,
});

const mapDispatchToProps = dispatch => ({
  setAuthData: updatedData => dispatch(actions.setAuthData(updatedData)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Workspace);
