let apiVersion = process.env.REACT_APP_API_URL;
//let apiVersion = "https://3ba1-45-250-227-212.ngrok.io";

let openStreetMapVersion = "https://nominatim.openstreetmap.org";

export const loginUrl = `${apiVersion}/api/m1/v1/users/login`;

export const updateEmailUrl = `${apiVersion}/api/m1/v1/users/email/verification-link`;

export const resendEmailUrl = `${apiVersion}/api/m1/v1/users/email/resend`;

export const searchLocationByCoordinatesUrl = (lat, lng) =>
  `${openStreetMapVersion}/reverse?lat=${lat}&lon=${lng}&format=json`;

export const searchLocationByZipcodeUrl = zipcode =>
  `${openStreetMapVersion}/search?format=json&postalcode=${zipcode}&country=USA&addressdetails=1`;

export const autoCompleteDataUrl = questionKey =>
  `${apiVersion}/api/m1/v1/companies/auto-complete?key=${questionKey}`;

export const updateUserFlagUrl = () =>
  `${apiVersion}/api/m1/v1/users/tutorials/flags`;

export const updateRunwayDetailsUrl = () =>
  `${apiVersion}/api/m2/v1/companies/runaway`;

export const getDashboardStatsUrl = () =>
  `${apiVersion}/api/m2/v1/companies/stats`;

export const getTodaysTopUrl = () =>
  `${apiVersion}/api/m2/v1/companies/dashboard`;

export const companyProfile = () => `${apiVersion}/api/m1/v1/companies/profile`;

export const powerUpsApiUrl = () => `${apiVersion}/api/m2/v1/powerups`;

export const powerUpPacksApiUrl = () =>
  `${apiVersion}/api/m2/v1/powerups/packs`;

export const excerciseStepsUrl = () =>
  `${apiVersion}/api/m2/v1/excercises/steps`;

export const modulesStepUrl = moduleId =>
  `${apiVersion}/api/m2/v1/modules/steps`;

export const modulesStepResponsesUrl = moduleId =>
  `${apiVersion}/api/m2/v1/modules/${moduleId}/responses`;

export const getExcerciseDetailsUrl = () =>
  `${apiVersion}/api/m2/v1/excercises/details`;

export const powerupDataUrl = id =>
  `${apiVersion}/api/m2/v1/powerups/details?id=${id}`;

export const lastVisitedPowerupDataUrl = `${apiVersion}/api/m2/v1/powerups/last-visited`;
export const startPowerupDataUrl = `${apiVersion}/api/m2/v1/excercises/start`;
export const startModuleDataUrl = `${apiVersion}/api/m2/v1/modules/start`;
export const uploadFile = `${apiVersion}/api/m1/v1/files/upload`;

export const updateStreak = `${apiVersion}/api/m2/v1/streaks`;

export const verifyEmail = token =>
  `${apiVersion}/api/m1/v1/users/email/verify?token=${token}`;

export const powerUpPacksDeatils = id =>
  `${apiVersion}/api/m2/v1/powerups/packs/details?id=${id}`;

export const moduleDetails = id =>
  `${apiVersion}/api/m2/v1/modules/${id}/responses`;

export const interviewCandidates = id =>
  `${apiVersion}/api/m1/v1/users/candidates`;

export const scheduleCanidateEvent = id =>
  `${apiVersion}/api/m2/v1/invitations`;
