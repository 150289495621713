import React from "react";

import { Box, Typography } from "@mui/material";
import { LegendComponent } from "../../../../../styles";
import numeral from "numeral";

const GraphLegends = ({ tam, sam, som }) => {
  return (
    <Box
      display={"flex"}
      justifyContent={{ md: "flex-start", xs: "center" }}
      sx={{ mt: { xs: 5, md: 0 }, pl: { xs: 0, md: 5 } }}
    >
      <Box>
        <Box display={"flex"} sx={{ mb: 3 }}>
          <LegendComponent type="TAM" />
          <Typography
            variant="custom070"
            color={"text.custom070"}
            sx={{ mr: 1 }}
          >
            TAM
          </Typography>
        </Box>
        <Box display={"flex"} sx={{ mb: 3 }}>
          <LegendComponent type="SAM" />
          <Typography
            variant="custom070"
            color={"text.custom050"}
            sx={{ mr: 1 }}
          >
            SAM
          </Typography>
        </Box>

        <Box display={"flex"}>
          <LegendComponent type="SOM" />
          <Typography
            variant="custom070"
            color={"text.custom030"}
            sx={{ mr: 1 }}
          >
            SOM
          </Typography>
        </Box>
      </Box>

      <Box>
        <Box sx={{ mb: 3 }}>
          <Typography variant="title_medium">
            ${numeral(tam)?.format("0,0")}
          </Typography>
        </Box>
        <Box sx={{ mb: 3 }}>
          <Typography variant="title_medium">
            ${numeral(sam)?.format("0,0")}
          </Typography>
        </Box>
        <Box>
          <Typography variant="title_medium">
            ${numeral(som)?.format("0,0")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GraphLegends;
