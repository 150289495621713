import React, { useState, useEffect } from "react";
import theme from "../../../../../constants/Theme";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import * as actions from "../../../../../modules/actions";
import { connect } from "react-redux";
import { useParams, useHistory, Prompt } from "react-router-dom";
import services from "../../../../../services";
import RouterPrompt from "../../../../../common/RouterPrompt";
import messages from "../../../../../constants/messages";
import constants from "../../../../../constants";
import { toastContainer } from "../../../../../utils/toast";
import LoadingButton from "../../../../../common/Loadingbutton";
import ExcerciseWrapper from "../../../../../components/Workspace/PowerUp/ExcerciseWrapper";
import ExcerciseSection from "../../../../../components/Workspace/PowerUp/ExcerciseSection";
import KnowledgeSidebar from "../../../../../components/Workspace/PowerUp/KnowledgeSidebar";
import {
  getExcerciseConfigurations,
  getExcerciseCompletionStatus,
  getDefaultInputStates,
  buttonDisable,
} from "./functions";
import BrandPersonality from "./Components/BrandPersonality";
import BrandStory from "./Components/BrandStory";
import UnlockYourDeck from "./Components/UnlockYourDeck";

function Brandstorming({ powerUpExcercises, setPowerUpExcercises }) {
  const [userInputs, setUserInputs] = useState(null);
  const [excerciseConfigs, setExcerciseConfigs] = useState();
  const [excerciseSteps, setExcerciseSteps] = useState([]);
  const [currentExcercise, setCurrentExcercise] = useState();

  const params = useParams();
  const history = useHistory();
  const moduleId = params.id;
  const excerciseParam = params.excercise;
  const tabItems = constants.EXCERCISE_TAB_ITEMS;
  const [currentTab, setCurrentTab] = useState(tabItems[0].key);
  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const [version, setVersion] = useState();

  const handleBackClick = () => {
    if (currentExcercise.stepNo === 1) {
      //send to details page
      history.push(`/workspace/powerup/${moduleId}`);
    } else {
      setUserInputs(null);
      let currentStep = excerciseSteps.find(
        excercise => excercise.stepNo === currentExcercise.stepNo - 1
      );
      const newExcerciseData = excerciseConfigs.excercises.find(
        excercise => excercise.step_id === currentStep.id
      );
      setCurrentExcercise(syncData(newExcerciseData));
    }
  };
  const updatepowerUpExcercises = data => {
    return new Promise((resolve, reject) => {
      try {
        let existingPowerUpExcercises = [...powerUpExcercises];
        let currentIndex = existingPowerUpExcercises.findIndex(
          excercise => excercise.stepNo === data.stepNo
        );

        // if excercise already exists update
        let requestBody = null;
        if (currentIndex >= 0) {
          var excercise = existingPowerUpExcercises[currentIndex];
          excercise = {
            ...excercise,
            answers: userInputs,
            isLastStep: data.isLastStep,
            isCompleted: data.isCompleted,
          };
          existingPowerUpExcercises[currentIndex] = excercise;
          requestBody = excercise.answers;
        } else {
          //Else insert new one
          existingPowerUpExcercises.push(data);
          requestBody = data.answers;
        }
        services
          .storeModuleStepResponses(moduleId, {
            ...data,
            answers: JSON.stringify(requestBody),
          })
          .then(response => {
            if (response.data.code === 200) {
              setPowerUpExcercises(existingPowerUpExcercises);
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
            console.log(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  };
  const handleNextClick = () => {
    setNextButtonLoading(true);
    if (currentExcercise.key === "decscribePersonna") {
      services.addAutoCompleteData({
        key: "jobTitle",
        values: [userInputs.jobTitle],
      });
    } else if (currentExcercise.key === "jobNeeds") {
      services.addAutoCompleteData({
        key: "jobActivity",
        values: userInputs.jobActivity,
      });
    }
    const isLastExcercise =
      currentExcercise.stepNo === excerciseConfigs.excercises.length;
    let data = {
      moduleResponseId: version,
      stepNo: currentExcercise.stepNo,
      answers: userInputs,
      isLastStep: isLastExcercise,
      isCompleted: getExcerciseCompletionStatus(currentExcercise, userInputs),
      moduleStepId: currentExcercise.id,
    };
    updatepowerUpExcercises(data)
      .then(response => {
        if (response) {
          setUserInputs(null);
          setNextButtonLoading(false);
          if (!data.isLastStep) {
            let currentStep = excerciseSteps.find(
              excercise => excercise.stepNo === currentExcercise.stepNo + 1
            );
            const newExcerciseData = excerciseConfigs.excercises.find(
              excercise => excercise.step_id === currentStep.id
            );
            setCurrentExcercise(syncData(newExcerciseData));
          } else {
            history.push(excerciseConfigs.summary_path);
          }
        }
      })
      .catch(error => {
        console.log(error);
        setNextButtonLoading(false);
        toastContainer(messages.EXCERCISE_STORE_ERROR, "error");
      });
  };

  const shouldShowPrompt = () => {
    if (userInputs && powerUpExcercises && currentExcercise) {
      const currentSavedStep = powerUpExcercises.find(
        ex => ex.stepNo === currentExcercise.step_number
      );
      if (currentSavedStep) {
        if (
          JSON.stringify(currentSavedStep.answers.value) ===
          JSON.stringify(userInputs)
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        if (Object.keys(userInputs).length === 0) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const BackButton = () => {
    return (
      <Button
        variant="outlined"
        color="primary"
        sx={{ mx: 1 }}
        onClick={handleBackClick}
      >
        Back
      </Button>
    );
  };

  const NextButton = () => {
    return (
      <LoadingButton
        variant="contained"
        color="primary"
        sx={{ mx: 1 }}
        disabled={buttonDisable(userInputs, currentExcercise?.component)}
        loading={nextButtonLoading}
        onClick={handleNextClick}
      >
        Next
      </LoadingButton>
    );
  };

  const syncData = jsonData => {
    const stepDetailsData = excerciseSteps.find(
      step => step.id === jsonData.step_id
    );
    return { ...jsonData, ...stepDetailsData };
  };

  const getLastExcercise = excercises => {
    excercises.sort((a, b) => (a.stepNo > b.stepNo ? 1 : -1));
    return excercises[excercises.length - 1].powerUpStepId;
  };

  useEffect(() => {
    if (excerciseConfigs) {
      services
        .getModulesSteps("md-brandstorm")
        .then(result => {
          if (result.data?.data) {
            const steps = result.data.data;
            steps.sort((a, b) => (a.stepNo > b.stepNo ? 1 : -1));
            setExcerciseSteps(steps);
          }
        })
        .catch(err => console.log(err));
    }
  }, [excerciseConfigs]);

  useEffect(() => {
    if (excerciseConfigs && excerciseSteps && excerciseSteps.length) {
      services
        .getModuleStepResponses(moduleId)
        .then(result => {
          const tempExcerciseDetails = result.data?.data[0];

          if (tempExcerciseDetails) {
            const excerciseDetails = tempExcerciseDetails.moduleStepResponses;
            setVersion(tempExcerciseDetails.id);
            if (!excerciseDetails || !excerciseDetails.length) {
              setPowerUpExcercises([]);
              setCurrentExcercise(syncData(excerciseConfigs.excercises[0]));
            } else {
              const mappedExcerciseDetails = excerciseDetails.map(
                excercise => ({
                  moduleResponseId: version,
                  moduleStepId: excercise.moduleStep.id,
                  answers: JSON.parse(excercise.answers),
                  stepNo: excercise.moduleStep.stepNo,
                })
              );

              setPowerUpExcercises(mappedExcerciseDetails);
              if (excerciseParam === "start") {
                const lastExcerciseStepId = getLastExcercise(
                  mappedExcerciseDetails
                );
                const latestExcercise = excerciseConfigs.excercises.find(
                  excercise => excercise.step_id === lastExcerciseStepId + 1
                );
                if (latestExcercise) {
                  setCurrentExcercise(syncData(latestExcercise));
                } else {
                  history.push(
                    `/workspace/brandstorming/${moduleId}/${version}/summary`
                  );
                }
              } else if (excerciseParam === "retake") {
                setPowerUpExcercises([]);
                setCurrentExcercise(syncData(excerciseConfigs.excercises[0]));
              } else {
                setCurrentExcercise(
                  syncData(
                    excerciseConfigs.excercises.find(
                      excercise => excercise.path === excerciseParam
                    )
                  )
                );
              }
            }
          }
        })
        .catch(err => console.log(err));
    }
  }, [excerciseSteps]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    if (currentExcercise) {
      const currentItem = powerUpExcercises.find(
        excercise => excercise.stepNo === currentExcercise.stepNo
      );

      //Check if there is previously filled step and see its input
      if (currentItem) {
        if (currentItem.answers && Object.keys(currentItem.answers).length) {
          setUserInputs(currentItem.answers);
        } else {
          setUserInputs(getDefaultInputStates(currentExcercise.component));
        }
      } else {
        setUserInputs(getDefaultInputStates(currentExcercise.component));
      }
      setCurrentTab(tabItems[0].key);
      history.push(currentExcercise.path);
    }
  }, [currentExcercise]);

  useEffect(() => {
    setExcerciseConfigs(getExcerciseConfigurations());
  }, []);

  const handleStepperStepChange = stepId => {
    setUserInputs(null);
    const changedStep = excerciseConfigs.excercises.find(
      excercise => excercise.step_id === stepId
    );
    if (changedStep) {
      setCurrentExcercise(syncData(changedStep));
    }
  };

  return (
    <>
      <RouterPrompt
        title={messages.UNSAVED_CHANGES_PROMPT_MESSAGE}
        when={shouldShowPrompt}
        onOK={() => true}
        onCancel={() => false}
        okText={"Ok"}
        cancelText={"Cancel"}
        stepPath={`/workspace/new-powerup/${moduleId}/${currentExcercise?.path}`}
      />

      <ExcerciseWrapper
        NextButton={NextButton}
        BackButton={BackButton}
        excerciseConfigs={excerciseConfigs}
      >
        {excerciseConfigs && (
          <ExcerciseSection
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            excerciseConfigurations={excerciseConfigs}
            currentExcercise={currentExcercise}
            excerciseSteps={excerciseSteps}
            NextButton={NextButton}
            BackButton={BackButton}
            powerUpExcercises={powerUpExcercises}
            handleStepperStepChange={handleStepperStepChange}
          >
            {currentExcercise &&
              (currentExcercise.component === "BrandPersonality" ? (
                <BrandPersonality
                  userInputs={userInputs}
                  setUserInputs={setUserInputs}
                />
              ) : currentExcercise.component === "BrandStory" ? (
                <BrandStory
                  userInputs={userInputs}
                  setUserInputs={setUserInputs}
                  recapContent={constants.BRANDSTORMING_RECAP_DATA}
                  powerUpExcercises={powerUpExcercises}
                />
              ) : currentExcercise.component === "UnlockYourDeck" ? (
                <UnlockYourDeck
                  userInputs={userInputs}
                  setUserInputs={setUserInputs}
                />
              ) : null)}
          </ExcerciseSection>
        )}

        <KnowledgeSidebar currentTab={currentTab} />
      </ExcerciseWrapper>
    </>
  );
}

const mapStoreToProps = ({ powerUp }) => ({
  powerUpExcercises: powerUp?.powerUpExcercises,
});

const mapDispatchToProps = dispatch => ({
  setPowerUpExcercises: updatedSteps =>
    dispatch(actions.setPowerUpExcercises(updatedSteps)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Brandstorming);
