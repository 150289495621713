import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Stack,
  Breadcrumbs,
  Link,
  Typography,
  IconButton,
  Grid,
  CircularProgress,
  Button,
  Card,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBack from "@mui/icons-material/ArrowBack";
import constants from "../../../constants";
import ROUTES_PATH from "../../../constants/routes";
import theme from "../../../constants/Theme";
import PowerUPHeader from "../../../components/PowerUP/Details/Header";
import ValuePropOverView from "../../../components/Workspace/PowerUp/ValueProp/Overview";
import { useParams, useHistory } from "react-router-dom";

import services from "../../../services";
import PowerUpKnowledgeSidebar from "../../../components/Workspace/PowerUp/Sidebar";
import { VALUE_PROP_SIDEBAR } from "../../../constants/powerup-data";
import PersonaWorkCard from "../../../components/Workspace/PowerUp/PersonaWorkCard";

export default function ValuePropPowerUp() {
  const history = useHistory();
  const [powerupDetails, setPowerupDetails] = useState("");
  const [powerupDetailsLoader, setPowerupDetailsLoader] = useState(false);
  const [powerupStepsData, setPowerupStepsData] = useState([]);
  const [completedWorks, setCompletedWorks] = useState([]);
  const [SIDEBARDATA, setSidebarData] = useState({});
  const [latestModule, setLatestModule] = useState();

  const params = useParams();
  const powerUpId = parseInt(params.id);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    setSidebarData(services.getSidebarContent(params.id));

    //service call to update last visited powerup status
    services
      .updateLastVisitedPowerup({
        powerUpId: params.id,
      })
      .then(response => {})
      .catch(error => {});

    setPowerupDetailsLoader(true);

    //service call to get powerup details
    services
      .getPowerupData(params.id)
      .then(response => {
        const res = response.data;

        if (res.code === 200) {
          //service call to update last visited powerup status
          services
            .updateLastVisitedPowerup({
              powerUpId: params.id,
            })
            .then(response => {})
            .catch(error => {});

          setPowerupDetails(res.data);
          if (res.data.modules && res.data.modules.length) {
            getModuteStepsApiCall(res.data.modules[0].moduleUuid);
          }
        }
      })
      .catch(error => {
        setPowerupDetailsLoader(false);
      });
  }, [params]);

  useEffect(() => {
    if (powerupDetails && powerupDetails.modules.length) {
      if (powerupDetails.modules.length === 1) {
        const currentModule = powerupDetails.modules[0];
        services
          .getModuleStepResponses(currentModule.id)
          .then(result => {
            const modStepResponses = result.data?.data;
            const completedVersions = modStepResponses?.filter(module =>
              module.hasOwnProperty("completionDate")
            );
            if (modStepResponses && modStepResponses.length) {
              const lastModule = modStepResponses[modStepResponses.length - 1];
              setLatestModule(lastModule);
            }

            const completedModulesFormatted = completedVersions.map(
              version => ({
                title: JSON.parse(
                  version.moduleStepResponses.find(step =>
                    step.answers.includes("personaName")
                  ).answers
                ).personaName,
                moduleId: version.moduleId,
                versionId: version.id,
                completionDate: version.completionDate,
              })
            );
            setCompletedWorks(completedModulesFormatted);
          })
          .catch(err => console.log(err));
      }
    }
  }, [powerupDetails]);

  const getPowerupStepsApiCall = () => {
    //service call to get powerup steps
    services
      .getPowerupSteps(powerUpId)
      .then(result => {
        setPowerupDetailsLoader(false);

        if (result.data?.data) {
          setPowerupStepsData(result.data?.data);
        }
      })
      .catch(err => console.log(err));
  };

  const getModuteStepsApiCall = id => {
    services
      .getModulesSteps(id)
      .then(result => {
        setPowerupDetailsLoader(false);
        if (result.data?.data) {
          setPowerupStepsData(result.data?.data);
        }
      })
      .catch(err => console.log(err));
  };

  const handleWorkspaceNavigation = () => {
    history.push(ROUTES_PATH.WORKSPACE);
  };

  const handleStartAction = () => {
    //Temporayy redirction to storyteller powrup screen until apis and workspace cards integration is completed.
    const moduleId = powerupDetails.modules[0].id;
    const moduleUuid = powerupDetails.modules[0].moduleUuid;
    if (powerupDetails?.companyTask?.taskStatus === "COMPLETED") {
      if (powerUpId !== 6 || powerUpId !== 9) {
        history.push(
          `/workspace/powerup/${moduleUuid}/${moduleId}/${latestModule.id}/retake`
        );
      } else {
        history.push(`/workspace/powerup/${moduleUuid}/${moduleId}/retake`);
      }
    } else if (powerupDetails?.companyTask?.taskStatus === "STARTED") {
      if (powerUpId !== 6 || powerUpId !== 9) {
        history.push(
          `/workspace/powerup/${moduleUuid}/${moduleId}/${latestModule.id}/start`
        );
      } else {
        history.push(`/workspace/powerup/${moduleUuid}/${moduleId}/start`);
      }
    } else {
      //api service call to start powerup

      const moduleId = powerupDetails.modules[0].id;
      const moduleUuid = powerupDetails.modules[0].moduleUuid;
      services
        .startModule({ moduleId })
        .then(response => {
          const res = response.data;
          if (res.code === 200) {
            if (powerUpId === 9 || powerUpId === 6) {
              history.push(
                `/workspace/powerup/${moduleUuid}/${moduleId}/start`
              );
            } else {
              history.push(
                `/workspace/powerup/${moduleUuid}/${moduleId}/${res.data.id}/start`
              );
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const getButtonLabel = () => {
    if (powerupDetails?.companyTask?.taskStatus === "COMPLETED") {
      return "Retake";
    } else if (powerupDetails?.companyTask?.taskStatus === "STARTED") {
      return "Continue";
    } else {
      return "Start";
    }
  };

  return (
    <>
      {/*Desktop screens*/}
      <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        {powerupDetailsLoader ? (
          <Box justifyContent={"center"} sx={{ display: "flex", mt: 18 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Stack
            sx={{
              minHeight: "100%",
              borderRadius: "28px",
              p: 4,
              background:
                "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
            }}
          >
            <Grid
              container
              flexDirection={"column"}
              sx={{ maxWidth: theme.breakpoints.values.xl, margin: "auto" }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton aria-label="ArrowBack" color="primary">
                  <ArrowBack />
                </IconButton>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<NavigateNextIcon sx={{ ml: 1.5, mr: 1 }} />}
                >
                  <Link
                    underline="hover"
                    color="primary"
                    component="button"
                    onClick={() => {
                      handleWorkspaceNavigation();
                    }}
                  >
                    <Typography color="primary" variant="h5">
                      Workspace
                    </Typography>
                  </Link>
                  <Typography color="text.primary" variant="h5">
                    {powerupDetails.powerUpName}
                  </Typography>
                </Breadcrumbs>
              </Box>
              <Grid container>
                <Grid
                  item
                  lg={8}
                  md={8}
                  sm={12}
                  xs={12}
                  sx={theme => ({
                    [theme.breakpoints.up("md")]: {
                      borderRight: `1px solid ${theme.palette.secondary.dark}`,
                      pr: 3,
                    },
                    mt: 4,
                  })}
                >
                  <Box>
                    <PowerUPHeader valuePropsHeaderData={powerupDetails} />
                    <Divider
                      color={theme.palette.secondary.dark}
                      sx={{ mt: 3 }}
                    />
                    <Box sx={{ mt: 3 }}>
                      <ValuePropOverView
                        valuePropsDetails={constants.VALUE_PROP_DETAILS}
                        valuePropsContents={powerupStepsData}
                        powerupApiDetails={powerupDetails}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  sx={theme => ({
                    [theme.breakpoints.up("md")]: {
                      pl: 3,
                    },
                    mt: 4,
                    mb: 6,
                  })}
                >
                  <PowerUpKnowledgeSidebar sidebar_content={SIDEBARDATA} />
                  <Card
                    sx={{
                      boxShadow: "none",
                      borderRadius: "28px",
                      p: 4,
                      width: 1,
                      overflow: "hidden",
                      boxSizing: "border-box", // Not certain why this bit is so important here.
                      display: "flex",
                      flexDirection: "column",
                      my: 1,
                    }}
                  >
                    <Typography variant="title_large">
                      Ready to start this PowerUP?
                    </Typography>
                    <Typography variant="title_large">
                      ${powerupDetails.amount}
                    </Typography>
                    <Typography variant="subtitle2">
                      Courtesy of StartupOS
                    </Typography>
                    <Button
                      sx={{
                        width: 1,
                        maxWidth: "unset",
                        my: 1,
                        mt: 4,
                      }}
                      onClick={() => {
                        handleStartAction();
                      }}
                      //disabled={
                      //  powerupDetails.id === 9 &&
                      //  powerupDetails?.preRequisites[2]?.companyTask
                      //    ? false
                      //    : true
                      //}
                    >
                      {getButtonLabel()}
                    </Button>
                  </Card>
                  <Box sx={{ mt: 3 }}>
                    {powerUpId === 3 && completedWorks.length ? (
                      <PersonaWorkCard completedWorks={completedWorks} />
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        )}
      </Box>

      {/* Mobile screens*/}
      <Box sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
        {powerupDetailsLoader ? (
          <Box justifyContent={"center"} sx={{ display: "flex", mt: 18 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Stack
            sx={{
              minHeight: "100%",
              p: 2,
              background:
                "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
            }}
          >
            <Grid
              container
              flexDirection={"column"}
              sx={{ maxWidth: theme.breakpoints.values.xl, margin: "auto" }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton aria-label="ArrowBack" color="primary">
                  <ArrowBack />
                </IconButton>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<NavigateNextIcon sx={{ ml: 1.5, mr: 1 }} />}
                >
                  <Link
                    underline="hover"
                    color="primary"
                    component="button"
                    onClick={() => {
                      handleWorkspaceNavigation();
                    }}
                  >
                    <Typography color="primary" variant="h5">
                      Workspace
                    </Typography>
                  </Link>
                  <Typography color="text.primary" variant="h5">
                    {powerupDetails.powerUpName}
                  </Typography>
                </Breadcrumbs>
              </Box>
              <Grid container>
                <Grid
                  item
                  lg={8}
                  md={8}
                  sm={12}
                  xs={12}
                  sx={theme => ({
                    [theme.breakpoints.up("md")]: {
                      borderRight: `1px solid ${theme.palette.secondary.dark}`,
                      pr: 3,
                    },
                    mt: 4,
                  })}
                >
                  <Box>
                    <PowerUPHeader
                      valuePropsHeaderData={powerupDetails}
                      valuePropsDetails={constants.VALUE_PROP_DETAILS_SIDEBAR}
                      powerupApiDetails={powerupDetails}
                    />

                    <Box sx={{ mt: 3 }}>
                      <ValuePropOverView
                        valuePropsDetails={constants.VALUE_PROP_DETAILS}
                        valuePropsContents={powerupStepsData}
                        powerupApiDetails={powerupDetails}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
        )}
      </Box>
      {!powerupDetailsLoader ? (
        <Box
          sx={{
            display: { xs: "block", sm: "block", md: "none" },

            pl: 3,
            pr: 2,
            mb: 2,
            py: 2,
            backgroundColor: theme.palette.secondary.white,
          }}
        >
          <Grid container>
            <Grid item xs={2}>
              <Typography variant="displaySmall">
                ${powerupDetails.amount}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Button
                onClick={() => {
                  handleStartAction();
                }}
              >
                {getButtonLabel()}
              </Button>
            </Grid>
          </Grid>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}
