import React, { useState, useEffect } from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import theme from "../../../../constants/Theme";

export default function PresentationPreview({ open, close, data }) {
  const [scroll, setScroll] = React.useState("paper");
  return (
    <>
      <Dialog
        open={open}
        onBackdropClick={close}
        scroll={scroll}
        sx={{
          display: "flex",
          width: 1,
          flexDirection: "column",

          "& .MuiDialog-paper": {
            width: "fit-content",
            maxWidth: "unset",
            borderRadius: 3,
          },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <Grid
          container
          sx={{
            display: "flex",
            width: 1,
            flexDirection: "column",
            pt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "max-content",
              pl: 2,
              pr: 2,
            }}
          >
            <Grid item xs={10} md={10}>
              <Typography variant="sys_light_on_surface_heading">
                Template Preview
              </Typography>
            </Grid>

            <Grid
              item
              xs={2}
              md={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <CloseIcon
                sx={{ cursor: "pointer", color: theme.palette.secondary.dark }}
                onClick={close}
              />
            </Grid>
          </Box>

          <DialogContent dividers={scroll === "paper"}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: { xs: "350px", sm: "500px", md: "720px" },
                maxHeight: { xs: "150px", sm: "300px", md: "400px" },
              }}
            >
              {data.imgList.map((item, index) => {
                return (
                  <Grid item xs={12} md={12}>
                    <Box
                      component="img"
                      src={item}
                      sx={{
                        display: "flex",
                        width: 1,
                        height: 1,
                        border: "1px solid rgba(124, 117, 127, 0.16)",
                        borderRadius: 3,
                        mb: 1,
                      }}
                    ></Box>
                  </Grid>
                );
              })}
            </Box>
          </DialogContent>
        </Grid>
      </Dialog>
    </>
  );
}
