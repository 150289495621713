import React from "react";
import { FooterWrapper, PageWrapper } from "./styles";
import { Box, Grid, Stack } from "@mui/material";

function PowerUPLayout({ main, sidebar, breadcrumbs, tab, footerComponent }) {
  return (
    <Box>
      <PageWrapper>
        <Box
          sx={{ maxWidth: theme => theme.breakpoints.values.xl, m: "0 auto" }}
        >
          <Box>{breadcrumbs}</Box>

          <Box
            display="grid"
            gridTemplateColumns={"repeat(12,1fr)"}
            gap={2}
            sx={{
              my: 2,
              display: {
                md: "grid",
                xs: "none",
              },
            }}
          >
            <Box gridColumn={{ md: "span 7", lg: "span 8", xl: "span 9" }}>
              {main}
            </Box>
            <Box gridColumn={{ md: "span 5", lg: "span 4", xl: "span 3" }}>
              {sidebar}
            </Box>
          </Box>
          <Box
            sx={{
              my: 2,
              display: {
                md: "none",
                xs: "block",
              },
            }}
          >
            <Box>{tab === "knowledge" ? sidebar : main}</Box>
          </Box>
        </Box>
      </PageWrapper>
      <FooterWrapper>{footerComponent}</FooterWrapper>
    </Box>
  );
}

export default PowerUPLayout;
