import React, { Fragment } from "react";
import { Box } from "@mui/material";
import { DetailsTab } from "./details-tab";
import { PowerTipsTab } from "./powertips-tab";
import { InspirationTab } from "./inspiration-tab";

const TabPanel = ({ ...props }) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{
        height: value === index ? 1 : 0,
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {value === index && <Fragment>{children}</Fragment>}
    </Box>
  );
};

export const TabContent = ({ ...props }) => {
  return (
    <TabPanel key={props.index} value={props.value} index={props.index}>
      {props.tab.name.toUpperCase() === "DETAILS" && (
        <DetailsTab tab={props.tab} />
      )}
      {props.tab.name.toUpperCase() === "POWER TIPS" && (
        <PowerTipsTab tab={props.tab} />
      )}
      {props.tab.name.toUpperCase() === "INSPIRATION" && (
        <InspirationTab tab={props.tab} />
      )}
    </TabPanel>
  );
};
