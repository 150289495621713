import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { TIMERCOLONICON } from "../../constants/image";
import { TimerBox } from "./styles";

const CountDownTimer = ({ days, hours, minutes }) => {
  return (
    <TimerBox>
      <Typography
        sx={{
          textAlign: "center",
          fontSize: "36px !important",
          background: "#F2DAFF",
          borderRadius: "16px 4px 4px 16px",
          padding: "8px",
        }}
        variant="h1"
        color="primary"
      >
        <Typography variant="h6">D</Typography> {days}
      </Typography>
      <Typography variant="h1" color="primary">
        <img src={TIMERCOLONICON} />
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          background: "#F2DAFF",
          borderRadius: "4px",
          padding: "8px",
        }}
        variant="h1"
        color="primary"
      >
        <Typography variant="h6">H</Typography>
        {hours}
      </Typography>
      <Typography variant="h1" color="primary">
        <img src={TIMERCOLONICON} />
      </Typography>
      <Typography
        sx={{
          textAlign: "center",
          background: "#F2DAFF",
          borderRadius: "4px 16px 16px 4px",
          padding: "8px",
        }}
        variant="h1"
        color="primary"
      >
        <Typography variant="h6">M</Typography>
        {minutes}
      </Typography>
    </TimerBox>
  );
};

//TODO - add prop-types to all components
CountDownTimer.propTypes = {
  days: PropTypes.number,
  hours: PropTypes.number,
  minutes: PropTypes.number,
};

CountDownTimer.defaultProps = {
  days: 0,
  hours: 0,
  minutes: 0,
};
export default CountDownTimer;
