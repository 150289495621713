import { Box, Grid } from "@mui/material";
import React from "react";
import Sidebar from "../../components/Onboarding/Sidebar";
import { PageWrapper } from "./styles";
import LayoutWrapper from "./LayoutWrapper";
import ContentWrapper from "./ContentWrapper";

export default function Layout({ children }) {
  return (
    <Box sx={{ height: "100vh", overflow: "hidden" }}>
      <Grid container sx={{ height: "100%", width: "100%" }}>
        <Grid
          item
          xl={2}
          lg={3}
          md={3}
          display={{ xs: "none", sm: "none", md: "block" }}
        >
          <Sidebar />
        </Grid>
        <Grid
          item
          xl={10}
          lg={9}
          md={9}
          sm={12}
          sx={{
            height: "100%",
            overflowY: "auto",
          }}
        >
          <ContentWrapper>{children}</ContentWrapper>
        </Grid>
      </Grid>
    </Box>
  );
}
