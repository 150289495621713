import services from "../../services";
import * as actionTypes from "./actionTypes";

export const setOnboardingData = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_ONBOARDING_DATA,
      payload,
    });
  };
};

export const setCompanyName = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_COMPANY_NAME,
      payload,
    });
  };
};

export const setCompanyLocation = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_COMPANY_LOCATION,
      payload,
    });
  };
};

export const setAutoCompleteData = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_AUTOCOMPLETE_DATA,
      payload,
    });
  };
};

export const getAutoCompleteData = questionKey => {
  return (dispatch, getState) => {
    const autoCompleteData = getState()?.onboarding?.fetchedAutoCompleteDetails;

    if (autoCompleteData[questionKey].length === 0) {
      services
        .autoCompleteData(questionKey)
        .then(response => {
          const res = response.data;
          if (res.code === 200) {
            dispatch(
              setAutoCompleteData({
                [questionKey]: res.data.values,
              })
            );
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };
};

export const setProductService = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_PRODUCT_SERVICE,
      payload,
    });
  };
};

export const setExperienceLevelsService = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_EXPERIENCE_LEVELS,
      payload,
    });
  };
};

export const setFounderSuperPowerService = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_FOUNDER_SUPER_POWER,
      payload,
    });
  };
};

export const setCommitmentLevelsService = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_COMMITMENT_LEVELS,
      payload,
    });
  };
};
export const setStartupJourney = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_STARTUP_JOURNEY,
      payload,
    });
  };
};
