import styled from "@emotion/styled";
import { sosPrimary } from "../../constants/Theme/colors";

export const PageWrapper = styled.div(({ theme }) => {
  return {
    background: sosPrimary[25],
    borderRadius: "28px",
    display: "block",
    [theme.breakpoints.up("lg")]: {
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    },
    [theme.breakpoints.down("lg")]: {
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    },
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    },
    [theme.breakpoints.down("md")]: {
      padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
      borderRadius: 0,
    },
  };
});

export const FooterWrapper = styled.div(({ theme }) => {
  return {
    backgroundColor: theme.palette.secondary.white,
    position: "sticky",
    //As we have to make this button bar sticky on the bottom navbar so adding bottom 72px explicit height as bottom navbar has height 72px , We cant use any other alternative here
    bottom: 72,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  };
});
