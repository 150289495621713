import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  Autocomplete,
  Grid,
} from "@mui/material";
import {
  STATES,
  PERSONA_INDUSTRY_TYPES,
  PERSONA_EDUCATION_LEVELS,
} from "../../../../../../../constants/powerup-data";
import services from "../../../../../../../services";
import AutocompleteInput from "../../../../../../../common/AutocompleteInput";

export default function DescribePersona({
  userInputs,
  setUserInputs,
  describePersonaValid,
}) {
  const [autoCompleteData, setAutoCompleteData] = useState([]);

  useEffect(() => {
    services
      .autoCompleteData("jobTitle")
      .then(response => {
        const values = response?.data?.data?.values;
        if (values && values.length) {
          setAutoCompleteData(values);
        }
      })
      .catch(err => console.log(err));
  }, []);

  const handleInputChange = (e, key) => {
    let value = e.target.value;
    if (key === "jobTitle" && typeof value === "number") {
      value = e.target.innerText;
    }
    if (key === "age") {
      if (describePersonaValid("age", value)) {
        const currentInput = { ...userInputs };
        currentInput[key] = value;
        setUserInputs(currentInput);
      }
    } else {
      const currentInput = { ...userInputs };
      currentInput[key] = value;
      setUserInputs(currentInput);
    }
  };
  return (
    <Box>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h5">What is their age?</Typography>

        <TextField
          label="Age (in years)"
          fullWidth
          sx={{ mt: 2 }}
          value={userInputs?.age}
          onChange={e => handleInputChange(e, "age")}
        />
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5">Where are they located?</Typography>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} xs={12}>
            <TextField
              label="State"
              fullWidth
              select
              sx={{ mt: 2, py: 0 }}
              onChange={e => handleInputChange(e, "state")}
              value={userInputs?.state || ""}
            >
              {STATES.map(option => (
                <MenuItem key={option.name} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <TextField
              label="City"
              fullWidth
              sx={{ mt: 2 }}
              value={userInputs?.city}
              onChange={e => handleInputChange(e, "city")}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Typography variant="h5">
          What is their highest level of education completed?
        </Typography>

        <TextField
          label="Education Level"
          fullWidth
          sx={{ mt: 2 }}
          value={userInputs?.educationLevel || ""}
          onChange={e => handleInputChange(e, "educationLevel")}
          select
        >
          {PERSONA_EDUCATION_LEVELS.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5">What industry do they work in?</Typography>

        <TextField
          label="Industry"
          fullWidth
          select
          sx={{ mt: 2 }}
          value={userInputs?.industry || ""}
          onChange={e => handleInputChange(e, "industry")}
        >
          {PERSONA_INDUSTRY_TYPES.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h5">What is their job title?</Typography>
        <AutocompleteInput
          label={"Title"}
          handleInputChange={handleInputChange}
          inputKey={"jobTitle"}
          autoCompleteData={autoCompleteData}
          inputValue={userInputs?.jobTitle || ""}
          sx={{ mt: 2 }}
          //multiTags
        />
      </Box>
    </Box>
  );
}
