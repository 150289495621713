import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import SidebarTabs from "./tabs";
import { POWERUPKNOWLEDGELOGO } from "./../../../../constants/image";
import { themePalette } from "../../../../constants/Theme/colors";

const SidebarCard = props => (
  <Card
    sx={{
      boxShadow: "none",
      borderRadius: "28px",
      p: {
        xs: 2,
        md: 4,
      },
      width: 1,
      overflow: "hidden",
      boxSizing: "border-box", // Not certain why this bit is so important here.
      display: "flex",
      flexDirection: "column",
      borderBottomLeftRadius: props.flatBottom ? 0 : "28px",
      borderBottomRightRadius: props.flatBottom ? 0 : "28px",
      borderTopLeftRadius: props.flatTop ? 0 : "28px",
      borderTopRightRadius: props.flatTop ? 0 : "28px",
      borderTop: props.topBorder
        ? `2px solid ${themePalette.palette.secondary.light}`
        : "none",
    }}
  >
    {props.children}
  </Card>
);

const Sidebar = ({ ...props }) => {
  const [sbContent, setSBContent] = useState(props.sidebar_content);

  useEffect(() => {
    setSBContent(props.sidebar_content);
  }, [props.sidebar_content]);

  //// TODO Uncomment when pulling sidebar data from API
  //const [sbContent, setSBContent] = useState({});
  //const [currentStep, setCurrentStep] = useState("0");
  //useEffect(() => {
  //  const fetchData = async () => {
  //    let ret = await getSidebarContent("props.sidebar_id");
  //    setSBContent(ret);
  //  };
  //  fetchData();
  //}, []);

  return (
    <Box
      sx={{
        display: "flex",
        width: 1,
        overflow: "scroll",
        flexDirection: "column",
      }}
    >
      <SidebarCard flatBottom={props.hideContentsSection ? false : true}>
        {typeof sbContent != "undefined" &&
        Object.keys(sbContent).length > 0 ? (
          <Fragment>
            <Box
              sx={{
                "& > img": {
                  width: 1,
                  maxHeight: "30px",
                  height: 1,
                },
              }}
            >
              <img src={POWERUPKNOWLEDGELOGO} />
            </Box>
            <Divider sx={{ my: 2 }} />
            <SidebarTabs tabs={sbContent.tabs} />
          </Fragment>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 1,
              width: 1,
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        )}
      </SidebarCard>
      <Box hidden={props.hideHelpSection ? true : false}>
        <SidebarCard
          flatTop={props.hideHelpSection ? false : true}
          topBorder={props.hideHelpSection ? false : true}
        >
          <Typography variant="title_medium" sx={{ my: 1 }}>
            Need help?
          </Typography>
          <Typography variant="subhead2" sx={{ my: 1 }}>
            Your StartupOS Advisor is here to help if you would like help or
            feedback on this PowerUp.
          </Typography>
          <Typography variant="caption" sx={{ my: 1 }}>
            Please expect a response within 2 business hours.
          </Typography>
          <Grid container justifyContent={"flex-end"} sx={{ my: 1 }}>
            <Grid item>
              <Button>Ask for help</Button>
            </Grid>
          </Grid>
        </SidebarCard>
      </Box>
    </Box>
  );
};
export default Sidebar;
