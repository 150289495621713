import React from "react";
import PropTypes from "prop-types";
import { CHECKBOXICON, FILLEDCHECKBOXICON } from "../../constants/image";
import { ValidationBoxText, ValidationContainer } from "./styles";
import { Box } from "@mui/material";

const PasswordValidation = ({
  className,
  validationErrorArray,
  disabled,
  formType,
}) => {
  return (
    <ValidationContainer>
      <ValidationBoxText>Your password must contain:</ValidationBoxText>
      <Box sx={{ mt: 2 }} className="d-flex">
        <img
          alt="checkbox"
          src={validationErrorArray[0] ? CHECKBOXICON : FILLEDCHECKBOXICON}
        />{" "}
        <ValidationBoxText className="pl-10">
          At least 8 characters
        </ValidationBoxText>
      </Box>
      <Box sx={{ mt: 2 }} className="d-flex">
        <img
          alt="checkbox"
          src={validationErrorArray[1] ? CHECKBOXICON : FILLEDCHECKBOXICON}
        />
        <ValidationBoxText className="pl-10">
          Lower case letters (a-z)
        </ValidationBoxText>
      </Box>
      <Box sx={{ mt: 2 }} className="d-flex">
        <img
          alt="checkbox"
          src={validationErrorArray[2] ? CHECKBOXICON : FILLEDCHECKBOXICON}
        />
        <ValidationBoxText className="pl-10">
          {" "}
          Upper case letters (A-Z)
        </ValidationBoxText>
      </Box>
      <Box sx={{ mt: 2 }} className="d-flex">
        <img
          alt="checkbox"
          src={validationErrorArray[3] ? CHECKBOXICON : FILLEDCHECKBOXICON}
        />
        <ValidationBoxText className="pl-10">Number (0-9)</ValidationBoxText>
      </Box>
      <Box sx={{ mt: 2 }} className="d-flex">
        <img
          alt="checkbox"
          src={validationErrorArray[4] ? CHECKBOXICON : FILLEDCHECKBOXICON}
        />
        <ValidationBoxText className="pl-10">One special character</ValidationBoxText>
      </Box>
    </ValidationContainer>
  );
};

PasswordValidation.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
};

PasswordValidation.defaultProps = {
  className: undefined,
  type: "info",
};

export default PasswordValidation;
