import React from "react";
import { Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { CurrentStreakIcon } from "../../../common/icons";
import { WorkspaceCard } from "../../../components/Workspace/Card/styles";
import { StreakContainer } from "./styles";
import constants from "../../../constants";

const CurrentStreaks = ({ data }) => {
  return (
    <WorkspaceCard variant="outlined">
      <Box
        sx={{
          py: 3,
          px: 3,
        }}
      >
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          sx={{ pb: 5 }}
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box display="flex" alignItems={"center"}>
              <CurrentStreakIcon />
              <Typography variant="h5" sx={{ ml: 1 }}>
                Current Streaks
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container rowGap={3}>
          {constants.CURRENT_STREAKS.map((item, index) => {
            const streak = data?.find(
              ele => ele?.streakType?.streakName === item.type
            )?.daysMaintained;

            return (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box display={"flex"} justifyContent="space-between">
                  <Box display={"flex"} alignItems="center">
                    <Typography color="secondary">{item.text}</Typography>&nbsp;
                    <Typography color="secondary" variant="h5">
                      {item.boldText}
                    </Typography>
                  </Box>
                  <StreakContainer>
                    <Typography variant="h5"> {streak || 0}</Typography>
                  </StreakContainer>
                </Box>
                {index !== constants.CURRENT_STREAKS.length - 1 && (
                  <Divider
                    sx={{ mt: 1.5, background: "rgba(118, 73, 160, 0.08)" }}
                  />
                )}
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </WorkspaceCard>
  );
};

export default CurrentStreaks;
