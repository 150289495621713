import React, { Component } from "react";
import { Grid, Button, Box, Typography, Stack } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./logIn.css";
import TextInput from "../../common/input/Input";
import ROUTES_PATH from "../../constants/routes";
import { REGEX } from "../../constants/regex";
import { manualLogin } from "../../utils/auth0";
import { loginGoogle, loginLinkedIn } from "../../utils/auth0-spa";

import { toastContainer } from "../../utils/toast";
import { Google, LinkedIn } from "@mui/icons-material";
import { Separator } from "../../common/separator";
import { themePalette } from "../../constants/Theme/colors";
import LoadingButton from "../../common/Loadingbutton";
class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      email: "",
      password: "",
      rememberMe: false,
      emailError: false,
      loginLoading: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);

    const queryParams = new URLSearchParams(this.props.location.search);

    const success = queryParams.get("success");

    const message = queryParams.get("message");

    if (message) {
      if (success === "true") {
        toastContainer(message, "success");
      } else {
        toastContainer(message, "error");
      }
    }
  }
  ValidateEmail(value) {
    if (REGEX.EMAIL.test(value)) {
      return true;
    }
    return false;
  }
  handleEmailInputChange = event => {
    this.setState({
      email: event,
    });
    if (this.ValidateEmail(event)) {
      this.setState({ emailError: false });
    } else {
      this.setState({ emailError: true });
    }
  };
  handlePasswordInputChange = event => {
    this.setState({
      password: event,
    });
  };
  handleRememberMe = event => {
    this.setState({
      rememberMe: event.target.checked,
    });
  };
  handleBackClick() {
    this.props.history.push(ROUTES_PATH.SIGN_UP);
  }

  handleLogin = async () => {
    this.setState({ loginLoading: true });
    try {
      const { email, password } = this.state;

      //call the auth0 /authorize api
      await manualLogin({ email, password });
    } catch (err) {
      this.setState({ loginLoading: false });
      console.log(err);
      toastContainer(err?.error_description, "error");
    }
  };

  render() {
    let { email, password, rememberMe, emailError } = this.state;
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100%" }}
      >
        <Grid
          className="signup-grid-container"
          sx={{ mt: 9 }}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={10} md={6} lg={6} className="form-container">
            <div className="signup-title">Log In</div>

            <TextInput
              type="text"
              label="Email address"
              value={email}
              error={emailError ? "Please enter valid email address" : ""}
              onChange={e => this.handleEmailInputChange(e)}
            />
            <TextInput
              type="password"
              label="Password"
              value={password}
              onChange={e => this.handlePasswordInputChange(e)}
            />
            <Box sx={{ pt: 1.5 }} className="d-flex ">
              <FormControlLabel
                sx={{ fontSize: 14, mt: -1 }}
                control={
                  <Checkbox
                    sx={{
                      color: "#7C757F",
                      //"&.Mui-checked": {
                      //  color: "blue",
                      //},
                    }}
                    size="small"
                    checked={rememberMe}
                    onChange={checked => this.handleRememberMe(checked)}
                  />
                }
                label={<Typography variant="subtitle">Remember Me </Typography>}
              />
              <div className="signup-sub-text ml-auto ">
                <span
                  onClick={() => {
                    this.props.history.push(ROUTES_PATH.FORGOT_PASSWORD);
                  }}
                >
                  Forgot password?
                </span>
              </div>
            </Box>

            <Box textAlign={"center"}>
              <LoadingButton
                sx={{ mt: 3, width: 1, maxWidth: "100%" }}
                disabled={!email || emailError || !password}
                onClick={this.handleLogin}
                loading={this.state.loginLoading}
              >
                Log In
              </LoadingButton>
            </Box>

            <Box sx={{ my: 2 }}>
              <Separator
                color={`${themePalette.palette.secondary.light}`}
                content={
                  <Typography
                    sx={{ px: 2 }}
                    color={themePalette.palette.secondary.main}
                  >
                    OR
                  </Typography>
                }
              ></Separator>
            </Box>

            <Box sx={{ mt: 2 }} textAlign="center">
              <Button
                onClick={loginGoogle}
                sx={{
                  my: 1,
                  maxWidth: "100%",
                  backgroundColor: "#2d3748",
                }}
              >
                <Google />
                &nbsp; Continue with Google
              </Button>
            </Box>
            <Box sx={{ my: 2 }} textAlign="center">
              <Button
                onClick={loginLinkedIn}
                sx={{
                  my: 1,
                  maxWidth: "100%",
                  backgroundColor: "#2867b2",
                }}
              >
                <LinkedIn />
                &nbsp; Continue with LinkedIn
              </Button>
            </Box>
            <Box sx={{ pt: 2, pb: 4 }} className="signup-sub-text align-center">
              Don’t have an account?&nbsp;
              <span
                onClick={() => {
                  this.props.history.push(ROUTES_PATH.ONBOARDING_BASEPATH);
                }}
              >
                Join free today.
              </span>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    );
  }
}

export default LogIn;
