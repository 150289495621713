import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { PageTitleWrapper } from "./styles";

export default function PageTitle({ iconUrl, title, action }) {
  return (
    <PageTitleWrapper>
      <Grid container>
        <Grid item md={12} sm={8} xs={6}>
          <Box sx={{ ml: 3, display: "flex", alignItems: "center" }}>
            <img src={iconUrl} alt="icon" />
            <Typography variant="h3" sx={{ ml: 2 }}>
              {title}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          sm={4}
          xs={6}
          display={{ sm: "block", md: "none", lg: "none" }}
        >
          {action}
        </Grid>
      </Grid>
    </PageTitleWrapper>
  );
}
