import React, { useState } from "react";
import { WORKSPACE_RIGHT_SIDEBAR_ITEMS } from "../../../../constants/workspace-sidebar";
import {
  Box,
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { ItemIcon, RightSidebarWrapper } from "./styles";
import WorkspaceUser from "../../WorkspaceUser";
import NavItemsList from "../../../../common/NavItemsList/NavItemsList";

export default function RightSidebar({ onLogout, userData }) {
  const [active, setActive] = useState();

  return (
    <RightSidebarWrapper>
      <WorkspaceUser onLogout={onLogout} userData={userData} />
      <ClickAwayListener onClickAway={() => setActive(null)}>
        <List sx={{ position: "fixed" }} disablePadding>
          {WORKSPACE_RIGHT_SIDEBAR_ITEMS.map((listItem, index) => (
            <ListItem
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                cursor: "Pointer",
                px: 1,
              }}
              onClick={() => setActive(listItem.key)}
              disableRipple
              disableTouchRipple
              disablePadding
            >
              <ItemIcon selected={active === listItem.key}>
                <img
                  src={listItem.icon}
                  style={{
                    filter:
                      active === listItem.key
                        ? "brightness(0%)"
                        : "brightness(100%)",
                  }}
                />
              </ItemIcon>
              <ListItemText>
                <Typography variant="subtitle2">{listItem.label}</Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </ClickAwayListener>
    </RightSidebarWrapper>
  );
}
