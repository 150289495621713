import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";

import { ProgressDarkIcon } from "../../../common/icons";
import { WorkspaceCard } from "../../../components/Workspace/Card/styles";

import CustomizedProgressBars from "../../../common/progressBar";

import ProgressBar from "./CircularProgress";
import { ProgressInnerValue } from "./styles";

import { useHistory } from "react-router-dom";

import ROUTES_PATH from "../../../constants/routes";

const ProgressCard = ({ data, gradientId = "progressDesktop" }) => {
  const history = useHistory();

  const handleNext = () => {
    history.push(ROUTES_PATH.WORKSPACE);
  };

  return (
    <WorkspaceCard variant="outlined">
      <Box
        sx={{
          py: 3,
          px: 3,
        }}
      >
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          sx={[
            theme => ({
              [theme.breakpoints.down("md")]: {
                paddingBottom: 4,
              },
              [theme.breakpoints.up("md")]: {
                paddingBottom: 5,
              },
            }),
          ]}
        >
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex">
              <ProgressDarkIcon />
              <Typography variant="h5" sx={{ ml: 1 }}>
                Progress
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            display={{ sm: "none", xs: "none", md: "block", lg: "block" }}
          >
            <Typography
              variant="h5"
              color="primary"
              align="right"
              onClick={handleNext}
            >
              Go to Workspace
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            display="flex"
            justifyContent={{
              xs: "center",
              sm: "center",
              md: "flex-start",
              lg: "flex-start",
            }}
          >
            <Box>
              <ProgressBar
                value={
                  data.circleTotalUnits
                    ? Math.ceil(
                        (parseInt(data.circleTotalUnits) /
                          parseInt(data.unitDetails.circleTotalUnits)) *
                          100
                      )
                    : 0
                }
                gradientId={gradientId}
              >
                <ProgressInnerValue>
                  <Typography variant="h1" className="test">
                    {`${data.circleTotalUnits}%`}
                  </Typography>
                  <Typography variant="h4" className="test">
                    {"Total"}
                  </Typography>
                </ProgressInnerValue>
              </ProgressBar>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pt: 3, mb: "auto" }}>
            <Typography
              variant="h5"
              align="left"
              sx={[
                theme => ({
                  [theme.breakpoints.down("md")]: {
                    paddingBottom: 2,
                  },
                  [theme.breakpoints.up("md")]: {
                    paddingBottom: 3,
                  },
                }),
              ]}
            >
              Breakdown
            </Typography>

            <Box display="flex" justifyContent={"space-between"}>
              <Typography variant="h5" align="left">
                Power Ups
              </Typography>

              <Typography variant="h5" align="right">
                {data.totalPowerUpsCompleted}/{data.unitDetails.powerUpsUnits}
              </Typography>
            </Box>
            <CustomizedProgressBars value={data.circleTotalUnits} />
            <Box
              sx={{ textAlign: "end", display: { xs: "block", md: "none" } }}
            >
              <Button sx={{ mt: 6, width: "auto" }} onClick={handleNext}>
                Go to Workspace
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </WorkspaceCard>
  );
};

export default ProgressCard;
