import { REGEX } from "../../../../../constants/regex";
export const getExcerciseConfigurations = () => {
  return {
    form_key: "tamSamSom",
    form_title: "TAM/SAM/SOM",
    summary_path: "/workspace/tamsamsom/summary",
    excercises: [
      {
        step_id: 9,
        component: "TAM",
        path: "tam",
        key: "tam",
      },
      {
        step_id: 10,
        component: "SAM",
        path: "sam",
        key: "sam",
      },
      {
        step_id: 11,
        component: "SOM",
        path: "som",
        key: "som",
      },
      {
        step_id: 12,
        component: "MARKET_TEMPERATURE",
        path: "market-temperature",
        key: "marketTemperature",
      },
    ],
  };
};

export const tamInputValid = (key, input) => {
  switch (key) {
    case "acv":
      if (input.match(REGEX.ACV_INPUT)) {
        return true;
      } else {
        return false;
      }

    case "noOfPeople":
      if (input.match(REGEX.NUMBERS_ONLY) && input.length <= 6) {
        return true;
      } else {
        return false;
      }

    case "timesPerYear":
      if (input.match(REGEX.NUMBERS_ONLY) && input.length <= 6) {
        return true;
      } else {
        return false;
      }
  }
};

export const samInputValid = (key, input) => {
  switch (key) {
    case "focusOn":
      if (
        input.match(REGEX.LETTERS) &&
        input.length >= 4 &&
        input.length <= 30
      ) {
        return true;
      } else {
        return false;
      }

    case "furtherFocusOn":
      if (
        input.match(REGEX.LETTERS) &&
        input.length >= 4 &&
        input.length <= 30
      ) {
        return true;
      } else {
        return false;
      }

    case "basedIn":
      if (
        input.match(REGEX.LETTERS) &&
        input.length >= 4 &&
        input.length <= 30
      ) {
        return true;
      } else {
        return false;
      }

    case "percentageOfPopulation":
      if (input.match(REGEX.NUMBERS_ONLY) && input.length <= 3) {
        return true;
      } else {
        return false;
      }

    case "percentageOfSam":
      if (input.match(REGEX.NUMBERS_ONLY) && input.length <= 3) {
        return true;
      } else {
        return false;
      }
  }
};

export const marketingTemperatureInputValid = (key, input) => {
  switch (key) {
    case "largerMarket":
      if (
        input.match(REGEX.LETTERS) &&
        input.length >= 4 &&
        input.length <= 30
      ) {
        return true;
      } else {
        return false;
      }

    case "marketQuote":
      if (input.match(REGEX.QUOTE) && input.length >= 4 && input.length <= 80) {
        return true;
      } else {
        return false;
      }

    case "personQuoted":
      if (
        input.match(REGEX.LETTERS) &&
        input.length >= 4 &&
        input.length <= 30
      ) {
        return true;
      } else {
        return false;
      }

    case "largerMarketValue":
      if (
        input.match(REGEX.NUMBERS_ONLY) &&
        input.length >= 3 &&
        input.length <= 12
      ) {
        return true;
      } else {
        return false;
      }

    case "projectedGrowth":
      if (input.match(REGEX.NUMBERS_ONLY) && input.length <= 3) {
        return true;
      } else {
        return false;
      }
  }
};

export const somInputValid = (key, input) => {
  switch (key) {
    case "noFocusOn":
      if (
        input.match(REGEX.LETTERS) &&
        input.length >= 4 &&
        input.length <= 30
      ) {
        return true;
      } else {
        return false;
      }
    case "percentageOfSam":
      if (input.match(REGEX.NUMBERS_ONLY) && input.length <= 3) {
        return true;
      } else {
        return false;
      }
  }
};

export const buttonDisable = (userInputs, action) => {
  switch (action) {
    case "TAM":
      for (let key in userInputs) {
        if (!tamInputValid(key, userInputs[key])) {
          return true;
        }
      }
      return false;

    case "SAM":
      for (let key in userInputs) {
        if (!samInputValid(key, userInputs[key])) {
          return true;
        }
      }
      return false;

    case "SOM":
      for (let key in userInputs) {
        if (!somInputValid(key, userInputs[key])) {
          return true;
        }
      }
      return false;
    case "MARKET_TEMPERATURE":
      for (let key in userInputs) {
        if (!marketingTemperatureInputValid(key, userInputs[key])) {
          return true;
        }
      }
      return false;

    default:
      return true;
  }
};

export const getDefaultInputStates = component => {
  switch (component) {
    case "TAM":
      return {
        acv: "",
        noOfPeople: "",
        timesPerYear: "",
      };

    case "SAM":
      return {
        focusOn: "",
        percentageOfPopulation: "",
        furtherFocusOn: "",
        basedIn: "",
        percentageOfSam: "",
      };

    case "SOM":
      return {
        noFocusOn: "",
        percentageOfSam: "",
      };

    case "MARKET_TEMPERATURE":
      return {
        largerMarket: "",
        largerMarketValue: "",
        marketQuote: "",
        personQuoted: "",
        projectedGrowth: "",
      };

    default:
      return true;
  }
};

export const getExcerciseCompletionStatus = currentExcercise => {
  return true;
};

export const getTAMSAMSOMValues = powerUpExcercises => {
  const tamValues = powerUpExcercises.find(
    powerUpExcercise => powerUpExcercise.stepNo === 1
  )?.answers;
  const samValues = powerUpExcercises.find(
    powerUpExcercise => powerUpExcercise.stepNo === 2
  )?.answers;
  const somValues = powerUpExcercises.find(
    powerUpExcercise => powerUpExcercise.stepNo === 3
  )?.answers;

  let calculatedTam;
  let calculatedSam;
  let calculatedSom;

  if (tamValues) {
    const tamAcv = parseInt(tamValues?.acv);
    const tamNoOfPeople = parseInt(tamValues?.noOfPeople);
    const tamTimesPerYear = parseInt(tamValues?.timesPerYear);

    calculatedTam = tamAcv * tamNoOfPeople * tamTimesPerYear;
  }

  if (samValues) {
    const tamValue = parseInt(calculatedTam);
    const samPercentageOfPopulation = parseInt(
      samValues?.percentageOfPopulation
    );
    const samPercentageOfSam = parseInt(samValues?.percentageOfSam);

    if (tamValue > 0) {
      calculatedSam =
        tamValue *
        (samPercentageOfPopulation / 100) *
        (samPercentageOfSam / 100);
    }
  }

  if (somValues) {
    const samValue = parseInt(calculatedSam);
    const somPercentageOfSam = parseInt(somValues?.percentageOfSam);

    calculatedSom = samValue * (somPercentageOfSam / 100);
  }

  return {
    tam: isNaN(calculatedTam) ? 0 : calculatedTam,
    sam: isNaN(calculatedSam) ? 0 : calculatedSam,
    som: isNaN(calculatedSom) ? 0 : calculatedSom,
  };
};
