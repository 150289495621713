import { Typography, Box, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import theme from "../../../../constants/Theme";

export default function FrameYourStatement({
  userInputs,
  setUserInputs,
  attributes,
}) {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleInputChange = (e, type) => {
    let idx = userInputs.findIndex(itm => itm.key === type);
    let inputData = [...userInputs];

    if (idx >= 0) {
      let currentObj = inputData[idx];
      currentObj = { ...currentObj, value: e.target.value };
      inputData[idx] = currentObj;
    } else {
      inputData.push({ key: type, value: e.target.value });
    }
    setUserInputs(inputData);
  };
  const getInputValue = item => {
    let userInputData = userInputs.find(itm => itm.key === item.key);
    if (userInputData) {
      return userInputData.value;
    } else {
      return "";
    }
  };
  const responsiveLabelBackground = {
    [theme.breakpoints.down("md")]: {
      "& .MuiFormLabel-root": {
        background: theme.palette.secondary.backgroundGradient,
      },
    },
    [theme.breakpoints.up("md")]: {
      "& .MuiFormLabel-root": {
        backgroundColor: "secondary.white",
      },
    },
    width: 1,
    ml: 2,
  };

  return (
    <Box sx={{ mt: 6 }} flexGrow={1} display="flex" flexDirection={"column"}>
      {attributes.map(item => {
        return (
          <Box alignItems={"center"} sx={{ mt: 2, display: "flex" }}>
            <Typography variant="h4" sx={{ whiteSpace: "nowrap" }}>
              {item.label}
            </Typography>
            <TextField
              sx={responsiveLabelBackground}
              label={item.placeholder}
              value={getInputValue(item)}
              onChange={e => handleInputChange(e, item.key)}
            ></TextField>
          </Box>
        );
      })}
    </Box>
  );
}
