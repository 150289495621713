import { createTheme } from "@mui/material/styles";
import { sosPrimary, themePalette } from "./colors";

export const themeInputs = createTheme({
  components: {
    //
    // Using !important tags to override the typogtaphy theme styles.
    // These elements want to use body1, but the prototype calls for these values.
    //
    MuiInputBase: {
      styleOverrides: {
        //input: {
        //  fontFamily:
        //    '"RobotoRegular", "Helvetica", "Arial", sans-serif !important',
        //  fontSize: "16px !important",
        //},
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          //fontFamily:
          //  '"PoppinsMedium", "Helvetica", "Arial"," sans-serif" !important',
          //fontSize: "16px !important",
          zIndex: 0,
        },
      },
    },

    MuiOutlinedInput: {
      defaultProps: {
        color: "primary",
      },
      styleOverrides: {
        root: {
          borderRadius: "12px",
        },
      },
      //variants: [
      //  {
      //    props: { color: "primary", error: false, disabled: false },
      //    style: {
      //      "&:hover, &:focus": {
      //        ".MuiOutlinedInput-notchedOutline": {
      //          border: `2px solid ${themePalette.palette.primary.main}`,
      //        },
      //      },
      //    },
      //  },
      //],
    },

    MuiFilledInput: {
      defaultProps: {
        color: "primary",
      },
      variants: [
        {
          props: { color: "primary", error: false, disabled: false },
          style: {
            backgroundColor: sosPrimary[50],
            "&:hover": {
              backgroundColor: sosPrimary[200],
            },
            "&:focus-within": {
              backgroundColor: sosPrimary[50],
            },
          },
        },
      ],
    },

    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        color: "primary",
      },

      //variants: [
      //  {
      //    props: { error: true },
      //    style: {
      //      "& .MuiInputAdornment-root": {
      //        color: themePalette.palette.error.main,
      //      },
      //    },
      //  },
      //],
    },
  },
});
