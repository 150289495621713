import styled from "@emotion/styled";
import { IconButton } from "@mui/material";

export const IconComponent = styled(props => <IconButton {...props} />)(
  ({ theme, selected }) => {
    return {
      width: "72px",
      height: "72px",
      borderColor: theme.palette.secondary.dark,
      borderWidth: "1px",
      borderStyle: "solid",
      "&:hover": {
        borderColor: theme.palette.primary.main,
      },

      background: selected
        ? theme.palette.primary.gradient
        : theme.palette.secondary.white,

      cursor: "pointer",
    };
  }
);
