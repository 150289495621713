import styled from "@emotion/styled";

export const HeaderImageWrapper = styled.div(({ theme, image }) => {
  return {
    background: `url(${image}) no-repeat`,
    height: "50px",
    width: "200px",
    display: "none",
    justifyContent: "flex-start !important",
    alignItems: "center",
    marginTop: "25px",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  };
});

export const BackButtonWrapper = styled.div(({ theme }) => {
  return {
    margin: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0),
    },
  };
});
