import { Button, TextField, Box, Typography } from "@mui/material";
import React, { useState } from "react";
import DialogComponent from "./Dialog";
import { Add } from "@mui/icons-material";
import {
  addCandidatesButtonDisable,
  addExternalCandidatesValid,
} from "../Functions/functions";
import messages from "../../../../../constants/messages";
export default function AddExternalCandidate({ open, onClose, addCandidate }) {
  const [userInputs, setUserInputs] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const ActionComponent = () => {
    return (
      <Box sx={{ px: 3, py: 1 }}>
        <Button
          startIcon={<Add sx={{ fontSize: 16 }} />}
          size="sm"
          disabled={addCandidatesButtonDisable(userInputs)}
          onClick={() => addCandidate(userInputs)}
        >
          Add
        </Button>
      </Box>
    );
  };

  const handleInputChange = (e, key) => {
    const value = e.target.value;
    const currentInput = { ...userInputs };

    currentInput[key] = value;

    setUserInputs(currentInput);
  };

  const handleBlur = (e, key) => {
    switch (key) {
      case "firstName":
        if (addExternalCandidatesValid("firstName", e.target.value)) {
          setErrorMessages({ ...errorMessages, firstName: "" });
        } else {
          setErrorMessages({
            ...errorMessages,
            firstName: messages.NAME_VALID,
          });
        }
        break;

      case "lastName":
        if (addExternalCandidatesValid("lastName", e.target.value)) {
          setErrorMessages({ ...errorMessages, lastName: "" });
        } else {
          setErrorMessages({ ...errorMessages, lastName: messages.NAME_VALID });
        }
        break;

      case "email":
        if (addExternalCandidatesValid("email", e.target.value)) {
          setErrorMessages({ ...errorMessages, email: "" });
        } else {
          setErrorMessages({ ...errorMessages, email: messages.INVALID_EMAIL });
        }
        break;
    }
  };
  return (
    <DialogComponent
      title={"Add External Candidate"}
      titleVariant={"title_large"}
      isOpen={open}
      onClose={onClose}
      dialogAction={<ActionComponent />}
      PaperProps={{
        style: { borderRadius: 30 },
      }}
    >
      <Box sx={{ my: 2, p: 2 }}>
        <Typography variant="title_small">Name</Typography>
        <Box sx={{ mt: 2 }}>
          <TextField
            label="First Name"
            value={userInputs.firstName}
            fullWidth
            onChange={e => handleInputChange(e, "firstName")}
            error={!!errorMessages.firstName}
            helperText={errorMessages.firstName}
            onBlur={e => handleBlur(e, "firstName")}
          />
          <TextField
            label="Last Name"
            sx={{ mt: 2 }}
            value={userInputs.lastName}
            fullWidth
            onChange={e => handleInputChange(e, "lastName")}
            error={!!errorMessages.lastName}
            helperText={errorMessages.lastName}
            onBlur={e => handleBlur(e, "lastName")}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography variant="title_small">Email</Typography>
          <Box sx={{ mt: 2 }}>
            <TextField
              label="Email Address"
              value={userInputs.email}
              fullWidth
              onChange={e => handleInputChange(e, "email")}
              error={!!errorMessages.email}
              helperText={errorMessages.email}
            />
          </Box>
        </Box>
      </Box>
    </DialogComponent>
  );
}
