import React, { Fragment, useEffect, useState, useCallback } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  useMediaQuery,
  Button,
  Divider,
} from "@mui/material";
import PowerUPLayout from "../../../../layout/PowerUP";
import Sidebar from "../../../../components/Workspace/PowerUp/Sidebar";
import BreadcrumbsComp from "./Components/BreadcrumbsComp";
import {
  getDefaultScriptData,
  getNotesScriptData,
  getPowerUPData,
  getSidebarContent,
} from "./Functions/common";
import { INTERVIEWING_ICON, NOTES_ICON } from "../../../../constants/image";
import constants from "../../../../constants";
import Tabs from "../../../../components/Workspace/Tabs";
import CardWrapper from "./Components/CardWrapper";
import theme from "../../../../constants/Theme";
import { useHistory } from "react-router-dom";

import JsxParser from "react-jsx-parser";
import SelectInput from "../../../../common/SelectInput";
import CustomizedDatePicker from "../../../../components/Workspace/PowerUp/Datepicker";
import { DateTextField } from "./styles";
import InterviewScript from "./Components/InterviewScript";
import { useSelector } from "react-redux";
import services from "../../../../services";
import { useDispatch } from "react-redux";
import * as actions from "../../../../modules/actions";

const InterviewingYourCandidate = () => {
  const [powerupDetails, setPowerupDetails] = useState("");
  const [powerupDetailsLoader, setPowerupDetailsLoader] = useState(false);
  const [SIDEBARDATA, setSidebarData] = useState({});
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const tabItems = constants.EXCERCISE_TAB_ITEMS;
  const [currentTab, setCurrentTab] = useState(tabItems[0].key);

  const history = useHistory();

  const powerUpId = "4";

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setPowerupDetailsLoader(true);
    getPowerUPData(powerUpId).then(res => {
      setPowerupDetails(res);
    });
    getSidebarContent(powerUpId).then(res => {
      setSidebarData(res);
    });
    setPowerupDetailsLoader(false);
  }, []);

  const FooterComponent = () => {
    return (
      <Box display={"flex"} justifyContent={"center"}>
        <Button
          onClick={() => history.push("/workspace/powerup/4")}
          sx={{ m: 2 }}
        >
          Return to PowerUp Details
        </Button>
      </Box>
    );
  };

  const TabsComponent = () => {
    return (
      <Tabs
        tabItems={tabItems}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        color="primary"
        spacing={0}
        gridColumn={{
          lg: "none",
          md: "none",
          sm: "span 6",
          xs: "span 6",
        }}
        width={1}
      />
    );
  };

  const StatusAndDateComponent = ({
    interviewStatus,
    scheduledDate,
    handleSelectChange,
    handleDateChange,
  }) => {
    return (
      <Box>
        <SelectInput
          label="Interview Status"
          fullWidth
          wrapperStyle={{ my: 2 }}
          onInputChange={handleSelectChange}
          selectedValue={interviewStatus}
          inputKey="interview_status"
          options={constants.INTERVIEW_STATUS}
        />

        {interviewStatus && (
          <Box sx={{ mb: 2 }}>
            <CustomizedDatePicker
              value={
                !scheduledDate
                  ? interviewStatus === "Completed"
                    ? new Date()
                    : scheduledDate
                  : scheduledDate
              }
              label={` ${
                interviewStatus === "Completed" ? "Completed" : "Scheduled"
              } Date`}
              onChange={e => handleDateChange(e)}
              Component={params => (
                <DateTextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  value={scheduledDate}
                  sx={{
                    width: 1,
                  }}
                />
              )}
            />
          </Box>
        )}
      </Box>
    );
  };

  const EditorComponent = ({
    interviewScript,
    notesData,
    getChildData,
    getChildEventData,
  }) => {
    return (
      <Box>
        <InterviewScript
          scriptConfigData={interviewScript}
          readOnly={true}
          hideRestoreOption={true}
          containerStyle={{ pb: 0 }}
        />
        <Divider
          sx={{
            background: `${theme.palette.secondary.outlineXLight}`,
          }}
        />
        <Box sx={{ mt: 2 }}>
          <Typography variant="largeTitle">Notes</Typography>
          <InterviewScript
            hideRestoreOption={true}
            scriptConfigData={notesData}
            containerStyle={{ py: 0 }}
            customSaveButton={true}
            getChildData={getChildData}
            getChildEventData={getChildEventData}
          />
        </Box>
      </Box>
    );
  };

  const MainContent = () => {
    const [interviewScript, setInterviewScript] = useState(
      getDefaultScriptData({})
    );
    const [notesData, setNotesData] = useState(getNotesScriptData({}));
    const [currentModule, setCurrentModule] = useState();
    const [candidateDetails, setCandidateDetails] = useState();
    const [getChildScriptData, setChildScriptData] = useState(false);
    const [interviewStatus, setInterviewStatus] = useState("");
    const [scheduledDate, setScheduledDate] = useState(null);

    const powerupModuleDetails = useSelector(
      store => store?.powerUp?.powerupDetails
    );

    const interviewCandidates = useSelector(
      store => store?.powerUp?.interviewCandidates
    );

    const dispatch = useDispatch();

    const candidateEmail = history.location.state.email;

    useEffect(() => {
      const fetchedPowerupScriptDetails =
        powerupModuleDetails?.data?.moduleStepResponses?.find(
          moduleStep => moduleStep?.isLastStep === true
        );

      if (fetchedPowerupScriptDetails) {
        setCurrentModule({
          ...fetchedPowerupScriptDetails,
          responseId: powerupModuleDetails?.data?.id,
        });
        if (fetchedPowerupScriptDetails?.answers) {
          setInterviewScript(
            JSON.parse(fetchedPowerupScriptDetails?.answers)?.interviewScript
          );

          const candidates = JSON.parse(
            fetchedPowerupScriptDetails?.answers
          )?.candidateRecords;

          const currentNotesScriptData = candidates?.find(
            candidateData => candidateData?.email === candidateEmail
          );

          currentNotesScriptData?.interviewStatus &&
            setInterviewStatus(currentNotesScriptData?.interviewStatus);

          currentNotesScriptData?.scheduledDate &&
            setScheduledDate(currentNotesScriptData?.scheduledDate);

          currentNotesScriptData?.records &&
            setNotesData(currentNotesScriptData?.records);
        }
      }
    }, [powerupModuleDetails]);

    useEffect(() => {
      const candidate = interviewCandidates?.find(
        candidate => candidate.email === candidateEmail
      );

      setCandidateDetails(candidate);
    }, [candidateEmail]);

    const handleSaveAction = updatedScriptData => {
      const currentModuleAnswers = JSON.parse(currentModule?.answers);

      const currentModuleCandidates = currentModuleAnswers?.candidateRecords;

      const idx = currentModuleCandidates?.findIndex(
        candidate => candidate?.email === candidateDetails?.email
      );

      const currentCandidatePayload = {
        ...candidateDetails,
        interviewStatus,
        scheduledDate,
        records: updatedScriptData,
      };

      let updatedCandidateDetails;

      if (idx !== -1 && currentModuleCandidates) {
        updatedCandidateDetails = [...currentModuleCandidates];
        updatedCandidateDetails[idx] = {
          ...currentCandidatePayload,
        };
      } else {
        updatedCandidateDetails = currentModuleCandidates
          ? [...currentModuleCandidates, currentCandidatePayload]
          : [currentCandidatePayload];
      }

      const answers = {
        ...currentModuleAnswers,
        candidateRecords: updatedCandidateDetails,
      };

      const data = {
        moduleResponseId: currentModule?.responseId,
        stepNo: 2,
        answers: JSON.stringify(answers || currentModuleAnswers),
        isLastStep: true,
        isCompleted: true,
        moduleStepId: currentModule?.moduleStep?.id,
      };

      updateModuleStepResponseApiCall(currentModule.responseId, data);
    };

    const updateModuleStepResponseApiCall = (moduleId, data, cb = () => {}) => {
      services
        .storeModuleStepResponses(moduleId, data)
        .then(result => {
          dispatch(
            actions.setPowerupDetails({
              id: "build-script",
              data: result?.data?.data,
            })
          );
          cb();
        })
        .catch(error => console.log(error));
    };

    const getChildData = useCallback(
      async data => {
        if (currentModule) {
          handleSaveAction(data);
        }
      },
      [getChildScriptData]
    );

    const handleSelectChange = event => {
      const value = event.target.value;
      setInterviewStatus(value);
    };

    const handleDateChange = value => {
      setScheduledDate(value);
    };

    const FooterComponent = () => {
      return (
        <>
          <Box
            justifyContent={"center"}
            sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
          >
            <Button
              onClick={() => history.push("/workspace/powerup/4")}
              sx={{ m: 2 }}
            >
              Return to PowerUp Details
            </Button>
          </Box>
          <Divider
            sx={{
              background: `${theme.palette.secondary.outlineXLight}`,
              mt: 3,
              display: { xs: "none", sm: "none", md: "block" },
            }}
          />
          <Box
            justifyContent={"right"}
            sx={{ display: { xs: "none", sm: "none", md: "flex" }, mt: 3 }}
          >
            <Box
              sx={{
                mr: 1,
              }}
            >
              <Button
                variant="outlined"
                onClick={() => history.push("/workspace/powerup/4")}
              >
                Return to PowerUp Details
              </Button>
            </Box>
            <Box>
              <Button onClick={() => setChildScriptData(!getChildScriptData)}>
                Save Changes
              </Button>
            </Box>
          </Box>
        </>
      );
    };

    return (
      <Box>
        <Box display={"flex"} alignItems={"center"} sx={{ mt: 3 }}>
          <img src={INTERVIEWING_ICON} />
          <Typography variant="title_medium" sx={{ ml: 1 }}>
            Interviewing Your Candidate
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Typography variant="subhead1">
            <JsxParser
              components={[Typography]}
              jsx={
                "Scan the participant profile before starting the call, then take a moment to <b>relax</b>. Remember, you’re not here to sell your idea...you’re on a search for <b>truth.</b>"
              }
            />
          </Typography>
        </Box>

        {isSmallScreen && (
          <Box sx={{ mt: 2 }}>
            <TabsComponent />
          </Box>
        )}
        <Box display={{ xs: "block", lg: "none" }}>
          <StatusAndDateComponent
            interviewStatus={interviewStatus}
            scheduledDate={scheduledDate}
            handleSelectChange={handleSelectChange}
            handleDateChange={handleDateChange}
          />
        </Box>
        <Box
          sx={theme => ({
            [theme.breakpoints.up("md")]: {
              p: 3,
              mt: 3,
            },
            [theme.breakpoints.down("md")]: {
              background: theme.palette.text.background,
              p: 2,
            },
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: "16px",
          })}
        >
          <Box display={{ xs: "none", lg: "block" }}>
            <StatusAndDateComponent
              interviewStatus={interviewStatus}
              scheduledDate={scheduledDate}
              handleSelectChange={handleSelectChange}
              handleDateChange={handleDateChange}
            />
          </Box>

          <Box
            sx={theme => ({
              [theme.breakpoints.up("md")]: {
                border: `1px solid ${theme.palette.secondary.dark}`,
                borderRadius: "28px",
                p: 3,
                background:
                  "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC;",
              },
            })}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box component="img" src={candidateDetails?.avatar}></Box>
              <Box>
                <Box sx={{ ml: 2 }}>
                  <Typography
                    variant="title_large"
                    sx={{ color: "secondary.black" }}
                  >
                    {candidateDetails?.name}
                  </Typography>
                </Box>
                <Box sx={{ ml: 2 }}>
                  <Typography
                    variant="lable_large"
                    sx={{ color: "secondary.black" }}
                  >
                    {candidateDetails?.email}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider
            sx={{
              background: `${theme.palette.secondary.outlineXLight}`,
              mt: 3,
            }}
          />
          <EditorComponent
            interviewScript={interviewScript}
            getChildData={getChildData}
            getChildEventData={getChildScriptData}
            notesData={notesData}
          />
        </Box>
        <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
          <FooterComponent />
        </Box>
      </Box>
    );
  };

  return (
    <PowerUPLayout
      main={
        <Fragment>
          {powerupDetailsLoader ? (
            <Box justifyContent={"center"} sx={{ display: "flex", mt: 5 }}>
              <CircularProgress />
            </Box>
          ) : (
            <CardWrapper heading={"IDEA VALIDATION INTERVIEW"}>
              <MainContent />
            </CardWrapper>
          )}
        </Fragment>
      }
      sidebar={
        isSmallScreen ? (
          <Box display={"flex"} flexDirection={"column"}>
            <Box sx={{ my: 3 }}>
              <TabsComponent />
            </Box>
            <Sidebar sidebar_content={SIDEBARDATA} />
          </Box>
        ) : (
          <Sidebar sidebar_content={SIDEBARDATA} />
        )
      }
      breadcrumbs={<BreadcrumbsComp powerupDetails={powerupDetails} />}
      tab={currentTab}
      footerComponent={<FooterComponent />}
    />
  );
};

export default InterviewingYourCandidate;
