import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField, Box } from "@mui/material";
import { EYEICON, CLOSEDEYEICON } from "../../constants/image";
import { EyeIcon } from "./styles";

const TextInput = ({
  value,
  type,
  placeholder,
  onChange,
  className,
  label,
  error,
  disabled,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box>
      <TextField
        type={type === "password" ? (showPassword ? "text" : type) : type}
        sx={{
          width: "100%",
          mt: 3,
        }}
        error={error ? true : false}
        placeholder={placeholder}
        value={value}
        onChange={e => onChange(e.target.value)}
        disabled={disabled}
        label={label}
      ></TextField>

      {type === "password" && (
        <EyeIcon>
          {" "}
          <img
            alt="eye-icon"
            src={showPassword ? EYEICON : CLOSEDEYEICON}
            onClick={() => setShowPassword(prevState => !prevState)}
          />
        </EyeIcon>
      )}
      {error && (
        <span className="error-text" id={error}>
          {error}
        </span>
      )}
    </Box>
  );
};

//TODO - add prop-types to all components
TextInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

TextInput.defaultProps = {
  value: "",
  onChange: undefined,
  type: "text",
  placeholder: "",
  className: "",
  required: false,
  disabled: false,
};
export default TextInput;
