import React from "react";
import { Grid, Typography } from "@mui/material";
import { ROADRUNNERLOGOLARGE } from "../../../constants/image";
import { WelcomeSidebarContentWrapper } from "./styles";
import { Box } from "@mui/system";

export default function WelcomeSidebarComponent() {
  return (
    <WelcomeSidebarContentWrapper>
      <Box sx={{ textAlign: "center" }}>
        <img src={ROADRUNNERLOGOLARGE} draggable={false} alt="logo" />
        <h3>Welcome aboard!</h3>
        <Typography align="center" variant="h6">
          We’ll ask you some questions to personalize your experience in the
          StartupOS platform. That way, we can meet you where you are, so we can
          help accelerate your journey.
        </Typography>
        <Typography align="center" sx={{ mt: 1 }} variant="h6">
          Beep beep!
        </Typography>
      </Box>
    </WelcomeSidebarContentWrapper>
  );
}
