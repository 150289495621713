import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Card,
  Divider,
  Typography,
  CircularProgress,
} from "@mui/material";
import theme from "../../../../constants/Theme";
import { PowerUpAvatar } from "../Accordion/styles";
import FormSectionHeader from "../FormSectionHeader";
import Tabs from "../../Tabs";
import messages from "../../../../constants/messages";
import constants, { GREEN_CHECK_ICON, FLAG_ICON } from "../../../../constants";
import WorkspaceStepper from "../../Stepper";

export default function ExcerciseSection({
  currentExcercise,
  excerciseConfigurations,
  currentTab,
  setCurrentTab,
  children,
  NextButton,
  BackButton,
  excerciseSteps,
  showSummary,
  powerUpExcercises,
  handleStepperStepChange,
  version,
  isSummaryExists = true,
  FooterComponent = () => {},
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [updatedExcerciseSteps, setUpdatedExcerciseSteps] = useState([]);

  const getSerialNumber = number => {
    if (number > 9) {
      return number;
    } else {
      return `0${number}`;
    }
  };

  const completedStepIcon = () => {
    return (
      <PowerUpAvatar active>
        <img src={GREEN_CHECK_ICON} />
      </PowerUpAvatar>
    );
  };

  const activeStepIcon = ({ number }) => {
    return (
      <PowerUpAvatar active>
        <Typography variant="on_surface" sx={{ color: "text.dark" }}>
          {getSerialNumber(number)}
        </Typography>
      </PowerUpAvatar>
    );
  };

  const defaultStepIcon = ({ number }) => {
    return (
      <PowerUpAvatar>
        <Typography variant="on_surface" sx={{ color: "text.inactive" }}>
          {getSerialNumber(number)}
        </Typography>
      </PowerUpAvatar>
    );
  };

  const lastStepIcon = () => {
    return <img src={FLAG_ICON} height={"32px"} width={"32px"} />;
  };

  useEffect(() => {
    if (excerciseSteps) {
      if (isSummaryExists) {
        setUpdatedExcerciseSteps([...excerciseSteps, { isLast: true }]);
      } else {
        setUpdatedExcerciseSteps([...excerciseSteps]);
      }
    }
  }, [excerciseSteps]);

  useEffect(() => {
    if (currentExcercise && excerciseSteps) {
      const excerciseStepIndex = excerciseSteps.findIndex(
        step => currentExcercise.stepNo === step.stepNo
      );
      setActiveStep(excerciseStepIndex);
    }
  }, [currentExcercise]);

  useEffect(() => {
    handleStepperStepChange(excerciseSteps[activeStep]?.id);
  }, [activeStep]);

  const tabItems = constants.EXCERCISE_TAB_ITEMS;
  return (
    <Grid
      item
      md={8}
      sm={12}
      xs={12}
      sx={theme => ({
        [theme.breakpoints.up("md")]: {
          borderRight: `1px solid ${theme.palette.secondary.main}`,
          pr: 3,
          mt: 4,
        },
        [theme.breakpoints.down("md")]: {
          p: 2,
        },
        display: "flex",
        flexDirection: "column",
        minHeight: "100%",
      })}
    >
      <Card
        sx={{
          borderRadius: "28px",
          border: `1px solid ${theme.palette.secondary.dark}`,
          p: 3,
          display: "flex",
          flexDirection: "column",
          [theme.breakpoints.down("md")]: {
            background: "transparent",
            border: "none",
            boxShadow: "0",
            p: 1,
            mt: 2,
          },
        }}
      >
        <FormSectionHeader
          heading={excerciseConfigurations.form_title.toUpperCase()}
        />
        <Divider />

        <Box
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            mt: 3,
          }}
        >
          <Tabs
            tabItems={tabItems}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            color="primary"
            spacing={0}
            buttonWidth="50%"
            width="100%"
          />
        </Box>

        {currentExcercise && !showSummary ? (
          <>
            <Box
              sx={{ mt: 3, display: { sm: "none", xs: "none", md: "block" } }}
            >
              <WorkspaceStepper
                steps={updatedExcerciseSteps}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                ActiveIcon={activeStepIcon}
                CompletedIcon={completedStepIcon}
                DefaultIcon={defaultStepIcon}
                type="POWERUP"
                fixedWidth={1}
                linear={true}
                nonLinear={false}
                LastStepIcon={lastStepIcon}
                powerUpExcercises={powerUpExcercises}
                isSummaryExists={isSummaryExists}
              />
            </Box>

            {version && (
              <Box
                sx={{ mt: 3, display: { sm: "none", xs: "none", md: "block" } }}
              >
                <Divider />
                <Box sx={{ mt: 3 }}>
                  <Typography variant="title_small">
                    VERSION:{version}
                  </Typography>
                </Box>
              </Box>
            )}

            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                display: {
                  sm: currentTab === "excercise" ? "block" : "none",
                  xs: currentTab === "excercise" ? "block" : "none",
                  md: "flex",
                },
              }}
            >
              <Box
                alignItems={"center"}
                sx={{
                  display: {
                    sm: "block",
                    md: "none",
                    lg: "none",
                    xl: "none",
                  },
                  mt: 3,
                }}
              >
                <PowerUpAvatar sx={{ mb: 2 }}>
                  <Typography variant="title_medium">
                    {getSerialNumber(currentExcercise.stepNo)}
                  </Typography>
                </PowerUpAvatar>
                <Typography variant="title_large">
                  {currentExcercise.title}
                </Typography>
              </Box>

              <Box sx={{ mt: 3 }}>
                <Typography variant={"subhead1"}>
                  {currentExcercise.description}
                </Typography>
              </Box>

              <Box
                sx={{ mt: 3 }}
                flexGrow={1}
                display="flex"
                flexDirection={"column"}
              >
                {children}
              </Box>

              <Divider
                sx={{ my: 3, display: { sm: "none", xs: "none", md: "block" } }}
              />

              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "block" },
                }}
              >
                {isSummaryExists ? (
                  <Grid container>
                    <Grid
                      item
                      md={8}
                      display={{
                        xs: "none",
                        sm: "none",
                        md: "block",
                      }}
                    ></Grid>
                    <Grid item md={4} sm={12} xs={12}>
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        letterSpacing={2}
                      >
                        <BackButton />
                        <NextButton />
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <FooterComponent />
                )}
              </Box>
            </Box>
          </>
        ) : showSummary && !currentExcercise ? (
          <Typography>Summary</Typography>
        ) : (
          <Box
            display={"flex"}
            justifyContent="center"
            alignItems={"center"}
            flexGrow={1}
          >
            <CircularProgress />
          </Box>
        )}
      </Card>
    </Grid>
  );
}
