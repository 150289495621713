import { Card } from "@mui/material";
import { styled, createTheme } from "@mui/material/styles";
import theme from "../../../constants/Theme";

export const cardTheme = createTheme(theme, {
  components: {
    WorkspaceCard: {
      styleOverrides: {
        block: {
          display: "block",
        },
        border: "none",
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            border: `1px solid #7C757F !important`,
          },
        },
      ],
    },
  },
});

export const WorkspaceCard = styled(Card, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop =>
    prop !== "disabled" &&
    prop !== "alignment" &&
    prop !== "variant" &&
    prop !== "selected" /* && prop !== "customProp"  */,
  name: "WorkspaceCard",
  overridesResolver: (props, styles) => [
    props.disabled && styles.disabled,
    props.alignment == "centered" && styles.centered,
  ],
})(({ theme }) => ({
  // Applies to All WorkspaceCard Components
  borderRadius: "28px",
  transition: "all .2s",
  cursor: "pointer",
  boxShadow: theme.shadows[0],
  height: "100%",
}));
