import {
  Typography,
  Box,
  Divider,
  Grid,
  Stack,
  Card,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

import theme from "../../../../../../../constants/Theme";
import WorkspaceBreadCrumbs from "../../../../../../../components/Workspace/BreadCrumbs";
import FormSectionHeader from "../../../../../../../components/Workspace/PowerUp/FormSectionHeader";
import SummaryStepList from "../../../../../../../components/Workspace/PowerUp/SummaryStepList";
import constants from "../../../../../../../constants";
import { GreenTicklIcon, PencilIcon } from "../../../../../../../common/icons";

import TAMSAMSOMGraph from "../Graph";

import Tabs from "../../../../../../../components/Workspace/Tabs";
import KnowledgeSidebar from "../../../../../../../components/Workspace/PowerUp/KnowledgeSidebar";
import * as actions from "../../../../../../../modules/actions";
import RatingCard from "../../../../../../../components/Workspace/PowerUp/Rating";
import { getTAMSAMSOMValues } from "../../functions";

function StorytellerSummary({ powerUpExcercises, setPowerUpExcercises }) {
  const tabItems = constants.SUMMARY_TAB_ITEMS;
  const history = useHistory();
  const [calculatedTam, setCalculatedTam] = useState(0);
  const [calculatedSam, setCalculatedSam] = useState(0);
  const [calculatedSom, setCalculatedSom] = useState(0);

  const [currentTab, setCurrentTab] = useState(tabItems[0].key);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setCurrentTab(tabItems[0].key);
  }, []);

  useEffect(() => {
    const powerupValues = getTAMSAMSOMValues(powerUpExcercises);

    if (powerupValues.tam) {
      setCalculatedTam(powerupValues.tam);
    }
    if (powerupValues.sam) {
      setCalculatedSam(powerupValues.sam);
    }
    if (powerupValues.som) {
      setCalculatedSom(powerupValues.som);
    }
  }, [powerUpExcercises]);

  return (
    <>
      {" "}
      <Stack
        sx={{
          minHeight: "100%",
          borderRadius: "28px",
          p: 4,
          [theme.breakpoints.down("md")]: {
            p: 0,
            borderRadius: 0,
          },
          background:
            "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
        }}
      >
        <Grid
          container
          sx={{ maxWidth: theme.breakpoints.values.xl, margin: "auto" }}
          flexGrow={1}
          flexDirection="column"
        >
          <WorkspaceBreadCrumbs subpath="TAM/SAM/SOM" />
          <Box
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              mt: 3,
              mx: 2,
            }}
          >
            <Tabs
              tabItems={tabItems}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              color="primary"
              spacing={0}
              buttonWidth="50%"
              width="100%"
            />
          </Box>
          <Grid container sx={{ minHeight: "100%", mb: 10 }}>
            <Grid
              item
              md={8}
              sm={12}
              xs={12}
              sx={theme => ({
                [theme.breakpoints.up("md")]: {
                  borderRight: `1px solid ${theme.palette.secondary.main}`,
                  pr: 3,
                  mt: 4,
                },
                [theme.breakpoints.down("md")]: {
                  p: 2,
                },
                display: "flex",
                flexDirection: "column",
                minHeight: "100%",
                display: {
                  sm: currentTab === "excercise" ? "block" : "none",
                  xs: currentTab === "excercise" ? "block" : "none",
                  md: "flex",
                },
              })}
            >
              <Card
                sx={{
                  borderRadius: "28px",
                  border: `1px solid ${theme.palette.secondary.dark}`,
                  p: 3,
                  display: "flex",
                  flexDirection: "column",
                  [theme.breakpoints.down("md")]: {
                    background: "transparent",
                    border: "none",
                    boxShadow: "0",
                    p: 1,
                    mt: 2,
                  },
                }}
              >
                <FormSectionHeader heading="TAM/SAM/SOM" />
                <Divider
                  sx={{
                    background: theme => theme.palette.secondary.dark,
                    [theme.breakpoints.down("md")]: {
                      background: theme => theme.palette.secondary.borderLight,
                    },
                  }}
                />
                <Box sx={{ display: "flex", mt: 3, alignItems: "center" }}>
                  <Box
                    sx={{
                      mr: 2,
                    }}
                  >
                    <GreenTicklIcon />
                  </Box>

                  <Typography variant="h3">Completed!</Typography>
                </Box>

                <Box sx={{ mt: 1 }}>
                  <SummaryStepList
                    StepsData={constants.TAMSAMSOM_COMPLETED_STEPS}
                  />
                </Box>

                <Box flexGrow={1} flexDirection={"column"}>
                  <Box
                    sx={{
                      mt: 3,
                    }}
                  >
                    <Typography variant="largeTitle">Summary</Typography>
                  </Box>

                  <Box display={"grid"} sx={{ mt: 3 }}>
                    <Typography variant="subhead1">
                      Here’s a summary of the work you’ve completed in this
                      PowerUp. You can take this as many times as you’d like and
                      edit whatever you’ve already completed.
                    </Typography>

                    <Typography variant="subhead1" sx={{ mt: 3 }}>
                      Here’s your completed TAM/SAM/SOM graph.
                    </Typography>
                  </Box>

                  <Box sx={{ mt: { xs: 2, md: 5 } }}>
                    <TAMSAMSOMGraph
                      tam={calculatedTam}
                      sam={calculatedSam}
                      som={calculatedSom}
                    />
                  </Box>
                  <Divider
                    sx={{
                      background: theme => theme.palette.secondary.dark,
                      mt: 3,
                      [theme.breakpoints.down("md")]: {
                        background: theme =>
                          theme.palette.secondary.borderLight,
                      },
                    }}
                  />
                  <Box
                    sx={{
                      mt: 3,
                      display: { sm: "none", xs: "none", md: "flex" },
                    }}
                  >
                    <Box sx={{ ml: "auto" }}>
                      <Button variant="outlined">Rate PowerUp</Button>
                    </Box>
                    <Box sx={{ ml: 2 }}>
                      <Button
                        onClick={() => {
                          history.push("/workspace");
                        }}
                      >
                        Return to Workspace Home
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: { sm: "block", xs: "block", md: "none" },
                      background: theme.palette.secondary.white,
                      borderRadius: "28px",
                      p: 3,
                      mt: 3,
                    }}
                  >
                    <Typography variant="title_medium">
                      Want feedback?
                    </Typography>
                    <Box sx={{ mt: 3 }}>
                      <Typography variant="subhead2">
                        Your StartupOS Advisor is here to help if you would like
                        feedback for this completed PowerUp.
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: "center", mt: 4 }}>
                      <Button>Submit for feedback</Button>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <KnowledgeSidebar currentTab={currentTab} powerUpId={"7"} />

            <Box
              sx={{
                display: {
                  sm: currentTab === "rate" ? "flex" : "none",
                  xs: currentTab === "rate" ? "flex" : "none",
                  md: "none",
                },
                mt: 3,
                ml: 2,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  mr: 2,
                }}
              >
                <GreenTicklIcon />
              </Box>

              <Typography variant="h3">Completed!</Typography>
            </Box>

            <RatingCard currentTab={currentTab} />
          </Grid>
          <Box
            sx={{
              background: theme.palette.secondary.white,
              position: "fixed",
              bottom: 72,
              width: 1,
              textAlign: "center",
              py: 2,
              display: { sm: "block", xs: "block", md: "none" },
            }}
          >
            <Button
              onClick={() => {
                history.push("/workspace");
              }}
            >
              Return to Workspace Home
            </Button>
          </Box>
        </Grid>
      </Stack>
    </>
  );
}

const mapStoreToProps = ({ powerUp }) => ({
  powerUpExcercises: powerUp?.powerUpExcercises,
});

const mapDispatchToProps = dispatch => ({
  setPowerUpExcercises: updatedSteps =>
    dispatch(actions.setPowerUpExcercises(updatedSteps)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(StorytellerSummary);
