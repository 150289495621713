import { Grid, Typography, Box, Button } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

import { FeaturedPartnerIcon } from "../../../common/icons";
import VideoPlayerComponent from "../../../common/videoPlayer";
import constants from "../../../constants";
import ROUTES_PATH from "../../../constants/routes";

const FeaturedPartner = () => {
  const history = useHistory();

  return (
    <Grid
      container
      sx={{
        p: 3,
      }}
    >
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <FeaturedPartnerIcon />
        <Typography variant="h3" sx={{ ml: 2 }}>
          Featured Partner
        </Typography>
        <Typography
          variant="h5"
          sx={{
            marginLeft: "auto",
            cursor: "pointer",
            display: { xs: "none", md: "block" },
          }}
          color={"primary"}
          onClick={() => {
            history.push(ROUTES_PATH.NETWORK);
          }}
        >
          View all Partners
        </Typography>
      </Grid>
      <Grid container columnSpacing={1}>
        <Grid
          item
          lg={7}
          md={7}
          sm={12}
          xs={12}
          sx={{
            alignItems: "center",
            mt: 3,
            display: { xs: "block", md: "none" },
          }}
        >
          <VideoPlayerComponent
            videoUrl={constants.VALUE_PROP_DETAILS.videoUrl}
          />
        </Grid>
        <Grid
          item
          lg={5}
          md={5}
          sm={12}
          xs={12}
          sx={{ alignItems: "center", mt: 3 }}
        >
          <Typography
            variant="h8"
            sx={{ fontFamily: "PoppinsRegular !important" }}
          >
            FULL-SERVICE VIDEO PRODUCTION AGENCY THAT CREATES
          </Typography>
          <Typography variant="h3" sx={{ mt: 1 }}>
            Advanced Creative
          </Typography>
          <Typography
            variant="body1"
            sx={{ mt: 3, fontFamily: "PoppinsRegular !important" }}
          >
            Transform your content marketing strategy with unique, inspiring
            video content that engages, converts, and retains customers.
          </Typography>
          <Box sx={{ mt: 13.1, display: { xs: "none", md: "block" } }}>
            <Button
              style={{
                width: "auto",
                fontSize: "14px",
              }}
              onClick={() => {
                window.open("http://svb.com/");
              }}
            >
              View Partner Profile
            </Button>
          </Box>
          <Box
            sx={{
              float: "right",
              mt: 3,
              display: { xs: "flex", md: "none" },
            }}
          >
            <Button sx={{ width: "auto", fontSize: "14px" }} variant="outlined">
              Browse all
            </Button>
            <Button
              sx={{ width: "auto", ml: 1.2, fontSize: "14px" }}
              variant="contained"
            >
              View Profile
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          lg={7}
          md={7}
          sm={12}
          xs={12}
          sx={{
            alignItems: "center",
            mt: 4,
            display: { xs: "none", md: "block" },
          }}
        >
          <VideoPlayerComponent
            videoUrl={constants.VALUE_PROP_DETAILS.videoUrl}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FeaturedPartner;
