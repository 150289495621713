import React, { useState } from "react";
import { STARTUP_LOGO, EDITICON } from "../../../../constants/image";
import { Typography, Grid, Box, Link } from "@mui/material";
import { useSelector } from "react-redux";
import { InvestorToggleSwitch } from "../../MyProfile/styles";
import constants from "../../../../constants";
import theme from "../../../../constants/Theme";

export default function ProfileContainer(props) {
  const onboardingData = useSelector(state => state.onboarding);
  return (
    <Grid
      container
      spacing={2}
      flexDirection={"row"}
      sx={{ pl: { xs: 1, md: 1 } }}
    >
      <Grid item flexDirection={"column"} xs={12} sm={12} md={8} lg={8} xl={8}>
        <Box
          sx={{
            pt: 2,
            pl: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            component="img"
            src={
              props.profileArr?.logoUrl
                ? props.profileArr.logoUrl
                : STARTUP_LOGO
            }
            alt="company-logo"
            sx={{ maxWidth: "200px" }}
          ></Box>

          <Box sx={{ cursor: "pointer", display: { xs: "block", md: "none" } }}>
            <img
              src={EDITICON}
              alt="edit-icon"
              onClick={props.handleEditForm}
            ></img>
          </Box>
        </Box>

        <Typography variant="titleXLarge" sx={{ ml: { xs: 0, md: 2 } }}>
          {props.profileArr?.onboardingDetail?.companyName
            ? props.profileArr?.onboardingDetail?.companyName
            : "The Roadrunner.corp"}
        </Typography>
        <br />
        {props.profileArr?.tagLine ? (
          <Typography variant="subhead2" sx={{ ml: { xs: 0, md: 2 }, pt: 1 }}>
            {props.profileArr?.tagLine}
          </Typography>
        ) : (
          <Link
            component="button"
            variant="bodySubhead"
            underline="none"
            onClick={props.handleEditForm}
            sx={{ pl: { xs: 0, md: 2 }, pt: 1 }}
          >
            Edit your motto
          </Link>
        )}
        <Grid
          container
          flexDirection={"row"}
          sx={{ ml: { xs: 0, md: 2 }, pt: 1 }}
        >
          <Box
            sx={{
              color: `${theme.palette.secondary.dark}`,
              letterSpacing: theme.spacing(1),
            }}
          >
            <Typography variant="caption">
              {props.profileArr?.onboardingDetail?.companyType
                ? props.profileArr?.onboardingDetail?.companyType
                : ""}
              {" • "}
            </Typography>
            <Typography variant="caption">
              {props.profileArr?.onboardingDetail?.state
                ? props.profileArr?.onboardingDetail?.state
                : ""}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        flexDirection={"column"}
        xs={12}
        sm={12}
        md={4}
        lg={4}
        xl={4}
        sx={{
          display: "flex",
          alignItems: { xs: "center", md: "flex-end" },
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ cursor: "pointer", display: { xs: "none", md: "block" } }}>
          <img
            src={EDITICON}
            alt="edit-icon"
            onClick={props.handleEditForm}
          ></img>
        </Box>

        <Grid container flexDirection={"row"} sx={{ width: "max-content" }}>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            sx={{
              display: "flex",
              gap: "10px",
            }}
          >
            <Typography variant="title_small">Investor View</Typography>
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                alignSelf: "center",
              }}
            >
              <InvestorToggleSwitch
                defaultChecked
                onChange={props.investerToggle}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
