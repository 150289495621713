import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Typography } from "@mui/material";
import theme from "../../../constants/Theme";

export default function TabDescription(props) {
  return (
    <Grid item sx={12} md={8}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(3),
        }}
      >
        <Typography variant="overlineLarge" sx={{ textTransform: "uppercase" }}>
          {props?.selectedPowerupPack?.subTitle}
        </Typography>
        <Typography variant="headline1">
          {props?.selectedPowerupPack?.title}
        </Typography>
        <Typography variant="subhead1">
          {props?.selectedPowerupPack?.description}
        </Typography>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Button
            disabled
            style={{
              width: "auto",
            }}
          >
            {props?.startStatus ? "Start next PowerUp" : "Start first PowerUp"}
          </Button>
        </Box>
      </Box>
    </Grid>
  );
}
