import React from "react";
import { WorkspacePopoverBtn, UserSection } from "./styles";
import { Popover } from "@mui/material";

export default function WorkspaceUser({ onLogout, userData }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <UserSection
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        <img
          src={userData?.picture}
          style={{
            objectFit: "cover",
            width: 33,
            height: 33,
            borderRadius: 21,
          }}
        />
      </UserSection>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <WorkspacePopoverBtn onClick={onLogout}>Logout</WorkspacePopoverBtn>
      </Popover>
    </>
  );
}
