import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Stack, Grid, Box, Typography } from "@mui/material";
import PageTitle from "../../components/MyStartup/PageTitle";
import { MY_STARTUP_CARD_ICON } from "../../constants/image";
import {
  PageContentWrapper,
  ProfileContentWrapper,
  WorkContentWrapper,
} from "../../components/Workspace/PageContentWrapper";
import { StartupWrapper, TabContainer, TabGroup } from "./styles";
import MyProfile from "./MyProfile";
import MyWork from "./MyWork";
import Tabs from "../../components/Workspace/Tabs";
import EditForm from "./EditForm";
import constants from "../../constants";
import theme from "../../constants/Theme";
import { sosPrimary } from "../../constants/Theme/colors";
import { REGEX } from "../../constants/regex";
import messages from "../../constants/messages";
import { USAMAP } from "../../constants/image";
import services from "../../services";
import { toastContainer } from "../../utils/toast";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MyStartup() {
  const [currentTab, setCurrentTab] = useState(
    constants.MY_STARTUP_TAB_ITEMS[0].key
  );
  const tabItems = constants.MY_STARTUP_TAB_ITEMS;
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isInvestorView, setIsInvestorView] = useState(true);
  const [companyData, setCompanyData] = useState("");
  const [profilePayload, setProfilePayload] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [tagline, setTagline] = useState("");
  const [productOffering, setProductOffering] = useState("");
  const [startUpSize, setStartUpSize] = useState("");
  const [foundingDate, setFoundingDate] = useState("");
  const [companySite, setCompanySite] = useState("");
  const [about, setAbout] = useState("");
  const [logo, setLogo] = useState("");
  const [profileArr, setProfileArr] = useState("");
  const [file, setFile] = useState("");
  const onboardingData = useSelector(state => state.onboarding);
  const [link, setLink] = useState("");
  const [companyDataError, setCompanyDataError] = useState({
    companyNameError: "",
    taglineError: "",
    productOfferingError: "",
    locationError: "",
    startupSizeError: "",
    foundingDateError: "",
    companySiteError: "",
    aboutError: "",
    logoError: "",
    videoError: "",
    pitchDeckError: "",
  });
  const [showOther, setShowOther] = useState(false);
  const [showState, setShowState] = useState(
    onboardingData?.companyLocation?.state
  );
  const [zipCode, setZipCode] = useState(
    onboardingData?.companyLocation?.zipCode
  );
  const [state, setState] = useState(onboardingData?.companyLocation?.state);
  const [stateImage, setStateImage] = useState(
    onboardingData?.companyLocation?.stateImage
  );
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: {
      latitude: onboardingData?.companyLocation?.lat,
      longitude: onboardingData?.companyLocation?.lng,
    },
  });
  const [showInsights, setShowInsights] = useState(true);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [compLocation, setCompLocation] = useState("");
  const [value, setValue] = React.useState(0);
  const [saveLoading, setSaveLoading] = useState(false);
  const [browseFlag, setBrowseFlag] = useState(false);

  useEffect(() => {
    //Get data from API
    services
      .getCompanyProfile()
      .then(response => {
        let services = response.data;
        if (services.message === "Success") {
          setCompanyData(services.data);
          setCompanyName(services.data.onboardingDetail.companyName);
          setTagline(services.data.tagLine);
          setProductOffering(services.data.onboardingDetail.companyType);
          setCompLocation(services.data.onboardingDetail.state);
          setAbout(services.data.about);
          setFoundingDate(services.data.foundedYear);
          setCompanySite(services.data.websiteLink);
          setStartUpSize(services.data.startUpSize);
          setIsInvestorView(services.data.investorView);
          setShowInsights(services.data.showInsights);
        }
      })
      .catch(error => {
        console.log("ERROR ", error);
      });
  }, []);

  useEffect(() => {
    if (companyName && productOffering && compLocation && startUpSize) {
      setSaveDisabled(false);
    }
  }, [companyName, productOffering, compLocation, startUpSize]);

  const handleEditForm = () => {
    setIsEditOpen(!isEditOpen);
    setBrowseFlag(false);
  };
  const handleInvestorView = () => {
    setIsInvestorView(!isInvestorView);
  };
  const handleChange = (e, type) => {
    const currentYear = new Date().getFullYear();
    if (type == "companyName") {
      setCompanyName(e.target.value);
      setCompanyDataError(prevState => ({
        ...prevState,
        companyNameError: "",
      }));
    }
    if (type == "tagline") {
      setTagline(e.target.value);
      setCompanyDataError(prevState => ({
        ...prevState,
        taglineError: "",
      }));
    }
    if (type == "productOffering") {
      if (e.target.value == "Other") {
        setShowOther(true);
      }
      setProductOffering(e.target.value);
      setCompanyDataError(prevState => ({
        ...prevState,
        productOfferingError: "",
      }));
    }
    if (type == "location") {
      if (!e.target.value.match(REGEX.SPECIAL_CHARACTERS)) {
        handleZipCodeChange(e);
        setCompLocation(e.target.value);
        setCompanyDataError(prevState => ({
          ...prevState,
          locationError: "",
        }));
        setSaveDisabled(false);
      } else {
        setCompanyDataError(prevState => ({
          ...prevState,
          locationError: messages.LOCATION_INVALID,
        }));
        setSaveDisabled(true);
      }
    }
    if (type == "startUpSize") {
      setStartUpSize(e.target.value);
      if (type == "startUpSize") {
        setStartUpSize(e.target.value);
        setCompanyDataError(prevState => ({
          ...prevState,
          startupSizeError: "",
        }));
      }
    }
    if (type == "foundingDate") {
      setFoundingDate(e.target.value);
      if (
        e.target.value > currentYear ||
        !e.target.value.match(REGEX.FOUNDING_DATE)
      ) {
        setCompanyDataError(prevState => ({
          ...prevState,
          foundingDateError: messages.FOUNDING_DATE_INVALID,
        }));
        setSaveDisabled(true);
      } else {
        setFoundingDate(e.target.value);
        setCompanyDataError(prevState => ({
          ...prevState,
          foundingDateError: "",
        }));
        setSaveDisabled(false);
      }
    }
    if (type == "companySite") {
      setCompanySite(e.target.value);
      setCompanyDataError(prevState => ({
        ...prevState,
        companySiteError: "",
      }));
    }
    if (type == "about") {
      setAbout(e.target.value);
      setCompanyDataError(prevState => ({
        ...prevState,
        aboutError: "",
      }));
    }
  };

  const handleValidations = () => {
    setSaveLoading(true);
    let pass = true;
    if (!companyName || companyName == "") {
      setCompanyDataError(prevState => ({
        ...prevState,
        companyNameError: messages.COMPANY_NAME_BLANK,
      }));
      pass = false;
    }
    if (!companyName.match(REGEX.COMPANY_NAME)) {
      setCompanyDataError(prevState => ({
        ...prevState,
        companyNameError: messages.COMPANY_NAME_LENGTH_INVALID,
      }));
      pass = false;
    }

    if (!productOffering || productOffering == "") {
      setCompanyDataError(prevState => ({
        ...prevState,
        productOfferingError: messages.PRODUCT_OFFERING_BLANK,
      }));
      pass = false;
    }

    if (!compLocation || compLocation == "") {
      setCompanyDataError(prevState => ({
        ...prevState,
        locationError: messages.LOCATION_BLANK,
      }));
      pass = false;
    }

    if (foundingDate) {
      if (foundingDate.length > 0 && foundingDate.length < 4) {
        setCompanyDataError(prevState => ({
          ...prevState,
          foundingDateError: messages.FOUNDING_DATE_BLANK,
        }));
        pass = false;
      }
    }

    if (!startUpSize || startUpSize == "") {
      setCompanyDataError(prevState => ({
        ...prevState,
        startupSizeError: messages.STARTUP_SIZE_BLANK,
      }));
      pass = false;
    }

    if (pass) {
      handlePayload();
    }
  };

  const handlePayload = () => {
    let pass = false;
    let url;

    if (companySite) {
      url = companySite.match(REGEX.WEBSITE)
        ? companySite
        : "http://" + companySite;
      pass = true;
    } else {
      pass = true;
    }

    if (pass) {
      let payload = {
        onboardingDetail: {
          companyName: companyName
            ? companyName
            : companyData.onboardingDetail.companyName,
          companyType: productOffering
            ? productOffering
            : companyData.onboardingDetail.companyType,
          currentCommitment: companyData.onboardingDetail.currentCommitment,
          experienceDetails: companyData.onboardingDetail.experienceDetails,
          founderSuperpower: companyData.onboardingDetail.founderSuperpower,
          journeyDetails: companyData.onboardingDetail.journeyDetails,
          state: compLocation
            ? compLocation
            : companyData.onboardingDetail.state,
        },
        logoUrl: logo ? logo : companyData.logo,
        websiteLink: url ? url : companyData.websiteLink,
        startUpSize: startUpSize ? startUpSize : companyData.startUpSize,
        foundedYear: foundingDate ? foundingDate : companyData.foundedYear,
        tagLine: tagline ? tagline : companyData.tagLine,
        about: about ? about : companyData.about,
        investorView: isInvestorView
          ? isInvestorView
          : companyData.investorView,
        showInsights: showInsights ? showInsights : companyData.showInsights,
      };

      //Update API data
      services
        .updateCompanyProfile(payload)
        .then(response => {
          if (response.data.message === "Success") {
            setSaveLoading(false);
            setCompanyData(response.data.data);
            setCompanyName(response.data.data.onboardingDetail.companyName);
            setTagline(response.data.data.tagLine);
            setProductOffering(response.data.data.onboardingDetail.companyType);
            setCompLocation(response.data.data.onboardingDetail.state);
            setAbout(response.data.data.about);
            setFoundingDate(response.data.data.foundedYear);
            setCompanySite(response.data.data.websiteLink);
            setStartUpSize(response.data.data.startUpSize);
            setIsInvestorView(response.data.data.investorView);
            setShowInsights(response.data.data.showInsights);
          }
        })
        .catch(e => {
          setSaveLoading(false);
          console.log("Submit Error", e);
          toastContainer(messages.API_SUBMIT_ERROR, "error");
        });
    }

    handleEditForm();
  };

  const getFiles = async (data, type) => {
    setLoading(true);
    let pass;
    let fileGroup;
    var formData = new FormData();
    formData.append("file", data);
    formData.append("fileGroup", "LOGO");

    setLoading(true);
    services
      .uploadFile(formData)
      .then(response => {
        setLoading(false);
        setBrowseFlag(true);
        setLink(response.data.data);
        setFile("");
        if (type == "img") {
          setLogo(response.data.data.fileLocation);
        }
      })
      .catch(e => {
        console.log("File upload Error", e);
        setLoading(false);
        if (type == "img") {
          setCompanyDataError(prevState => ({
            ...prevState,
            logoError: messages.FILE_UPLOAD_ERROR,
          }));
        }
      });
  };

  const resetState = () => {
    setState("");
    setStateImage(USAMAP);
  };

  const handleZipCodeChange = async e => {
    const value = e.target.value;
    if (value.match(REGEX.ZIP_CODE)) {
      setZipCode(value);
      if (value.length >= 5) {
        const locationData = await services.searchLocationByZipcode(value);

        if (locationData.data) {
          const usaData = locationData?.data?.filter(
            loc => loc?.address?.country_code === "us"
          );
          if (usaData.length > 0) {
            if (usaData[0].address?.state) {
              setState(usaData[0].address.state);
              setLocation(usaData[0].address.city);
              setCompLocation(usaData[0].address.state);
              setShowState(true);
            } else {
              //If state dont found for zipcode api
              const lattitude = usaData[0]?.lat;
              const longitude = usaData[0]?.lon;

              const latLongData = await services.searchLocationByCoordinates(
                lattitude,
                longitude
              );
              const address = latLongData.data.address;

              if (address.country_code === "us") {
                setState(address.state);
                setLocation(address.city);
                setCompLocation(address.state);
                setShowState(true);
              } else {
                toastContainer(messages.LOCATION_INVALID, "error");
              }
            }
          } else {
            toastContainer(messages.LOCATION_INVALID, "error");
            resetState();
          }
        }
      } else {
        resetState();
      }
    } else {
      resetState();
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    setValue(newValue);
  };

  const handleClear = () => {
    setCompLocation("");
  };

  return (
    <>
      <Stack
        sx={{
          minHeight: "100%",
          borderRadius: { md: "28px" },
          display: "block",
          background:
            "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
        }}
      >
        <EditForm
          open={isEditOpen}
          close={handleEditForm}
          handleChange={handleChange}
          handleUploadAction={getFiles}
          handleValidations={handleValidations}
          companyDataError={companyDataError}
          setCompanyDataError={setCompanyDataError}
          showOther={showOther}
          setShowOther={setShowOther}
          handleZipCodeChange={handleZipCodeChange}
          companyData={companyData}
          companyName={companyName}
          tagline={tagline}
          productOffering={productOffering}
          compLocation={compLocation}
          startUpSize={startUpSize}
          foundingDate={foundingDate}
          companySite={companySite}
          about={about}
          logo={logo}
          saveDisabled={saveDisabled}
          loading={loading}
          setLoading={setLoading}
          handleClear={handleClear}
          saveLoading={saveLoading}
          browseFlag={browseFlag}
        />
        <Grid
          container
          sx={{
            p: { md: 4 },
            maxWidth: theme.breakpoints.values.xl,
            margin: "auto",
          }}
        >
          <Grid item md={12} xs={12}>
            <Box sx={{ p: { xs: 2 } }}>
              <PageTitle
                iconUrl={MY_STARTUP_CARD_ICON}
                title="My Startup"
              ></PageTitle>
            </Box>
          </Grid>
          <Grid item md={12} xs={12}>
            <ProfileContentWrapper>
              <Grid item md={12} sm={12} justifyContent="center">
                <Box justifyContent="center" alignItems="center">
                  <TabGroup value={value} onChange={handleTabChange}>
                    <TabContainer
                      type="myProfile"
                      label="My Profile"
                      {...a11yProps(0)}
                    />
                    <TabContainer
                      type="myWork"
                      label="My Work"
                      {...a11yProps(1)}
                      sx={{ ml: { sm: 0, md: 1 } }}
                    />
                  </TabGroup>
                </Box>
              </Grid>
              {value === 0 && (
                <MyProfile
                  handleEditForm={handleEditForm}
                  investerToggle={handleInvestorView}
                  isInvestorView={isInvestorView}
                  profileArr={companyData}
                  showInsights={showInsights}
                  setShowInsights={setShowInsights}
                />
              )}
              {value === 1 && (
                <WorkContentWrapper>
                  <MyWork handleEditForm={handleEditForm} />
                </WorkContentWrapper>
              )}
            </ProfileContentWrapper>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
