import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const pinDrop = keyframes`
            from {
              opacity: 0;
              transform: translateY(-200px);
            }

            to{
            opacity: 1;
              transform: translateY(-10px);
            }
          `;

export const MapImageWrapper = styled.div(({ theme, image }) => {
  return {
    background: `url("${image}") no-repeat center center`,
    height: "237px",
    width: "250px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    backgroundSize: "contain",
    [theme.breakpoints.down("sm")]: {
      height: "204px",
      width: "215px",
    },
  };
});

export const CenteredPinPlacement = styled.div(({ theme, pinMarkerIcon }) => {
  return {
    backgroundImage: `url(${pinMarkerIcon})`,
    backgroundRepeat: "no-repeat",
    height: "45px",
    width: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});

export const Pin = styled.img(({ theme }) => {
  return {
    animation: `${pinDrop} 1.5s forwards`,
  };
});

export const ButtonWrapper = styled.div(({ theme }) => {
  return {
    width: "100%",
    textAlign:"center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  };
});
