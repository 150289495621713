import React from "react";
import { Typography, Box } from "@mui/material";
import JsxParser from "react-jsx-parser";
import { ContentWrapper } from "./styles";
import PowerupAccordion from "../../Accordion";
import VideoPlayerComponent from "../../../../../common/videoPlayer";
import theme from "../../../../../constants/Theme";
import PrerequisitesSteps from "../../PrerequisitesSteps";
import PersonaWorkCard from "../../PersonaWorkCard";

export default function ValuePropOverView({
  valuePropsDetails,
  valuePropsContents,
  powerupApiDetails,
}) {
  return (
    <ContentWrapper sx={{ p: 3 }}>
      <Box sx={{ mt: 3, display: { xs: "block", sm: "block", md: "none" } }}>
        <PersonaWorkCard />
      </Box>
      <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <VideoPlayerComponent videoUrl={powerupApiDetails.videoUrl} />
      </Box>
      <Box
        sx={theme => ({
          [theme.breakpoints.down("md")]: {
            mt: 6,
          },
          mt: 3,
        })}
      >
        <Typography variant="headline2" color="text.secondary">
          {valuePropsDetails.title}
        </Typography>
      </Box>

      <Box sx={{ mt: 1 }}>
        <Typography variant="body1">{powerupApiDetails.overview}</Typography>
      </Box>
      <Box sx={{ display: { xs: "block", sm: "block", md: "none" }, mt: 6 }}>
        <VideoPlayerComponent videoUrl={valuePropsDetails.videoUrl} />
      </Box>

      {valuePropsDetails.id === 1 ? (
        <>
          <Box
            sx={theme => ({
              [theme.breakpoints.down("md")]: {
                mt: 6,
              },
              mt: 3,
            })}
          >
            <Typography variant="largeTitle" color="text.secondary">
              {valuePropsDetails.subTitle1}
            </Typography>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Typography variant="body1">
              {powerupApiDetails.beforeBegin}
            </Typography>
          </Box>
        </>
      ) : (
        ""
      )}

      <Box
        sx={theme => ({
          [theme.breakpoints.down("md")]: {
            mt: 6,
          },
          mt: 3,
        })}
      >
        <Typography variant="largeTitle" color="text.secondary">
          {valuePropsDetails.subTitle2}
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant="body1">
          <JsxParser
            components={{ Typography }}
            jsx={powerupApiDetails?.learning}
          />
        </Typography>

        {/*<Typography variant="body1">{powerupApiDetails.learning}</Typography>*/}
      </Box>

      {/* Render Prerequisites component for powerup */}
      {powerupApiDetails.preRequisites?.length > 0 && (
        <Box sx={{ pb: 2 }}>
          <Box
            sx={theme => ({
              [theme.breakpoints.down("md")]: {
                mt: 6,
              },
              mt: 3,
            })}
          >
            <Typography variant="largeTitle" color="text.secondary">
              What you’ll need
            </Typography>
          </Box>
          <Box sx={{ mt: 1 }}>
            <PrerequisitesSteps
              powerupType={powerupApiDetails.powerUpName}
              prerequisitesData={powerupApiDetails.preRequisites}
            />
          </Box>
        </Box>
      )}
      <Box sx={{ mt: 3, display: { xs: "block", sm: "block", md: "none" } }}>
        <PersonaWorkCard />
      </Box>
      <Box
        sx={theme => ({
          [theme.breakpoints.down("md")]: {
            mt: 6,
          },
          mt: 3,
        })}
      >
        <Typography variant="headline3" color="text.secondary">
          Contents
        </Typography>
      </Box>

      <Box
        sx={{
          mt: 2,
          backgroundColor: theme.palette.secondary.white,
          borderRadius: "28px",
        }}
      >
        <PowerupAccordion valuePropsContents={valuePropsContents} />
      </Box>
    </ContentWrapper>
  );
}
