import React from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";

const VideoPlayerComponent = ({ videoUrl }) => {
  return (
    <ReactPlayer
      className="react-player"
      controls={true}
      width="100%"
      height="auto"
      url={videoUrl}
    />
  );
};

{
  VideoPlayerComponent.propTypes = {
    videoUrl: PropTypes.string,
  };

  VideoPlayerComponent.defaultProps = {
    videoUrl: "",
  };
}

export default VideoPlayerComponent;
