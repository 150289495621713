import React from "react";
import { BACKBUTTON, LOGOICON } from "../../../constants/image";
import { BackButtonWrapper, HeaderImageWrapper } from "./styles";
import ROUTES_PATH from "../../../constants/routes";

export default function Header({ onBack }) {
  return (
    <header sx={{ pt: 4 }}>
      <a href={ROUTES_PATH.HOME_PAGE_URL} draggable={false}>
        <HeaderImageWrapper image={LOGOICON}></HeaderImageWrapper>
      </a>
      <BackButtonWrapper>
        <img
          src={BACKBUTTON}
          alt="back"
          className="cursor-pointer"
          onClick={() => onBack()}
        />
      </BackButtonWrapper>
    </header>
  );
}
