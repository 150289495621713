import React, { useState, useEffect } from "react";
import theme from "../../../constants/Theme";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import FormSectionHeader from "../../../components/Workspace/PowerUp/FormSectionHeader";
import WorkspaceBreadCrumbs from "../../../components/Workspace/BreadCrumbs";
import * as actions from "../../../modules/actions";
import { connect } from "react-redux";
import { PowerUpAvatar } from "../../../components/Workspace/PowerUp/Accordion/styles";
import { useParams, useHistory, Prompt } from "react-router-dom";
import services from "../../../services";
import JsxParser from "react-jsx-parser";
import Sidebar from "../../../components/Workspace/PowerUp/Sidebar";
import RouterPrompt from "../../../common/RouterPrompt";
import messages from "../../../constants/messages";
import constants from "../../../constants";
import Tabs from "../../../components/Workspace/Tabs";
import { toastContainer } from "../../../utils/toast";
import LoadingButton from "../../../common/Loadingbutton";
import ExcerciseWrapper from "../../../components/Workspace/PowerUp/ExcerciseWrapper";
import ExcerciseSection from "../../../components/Workspace/PowerUp/ExcerciseSection";
import KnowledgeSidebar from "../../../components/Workspace/PowerUp/KnowledgeSidebar";
import {
  getExcerciseConfigurations,
  getStepsData,
  getExcerciseCompletionStatus,
  handleButtonDisable,
} from "./powerupFunctions";
import TextAreaInput from "../../../components/Workspace/PowerUp/TextAreaInput";
import MultivalueInputFormContainer from "../../../components/Workspace/PowerUp/MultivalueInputFormContainer";
import FrameYourStatement from "../../../components/Workspace/PowerUp/FrameYourStatement";
import PowerupRecapTab from "../../../components/Workspace/PowerUp/RecapTab";

function PowerupExcercises({ powerUpExcercises, setPowerUpExcercises }) {
  const [userInputs, setUserInputs] = useState([]);
  const [excerciseConfigs, setExcerciseConfigs] = useState();
  const [excerciseSteps, setExcerciseSteps] = useState([]);
  const [currentExcercise, setCurrentExcercise] = useState();
  const params = useParams();
  const history = useHistory();

  const excerciseParam = params.excercise;
  const tabItems = constants.EXCERCISE_TAB_ITEMS;
  const [currentTab, setCurrentTab] = useState(tabItems[0].key);
  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const moduleUuid = params.moduleUuid;
  const moduleId = parseInt(params.id);
  const versionId = parseInt(params.version);
  const powerUpId = moduleUuid === "md-value-prop" ? 1 : 2;

  const handleBackClick = () => {
    if (currentExcercise.stepNo === 1) {
      history.push(`/workspace/powerup/${powerUpId}`);
    } else {
      setUserInputs([]);
      let currentStep = excerciseSteps.find(
        excercise => excercise.stepNo === currentExcercise.stepNo - 1
      );
      const newExcerciseData = excerciseConfigs.excercises.find(
        excercise => excercise.step_id === currentStep.id
      );
      setCurrentExcercise(syncData(newExcerciseData));
    }
  };
  const updatepowerUpExcercises = data => {
    return new Promise((resolve, reject) => {
      try {
        let existingPowerUpExcercises = [...powerUpExcercises];
        let currentIndex = existingPowerUpExcercises.findIndex(
          excercise => excercise.stepNo === data.stepNo
        );

        // if excercise already exists update
        let requestBody = null;
        if (currentIndex >= 0) {
          var excercise = existingPowerUpExcercises[currentIndex];
          excercise = {
            ...excercise,
            answers: userInputs,
            isLastStep: data.isLastStep,
          };
          existingPowerUpExcercises[currentIndex] = excercise;
          requestBody = excercise.answers;
        } else {
          //Else insert new one
          existingPowerUpExcercises.push(data);
          requestBody = data.answers;
        }

        services
          .storeModuleStepResponses(moduleId, {
            ...data,
            answers: JSON.stringify(requestBody),
          })
          .then(response => {
            if (response.data.code === 200) {
              setPowerUpExcercises(existingPowerUpExcercises);
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
            console.log(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  };
  const handleNextClick = () => {
    setNextButtonLoading(true);
    const isLastExcercise =
      currentExcercise.stepNo === excerciseConfigs.excercises.length;
    let data = {
      moduleResponseId: versionId,
      stepNo: currentExcercise.stepNo,
      answers: userInputs,
      isLastStep: isLastExcercise,
      isCompleted: getExcerciseCompletionStatus(currentExcercise, userInputs),
      moduleStepId: currentExcercise.id,
    };

    updatepowerUpExcercises(data)
      .then(response => {
        if (response) {
          setNextButtonLoading(false);
          if (!data.isLastStep) {
            let currentStep = excerciseSteps.find(
              excercise => excercise.stepNo === currentExcercise.stepNo + 1
            );
            const newExcerciseData = excerciseConfigs.excercises.find(
              excercise => excercise.step_id === currentStep.id
            );
            setCurrentExcercise(syncData(newExcerciseData));
          } else {
            history.push(
              excerciseConfigs.summary_path + "/" + moduleId + "/" + versionId
            );
          }
          setUserInputs([]);
        }
      })
      .catch(error => {
        console.log(error);
        setNextButtonLoading(false);
        toastContainer(messages.EXCERCISE_STORE_ERROR, "error");
      });
  };

  const shouldShowPrompt = () => {
    if (powerUpExcercises && currentExcercise) {
      const currentSavedStep = powerUpExcercises.find(
        ex => ex.stepNo === currentExcercise.stepNo
      );
      if (currentSavedStep) {
        if (
          JSON.stringify(currentSavedStep.answers) ===
          JSON.stringify(userInputs)
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        if (userInputs.length === 0) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const BackButton = () => {
    return (
      <Button
        variant="outlined"
        color="primary"
        sx={{ mx: 1 }}
        onClick={handleBackClick}
      >
        Back
      </Button>
    );
  };

  const NextButton = () => {
    return (
      <LoadingButton
        variant="contained"
        color="primary"
        sx={{ mx: 1 }}
        disabled={handleButtonDisable(currentExcercise, userInputs)}
        loading={nextButtonLoading}
        onClick={handleNextClick}
      >
        Next
      </LoadingButton>
    );
  };

  const syncData = jsonData => {
    const stepDetailsData = excerciseSteps.find(
      step => step.id === jsonData.step_id
    );
    return { ...jsonData, ...stepDetailsData };
  };

  const getLastExcercise = excercises => {
    excercises.sort((a, b) => (a.stepNo > b.stepNo ? 1 : -1));
    return excercises[excercises.length - 1].moduleStepId;
  };

  useEffect(() => {
    if (excerciseConfigs) {
      services
        .getModulesSteps(moduleUuid)
        .then(result => {
          if (result.data?.data) {
            const steps = result.data.data;
            steps.sort((a, b) => (a.stepNo > b.stepNo ? 1 : -1));
            setExcerciseSteps(steps);
          }
        })
        .catch(err => console.log(err));
    }
  }, [excerciseConfigs]);

  useEffect(() => {
    if (excerciseConfigs && excerciseSteps && excerciseSteps.length) {
      services
        .getModuleStepResponses(moduleId)
        .then(result => {
          const tempExcerciseDetails = result.data?.data.find(
            excercise => excercise.id === versionId
          );

          if (tempExcerciseDetails) {
            const excerciseDetails =
              tempExcerciseDetails.moduleStepResponses.sort((a, b) =>
                a.id > b.id ? 1 : -1
              );

            if (!excerciseDetails || !excerciseDetails.length) {
              setPowerUpExcercises([]);
              setCurrentExcercise(syncData(excerciseConfigs.excercises[0]));
            } else {
              const mappedExcerciseDetails = excerciseDetails.map(
                excercise => ({
                  moduleResponseId: versionId,
                  moduleStepId: excercise.moduleStep.id,
                  answers: JSON.parse(excercise.answers),
                  stepNo: excercise.moduleStep.stepNo,
                })
              );

              setPowerUpExcercises(mappedExcerciseDetails);
              if (excerciseParam === "start") {
                const lastExcerciseStepId = getLastExcercise(
                  mappedExcerciseDetails
                );
                const latestExcercise = excerciseConfigs.excercises.find(
                  excercise => excercise.step_id === lastExcerciseStepId + 1
                );
                if (latestExcercise) {
                  setCurrentExcercise(syncData(latestExcercise));
                } else {
                  history.push(
                    excerciseConfigs.summary_path +
                      "/" +
                      moduleId +
                      "/" +
                      versionId
                  );
                }
              } else if (excerciseParam === "retake") {
                setPowerUpExcercises([]);
                setCurrentExcercise(syncData(excerciseConfigs.excercises[0]));
              } else {
                setCurrentExcercise(
                  syncData(
                    excerciseConfigs.excercises.find(
                      excercise => excercise.path === excerciseParam
                    )
                  )
                );
              }
            }
          }
        })
        .catch(err => console.log(err));
    }
  }, [excerciseSteps]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    if (currentExcercise) {
      const currentItem = powerUpExcercises.find(
        excercise => excercise.stepNo === currentExcercise.stepNo
      );

      //Check if there is previously filled step and see its input
      if (currentItem) {
        if (currentItem.answers && currentItem.answers.length) {
          setUserInputs(currentItem.answers);
        } else {
          setUserInputs([]);
        }
      } else {
        setUserInputs([]);
      }
      setCurrentTab(tabItems[0].key);
      history.push(currentExcercise.path);
    }
  }, [currentExcercise]);

  useEffect(() => {
    setExcerciseConfigs(getExcerciseConfigurations(powerUpId));
  }, []);

  const handleStepperStepChange = stepId => {
    setUserInputs([]);
    const changedStep = excerciseConfigs.excercises.find(
      excercise => excercise.step_id === stepId
    );
    if (changedStep) {
      setCurrentExcercise(syncData(changedStep));
    }
  };

  return (
    <>
      <RouterPrompt
        title={messages.UNSAVED_CHANGES_PROMPT_MESSAGE}
        when={shouldShowPrompt}
        onOK={() => true}
        onCancel={() => false}
        okText={"Ok"}
        cancelText={"Cancel"}
        stepPath={`/workspace/powerup/${moduleUuid}/${moduleId}/${versionId}/${currentExcercise?.path}`}
      />
      <ExcerciseWrapper
        NextButton={NextButton}
        BackButton={BackButton}
        excerciseConfigs={excerciseConfigs}
        currentExcercise={currentExcercise}
      >
        {excerciseConfigs && (
          <ExcerciseSection
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            excerciseConfigurations={excerciseConfigs}
            currentExcercise={currentExcercise}
            excerciseSteps={excerciseSteps}
            NextButton={NextButton}
            BackButton={BackButton}
            showSummary={showSummary}
            powerUpExcercises={powerUpExcercises}
            handleStepperStepChange={handleStepperStepChange}
          >
            {currentExcercise &&
              (currentExcercise.input_type === "TEXTAREA" ? (
                <TextAreaInput
                  userInputs={userInputs}
                  setUserInputs={setUserInputs}
                />
              ) : currentExcercise.input_type === "MULTIVALUE_TAGS_INPUT" ? (
                <MultivalueInputFormContainer
                  userInputs={userInputs}
                  setUserInputs={setUserInputs}
                  inputLabel={currentExcercise?.input_label}
                  inputPlaceholder={currentExcercise?.input_placeholder}
                  outputContainerLabel={
                    currentExcercise?.output_container_label
                  }
                />
              ) : currentExcercise.input_type === "FRAME_STATEMENT" ? (
                <FrameYourStatement
                  userInputs={userInputs}
                  setUserInputs={setUserInputs}
                  attributes={
                    currentExcercise.hasOwnProperty("attributes") &&
                    currentExcercise.attributes
                  }
                />
              ) : null)}
            {currentExcercise &&
            currentExcercise.input_type === "FRAME_STATEMENT" ? (
              <Box>
                <Box sx={{ mt: 3, mb: 3 }}>
                  <Typography variant="subhead1" color="text.primary">
                    Here’s a recap of what you just brainstormed to inspire
                    building your proposition above.
                  </Typography>
                </Box>
                <PowerupRecapTab
                  valuePropContent={constants.VALUE_PROP_RECAP_DATA}
                  powerUpExcercisesData={powerUpExcercises}
                />
              </Box>
            ) : null}
          </ExcerciseSection>
        )}
        <KnowledgeSidebar currentTab={currentTab} powerUpId={powerUpId} />
      </ExcerciseWrapper>
    </>
  );
}

const mapStoreToProps = ({ powerUp }) => ({
  powerUpExcercises: powerUp?.powerUpExcercises,
});

const mapDispatchToProps = dispatch => ({
  setPowerUpExcercises: updatedSteps =>
    dispatch(actions.setPowerUpExcercises(updatedSteps)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(PowerupExcercises);
