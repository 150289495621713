import React, { useEffect, useRef, forwardRef } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  colors,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import PropTypes from "prop-types";

import {
  WorkspaceStepIcon,
  PowerUpStepIcon,
  PluginStepIcon,
  FusionStepIcon,
  DemoStepIcon,
  CompletedIcon,
  PowerUpFilledIcon,
} from "../../../common/icons";

const WorkspaceStepper = forwardRef(
  (
    {
      activeStep,
      setActiveStep,
      steps,
      nonLinear,
      linear,
      type,
      fixedWidth,
      PowerUpActive,
      PowerUpDefault,
      PluginActive,
      PluginDefault,
      FusionActive,
      FusionDefault,
      DemoActive,
      DemoDefault,
      CompletedDefault,
      PowerUpFilled,
      handleKeyDown,
      moveCaro,
    },
    ref
  ) => {
    const StepIcon = props => {
      const { active, icon } = props;
      if (icon && steps[icon - 1]?.companyTask?.taskStatus == "STARTED") {
        return <PowerUpFilled sx={{ cursor: "pointer" }} number={icon} />;
      }
      if (icon && steps[icon - 1]?.companyTask?.taskStatus == "COMPLETED") {
        switch (icon) {
          case 5:
          case 9: {
            return (
              <PluginDefault
                sx={{
                  cursor: "pointer",
                }}
                completed
              />
            );
          }
          case 11: {
            return (
              <FusionActive
                sx={{ cursor: "pointer" }}
                completed
                number={icon}
              />
            );
          }
          case 12: {
            return (
              <CompletedDefault sx={{ cursor: "pointer" }} number={icon} />
            );
          }
          default: {
            return (
              <CompletedDefault sx={{ cursor: "pointer" }} number={icon} />
            );
          }
        }
      } else if (active) {
        switch (icon) {
          case 5:
          case 9: {
            return (
              <PluginActive
                sx={{
                  cursor: "pointer",
                }}
                active
                inProgress={steps[icon - 1].inProgress}
                number={icon}
              />
            );
          }
          case 11: {
            return (
              <FusionActive
                sx={{
                  cursor: "pointer",
                }}
                active
                number={icon}
              />
            );
          }
          case 12: {
            return (
              <DemoActive sx={{ cursor: "pointer" }} active number={icon} />
            );
          }
          default: {
            return (
              <PowerUpActive sx={{ cursor: "pointer" }} active number={icon} />
            );
          }
        }
      } else {
        switch (icon) {
          case 5:
          case 9: {
            return <PluginDefault sx={{ cursor: "pointer" }} number={icon} />;
          }
          case 11: {
            return <FusionDefault sx={{ cursor: "pointer" }} number={icon} />;
          }
          case 12: {
            return <DemoDefault sx={{ cursor: "pointer" }} number={icon} />;
          }
          default: {
            return <PowerUpDefault sx={{ cursor: "pointer" }} number={icon} />;
          }
        }
      }
    };

    const StepperLabel = index => {
      let label;
      steps[index].taskName == "POWER_UP"
        ? (label = "PowerUp")
        : steps[index].taskName == "PLUGIN"
        ? (label = "PlugIn")
        : steps[index].taskName == "FUSION"
        ? (label = "Fusion")
        : (label = "Demo Day");

      return (
        <Typography variant="path_text" sx={{ textTransform: "none" }}>
          {label}
        </Typography>
      );
    };

    const handleStep = i => {
      setActiveStep(i);
    };

    return (
      <>
        <Box
          sx={
            type === "WORKSPACE"
              ? {
                  position: "absolute",
                  overflowX: "scroll",
                  width: "100%",
                  "scrollbar-width": "none",
                }
              : {}
          }
          onScroll={() => handleKeyDown()}
        >
          <Stepper
            nonLinear={nonLinear}
            linear={linear}
            activeStep={activeStep}
            alternativeLabel
            connector={<StepConnector />}
            sx={{
              width: 1,
            }}
          >
            {steps.map((step, index) => (
              <Step
                key={index}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (moveCaro) {
                    moveCaro(activeStep, index);
                  } else {
                    handleStep(index);
                  }
                }}
                ref={el => (ref.current[index] = el)}
                className={index != 0 ? index : "0"}
              >
                <StepLabel
                  StepIconComponent={StepIcon}
                  sx={{
                    width: { xs: "100vw", md: "auto" },
                  }}
                >
                  {StepperLabel(index)}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </>
    );
  }
);

{
  WorkspaceStepper.propTypes = {
    nonLinear: PropTypes.bool,
    linear: PropTypes.bool,
    ActiveIcon: PropTypes.func,
    DefaultIcon: PropTypes.func,
    CompletedDefault: PropTypes.func,
    steps: PropTypes.array,
    type: PropTypes.string,
    activeStep: PropTypes.number,
    setActiveStep: PropTypes.func,
    fixedWidth: PropTypes.number,
    LastStepIcon: PropTypes.func,
  };
  WorkspaceStepper.defaultProps = {
    nonLinear: true,
    linear: false,
    PowerUpActive: PowerUpStepIcon,
    PowerUpDefault: PowerUpStepIcon,
    PluginActive: PluginStepIcon,
    PluginDefault: PluginStepIcon,
    FusionActive: FusionStepIcon,
    FusionDefault: FusionStepIcon,
    DemoActive: DemoStepIcon,
    DemoDefault: DemoStepIcon,
    ActiveIcon: WorkspaceStepIcon,
    DefaultIcon: WorkspaceStepIcon,
    CompletedDefault: CompletedIcon,
    PowerUpFilled: PowerUpFilledIcon,
    steps: [],
    type: "WORKSPACE",
    activeStep: 0,
    setActiveStep: null,
    fixedWidth: 1300,
    LastStepIcon: null,
  };
}

export default WorkspaceStepper;
