const ROUTES_PATH = {
  HOME_PAGE_URL: "https://dev.startupos.com/",
  SIGN_UP: "/signup",
  LOG_IN: "/login",
  CREATE_ACCOUNT: "/create-account",
  EMAIL_VERIFICATION: "/email-verification",
  SOCIAL_LOGIN: "/social-signup",
  ONBOARDING: "/onboarding/:step",
  FORGOT_PASSWORD: "/forgot-password",
  CREATE_PASSWORD: "/create-password",
  RESEND_EMAIL: "/resend-email",
  AUTHORIZE: "/authorize",
  ONBOARDING_BASEPATH: "/onboarding",
  AUTHORIZE_SUCCESS: "/success",
  DASHBOARD: "/dashboard",

  //workspace
  WORKSPACE: "/workspace",
  VALUE_PROPOSITON: "/value-proposition",
  VALUE_PROPOSITON_ADD: "/value-proposition-add",
  FRAME_YOUR_STATEMENT: "/frame-your-statement",

  //network
  NETWORK: "/network",

  ONBOARDING_COMPANY_NAME: "/onboarding/company-name",
  ONBOARDING_LOCATION: "/onboarding/location",
  ONBOARDING_PRODUCT_SERVICE: "/onboarding/product-service",
  ONBOARDING_STARTUP_JOURNEY: "/onboarding/startup-journey",
  ONBOARDING_FOUNDER_EXPERIENCE_LEVEL: "/onboarding/experience-level",
  ONBOARDING_FOUNDER_SUPERPOWER: "/onboarding/founder-super-power",
  ONBOARDING_TIME_COMMITMENT: "/onboarding/time-commitment",
  STARTUP: "/mystartup",
  WORKSPACE_POWERUP_DETAILS: "/workspace/powerUp/:id",
  STORYTELLER_POWERUP_SUMMARY:
    "/workspace/storyteller/summary/:moduleId/:versionId",
  VALUEPROP_POWERUP_SUMMARY:
    "/workspace/value-prop/summary/:moduleId/:versionId",
  PERSONA_BUILDER_POWERUP_SUMMARY:
    "/workspace/persona-builder/:moduleId/:versionId/:type",
  TAMSAMSOM_POWERUP_SUMMARY: "/workspace/tamsamsom/summary",
  REVENUW_MODELER_POWERUP_SUMMARY:
    "/workspace/revenuemodeler/summary/:moduleId",
  WORKSPACE_POWERUP_EXCERCISE:
    "/workspace/powerup/:moduleUuid/:id/:version/:excercise",
  TAM_SAM_SOM_POWERUP: "/workspace/powerup/md-tam/:id/:version/:excercise",
  IDEA_VALIDATION_INTERVIEW: "/workspace/powerup/4",
  IDEA_VALIDATION_INTERVIEW_POWERUP:
    "/workspace/powerup/md-ideavalidation/:id/:version/:excercise",
  INITIAL_PERSONNA_BUILDER_POWERUP:
    "/workspace/powerup/md-persona/:id/:version/:excercise",
  REVENUE_MODLER: "/workspace/powerup/md-revenue/:id/:excercise",
  BRANDSTORMING_POWERUP: "/workspace/powerup/md-brandstorm/:id/:excercise",
  BRANDSTORMING_SUMMARY: "/workspace/brandstorming/summary",
  INTERVIEWING_YOUR_CANDIDATE:
    "/workspace/powerup/4/interviewing-your-candidate",
  AGGREGATE_NOTES: "/workspace/powerup/4/aggregate-notes",
  BROWSE_INTERVIEW_CANDIDATES:
    "/workspace/powerup/4/browse-candidates/:id/:version/browse",
};

export default ROUTES_PATH;
