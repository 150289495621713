import React from "react";
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import theme from "../../../../../constants/Theme";
import ArrowBack from "@mui/icons-material/ArrowBack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../../../../constants/routes";

const BreadcrumbsComp = ({ powerupDetails, sectionTitle }) => {
  const history = useHistory();
  const handleWorkspaceNavigation = () => {
    history.push(ROUTES_PATH.WORKSPACE);
  };

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <IconButton aria-label="ArrowBack" color="primary">
        <ArrowBack />
      </IconButton>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon sx={{ ml: 1.5, mr: 1 }} />}
      >
        <Link
          underline="hover"
          color="primary"
          component="button"
          onClick={() => {
            handleWorkspaceNavigation();
          }}
        >
          <Typography color="primary" variant="h5">
            Workspace
          </Typography>
        </Link>
        {!isSmallScreen && (
          <Typography color="text.primary" variant="h5">
            {powerupDetails?.powerUpName}
          </Typography>
        )}

        {!isSmallScreen && (
          <Typography color="text.primary" variant="h5">
            {sectionTitle}
          </Typography>
        )}
      </Breadcrumbs>
    </Box>
  );
};

export default BreadcrumbsComp;
