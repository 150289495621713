import { Add, Bookmark, BookmarkBorder } from "@mui/icons-material";
import { Box, Card, Divider, Grid, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import theme from "../../../../../constants/Theme";
import services from "../../../../../services";
import { getInterviewCandidates } from "../Functions/common";
import AddExternalCandidate from "./ExternalCandidatePopup";
import { toastContainer } from "../../../../../utils/toast";
import messages from "../../../../../constants/messages";

export default function ManageCandidates({ candidates, currentModuleObject }) {
  const [showAddCandidates, setShowAddCandidates] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const history = useHistory();

  const returnToDetailsPage = () => {
    history.push("/workspace/powerup/4");
  };

  useEffect(() => {
    if (currentModuleObject) {
      console.log("module-loaded", currentModuleObject);
      const responses = currentModuleObject.moduleStepResponses;
      if (responses.length) {
        setSelectedCandidates(JSON.parse(responses[0].answers).candidates);
      }
    }
  }, [currentModuleObject]);

  const closeAddCandidatePopup = () => {
    setShowAddCandidates(false);
  };

  const addCandidate = data => {
    const payload = {
      ...data,
      inviteRole: "GENERAL",
    };

    services
      .scheduleCandidateEvent(payload)
      .then(result => {
        toastContainer(messages.EVENT_SCHEDULED_SUCCESS, "success");
      })
      .catch(err => toastContainer(messages.EVENT_SCHEDULED_ERROR, "error"));
    closeAddCandidatePopup();
  };

  const handleSelect = data => {
    let newCandidates = [...selectedCandidates];
    //check if selected
    const existingIndex = newCandidates.findIndex(
      candidate => candidate.email === data.email
    );

    if (existingIndex >= 0) {
      //remove previously selected
      newCandidates = newCandidates.filter(
        candidate => candidate.email !== data.email
      );
    } else {
      //add in selected candidates
      newCandidates.push(data);
    }
    services.storeModuleStepResponses(currentModuleObject.moduleId, {
      answers: JSON.stringify({ candidates: newCandidates }),
      moduleResponseId: currentModuleObject.id,
      isLastStep: true,
      isCompleted: true,
      //fixed module stepid
      moduleStepId: 24,
    });
    setSelectedCandidates(newCandidates);
  };

  const isSelected = data => {
    const selected = selectedCandidates.find(
      candidate => candidate.email === data.email
    );
    if (selected) {
      return true;
    } else {
      return false;
    }
  };

  const CandidateCard = ({ data }) => {
    return (
      <Card
        sx={{
          background: theme.palette.secondary.backgroundGradient,
          border: `1px solid ${theme.palette.secondary.main}`,
          borderRadius: "12px",
          p: 2,
        }}
      >
        <Grid container>
          <Grid item xs={11}>
            <Box display={"flex"} alignItems="center">
              <img src={data.avatar} height="40" width="40" />
              <Box sx={{ ml: 1 }} display={"flex"} flexDirection="column">
                <Typography variant="title_medium">{data.name}</Typography>
                <Typography variant="subhead2">{data.email}</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={1}>
            {isSelected(data) ? (
              <Bookmark
                sx={{ cursor: "pointer" }}
                onClick={() => handleSelect(data)}
              />
            ) : (
              <BookmarkBorder
                sx={{ cursor: "pointer" }}
                onClick={() => handleSelect(data)}
              />
            )}
          </Grid>
        </Grid>
      </Card>
    );
  };
  return (
    <Box>
      <Typography variant="title_large">Candidates in StartupOS</Typography>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        {candidates &&
          candidates.map(data => {
            return (
              <Grid item md={6} sm={12} xs={12}>
                <CandidateCard data={data} />
              </Grid>
            );
          })}
      </Grid>

      <Divider sx={{ mt: 3, mb: 2 }} />

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="1fr"
        gap={2}
      >
        <Box
          sx={{
            gridColumn: {
              xl: "span 6",
              lg: "span 2",
              md: "span 2",
              sm: "none",
              xs: "none",
            },
            display: { xs: "none", sm: "none", md: "block" },
          }}
        ></Box>
        <Box
          sx={{
            gridColumn: {
              xl: "span 3",
              lg: "span 5",
              md: "span 5",
              sm: "span 12",
              xs: "span 12",
            },
          }}
          textAlign="center"
        >
          <Button
            variant="outlined"
            startIcon={<Add sx={{ fontSize: 16 }} />}
            size="sm"
            onClick={() => setShowAddCandidates(true)}
          >
            Add external candidate
          </Button>
        </Box>
        <Box
          sx={{
            gridColumn: {
              xl: "span 3",
              lg: "span 5",
              md: "span 5",
              sm: "none",
              xs: "none",
            },
            display: { xs: "none", sm: "none", md: "block" },
          }}
        >
          <Button onClick={returnToDetailsPage}>
            Return to PowerUp Details
          </Button>
        </Box>
      </Box>
      <AddExternalCandidate
        open={showAddCandidates}
        onClose={closeAddCandidatePopup}
        addCandidate={addCandidate}
      />
    </Box>
  );
}
