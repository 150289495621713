import React from "react";
import { Grid, IconButton, Box } from "@mui/material";
import { FABICON, STARTUPOS_TRANSPARENT } from "../../../constants/image";
export default function ChatIcons() {
  return (
    <>
      <Box
        sx={{
          display: { sm: "none", xs: "none", md: "block" },
          position: "fixed",
          bottom: "5%",
          right: "2%",
        }}
      >
        <IconButton
          variant="contained"
          color="primary"
          sx={{ p: 3, borderRadius: "16px" }}
        >
          <img src={STARTUPOS_TRANSPARENT} />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: { md: "none", lg: "none", xl: "none" },
          position: "fixed",
          bottom: "20%",
          right: "2%",
        }}
      >
        <IconButton>
          <img src={FABICON} />
        </IconButton>
      </Box>
    </>
  );
}
