import styled from "@emotion/styled";

export const ValidationContainer = styled.div(({ theme }) => {
  return {
    border: "1px solid #A1ACAC",
    boxSizing: "border-box",
    borderRadius: "5px",
    padding: "16px 28px 16px 12px",
    marginTop: "16px",
  };
});

export const ValidationBoxText = styled.div(({ theme }) => {
  return {
    fontFamily: "var(--roboto-regular)",
    fontStyle: "var(--font-style-normal)",
    fontWeight: "var(--font-weight-400)",
    fontSize: "var(--font-size-14)",
    lineHeight: "var(--line-height-150-percent)",
    letterSpacing: "-0.006em",
    color: "var(--sub-text-color)",
  };
});
