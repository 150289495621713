import React, { useEffect, useState, useRef } from "react";
import { Typography, Box, Grid, TextField, Divider } from "@mui/material";
import { Responsive, WidthProvider } from "react-grid-layout";
import RemoveRedEyeSharpIcon from "@mui/icons-material/RemoveRedEyeSharp";
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import useSize from "@react-hook/size";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Modal } from "@mui/material";
import theme from "../../constants/Theme";
import services from "../../services";
import { RESIZE_ICON } from "../../constants/image";
import IconPicker from "../IconPicker";
import { AVATARS, CONTACT_ICONS } from "../../constants/powerup-data";
import { PROFILE_PLACE_HOLDER } from "../../constants/image";

const ObserveHeightComponent = ({ children, reportChange }) => {
  const target = useRef(null);
  const [width, height] = useSize(target);

  useEffect(() => {
    reportChange({
      width: width,
      height: height,
    });
  }, [width, height]);

  return (
    <Box sx={{ p: 1, boxSizing: "border-box" }} ref={target}>
      {children}
    </Box>
  );
};
const getTextFromSelectedIcon = urls => {
  const iconTextMap = {
    Phone: CONTACT_ICONS[0],
    Email: CONTACT_ICONS[1],
    Text: CONTACT_ICONS[2],
    "Social Media": CONTACT_ICONS[3],
    People: CONTACT_ICONS[4],
  };
  let selectedIcons = [];
  for (const key in iconTextMap) {
    if (
      Object.hasOwnProperty.call(iconTextMap, key) &&
      urls.includes(iconTextMap[key])
    ) {
      selectedIcons.push(key);
    }
  }
  return selectedIcons.join(",");
};

const ResponsiveGridLayout = WidthProvider(Responsive);
const GridStackComp = ({
  powerUpExcercises,
  excerciseSteps,
  initialData,
  autoSave,
  triggerSaveAction,
  setTriggerSaveAction,
  moduleId,
  versionId,
  getExerciseDetailsApiCall,
}) => {
  const gridParams = {
    autoSize: true,
    cols: 12,
    rowHeight: 20,
  };
  const [profileObj, setProfileObj] = useState([
    {
      label: "Name",
      value: initialData?.personaName,
      avatar: initialData?.avatar,
    },
    { label: "Age", value: initialData?.age },
    { label: "Occupation", value: initialData?.jobTitle },
    { label: "Industry", value: initialData?.industry },
    {
      label: "Location",
      value: initialData?.state + "," + initialData?.city,
    },
    {
      label: "Highest Level of Education",
      value: initialData?.educationLevel,
    },
    {
      label: "Preferred communication",
      value: getTextFromSelectedIcon(initialData?.communicationMethods),
    },
  ]);
  const [layout, setLayout] = useState([
    {
      i: "1",
      x: 0,
      y: 2,
      w: 4,
      h: 7,
    },
    {
      i: "2",
      x: 4,
      y: 2,
      w: 4,
      h: 7,
    },
    {
      i: "3",
      x: 8,
      y: 2,
      w: 4,
      h: 7,
    },
    {
      i: "4",
      x: 0,
      y: 4,
      w: 6,
      h: 7,
    },
    {
      i: "5",
      x: 6,
      y: 4,
      w: 6,
      h: 7,
    },
  ]);
  const [cardContent, setCardContent] = useState([
    {
      id: 1,
      hidden: false,
      title: "Goals & Objectives",
      text: initialData?.goals.join("\r\n"),
    },
    {
      id: 2,
      hidden: false,
      title: "Biggest Challenges",
      text: initialData?.challenges.join("\r\n"),
    },
    {
      id: 3,
      hidden: false,
      title: "Job Responsibilities",
      text: initialData?.jobResponsibilities.join("\r\n"),
    },
    {
      id: 4,
      hidden: false,
      title: "Tools Needed",
      text: initialData?.tools.join("\r\n"),
    },
    {
      id: 5,
      hidden: false,
      title: "Gain Information By",
      text: "find a mentor who has been in her shoes before and knows the path she should take get in the room with investors and start building a future for her startup",
    },
  ]);
  const [currentExerciseData, setCurrentExerciseData] = useState(null);

  const [editAvatarModalOpen, setEditAvatarModalOpen] = useState(false);

  useEffect(() => {
    let currentStep = excerciseSteps.find(excercise => excercise.stepNo === 4);

    const newExcerciseData = powerUpExcercises.find(
      excercise => excercise.stepNo === currentStep.stepNo
    );

    if (newExcerciseData?.answers?.gridData) {
      setLayout(newExcerciseData?.answers?.gridData);
      setCardContent(newExcerciseData?.answers?.contentData);
      setProfileObj(newExcerciseData?.answers?.profileData);
    }
    setCurrentExerciseData(newExcerciseData);
  }, []);

  useEffect(() => {
    if (currentExerciseData) {
      powerupApiCall(layout, cardContent, profileObj);
    }
  }, [currentExerciseData]);

  useEffect(() => {
    //Trigger autosave flag and call save api and again set trigger flag false
    if (triggerSaveAction) {
      powerupApiCall(layout, cardContent, profileObj);
      setTriggerSaveAction(false);
    }
  }, [triggerSaveAction]);

  const selectAvatar = data => {
    let cardData = [...profileObj];
    cardData[0].avatar = data;
    setProfileObj(cardData);
    powerupApiCall(layout, cardContent, cardData);
  };

  const onDragStop = (newLayout, layouts) => {
    layout.map(layoutItem => {
      if (layoutItem.i === layouts.i) {
        layoutItem = layouts;
        return layoutItem;
      }
    });

    powerupApiCall(newLayout, cardContent, profileObj);
  };
  const onResizeStop = (newLayout, layouts) => {
    layout.map(layoutItem => {
      if (layoutItem.i === layouts.i) {
        layoutItem = layouts;
        return layoutItem;
      }
    });
    powerupApiCall(newLayout, cardContent, profileObj);
  };
  const handleResize = (size, key) => {
    let element = layout.find(x => !x.hidden && x.i === key);
    if (typeof element !== "undefined" && element.hasOwnProperty("h")) {
      let boxHeight = element.h;
      if (size && size.height && size.height >= boxHeight) {
        let heightFactor = Math.round(size.height / gridParams.rowHeight);
        heightFactor = heightFactor >= 1 ? heightFactor : 1;
        setLayout(
          layout.map(item =>
            item.i === key ? { ...item, h: heightFactor } : item
          )
        );
      }
    }
  };
  const addNewSection = () => {
    let newLayoutElement = {
      i: `${layout.length + 1}`,
      x: 0,
      y: Math.max(...layout.map(o => o.y)) + 2,
      w: 12,
      h: 1,
    };
    setLayout(layout => [...layout, newLayoutElement]);

    let newContentElement = {
      id: layout.length + 1,
      title: "",
      text: "",
    };

    setCardContent(cardContent => [...cardContent, newContentElement]);
  };
  const handleTextChange = (event, id, type) => {
    let newcontent = cardContent.map(item => {
      if (item.id == id) {
        if (type === "title") {
          item.title = event.target.value;
        } else if (type === "text") {
          item.text = event.target.value;
        } else {
          if (profileObj) {
            profileObj.find(someobject => someobject.label == type).value =
              event.target.value;
          }
        }
      }

      return item;
    });
    setCardContent(newcontent);
  };
  const powerupApiCall = (gridData, contentData, profileData) => {
    if (autoSave || triggerSaveAction) {
      let requestBody = {
        ...currentExerciseData?.answers,
        gridData: gridData,
        contentData: contentData,
        profileData: profileData,
      };

      services
        .storeModuleStepResponses(moduleId, {
          ...currentExerciseData,
          isLastStep: true,
          answers: JSON.stringify(requestBody),
        })
        .then(response => {
          if (response.data.code === 200) {
            getExerciseDetailsApiCall();
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const handleOnBlur = e => {
    powerupApiCall(layout, cardContent, profileObj);
  };

  const onEyeIconClicked = (id, isHidden) => {
    let newContent = cardContent.map(item => {
      if (item.id == id) {
        item.hidden = !isHidden;
      }
      return item;
    });

    setCardContent(newContent);

    let newLayout = layout.map(itm => {
      if (id == itm.i) {
        let newLayoutElement = {
          i: `${id}`,
          x: 0,
          y:
            Math.max(
              ...layout
                .filter(
                  (lay, indexx) => cardContent[indexx].hidden == !isHidden
                )
                .map(o => o.y)
            ) + 2,
          w: 12,
          h: 1,
          isDraggable: isHidden,
          isResizable: isHidden,
        };
        return newLayoutElement;
      }
      return itm;
    });
    setLayout(newLayout);

    powerupApiCall(newLayout, newContent, profileObj);
  };

  return (
    <Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: "34px",
            mx: 1,
          }}
        >
          <Box
            src={(profileObj && profileObj[0]?.avatar) || PROFILE_PLACE_HOLDER}
            onClick={() => setEditAvatarModalOpen(true)}
            component="img"
            sx={{
              p: 10,
              height: "66px",
              width: "66px",
              cursor: "Pointer",
            }}
          ></Box>
          <Box sx={{ height: 1, py: 2 }}>
            {profileObj &&
              profileObj.map(itm => {
                return (
                  <Grid sx={{ display: "flex", pl: 3, pt: 1 }} container>
                    <Grid item sm={7} md={7}>
                      <Typography variant="label_medium">
                        {itm.label}:
                      </Typography>
                    </Grid>
                    <Grid item sm={5} md={5}>
                      <TextField
                        type="text"
                        variant="standard"
                        multiline
                        sx={{ pr: 2 }}
                        InputProps={{
                          disableUnderline: true,

                          style: {
                            fontSize: "12px",
                            lineHeight: "16px",
                            fontFamily:
                              '"PoppinsMedium",  "Helvetica", "Arial", sans-serif',
                          },
                        }}
                        value={itm.value}
                        onChange={e => handleTextChange(e, "1", itm.label)}
                        onBlur={e => handleOnBlur(e)}
                      />
                    </Grid>
                  </Grid>
                );
              })}
          </Box>
        </Box>
      </Box>

      <ResponsiveGridLayout
        className="layout"
        layouts={{ lg: layout }}
        onDragStop={onDragStop}
        onResizeStop={onResizeStop}
        rowHeight={gridParams.rowHeight}
        cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
        width={800}
      >
        {layout.map((item, idx) => {
          return !cardContent[idx].hidden ? (
            <Box
              sx={{
                border: `1px solid ${theme.palette.secondary.dark}`,
                borderRadius: "34px",
                ".eyeIcon": {
                  display: "none",
                },
                "&:hover .eyeIcon": {
                  display: "block",
                },
                ".dragIcon": {
                  display: "none",
                },
                "&:hover .dragIcon": {
                  display: "block",
                },
                ".resizeIcon": {
                  display: "none",
                },
                "&:hover .resizeIcon": {
                  display: "block",
                },
              }}
              key={item.i}
            >
              <ObserveHeightComponent
                reportChange={size => handleResize(size, item.i)}
              >
                <Box>
                  <Box
                    sx={{
                      py: 3,
                      pl: 3,
                      pr: 1,
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Box>
                        <TextField
                          type="text"
                          variant="standard"
                          placeholder="New Section"
                          multiline
                          sx={{ pr: 2 }}
                          InputProps={{
                            disableUnderline: true,

                            style: {
                              fontSize: "12px",
                              lineHeight: "16px",
                              fontFamily:
                                '"PoppinsMedium",  "Helvetica", "Arial", sans-serif',
                            },
                          }}
                          value={cardContent[idx].title}
                          onChange={e => handleTextChange(e, item.i, "title")}
                          onBlur={e => handleOnBlur(e)}
                        />
                      </Box>
                      <Box
                        sx={{
                          textAlign: "right",
                          cursor: "pointer",
                          ml: "auto",
                        }}
                      >
                        <Box
                          className="eyeIcon"
                          component={
                            cardContent[idx].hidden
                              ? VisibilityOffSharpIcon
                              : RemoveRedEyeSharpIcon
                          }
                          sx={{
                            cursor: "pointer",
                            color: theme.palette.secondary.dark,
                          }}
                          onClick={() => {
                            onEyeIconClicked(item.i, cardContent[idx].hidden);
                          }}
                        ></Box>
                      </Box>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                      <TextField
                        type="text"
                        variant="standard"
                        placeholder="Description"
                        InputProps={{
                          disableUnderline: true,

                          style: {
                            ontFamily:
                              '"RobotoRegular",  "Helvetica", "Arial", sans-serif',
                            fontSize: "12px",
                          },
                        }}
                        sx={{ width: 1 }}
                        multiline
                        value={cardContent[idx].text}
                        onChange={e => handleTextChange(e, item.i, "text")}
                        onBlur={e => handleOnBlur(e)}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: 1,
                      justifyContent: "space-between",
                      position: "absolute",
                      bottom: theme.spacing(1),
                      pb: 1,
                    }}
                  >
                    {/* Drag icon */}
                    <Box
                      className="dragIcon"
                      component={
                        cardContent[idx].hidden
                          ? VisibilityOffSharpIcon
                          : DragIndicatorIcon
                      }
                      sx={{
                        cursor: "pointer",
                        color: theme.palette.secondary.dark,
                      }}
                    ></Box>
                    {/* resize icon */}
                    <Box
                      className="resizeIcon"
                      component="img"
                      src={RESIZE_ICON}
                      sx={{
                        display: "flex",
                        float: "right",
                        alignSelf: "flex-end",
                        placeSelf: "flex-end",
                        pr: 2,
                      }}
                    ></Box>
                  </Box>
                </Box>
              </ObserveHeightComponent>
            </Box>
          ) : (
            ""
          );
        })}
      </ResponsiveGridLayout>
      <Box
        sx={{
          border: `1px dashed ${theme.palette.secondary.dark}`,
          borderRadius: "28px",
          py: 5,
          mx: 1,
          textAlign: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          addNewSection();
        }}
      >
        <Typography variant="label_medium"> Add new section +</Typography>
      </Box>
      <Divider sx={{ mt: 3, borderColor: "secondary.light" }} />
      <ResponsiveGridLayout
        className="layout"
        layouts={{ lg: layout }}
        rowHeight={gridParams.rowHeight}
        cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
        autoSize={gridParams.autoSize}
        width={800}
        //margin={[0, 0]}
      >
        {layout.map((item, idx) => {
          return cardContent[idx].hidden && idx !== 0 ? (
            <Box
              sx={{
                border: `1px solid ${theme.palette.secondary.dark}`,
                borderRadius: "34px",
                opacity: "0.5",
              }}
              key={item.i}
            >
              <ObserveHeightComponent
                reportChange={size => handleResize(size, item.i)}
              >
                <Box
                  sx={{
                    py: 3,
                    pl: 3,
                    pr: 1,

                    ".eyeIcon": {
                      display: "none",
                    },
                    "&:hover .eyeIcon": {
                      display: "block",
                    },
                    ".dragIcon": {
                      display: "none",
                    },
                    "&:hover .dragIcon": {
                      display: "block",
                    },
                    ".resizeIcon": {
                      display: "none",
                    },
                    "&:hover .resizeIcon": {
                      display: "block",
                    },
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Box>
                      <TextField
                        type="text"
                        variant="standard"
                        placeholder="New Section"
                        multiline
                        sx={{ pr: 2, width: 1 }}
                        InputProps={{
                          disableUnderline: true,

                          style: {
                            fontSize: "12px",
                            lineHeight: "16px",
                            fontFamily:
                              '"PoppinsMedium",  "Helvetica", "Arial", sans-serif',
                          },
                        }}
                        value={cardContent[idx].title}
                        onChange={e => handleTextChange(e, item.i, "title")}
                      />
                    </Box>
                    <Box
                      sx={{
                        textAlign: "right",
                        cursor: "pointer",
                        marginLeft: "auto",
                      }}
                    >
                      <Box
                        className="eyeIcon"
                        component={
                          cardContent[idx].hidden
                            ? VisibilityOffSharpIcon
                            : RemoveRedEyeSharpIcon
                        }
                        sx={{
                          cursor: "pointer",
                          color: theme.palette.secondary.dark,
                        }}
                        onClick={() => {
                          onEyeIconClicked(item.i, cardContent[idx].hidden);
                        }}
                      ></Box>
                    </Box>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      type="text"
                      variant="standard"
                      placeholder="Description"
                      InputProps={{
                        disableUnderline: true,

                        style: {
                          ontFamily:
                            '"RobotoRegular",  "Helvetica", "Arial", sans-serif',
                          fontSize: "12px",
                        },
                      }}
                      sx={{ width: 1 }}
                      multiline
                      value={cardContent[idx].text}
                      onChange={e => handleTextChange(e, item.i, "text")}
                    />
                  </Box>
                  <Box
                    sx={{
                      textAlign: "end",
                      cursor: "pointer",
                      marginLeft: "auto",
                    }}
                  >
                    <Box
                      className="dragIcon"
                      component={
                        cardContent[idx].hidden
                          ? DragIndicatorIcon
                          : RemoveRedEyeSharpIcon
                      }
                      sx={{
                        cursor: "pointer",
                        color: theme.palette.secondary.dark,
                      }}
                    ></Box>
                    <Box
                      className="resizeIcon"
                      component="img"
                      src={RESIZE_ICON}
                      sx={{ display: "flex", float: "right" }}
                    ></Box>
                  </Box>
                </Box>
              </ObserveHeightComponent>
            </Box>
          ) : (
            ""
          );
        })}
      </ResponsiveGridLayout>
      <Modal
        open={editAvatarModalOpen}
        onClose={() => setEditAvatarModalOpen(false)}
      >
        <Box
          sx={{
            width: "50%",
            height: "50%",
            margin: "auto",
            p: 5,
            background: theme.palette.secondary.white,
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
          }}
        >
          <Typography variant="title_small">Update your avatar</Typography>
          <IconPicker
            selectedIcon={cardContent[0].avatar}
            setSelectedIcon={selectAvatar}
            icons={AVATARS}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default GridStackComp;
