import * as React from "react";

import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import { BorderLinearProgress } from "./styles";

function CustomizedProgressBars({ value }) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <BorderLinearProgress variant="determinate" value={value} />
    </Box>
  );
}

CustomizedProgressBars.propTypes = {
  value: PropTypes.number,
};

CustomizedProgressBars.defaultProps = {
  value: 9,
};
export default CustomizedProgressBars;
