import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Countdown from "react-countdown";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import JsxParser from "react-jsx-parser";

import { TIMERICON, EDITICON } from "../../../constants/image";
import { StayOnTrackTitle, CalenderBox } from "./styles";
import CountDownTimer from "../../../common/countDownTimer/CountDownTimer";
import constants from "../../../constants";
import { getCharMonth, getDate } from "../../../utils/date";
import theme from "../../../constants/Theme";

export default function StayOnTrack({ data, stayOnTrackData }) {
  const getPadStart = number => {
    return (Number(number) < 10 ? "0" : "") + number;
  };

  const timerRenderer = ({ days, hours, minutes }) => {
    return (
      <Box>
        <CountDownTimer
          days={getPadStart(days)}
          hours={getPadStart(hours)}
          minutes={getPadStart(minutes)}
        />
      </Box>
    );
  };
  return (
    <Box sx={{ p: 3, width: "100%" }} alignItems="center">
      <StayOnTrackTitle>
        <img src={TIMERICON} alt="timer" />
        <Typography
          sx={{
            pl: 2,
          }}
          variant="h3"
        >
          Stay on Track
        </Typography>
      </StayOnTrackTitle>
      <Box sx={{ alignItems: "center", display: "flex", mt: 4 }}>
        <Typography variant="h4" color="secondary">
          Days to Pitch Readiness
        </Typography>
      </Box>
      <Box sx={{ mt: 4 }}>
        <Countdown
          daysInHours={false}
          date={data?.demoDate ? new Date(data?.demoDate) : new Date()}
          renderer={timerRenderer}
        />
      </Box>
      <Divider sx={{ mt: 3, background: theme.palette.secondary.dark }} />
      <Typography
        sx={{
          mt: 3,
        }}
        variant="h4"
      >
        Today’s Top 3
      </Typography>

      {stayOnTrackData?.todayTopDetails?.map((topData, i) => (
        <>
          <FormControlLabel
            className="parser-control"
            sx={{ fontSize: 14, mt: 3 }}
            control={
              <Checkbox
                sx={{
                  color: theme.palette.primary.main,
                  mr: 2,
                }}
                size="small"
              />
            }
            label={
              <JsxParser components={{ Typography }} jsx={topData?.text} />
            }
          />
          {i === stayOnTrackData?.todayTopDetails.length - 1 ? (
            <Divider sx={{ mt: 3, background: theme.palette.secondary.dark }} />
          ) : (
            <Divider sx={{ mt: 3, background: "rgba(118, 73, 160, 0.08)" }} />
          )}
        </>
      ))}

      <Box sx={{ mt: 4, display: "flex" }}>
        <Typography variant="h4" color="secondary">
          Next Key Milestones
        </Typography>
      </Box>
      <Box sx={{ mt: 2.7 }}>
        {stayOnTrackData?.milestones.map((item, index) => {
          return (
            <>
              <Box
                sx={{ display: "flex", alignItems: "baseline" }}
                className="parser-control"
              >
                <CalenderBox>
                  <Typography
                    variant="h7"
                    sx={{ textAlign: "center" }}
                    color="primary"
                  >
                    {getCharMonth(item.date)}
                  </Typography>
                  <Typography variant="h5" sx={{ textAlign: "center" }}>
                    {getDate(item.date)}
                  </Typography>
                </CalenderBox>
                <Typography sx={{ ml: 2 }}>
                  <JsxParser component={{ Typography }} jsx={item?.title} />

                  <Typography
                    color="primary"
                    sx={{ fontSize: "12px !important" }}
                  >
                    <JsxParser
                      component={{ Typography }}
                      jsx={item?.subTitle}
                    />
                  </Typography>
                </Typography>
              </Box>
              {index !== stayOnTrackData?.milestones.length - 1 ? (
                <Divider
                  sx={{
                    mt: 1,
                    ml: 8.2,
                    background: "rgba(118, 73, 160, 0.08)",
                  }}
                />
              ) : (
                ""
              )}
            </>
          );
        })}
        <Box sx={{ textAlign: "end", display: { xs: "block", md: "none" } }}>
          <Button sx={{ mt: 3, width: "auto" }}> View all Milestones</Button>
        </Box>
      </Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Divider sx={{ mt: 3, background: theme.palette.secondary.dark }} />
        <Typography
          sx={{
            mt: 3,
          }}
          variant="h4"
          color="secondary"
        >
          Current Streaks
        </Typography>

        {constants.CURRENT_STREAKS.map((item, index) => {
          const streak = data.streaks?.find(
            ele => ele?.streakType?.streakName === item.type
          )?.daysMaintained;

          return (
            <>
              {" "}
              <Box sx={{ mt: 3.6, display: "flex", alignItems: "baseline" }}>
                <Typography color="secondary">{item.text}</Typography>
                &nbsp;
                <Typography color="secondary" variant="h5">
                  {item.boldText}
                </Typography>
                <Typography
                  color="primary"
                  variant="h5"
                  sx={{
                    marginLeft: "auto",
                    marginLeft: "auto",
                    background: "#F2DAFF",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  {streak || 0}
                </Typography>
              </Box>{" "}
              {index !== constants.CURRENT_STREAKS.length - 1 ? (
                <Divider
                  sx={{
                    mt: 2,

                    background: "rgba(118, 73, 160, 0.08)",
                  }}
                />
              ) : (
                ""
              )}
            </>
          );
        })}
      </Box>
    </Box>
  );
}
