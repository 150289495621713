import React, { useState, useEffect } from "react";

import MUIRichTextEditor from "mui-rte";

import {
  convertToRaw,
  EditorState,
  ContentBlock,
  CharacterMetadata,
  genKey,
  ContentState,
} from "draft-js";

import { List, Repeat } from "immutable";

import { stateFromMarkdown } from "draft-js-import-markdown"; // using module to convert markdown to draft-js state markdown as draft-js don't provide any conversion solution for markdown to rawData
import { Box, ThemeProvider, Typography } from "@mui/material";
import theme from "../../../../../constants/Theme";

export default function TextEditorComponent(props) {
  const { version, id, label, initialValue, onChange, readOnly, ...rest } =
    props;
  const [initial, setInitial] = useState("");

  useEffect(() => {
    let init_state;
    if (Array.isArray(initialValue)) {
      //if initial value is array then render list of array items in unordered manner else render markdown
      const contentBlocksArray = initialValue.map(word => {
        return new ContentBlock({
          key: genKey(),
          type: "unordered-list-item",
          characterList: new List(
            Repeat(CharacterMetadata.create(), word.length)
          ),
          text: word,
        });
      });

      init_state = EditorState.createWithContent(
        ContentState.createFromBlockArray(contentBlocksArray)
      );
    } else {
      if (initialValue) {
        init_state = EditorState.createWithContent(
          stateFromMarkdown(initialValue)
        );
      }
    }

    if (init_state) {
      setInitial(JSON.stringify(convertToRaw(init_state.getCurrentContent())));

      onChange({ key: id, value: init_state });
    }
  }, [version]);

  Object.assign(theme, {
    overrides: {
      MUIRichTextEditor: {
        editor: {
          minHeight: "50px",
          display: !(readOnly && !initialValue) ? "block" : "none",
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Box sx={{ pb: !(readOnly && !initialValue) ? 2 : 0 }}>
          <Typography variant="title_medium">{label}</Typography>
        </Box>
        <MUIRichTextEditor
          key={id}
          onChange={data => onChange({ key: id, value: data })}
          value={initial}
          toolbar={false}
          inlineToolbar={false}
          readOnly={readOnly}
          {...rest}
        ></MUIRichTextEditor>
      </Box>
    </ThemeProvider>
  );
}
