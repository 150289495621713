import React, { Fragment, useEffect } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { AttributeItem } from "./attribute-item";

export const DetailsTab = ({ ...props }) => {
  const [tab, setTab] = React.useState(props.tab);
  useEffect(() => {
    setTab(props.tab);
  }, [props.tab]);
  return (
    <Fragment>
      {tab && tab.name && (
        <>
          <Typography
            variant="title_medium"
            sx={{ textTransform: "capitalize", my: 2 }}
          >
            {tab.name}
          </Typography>
          <Divider />
        </>
      )}
      <Box
        sx={{
          boxSizing: "border-box",
          flexGrow: 1,
          overflow: "scroll",
        }}
      >
        {tab && tab.description && (
          <Box sx={{ my: 2 }}>
            <Typography>{tab.description}</Typography>
          </Box>
        )}

        {tab &&
          tab.powerup_attributes &&
          tab.powerup_attributes.length > 0 &&
          tab.powerup_attributes.map((item, index) => (
            <Box sx={{ my: 4 }} key={index}>
              <AttributeItem
                name={item.name}
                value={item.value}
                icon={item.icon}
              />
            </Box>
          ))}
      </Box>
    </Fragment>
  );
};
