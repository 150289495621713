import React, { Fragment } from "react";
import { Box } from "@mui/material";

import { TabBar } from "./components/tab-bar";
import { TabContent } from "./components/tab-content";

export default function SidebarTabs({ ...props }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    if (newValue !== null) {
      setValue(newValue);
    }
  };

  return (
    <Fragment>
      <Box sx={{ my: 2 }}>
        <TabBar tabs={props.tabs} handleChange={handleChange} value={value} />
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          overflow: "hidden",
        }}
      >
        {props.tabs &&
          props.tabs.length > 0 &&
          props.tabs.map((tab, index) => (
            <TabContent key={index} tab={tab} index={index} value={value} />
          ))}
      </Box>
    </Fragment>
  );
}
