import React from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import { WorkspaceStepIcon } from "../../../common/icons";

const WorkspaceStepper = ({
  activeStep,
  setActiveStep,
  steps,
  nonLinear,
  linear,
  type,
  ActiveIcon,
  DefaultIcon,
  CompletedIcon,
  fixedWidth,
  LastStepIcon,
  powerUpExcercises,
  isSummaryExists,
}) => {
  const StepIcon = props => {
    const { active, completed, error, icon } = props;
    if (type === "POWERUP") {
      if (icon === steps.length && isSummaryExists) {
        return <LastStepIcon />;
      } else {
        if (active) {
          return <ActiveIcon active number={icon} />;
        } else if (isStepFilled(steps[icon - 1])) {
          return <CompletedIcon active />;
        } else {
          return <DefaultIcon number={icon} />;
        }
      }
    } else {
      if (active) {
        return <ActiveIcon active number={icon} />;
      } else {
        return <DefaultIcon number={icon} />;
      }
    }
  };

  const isStepFilled = step => {
    const filledStep = powerUpExcercises.find(
      excercise => excercise.stepNo === step.stepNo
    );
    if (filledStep) {
      return true;
    } else {
      return false;
    }
  };

  const handleStep = (index, step) => () => {
    if (linear && type === "POWERUP") {
      if (isStepFilled(step)) {
        setActiveStep(index);
      }
    } else {
      setActiveStep(index);
    }
  };

  return (
    <>
      <Box
        sx={
          type === "WORKSPACE"
            ? { position: "absolute", overflowX: "scroll", width: "100%" }
            : {}
        }
      >
        <Stepper
          nonLinear={nonLinear}
          linear={linear}
          activeStep={activeStep}
          connector={<StepConnector />}
          style={{ width: fixedWidth, minWidth: "100%" }} // defining the abosulte width of stepper in order to configure the steppers scrollable in responsive.
        >
          {steps.map((step, index) => (
            <Step
              key={index}
              onClick={handleStep(index, step)}
              sx={{ cursor: "pointer", pl: index === 0 ? 0 : 1 }}
            >
              <StepLabel StepIconComponent={StepIcon}>
                <Typography
                  variant={activeStep === index ? "title_medium" : "subhead2"}
                  sx={{
                    color: theme =>
                      activeStep === index
                        ? theme.palette.text.primary
                        : theme.palette.secondary.dark,
                  }}
                >
                  {type === "WORKSPACE"
                    ? step.powerUpPackName
                    : type === "POWERUP" && !step.isLast
                    ? activeStep === index
                      ? step.title
                      : null
                    : null}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </>
  );
};

{
  WorkspaceStepper.propTypes = {
    nonLinear: PropTypes.bool,
    linear: PropTypes.bool,
    ActiveIcon: PropTypes.func,
    DefaultIcon: PropTypes.func,
    CompletedIcon: PropTypes.func,
    steps: PropTypes.array,
    type: PropTypes.string,
    activeStep: PropTypes.number,
    setActiveStep: PropTypes.func,
    fixedWidth: PropTypes.number,
    LastStepIcon: PropTypes.func,
  };
  WorkspaceStepper.defaultProps = {
    nonLinear: true,
    linear: false,
    ActiveIcon: WorkspaceStepIcon,
    DefaultIcon: WorkspaceStepIcon,
    CompletedIcon: null,
    steps: [],
    type: "WORKSPACE",
    activeStep: 0,
    setActiveStep: null,
    fixedWidth: 1300,
    LastStepIcon: null,
  };
}

export default WorkspaceStepper;
