import {
  Typography,
  Box,
  Divider,
  Grid,
  Stack,
  Card,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import SummaryPdf from "../../../components/Workspace/SummaryPdf";
import { useHistory, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import theme from "../../../constants/Theme";
import WorkspaceBreadCrumbs from "../../../components/Workspace/BreadCrumbs";
import FormSectionHeader from "../../../components/Workspace/PowerUp/FormSectionHeader";
import constants from "../../../constants";
import { GreenTicklIcon } from "../../../common/icons";
import Tabs from "../../../components/Workspace/Tabs";
import KnowledgeSidebar from "../../../components/Workspace/PowerUp/KnowledgeSidebar";
import { connect } from "react-redux";
import * as actions from "../../../modules/actions";
import RatingCard from "../../../components/Workspace/PowerUp/Rating";
import SummaryStepList from "../../../components/Workspace/PowerUp/SummaryStepList";
import GridStackComp from "../../../common/GridStack";
import services from "../../../services";
import { ArchiveOutlined, FormatColorResetTwoTone } from "@mui/icons-material";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { sosPrimary } from "../../../constants/Theme/colors";

function PersonaBuilderSummary({ powerUpExcercises, setPowerUpExcercises }) {
  const tabItems = constants.SUMMARY_TAB_ITEMS;
  const history = useHistory();
  const summaryGridRef = useRef(null);
  const [loader, setLoader] = useState(FormatColorResetTwoTone);
  const [currentTab, setCurrentTab] = useState(tabItems[0].key);
  const [exerciseDetails, setExerciseDetails] = useState([]);
  const [excerciseSteps, setExcerciseSteps] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const [triggerSaveAction, setTriggerSaveAction] = useState(false);

  const params = useParams();

  const moduleId = parseInt(params.moduleId);
  const versionId = parseInt(params.versionId);

  const createNewPersonna = () => {
    services
      .startModule({ moduleId })
      .then(result => {
        history.push(
          `/workspace/powerup/${"md-persona"}/${moduleId}/${
            result.data.data.id
          }/start`
        );
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setLoader(true);
    getExerciseDetailsApiCall();
    setCurrentTab(tabItems[0].key);
  }, []);

  const getExerciseDetailsApiCall = () => {
    services
      .getModuleStepResponses(moduleId)
      .then(result => {
        const moduleStepsResponses = result.data.data;

        const currentModuleResponse = moduleStepsResponses.find(
          module => module.id === versionId
        );

        setExcerciseSteps(
          currentModuleResponse.moduleStepResponses.map(data => data.moduleStep)
        );
        setLoader(false);

        const mappedExcerciseDetails =
          currentModuleResponse.moduleStepResponses.map(excercise => ({
            moduleResponseId: versionId,
            moduleStepId: excercise.moduleStep.id,
            answers: JSON.parse(excercise.answers),
            stepNo: excercise.moduleStep.stepNo,
            isCompleted: true,
          }));

        setExerciseDetails(mappedExcerciseDetails);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    if (exerciseDetails.length) {
      var initialData = {};
      exerciseDetails.map(exercise => {
        initialData = { ...initialData, ...exercise.answers };
      });
      setInitialData(initialData);
    }
  }, [exerciseDetails]);

  const handleExport = async type => {
    const element = summaryGridRef.current;
    const canvas = await html2canvas(element, {
      // allowTaint: true,
      useCORS: true,
      proxy: "http://d3rm35snnwgfg3.cloudfront.net",
      logging: true,
      onclone: clonedDoc => {
        clonedDoc.getElementById("summaryDivHeader").style.visibility =
          "visible";
        clonedDoc.getElementById("summaryDivHeader").style.maxHeight =
          "inherit";
      },
    });

    const data = canvas.toDataURL("image/png", 1);
    if (type === "img") {
      const link = document.createElement("a");
      link.href = data;
      link.download = "summary.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      const pdf = new jsPDF();
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("summary.pdf");
    }
  };

  return (
    <>
      {loader ? (
        <Box justifyContent={"center"} sx={{ display: "flex", mt: 18 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Stack
          sx={{
            minHeight: 1,
            borderRadius: "28px",
            p: 4,
            [theme.breakpoints.down("md")]: {
              p: 0,
              borderRadius: 0,
            },
            background:
              "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
          }}
        >
          <Grid
            container
            sx={{ maxWidth: theme.breakpoints.values.xl, margin: "auto" }}
            flexGrow={1}
            flexDirection="column"
          >
            <WorkspaceBreadCrumbs subpath="Initial Persona Builder" />
            <Box
              sx={{
                display: { xs: "block", sm: "block", md: "none" },
                mt: 3,
                mx: 2,
              }}
            >
              <Tabs
                tabItems={tabItems}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                color="primary"
                spacing={0}
                buttonWidth="50%"
                width="100%"
              />
            </Box>
            <Grid container sx={{ minHeight: "100%", mb: 10 }}>
              <Grid
                item
                md={8}
                sm={12}
                xs={12}
                sx={theme => ({
                  [theme.breakpoints.up("md")]: {
                    borderRight: `1px solid ${theme.palette.secondary.main}`,
                    pr: 3,
                    mt: 4,
                  },
                  [theme.breakpoints.down("md")]: {
                    p: 2,
                  },
                  flexDirection: "column",
                  minHeight: "100%",
                  display: {
                    sm: currentTab === "excercise" ? "flex" : "none",
                    xs: currentTab === "excercise" ? "flex" : "none",
                    md: "flex",
                  },
                })}
              >
                <Card
                  sx={{
                    borderRadius: "28px",
                    border: `1px solid ${theme.palette.secondary.dark}`,
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    [theme.breakpoints.down("md")]: {
                      background: "transparent",
                      border: "none",
                      boxShadow: "0",
                      p: 1,
                      mt: 2,
                    },
                  }}
                >
                  <FormSectionHeader heading="INITIAL PERSONA BUILDER" />
                  <Divider
                    sx={{
                      background: theme => theme.palette.secondary.dark,
                      [theme.breakpoints.down("md")]: {
                        background: theme =>
                          theme.palette.secondary.borderLight,
                      },
                    }}
                  />
                  <Box flexDirection={"column"}>
                    {params.type !== "completed-summary" ? (
                      <>
                        <Box
                          sx={{ display: "flex", mt: 3, alignItems: "center" }}
                        >
                          <Box
                            sx={{
                              mr: 2,
                            }}
                          >
                            <GreenTicklIcon />
                          </Box>

                          <Typography variant="h3">Completed!</Typography>
                        </Box>
                        <Box sx={{ mt: 3 }}>
                          <SummaryStepList
                            StepsData={constants.PERSONA_BUILDER_STEPS}
                          />
                        </Box>
                      </>
                    ) : null}

                    <Box sx={{ mt: 3 }}>
                      <Typography variant="largeTitle">Summary</Typography>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                      <Typography variant="subhead1">
                        Here’s a summary of the work you’ve completed in this
                        PowerUp. You can take this as many times as you’d like
                        and edit whatever you’ve already completed. Don’t forget
                        to get feedback on your completed PowerUp.
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        mt: 3,
                        [theme.breakpoints.down("md")]: {
                          justifyContent: "center",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          [theme.breakpoints.up("md")]: {
                            marginLeft: "auto",
                          },
                          mr: 1,
                        }}
                      >
                        <Button
                          variant="outlined"
                          startIcon={<AddIcon />}
                          onClick={() => handleExport("img")}
                        >
                          Export to PNG
                        </Button>
                      </Box>
                      <Box>
                        <Button
                          variant="outlined"
                          startIcon={<AddIcon />}
                          onClick={() => handleExport("pdf")}
                        >
                          Export to PDF
                        </Button>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        mt: 3,
                        width: 1,
                        display: { sm: "none", xs: "none", md: "block" },
                      }}
                    >
                      {initialData && (
                        <>
                          <GridStackComp
                            powerUpExcercises={exerciseDetails}
                            excerciseSteps={excerciseSteps}
                            initialData={initialData}
                            autoSave={params.type === "summary"}
                            triggerSaveAction={triggerSaveAction}
                            setTriggerSaveAction={setTriggerSaveAction}
                            moduleId={moduleId}
                            versionId={versionId}
                            getExerciseDetailsApiCall={
                              getExerciseDetailsApiCall
                            }
                          />
                          <SummaryPdf
                            ref={summaryGridRef}
                            powerUpExcercises={exerciseDetails}
                            excerciseSteps={excerciseSteps}
                            initialData={initialData}
                            autoSave={params.type === "summary"}
                            triggerSaveAction={triggerSaveAction}
                            setTriggerSaveAction={setTriggerSaveAction}
                            moduleId={moduleId}
                            versionId={versionId}
                          />
                        </>
                      )}
                    </Box>

                    {/*<Divider sx={{ mt: 3, borderColor: "secondary.light" }} />*/}
                    <Box
                      sx={{
                        display: { sm: "block", xs: "block", md: "none" },
                        background: theme.palette.secondary.white,
                        borderRadius: "28px",
                        p: 3,
                        mt: 3,
                      }}
                    >
                      <Typography variant="title_medium">
                        Want feedback?
                      </Typography>
                      <Box sx={{ mt: 3 }}>
                        <Typography variant="subhead2">
                          Your StartupOS Advisor is here to help if you would
                          like feedback for this completed PowerUp.
                        </Typography>
                      </Box>
                      <Box sx={{ textAlign: "center", mt: 4 }}>
                        <Button>Submit for feedback</Button>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        mt: 3,
                        display: { sm: "none", xs: "none", md: "flex" },
                        justifyContent:
                          params.type === "completed-summary"
                            ? "space-between"
                            : "flex-end",
                      }}
                    >
                      {params.type === "summary" ? (
                        <>
                          <Box>
                            <Button
                              onClick={createNewPersonna}
                              variant="nofill"
                              sx={{ color: sosPrimary[500] }}
                            >
                              Create another persona
                            </Button>
                          </Box>
                          <Box sx={{ display: "flex", ml: "auto" }}>
                            <Box sx={{ ml: 1 }}>
                              <Button variant="outlined">Rate PowerUp</Button>
                            </Box>
                            <Box sx={{ ml: 2 }}>
                              <Button
                                onClick={() => {
                                  history.push("/workspace");
                                }}
                              >
                                Return to Workspace Home
                              </Button>
                            </Box>
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box>
                            <Button
                              variant="outlined"
                              startIcon={<ArchiveOutlined />}
                            >
                              Archive
                            </Button>
                          </Box>
                          <Box sx={{ ml: 2 }}>
                            <Button onClick={() => setTriggerSaveAction(true)}>
                              Save Changes
                            </Button>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                </Card>
              </Grid>

              <KnowledgeSidebar currentTab={currentTab} />

              <Box
                sx={{
                  display: {
                    sm: currentTab === "rate" ? "flex" : "none",
                    xs: currentTab === "rate" ? "flex" : "none",
                    md: "none",
                  },
                  mt: 3,
                  ml: 2,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    mr: 2,
                  }}
                >
                  <GreenTicklIcon />
                </Box>
                <Typography variant="h3">Completed!</Typography>
              </Box>
              <RatingCard currentTab={currentTab} />
            </Grid>
            <Box
              sx={{
                background: theme.palette.secondary.white,
                position: "fixed",
                bottom: 72,
                width: 1,
                textAlign: "center",
                py: 2,
                display: { sm: "block", xs: "block", md: "none" },
              }}
            >
              <Button
                onClick={() => {
                  history.push("/workspace");
                }}
              >
                Return to Workspace Home
              </Button>
            </Box>
          </Grid>
        </Stack>
      )}
    </>
  );
}

const mapStoreToProps = ({ powerUp }) => ({
  powerUpExcercises: powerUp?.powerUpExcercises,
});

const mapDispatchToProps = dispatch => ({
  setPowerUpExcercises: updatedSteps =>
    dispatch(actions.setPowerUpExcercises(updatedSteps)),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(PersonaBuilderSummary);
