import React from "react";
import PropTypes from "prop-types";
import { PageWrapper, HeaderArea, MainArea } from "./styles";
import AppHeader from "../../components/AppHeader/index";
import { Box, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function DefaultLayout({ children }) {
  const history = useHistory();

  const onBackHandler = () => {
    const pathname = window.location.pathname;
    pathname.split("/")[1] === ""
      ? (window.location.href = process.env.REACT_APP_HOME_URL)
      : history.goBack();
  };

  return (
    <Box sx={{ position: "relative" }}>
      <PageWrapper>
        <HeaderArea>
          <AppHeader onBack={onBackHandler} />
        </HeaderArea>
        <Grid container justifyContent={"center"} alignItems="center">
          <Grid item xs={12} sm={10} md={10} lg={8}>
            <MainArea>{children}</MainArea>
          </Grid>
        </Grid>
      </PageWrapper>
    </Box>
  );
}
DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
