import { ClearOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  tableCellClasses,
  CardActions,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import theme from "../../../../../constants/Theme";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useHistory } from "react-router-dom";
import { getInterviewCandidates } from "../Functions/common";
import MessageHandlerModal from "../../../../../common/modal/MessageHandler";
import ROUTES_PATH from "../../../../../constants/routes";

import services from "../../../../../services";
export default function BrowseCandidatesTable({
  moduleInterview,
  handleStartAction,
  module,
}) {
  const [candidates, setCandidates] = useState(getInterviewCandidates());
  const [isAscending, setIsAscending] = useState(true);
  const [modalConfigs, setModalConfigs] = useState({
    show: false,
  });
  const history = useHistory();
  const handelSort = () => {
    setIsAscending(!isAscending);
    sortCandidates();
  };

  useEffect(() => {
    if (moduleInterview) {
      if (moduleInterview.moduleStepResponses.length) {
        setCandidates(
          JSON.parse(moduleInterview.moduleStepResponses[0].answers).candidates
        );
      } else {
        setCandidates([]);
      }
    } else {
      setCandidates([]);
    }
  }, [moduleInterview]);

  const sortCandidates = () => {
    let data = [...candidates];
    if (isAscending) {
      data.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      });
    } else {
      data.sort((a, b) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1;
      });
    }

    setCandidates(data);
  };

  const removeCandidate = email => {
    setModalConfigs({ show: true, email: email });
  };

  const handleCancel = () => {
    setModalConfigs({ show: false });
  };

  const handleRemove = email => {
    const filteredCandidates = candidates.filter(
      candidate => candidate.email !== email
    );
    services.storeModuleStepResponses(moduleInterview.moduleId, {
      answers: JSON.stringify({ candidates: filteredCandidates }),
      moduleResponseId: moduleInterview.id,
      isLastStep: true,
      isCompleted: true,
      //fixed module stepid
      moduleStepId: 24,
    });
    setCandidates(filteredCandidates);

    setModalConfigs({ show: false });
  };
  return (
    <Card size="large" variant="outlined">
      <Box sx={{ my: 2 }}>
        <Typography variant="title_medium">Interview Candidates</Typography>
      </Box>

      {candidates.length === 0 ? (
        <Box sx={{ mb: 3 }} textAlign="center">
          <Button onClick={() => handleStartAction(module)}>
            Browse candidates
          </Button>
        </Box>
      ) : (
        <Box>
          <Divider />
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    onClick={handelSort}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography variant="title_small">Name</Typography>
                    <Box
                      sx={{
                        ml: 1,
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {isAscending ? (
                        <ArrowDownwardIcon />
                      ) : (
                        <ArrowUpwardIcon />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="title_small">Status</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="title_small">Date</Typography>
                  </TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none",
                  },
                }}
              >
                {candidates.map(row => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Box display={"flex"} alignItems={"center"}>
                        <img src={row.avatar} height="40" width="40" />
                        <Typography sx={{ ml: 1 }} variant="title_small">
                          {row.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      {row.selected ? "Scheduled" : "--"}
                    </TableCell>
                    <TableCell align="left">{row.interviewDate}</TableCell>
                    <TableCell align="left">
                      <Button
                        variant="noOutlined"
                        size="sm"
                        onClick={() =>
                          history.push({
                            pathname:
                              "/workspace/powerup/4/interviewing-your-candidate",
                            state: { email: row?.email },
                          })
                        }
                      >
                        <Typography variant="title_small" color="primary">
                          Go to interview
                        </Typography>
                      </Button>
                    </TableCell>
                    <TableCell
                      align="left"
                      onClick={() => removeCandidate(row.email)}
                    >
                      <ClearOutlined sx={{ cursor: "pointer" }} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Divider sx={{ mt: 2 }} />

          <CardActions
            sx={{
              mt: 2,
              display: "flex",
              [theme.breakpoints.up("md")]: {
                pl: 10,
              },
              [theme.breakpoints.up("xl")]: {
                pl: 40,
              },
            }}
            disableRipple
            disableTouchRipple
          >
            {/*TODO Redirect to browse candidate page when created*/}
            <Button
              onClick={() =>
                history.push(
                  `/workspace/powerup/4/browse-candidates/${moduleInterview.moduleId}/${moduleInterview.id}/browse`
                )
              }
              variant="outlined"
              size="sm"
              sx={{ px: 1, mr: 1 }}
            >
              Browse more candidates
            </Button>

            {/*TODO Redirect to aggregate notes page when created*/}
            <Button
              fullWidth
              onClick={() => {
                history.push(ROUTES_PATH.AGGREGATE_NOTES);
              }}
            >
              Aggregate Notes
            </Button>
          </CardActions>
        </Box>
      )}

      <MessageHandlerModal
        isOpen={modalConfigs.show}
        onCancel={handleCancel}
        onOk={() => handleRemove(modalConfigs.email)}
        text="Are you sure you want to remove candidate?"
        okText="Delete"
        cancelText="No Thanks"
        messageType="primary"
        heading={"Delete"}
      />
    </Card>
  );
}
