import { Box, Grid, Button, Typography, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import OnboardingCard from "../../../common/card/OnboardingCard";
import constants from "../../../constants";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../../constants/routes";
import * as actions from "../../../modules/actions";
import theme from "../../../constants/Theme";

function StartupJourney({
  getAutoCompleteData,
  fetchedAutoCompleteDetails,
  journeyDetails,
  setStartupJourney,
  autoCompleteDetails,
}) {
  const history = useHistory();

  const [startupJourneyData, setStartupJourneyData] = useState(
    constants.STARTUP_JOURNEY_DATA
  );

  const [answers, setAnswers] = useState([]);
  const [selectedJourneys, setSelectedJourneys] = useState([]);

  useEffect(() => {
    const data = [...startupJourneyData];
    //finding previously saved journey details
    var previousSelectedJourneys = [];
    data.map((item, idx) => {
      //checking if startup journey data's text exists in onboarding.journeyDetails
      if (journeyDetails.includes(item.text)) {
        item.selected = true;
        //if found then push that  into previousSelectedJourneys array
        previousSelectedJourneys.push({ index: idx, value: item.text });
      } else {
        item.selected = false;
      }
      //Finding the autocompleteData journeyDetails for input box
      if (item.text === "Other") {
        //if found add it into answers array which holds state of input box's answers
        if (autoCompleteDetails.journeyDetails) {
          setAnswers([autoCompleteDetails.journeyDetails]);
          item.selected = true;
        }
      }

      data[idx] = item;
    });
    setSelectedJourneys(previousSelectedJourneys);
    setStartupJourneyData(data);
  }, []);

  useEffect(async () => {
    getAutoCompleteData("journeyDetails");
  }, []);

  const handleSelect = (index, answer) => {
    //updating startupjourney data's selected flag based on index
    const data = [...startupJourneyData];
    var selectedJourney = data[index];
    selectedJourney = {
      ...selectedJourney,
      selected: answer ? true : false,
    };
    data[index] = selectedJourney;
    setStartupJourneyData(data);

    //if not getting input for Other Autocomplete field
    if (startupJourneyData[index].text !== "Other") {
      if (answer) {
        var selectedAnswers = [...selectedJourneys];
        var alreadyExistsIndex = selectedAnswers.findIndex(
          (answer) => answer.index === index
        );
        if (alreadyExistsIndex >= 0) {
          var existingAnswer = selectedAnswers[alreadyExistsIndex];
          existingAnswer = {
            ...existingAnswer,
            value: answer,
          };
          selectedAnswers[alreadyExistsIndex] = existingAnswer;
          setSelectedJourneys(selectedAnswers);
        } else {
          setSelectedJourneys([
            ...selectedJourneys,
            { index: index, value: answer },
          ]);
        }
      } else {
        //If no answer comes fromcard remove selected journey
        var filtered = selectedJourneys.filter(
          (answer) => answer.index !== index
        );
        setSelectedJourneys(filtered);
      }
    } else {
      //listen answer from input field and add to answers array
      var autocompleteAnswers = [...answers];
      if (answer) {
        autocompleteAnswers = [];
        autocompleteAnswers[0] = answer;
      } else {
        autocompleteAnswers = [];
      }
      setAnswers(autocompleteAnswers);
    }
  };

  const handleNext = () => {
    const selectedAnswersToString = selectedJourneys.map(
      (journey) => journey.value
    );
    const payload = {
      journeyDetails: selectedAnswersToString,
      autoCompleteData: { journeyDetails: answers.length ? answers[0] : "" },
    };
    setStartupJourney(payload);
    history.push(ROUTES_PATH.ONBOARDING_FOUNDER_EXPERIENCE_LEVEL);
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100%" }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 3 }}
      >
        <Box>
          <Typography
            sx={{
              alignItems: "center",
              color: "#0A141B",
              textAlign: "center",
            }}
            variant="h2"
            color="secondary"
          >
            Help us determine where you are in your journey.
          </Typography>
          <Typography
            align="center"
            sx={{ mt: 3, color: "#6A7575", fontWeight: 400, fontSize: "12px" }}
          >
            Select everything you’ve done so far.
          </Typography>
        </Box>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          maxWidth: theme.breakpoints.values.md,
          mt:3,
          pb:3
        }}
      >
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="1fr"
          gap={2}
          width={1}
        >
          {startupJourneyData.map((item, index) => {
            return (
              <Box
                sx={{
                  gridColumn: {
                    lg: "span 3",
                    md: "span 3",
                    sm: "span 6",
                    xs: "span 6",
                  },
                }}
              >
                <OnboardingCard
                  cardText={item.text}
                  iconComponent={<item.iconComponent />}
                  selected={item.selected}
                  onSelect={handleSelect}
                  index={index}
                  isInput={item.isInput}
                  suggestions={fetchedAutoCompleteDetails["journeyDetails"]}
                  inputValue={autoCompleteDetails.journeyDetails}
                  maxInputLength={25}
                />
              </Box>
            );
          })}
        </Box>
      </Grid>
      <Grid container justifyContent="center" sx={{ mt: 7.5, mb: 3 }}>
        <Grid item xs={10} sm={10} md={4} lg={4}>
          <Box textAlign={"center"}>
            <Button
              onClick={() => handleNext()}
              sx={{ fontSize: "14px" }}
              disabled={
                startupJourneyData?.filter((item) => item.selected === true)
                  .length === 0
              }
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}

const mapStoreToProps = ({ onboarding }) => (
  {
  fetchedAutoCompleteDetails: onboarding?.fetchedAutoCompleteDetails,
  journeyDetails: onboarding?.journeyDetails,
  autoCompleteDetails: onboarding?.autoCompleteDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getAutoCompleteData: (questionKey) =>
    dispatch(actions.getAutoCompleteData(questionKey)),
  setStartupJourney: (updatedData) =>
    dispatch(actions.setStartupJourney(updatedData)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(StartupJourney);
