import React from "react";
import { Typography, Grid, Divider, Link, Box } from "@mui/material";
import { useSelector } from "react-redux";
import constants from "../../../../constants";
import theme from "../../../../constants/Theme";
import VideoPlayerComponent from "../../../../common/videoPlayer";

export default function AboutContainer(props) {
  const onboardingData = useSelector(state => state.onboarding);
  return (
    <Grid container spacing={2} flexDirection={"row"}>
      <Grid item xs={12} md={8}>
        <Grid
          container
          spacing={2}
          height="max-content"
          sx={{ pl: { xs: 1, md: 2 }, pt: 3 }}
        >
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                color: `${theme.palette.text.primary}`,
              }}
            >
              <Typography variant="h3">About</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                color: `${theme.palette.text.primary}`,
              }}
            >
              {props.profileArr?.about ? (
                <Typography variant="bodySubhead2">
                  {props.profileArr?.about}
                </Typography>
              ) : (
                <Link
                  component="button"
                  variant="bodySubhead"
                  underline="none"
                  onClick={props.handleEditForm}
                >
                  Edit your About
                </Link>
              )}
            </Box>
            <Divider
              sx={{
                mt: 3,
                background: `${theme.palette.secondary.outlineLight}`,
                display: { xs: "block", md: "none" },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3} sx={{ display: "flex", mt: 1 }}>
        <Divider
          sx={{
            mt: 0,
            p: 0.2,
            background: `${theme.palette.secondary.outlineLight}`,
            display: { xs: "none", md: "block" },
          }}
        />
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "max-content",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "start",
            gap: { xs: "10px", md: "0px" },
            pl: { xs: 1, md: 1 },
          }}
        >
          <Box
            sx={{
              lineHeight: 2,
              p: { xs: 0, md: 1 },
              color: `${theme.palette.text.primary}`,
            }}
          >
            <Typography variant="subhead2">Website</Typography>
          </Box>

          {props.profileArr?.websiteLink ? (
            <Link
              variant="bodySubhead2"
              target="_blank"
              onClick={() => window.open(props.profileArr.websiteLink)}
              rel="noopener"
              sx={{
                color: `${theme.palette.text.link}`,
                cursor: "pointer",
                p: { xs: 0, md: 1 },
              }}
            >
              {props.profileArr?.websiteLink}
            </Link>
          ) : (
            <Link
              component="button"
              variant="bodySubhead2"
              underline="none"
              sx={{
                ml: { xs: 0, md: 1 },
                mt: { xs: 1, md: 0 },
                textAlign: "start",
              }}
              onClick={props.handleEditForm}
            >
              Edit your site link
            </Link>
          )}

          <Box
            sx={{
              lineHeight: 1,
              p: { xs: 0, md: 1 },
              mt: 1,
              color: `${theme.palette.text.primary}`,
            }}
          >
            <Typography variant="subhead2">Industry</Typography>
          </Box>
          <Box
            sx={{
              color: `${theme.palette.text.primary}`,
              mb: 1,
              pl: { xs: 0, md: 1 },
            }}
          >
            {props.profileArr?.onboardingDetail?.companyType ? (
              <Typography variant="bodySubhead2">
                {props.profileArr?.onboardingDetail?.companyType}
              </Typography>
            ) : (
              <Link
                component="button"
                variant="bodySubhead2"
                onClick={props.handleEditForm}
                underline="none"
                sx={{
                  ml: { xs: 0, md: 0 },
                  pt: 1,
                  textAlign: "start",
                }}
              >
                Edit your industry
              </Link>
            )}
          </Box>

          <Box
            sx={{
              lineHeight: 2,
              p: { xs: 0, md: 1 },
              color: `${theme.palette.text.primary}`,
            }}
          >
            <Typography variant="subhead2">Startup Size</Typography>
          </Box>
          <Box
            sx={{
              color: `${theme.palette.text.primary}`,
              padding: { xs: 0, md: 1 },
            }}
          >
            {props.profileArr?.startUpSize ? (
              <Typography variant="bodySubhead2">
                {props.profileArr?.startUpSize}
              </Typography>
            ) : (
              <Link
                component="button"
                variant="bodySubhead2"
                sx={{ ml: 0, mt: { xs: 1, md: 0 }, textAlign: "start" }}
                onClick={props.handleEditForm}
                underline="none"
              >
                Edit your startup size
              </Link>
            )}
          </Box>

          <Box
            sx={{
              lineHeight: 2,
              p: { xs: 0, md: 1 },
              color: `${theme.palette.text.primary}`,
            }}
          >
            <Typography variant="subhead2">Founded</Typography>
          </Box>
          <Box
            sx={{
              color: `${theme.palette.text.primary}`,
              padding: { xs: 0, md: 1 },
            }}
          >
            {props.profileArr?.foundedYear ? (
              <Typography variant="bodySubhead2">
                {props.profileArr?.foundedYear}
              </Typography>
            ) : (
              <Link
                component="button"
                variant="bodySubhead2"
                underline="none"
                sx={{ textAlign: "start", mt: { xs: 1, md: 0 } }}
                onClick={props.handleEditForm}
              >
                Edit your founded date
              </Link>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
