import { REGEX } from "../../../../../constants/regex";
export const getExcerciseConfigurations = () => {
  return {
    form_key: "brandstorming",
    form_title: "Brandstorming",
    summary_path: "/workspace/brandstorming/summary",
    excercises: [
      {
        step_id: 21,
        component: "BrandPersonality",
        path: "brand-personality",
        key: "brandPersonality",
        description:
          "Let’s start learning who you are. It’s helpful to identify traits about you, traits that DO NOT describe you, and traits about your perfect customer(s).",
        stepNo: 1,
        stepUuid: "brand-personality",
        title: "Brand Personality",
      },
      {
        step_id: 22,
        component: "BrandStory",
        path: "brand-story",
        key: "brandStory",
        description:
          "Your mission and values should be integral to all of your decisions. We want to understand the soul of your business, help you massage the language, and use it in your visual brand assets.",
        stepNo: 2,
        stepUuid: "brand-story",
        title: "Brand Story",
      },
      {
        step_id: 23,
        component: "UnlockYourDeck",
        path: "unlock-deck",
        key: "unlockDeck",
        description: "Choose your Pitch Perfect theme",
        stepNo: 3,
        stepUuid: "unlock-deck",
        title: "Unlock Your Deck",
      },
    ],
  };
};

export const getExcerciseCompletionStatus = currentExcercise => {
  return true;
};

export const buttonDisable = (userInputs, action) => {
  switch (action) {
    case "BrandPersonality":
      for (let key in userInputs) {
        if (!userInputs[key].length) {
          return true;
        }
      }
      return false;
    case "BrandStory":
      for (let key in userInputs) {
        if (
          !userInputs[key].length ||
          (userInputs[key].length < 30 &&
            !userInputs[key].match(REGEX.STORYTELLER_INPUTBOX))
        ) {
          return true;
        }
      }
      return false;

    case "UnlockYourDeck":
      for (let key in userInputs) {
        if (userInputs[key] === "") {
          return true;
        }
      }
      return false;

    default:
      return true;
  }
};

export const getDefaultInputStates = component => {
  switch (component) {
    case "BrandPersonality":
      return {
        brandDescription: [],
        brandDetractors: [],
        idealCustomer: [],
      };
    case "BrandStory":
      return {
        brandMission: "",
        brandValues: "",
      };
    case "UnlockYourDeck":
      return {
        template: "",
      };
  }
};
