import React, { useState } from "react";
import { Grid, Divider, Typography, Box } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import FolderIcon from "@mui/icons-material/Folder";
import { POWERUP_ITEMS } from "../../../constants/powerup-data";
import theme from "../../../constants/Theme";

export default function MyWork() {
  const [powerUpItems, setPowerUpItems] = useState([
    {
      title: "Value Proposition",
    },
    {
      title: "Storyteller",
    },
  ]);
  const [isAscending, setIsAscending] = useState(true);

  const handelSort = () => {
    setIsAscending(!isAscending);
    sortWorkItems();
  };

  const sortWorkItems = () => {
    let data = [...powerUpItems];
    if (isAscending) {
      data.sort((a, b) => {
        return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
      });
    } else {
      data.sort((a, b) => {
        return a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1;
      });
    }

    setPowerUpItems(data);
  };

  return (
    <Grid container spacing={0}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "flex-start",
          paddingLeft: theme.spacing(2),
        }}
      >
        <Grid item xs={12} md={12} width={"100%"} sx={{ mt: 10 }}>
          <Typography variant="titleXLarge" sx={{ ml: { md: 2 } }}>
            Completed Work
          </Typography>
          <Divider
            sx={{
              mt: 3,
              background: `${theme.palette.secondary.outlineLight}`,
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} xl={12} sx={{ width: "100%", mt: 3 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              pt: 2,
            }}
          >
            <Box
              sx={{
                width: "max-content",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <Typography variant="bodyLarge" sx={{ ml: 2 }}>
                Name
              </Typography>
              <div onClick={handelSort} style={{ cursor: "pointer" }}>
                {isAscending ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
              </div>
            </Box>

            <Box
              sx={{
                width: "max-content",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "12px",
              }}
            >
              <Typography variant="bodyLarge" sx={{ ml: 2 }}>
                Last modified
              </Typography>
            </Box>
          </Box>
          <Divider
            sx={{
              mt: 2,
              background: `${theme.palette.secondary.outlineXLight}`,
            }}
          />
        </Grid>
        <Grid item xs={12} md={12} width={"100%"}>
          {powerUpItems?.map((item, index) => {
            if (index < 2) {
              return (
                <div>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      pt: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        padding: theme.spacing(1),

                        cursor: "pointer",
                      }}
                    >
                      <FolderIcon />
                      <Typography variant="bodyLarge">{item.title}</Typography>
                    </Box>
                    <Box sx={{ p: 2 }}>
                      <Typography variant="bodyMedium">Mar 23, 2022</Typography>
                    </Box>
                  </Box>
                  <Divider
                    sx={{
                      mt: 2,
                      background: `${theme.palette.secondary.outlineXLight}`,
                    }}
                  />
                </div>
              );
            }
          })}
        </Grid>
      </Box>
    </Grid>
  );
}
