import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Button,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { PageContentWrapper } from "../../components/Workspace/PageContentWrapper";
import {
  PowerupPacksContainerWrapper,
  PowerupContainer,
  PowerupWrapper,
  TabsContainer,
  PowerUpTabs,
  PowerUpTab,
  ProgressWrapper,
} from "./styles";
import PowerupHeader from "../../components/Workspace/PowerUp/Header";
import PowerupListViewContainer from "./PowerupList";
import constants from "../../constants";
import { WORKSPACE_STEPS } from "../../constants/steps";
import theme from "../../constants/Theme";
import services from "../../services";
import { ProgressHeroContainer } from "./ProgressHero/styles";
import ProgressHero from "../Workspace/ProgressHero";
import StaticContent from "./StaticContent";
import { toastContainer } from "../../utils/toast";
import { useHistory } from "react-router-dom";
import TabDescription from "../../components/Workspace/TabDescription";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const initialState = {
  demoDate: new Date(),
  startUpScore: 250,
  unitDetails: {
    startUpMinScore: 0,
    startUpMaxScore: 850,
    demoDateRange: 28,
  },
};

export default function StartupWorkspace() {
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(
    constants.POWERUP_TAB_ITEMS[0].key
  );
  const [activeStep, setActiveStep] = useState(0);
  const [powerupsToDisplay, setPowerupsToDisplay] = useState([]);
  const [selectedPowerupPack, setSelectedPowerupPack] = useState(null);
  const [powerUpPacks, setPowerUpPacks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [powerUpStart, setPowerUpStart] = useState(false);
  const [startPitchPerfect, setStartPitchPerfect] = useState(false);
  const [powerUpSteps, setPowerUpSteps] = useState([]);
  const [heroData, setHeroData] = useState("");
  const [startStatus, setStartStatus] = useState(false);
  const [progressDetails, setProgressDetails] = useState({
    expectedScore: 23,
    actualScore: 50,
  });
  const [progressInstrumentData, setProgressInstrumentData] =
    useState(initialState);
  const [heroLoading, setHeroLoading] = useState(true);

  //api call for get powerup packs
  const getPowerupPacksData = async () => {
    setLoading(true);
    try {
      const powerupsPacks = await services.getPowerUpPacksList();
      const powerupPacksData = powerupsPacks.data.data;
      const data = powerupPacksData?.packs;
      setActiveStep(0);
      setSelectedPowerupPack(data[0]);
      setPowerupsToDisplay(data[0]?.powerups || []);
      powerupPacksData?.progressDetails &&
        setProgressDetails(powerupPacksData?.progressDetails);
      setPowerUpPacks(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toastContainer(error?.error_description, "error");
    }
  };

  //api call for get powerups
  const getPowerupsData = async () => {
    setLoading(true);
    try {
      const powerups = await services.getPowerUpsList();
      const data = powerups.data.data;
      setPowerupsToDisplay(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toastContainer(error?.error_description, "error");
    }
  };

  //api call for powerup packs details
  const getPowerupTasks = async () => {
    try {
      const details = await services.getPoweupPacksDetails(1);
      const data = details.data;

      setHeroData(data.data);
      setHeroLoading(false);
    } catch (error) {
      console.log(error);
      setHeroLoading(false);
      toastContainer(error?.error_description, "error");
    }
  };

  //Handle tab change
  useEffect(() => {
    if (currentTab === constants.POWERUP_TAB_ITEMS[0].key) {
      getPowerupPacksData();
    } else {
      getPowerupsData();
    }
  }, [currentTab]);

  useEffect(() => {
    getPowerupTasks();
  }, [selectedPowerupPack]);

  // handle stepper change
  useEffect(() => {
    setPowerupsToDisplay(powerUpPacks[activeStep]?.powerups || []);
  }, [activeStep]);

  //api call to get progress instruments data
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    services
      .getDashboardStats()
      .then(res => {
        const data = res.data;
        const resData = data.data;
        setProgressInstrumentData(resData);
      })
      .catch(err => {
        console.log(err);
        toastContainer(err?.error_description, "error");
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStartPowerUp = id => {
    history.push("/workspace/powerup/" + id);
  };

  const handlePowerupChange = event => {
    setCurrentTab(event.target.value);
  };

  //Start button handler
  useEffect(() => {
    let task = heroData.manifest?.tasks[0]?.companyTask;
    if (task && task.taskStatus === "COMPLETED") {
      setStartStatus(true);
    }
  }, [heroData]);

  const tabItems = constants.POWERUP_TAB_ITEMS;

  return (
    <Stack
      sx={{
        width: 1,
        borderRadius: "28px",
        background:
          "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
        [theme.breakpoints.down("md")]: {
          borderRadius: 0,
        },
      }}
    >
      <Grid
        sx={{
          p: { xs: 0, md: 4 },

          maxWidth: theme.breakpoints.values.xl,
          flexGrow: 1,
          margin: "auto",
        }}
        flexDirection={"column"}
        container
      >
        <PageContentWrapper
          padding={theme.spacing(0)}
          marginTop={theme.spacing(0)}
        >
          <PowerupWrapper
            sx={{
              p: { xs: 2, md: 3 },
            }}
          >
            <Select
              displayEmpty
              value={currentTab}
              onChange={handlePowerupChange}
              sx={{
                "&& fieldset": {
                  border: "none",
                },
              }}
            >
              {tabItems.map((tabItem, index) => {
                return <MenuItem value={tabItem.key}>{tabItem.label}</MenuItem>;
              })}
            </Select>
          </PowerupWrapper>
          <Divider
            sx={{
              background: theme => theme.palette.secondary.light,
            }}
          />

          {!loading ? (
            <Box>
              {/*Powerup Packs*/}
              {currentTab === "poweupPacks" ? (
                <Box>
                  {/* Tabs */}
                  <TabsContainer>
                    <PowerUpTabs
                      value={value}
                      onChange={handleChange}
                      variant={
                        window.innerWidth < theme.breakpoints.values.md
                          ? "scrollable"
                          : "fullWidth"
                      }
                      scrollButtons="none"
                    >
                      {WORKSPACE_STEPS.map((item, index) => {
                        return (
                          <PowerUpTab
                            label={
                              <Typography
                                variant="subhead2"
                                sx={{ textTransform: "none" }}
                                value={index + 1}
                              >
                                {item.label}
                              </Typography>
                            }
                            {...a11yProps(index)}
                            disabled
                          />
                        );
                      })}
                    </PowerUpTabs>
                  </TabsContainer>
                  {/* Static content */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      mt: { xs: 4, md: 0 },
                      p: { xs: 2, md: 6 },
                    }}
                  >
                    {/* Static content */}
                    <Grid container>
                      <Grid item xs={12} md={12}>
                        <Grid container flexDirection="row" spacing={2}>
                          {selectedPowerupPack && (
                            <TabDescription
                              selectedPowerupPack={selectedPowerupPack}
                              startStatus={startStatus}
                              activeStep={activeStep}
                            />
                          )}
                          <Grid
                            item
                            xs={12}
                            md={4}
                            sx={{ display: { xs: "none", md: "block" } }}
                          >
                            <StaticContent
                              data={progressInstrumentData}
                              progressDetails={progressDetails}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* Progress Hero container */}
                  {!heroLoading ? (
                    heroData && (
                      <Grid container>
                        <ProgressHeroContainer
                          taskImgUrl={
                            heroData?.manifest?.tasks[activeStep]?.taskImgUrl
                          }
                        >
                          <Grid item xs={12} md={12}>
                            <ProgressHero
                              activeStep={activeStep}
                              setActiveStep={setActiveStep}
                              powerUps={heroData?.manifest?.tasks}
                              loading={loading}
                              handleStartPowerUp={handleStartPowerUp}
                              points={progressInstrumentData.startUpScore}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={12}
                            sx={{
                              display: {
                                xs: "flex",
                                md: "none",
                              },
                              width: 1,
                            }}
                          >
                            <ProgressWrapper>
                              <Typography variant="title_large">
                                Your Progress
                              </Typography>
                              <StaticContent
                                display="mobileContainer"
                                data={progressInstrumentData}
                                progressDetails={progressDetails}
                              />
                            </ProgressWrapper>
                          </Grid>
                        </ProgressHeroContainer>
                      </Grid>
                    )
                  ) : (
                    <Box
                      display={"flex"}
                      justifyContent="center"
                      alignItems={"center"}
                      flexGrow={1}
                      sx={{ pb: 2 }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              ) : (
                <PowerupContainer>
                  <PowerupListViewContainer powerupItems={powerupsToDisplay} />
                </PowerupContainer>
              )}
            </Box>
          ) : (
            <Box
              display={"flex"}
              justifyContent="center"
              alignItems={"center"}
              flexGrow={1}
            >
              <CircularProgress />
            </Box>
          )}
        </PageContentWrapper>
      </Grid>
    </Stack>
  );
}
