import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";

export const TabBar = ({ ...props }) => {
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      value: index,
    };
  }
  return (
    <Fragment>
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "block",
          },
        }}
      >
        <ToggleButtonGroup
          color="primary"
          exclusive
          value={props.value}
          onChange={props.handleChange}
          size="small"
          fullWidth
        >
          {props.tabs &&
            props.tabs.length > 0 &&
            props.tabs.map((tab, index) => (
              <ToggleButton key={index} {...a11yProps(index)}>
                <Typography
                  variant="label"
                  color={"primary"}
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  {tab.name}
                </Typography>
              </ToggleButton>
            ))}
        </ToggleButtonGroup>
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "none",
          },
        }}
      >
        <ToggleButtonGroup
          orientation="vertical"
          color="primary"
          exclusive
          value={props.value}
          onChange={props.handleChange}
          size="small"
          fullWidth
        >
          {props.tabs &&
            props.tabs.length > 0 &&
            props.tabs.map((tab, index) => (
              <ToggleButton key={index} {...a11yProps(index)}>
                <Typography
                  variant="label"
                  color={"primary"}
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  {tab.name}
                </Typography>
              </ToggleButton>
            ))}
        </ToggleButtonGroup>
      </Box>
    </Fragment>
  );
};
