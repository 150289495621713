import React from "react";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";

export default function SelectInput({
  label,
  onInputChange,
  selectedValue,
  fullWidth,
  options,
  inputKey,
  ...props
}) {
  return (
    <FormControl fullWidth={fullWidth} sx={props.wrapperStyle}>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        labelId="select-label"
        id="demo-simple-select"
        label={label}
        fullWidth={fullWidth}
        select
        onChange={e => onInputChange(e, inputKey)}
        value={selectedValue || ""}
      >
        {options.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
