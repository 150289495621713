import { REGEX } from "../../../../../constants/regex";
export const getExcerciseConfigurations = () => {
  return {
    form_key: "initialPersonnaBuilder",
    form_title: "Initial Persona Builder",
    summary_path: "/workspace/persona-builder/summary",
    excercises: [
      {
        step_id: 13,
        component: "NAME_AVATAR",
        path: "name-avatar",
        key: "nameAvatar",
      },
      {
        step_id: 14,
        component: "DESCRIBE_PERSONA",
        path: "describe_personna",
        key: "decscribePersonna",
      },
      {
        step_id: 15,
        component: "JOB_RESPONSIBILITIES",
        path: "job-responsibilities",
        key: "jobResponsibilities",
      },
      {
        step_id: 16,
        component: "JOB_NEEDS",
        path: "job-needs",
        key: "jobNeeds",
      },
    ],
  };
};

export const getExcerciseCompletionStatus = currentExcercise => {
  return true;
};

export const nameAvatarInputValid = (key, input) => {
  switch (key) {
    case "personaName":
      if (input.match(REGEX.PERSONANAME_INPUTBOX)) {
        return true;
      } else {
        return false;
      }
    case "avatar":
      if (input) {
        return true;
      } else {
        return false;
      }
  }
};

export const jobNeedsInputValid = (key, input) => {
  return true;
};

export const jobResponsibilityInputValid = (key, input) => {
  switch (key) {
    case "goals":
    case "jobResponsibilities":
      if (input.match(REGEX.POWERUP_INPUT)) {
        return true;
      } else {
        return false;
      }
    case "challenges":
      if (input.length) {
        return true;
      } else {
        return false;
      }
  }
};

export const describePersonaValid = (key, input) => {
  switch (key) {
    case "age":
      if (input.match(REGEX.NUMBERS_ONLY)) {
        return true;
      } else {
        return false;
      }

    default:
      if (input) {
        return true;
      } else {
        return false;
      }
  }
};

export const buttonDisable = (userInputs, action) => {
  switch (action) {
    case "NAME_AVATAR":
      for (let key in userInputs) {
        if (!nameAvatarInputValid(key, userInputs[key])) {
          return true;
        }
      }
      return false;
    case "DESCRIBE_PERSONA":
      for (let key in userInputs) {
        if (!describePersonaValid(key, userInputs[key])) {
          return true;
        }
      }
      return false;

    case "JOB_RESPONSIBILITIES":
    case "JOB_NEEDS":
      for (let key in userInputs) {
        if (!userInputs[key].length) {
          return true;
        }
      }
      return false;

    default:
      return true;
  }
};

export const getDefaultInputStates = component => {
  switch (component) {
    case "NAME_AVATAR":
      return {
        personaName: "",
        avatar: null,
      };

    case "DESCRIBE_PERSONA":
      return {
        age: "",
        city: "",
        state: "",
        educationLevel: "",
        industry: "",
        jobTitle: "",
      };
    case "JOB_RESPONSIBILITIES":
      return {
        goals: [],
        challenges: [],
        jobResponsibilities: [],
      };

    case "JOB_NEEDS":
      return {
        tools: [],
        communicationMethods: [],
        jobActivity: [],
      };

    default:
      return true;
  }
};
