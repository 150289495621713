import React from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { StartupOSCircleSmallIcon } from "../../../../common/icons";
import { WorkspaceCard } from "../../../../components/Workspace/Card/styles";

import ScoreProgress from "./ScoreProgress";

const StartupOSScore = ({ id, value }) => {
  return (
    <WorkspaceCard variant="outlined">
      <Box
        sx={{
          py: 3,
          px: 3,
        }}
      >
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          sx={{ pb: 5 }}
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box display="flex" alignItems={"center"}>
              <StartupOSCircleSmallIcon />
              <Typography variant="h5" sx={{ ml: 1 }}>
                StartupOS Score
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            display={"flex"}
            sx={[
              { pb: 2 },
              theme => ({
                [theme.breakpoints.down("md")]: {
                  justifyContent: "center",
                },
              }),
            ]}
          >
            <ScoreProgress id={id} maxValue={850} value={value} />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography variant="h5" sx={{ fontWeight: 700, pb: 3 }}>
              Keep it going!
            </Typography>
            <Typography variant="body1">
              You increased your score last week by completing 4 Power Ups and
              getting feedback. Great job!
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </WorkspaceCard>
  );
};

export default StartupOSScore;
