import {
  TEMPLATE_1_HEADER,
  TEMPLATE_2_HEADER,
  TEMPLATE_3_HEADER,
  TEMP1_IMG1,
  TEMP1_IMG2,
  TEMP1_IMG3,
  TEMP1_IMG4,
  TEMP1_IMG5,
  TEMP1_IMG6,
  TEMP1_IMG7,
  TEMP1_IMG8,
  TEMP1_IMG9,
  TEMP1_IMG10,
  TEMP1_IMG11,
  TEMP1_IMG12,
  TEMP1_IMG13,
  TEMP2_IMG1,
  TEMP2_IMG2,
  TEMP2_IMG3,
  TEMP2_IMG4,
  TEMP2_IMG5,
  TEMP2_IMG6,
  TEMP2_IMG7,
  TEMP2_IMG8,
  TEMP2_IMG9,
  TEMP2_IMG10,
  TEMP2_IMG11,
  TEMP2_IMG12,
  TEMP2_IMG13,
  TEMP3_IMG1,
  TEMP3_IMG2,
  TEMP3_IMG3,
  TEMP3_IMG4,
  TEMP3_IMG5,
  TEMP3_IMG6,
  TEMP3_IMG7,
  TEMP3_IMG8,
  TEMP3_IMG9,
  TEMP3_IMG10,
  TEMP3_IMG11,
  TEMP3_IMG12,
  TEMP3_IMG13,
  TEMP1_FILE,
  TEMP2_FILE,
  TEMP3_FILE,
} from "../constants/image";
export const TEMPLATE_TYPES = [
  {
    id: 0,
    tempNumber: 1,
    name: "Template 1",
    imgList: [
      TEMPLATE_1_HEADER,
      TEMP1_IMG1,
      TEMP1_IMG2,
      TEMP1_IMG3,
      TEMP1_IMG4,
      TEMP1_IMG5,
      TEMP1_IMG6,
      TEMP1_IMG7,
      TEMP1_IMG8,
      TEMP1_IMG9,
      TEMP1_IMG10,
      TEMP1_IMG11,
      TEMP1_IMG12,
      TEMP1_IMG13,
    ],
    headerImage: TEMPLATE_1_HEADER,
    fileUrl: TEMP1_FILE,
  },
  {
    id: 1,
    tempNumber: 2,
    name: "Template 2",
    imgList: [
      TEMPLATE_2_HEADER,
      TEMP2_IMG1,
      TEMP2_IMG2,
      TEMP2_IMG3,
      TEMP2_IMG4,
      TEMP2_IMG5,
      TEMP2_IMG6,
      TEMP2_IMG7,
      TEMP2_IMG8,
      TEMP2_IMG9,
      TEMP2_IMG10,
      TEMP2_IMG11,
      TEMP2_IMG12,
      TEMP2_IMG13,
    ],
    headerImage: TEMPLATE_2_HEADER,
    fileUrl: TEMP2_FILE,
  },
  {
    id: 2,
    tempNumber: 3,
    name: "Template 3",
    imgList: [
      TEMPLATE_3_HEADER,
      TEMP3_IMG1,
      TEMP3_IMG2,
      TEMP3_IMG3,
      TEMP3_IMG4,
      TEMP3_IMG5,
      TEMP3_IMG6,
      TEMP3_IMG7,
      TEMP3_IMG8,
      TEMP3_IMG9,
      TEMP3_IMG10,
      TEMP3_IMG11,
      TEMP3_IMG12,
      TEMP3_IMG13,
    ],
    headerImage: TEMPLATE_3_HEADER,
    fileUrl: TEMP3_FILE,
  },
];

export const BRANDSTORM_SUMMARY_DEFAULTDATA = {
  brandMission:
    "As best you can, describe your mission. A mission statement is a short summary of your purpose. State what you’re offering, who you’re selling to, and even why you do it",
  brandValues:
    "As best you can, describe your values. Think bigger than your products or services. What motivates you? Which principles should guide your actions – big or small? Your values should have special meaning to your employees and leadership.",
};

export const BRANDSTORMING_STEPS = [
  {
    index: "01",
    label: "Brand Personality",
  },
  {
    index: "02",
    label: "Brand Story",
  },
  {
    index: "03",
    label: "Unlock Your Deck",
  },
];
