import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { PageWrapper } from "./styles";
import { Box } from "@mui/material";

import { getUser, logout } from "../../utils/auth0-spa";
import { authWebLogout } from "../../utils/auth0";
import LayoutWrapper from "./LayoutWrapper";

function WorkspaceLayout({ children, userData }) {
  const onLgoutHandler = async () => {
    await logout();
    await authWebLogout();
  };

  return (
    <Box>
      <PageWrapper>
        <LayoutWrapper onLogout={onLgoutHandler} userData={userData}>
          {children}
        </LayoutWrapper>
      </PageWrapper>
    </Box>
  );
}

WorkspaceLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

const mapStoreToProps = ({ auth }) => ({
  userData: auth.userData,
});

export default connect(mapStoreToProps, undefined)(WorkspaceLayout);
