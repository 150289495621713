import {
  Typography,
  Box,
  Divider,
  Grid,
  Stack,
  Card,
  Button,
  Link,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import theme from "../../../../../../constants/Theme";
import WorkspaceBreadCrumbs from "../../../../../../components/Workspace/BreadCrumbs";
import FormSectionHeader from "../../../../../../components/Workspace/PowerUp/FormSectionHeader";
import SummaryStepList from "../../../../../../components/Workspace/PowerUp/SummaryStepList";
import constants from "../../../../../../constants";
import { GreenTicklIcon, PencilIcon } from "../../../../../../common/icons";
import { NumberContainer } from "../../../../../../components/Workspace/PowerUp/SummaryStepList/styles";
import Tabs from "../../../../../../components/Workspace/Tabs";
import KnowledgeSidebar from "../../../../../../components/Workspace/PowerUp/KnowledgeSidebar";
import { connect } from "react-redux";
import * as actions from "../../../../../../modules/actions";
import RatingCard from "../../../../../../components/Workspace/PowerUp/Rating";
import { Add } from "@mui/icons-material";
import {
  TEMPLATE_TYPES,
  BRANDSTORM_SUMMARY_DEFAULTDATA,
  BRANDSTORMING_STEPS,
} from "../../../../../../constants/brandstorming-data";

function BrandStormingSummary({ powerUpExcercises, setPowerUpExcercises }) {
  const tabItems = constants.SUMMARY_TAB_ITEMS;
  const history = useHistory();
  const [selectedTemplate, setSelectedTemplate] = useState(TEMPLATE_TYPES[0]);

  const [currentTab, setCurrentTab] = useState(tabItems[0].key);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setCurrentTab(tabItems[0].key);
  }, []);

  useEffect(() => {
    if (powerUpExcercises) {
      const unlockDeck = powerUpExcercises.find(
        excercise => excercise.stepNo === 3
      );
      if (unlockDeck && unlockDeck.answers) {
        const templateId = unlockDeck.answers.templateId;
        const currentTemplate = TEMPLATE_TYPES.find(
          type => type.id === templateId
        );
        if (currentTemplate) setSelectedTemplate(currentTemplate);
      }
    }
  }, [powerUpExcercises]);

  const renderBrandStoryDetails = () => {
    if (powerUpExcercises) {
      //Getting brandstory step responses
      let brandStory = powerUpExcercises.find(
        excercise => excercise.stepNo === 2 && excercise.moduleStepId === 22
      );

      const handleEditAction = () => {
        history.push(`/workspace/powerup/md-brandstorm/8/brand-story`);
      };

      //use default data i no brandstory found use this until previous step integration isnt completed
      if (!brandStory) {
        brandStory = { answers: BRANDSTORM_SUMMARY_DEFAULTDATA };
      }

      if (brandStory && brandStory.answers) {
        return (
          <>
            {Object.keys(brandStory.answers).map(key => {
              return (
                <Box sx={{ mt: 3 }}>
                  {" "}
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="title_medium">
                      {key === "brandMission"
                        ? "Brand Mission"
                        : "Brand Values"}
                    </Typography>
                    <Box
                      sx={{
                        ml: "auto",
                        display: {
                          sm: "none",
                          xs: "none",
                          md: "block",
                        },
                      }}
                    >
                      <Button
                        onClick={() => {
                          handleEditAction();
                        }}
                        variant="nofill"
                        startIcon={<PencilIcon />}
                      >
                        Edit Answer
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        ml: "auto",
                        mr: 3,
                        display: {
                          sm: "block",
                          xs: "block",
                          md: "none",
                        },
                      }}
                      onClick={() => {
                        handleEditAction();
                      }}
                    >
                      <PencilIcon />
                    </Box>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="title_medium">
                      {brandStory.answers[key]}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </>
        );
      }
    }
  };

  return (
    <>
      {" "}
      <Stack
        sx={{
          minHeight: "100%",
          borderRadius: "28px",
          p: 4,
          [theme.breakpoints.down("md")]: {
            p: 0,
            borderRadius: 0,
          },
          background:
            "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
        }}
      >
        <Grid
          container
          sx={{ maxWidth: theme.breakpoints.values.xl, margin: "auto" }}
          flexGrow={1}
          flexDirection="column"
        >
          <WorkspaceBreadCrumbs subpath="Storyteller" />
          <Box
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              mt: 3,
              mx: 2,
            }}
          >
            <Tabs
              tabItems={tabItems}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              color="primary"
              spacing={0}
              buttonWidth="50%"
              width="100%"
            />
          </Box>
          <Grid container sx={{ minHeight: "100%", mb: 10 }}>
            <Grid
              item
              md={8}
              sm={12}
              xs={12}
              sx={theme => ({
                [theme.breakpoints.up("md")]: {
                  borderRight: `1px solid ${theme.palette.secondary.main}`,
                  pr: 3,
                  mt: 4,
                },
                [theme.breakpoints.down("md")]: {
                  p: 2,
                },
                flexDirection: "column",
                minHeight: "100%",
                display: {
                  sm: currentTab === "excercise" ? "block" : "none",
                  xs: currentTab === "excercise" ? "block" : "none",
                  md: "flex",
                },
              })}
            >
              <Card
                sx={{
                  borderRadius: "28px",
                  border: `1px solid ${theme.palette.secondary.dark}`,
                  p: 3,
                  display: "flex",
                  flexDirection: "column",
                  [theme.breakpoints.down("md")]: {
                    background: "transparent",
                    border: "none",
                    boxShadow: "0",
                    p: 1,
                    mt: 2,
                  },
                }}
              >
                <FormSectionHeader heading="STORYTELLER" />
                <Divider
                  sx={{
                    background: theme => theme.palette.secondary.dark,
                    [theme.breakpoints.down("md")]: {
                      background: theme => theme.palette.secondary.borderLight,
                    },
                  }}
                />
                <Box sx={{ display: "flex", mt: 3, alignItems: "center" }}>
                  <Box
                    sx={{
                      mr: 2,
                    }}
                  >
                    <GreenTicklIcon />
                  </Box>

                  <Typography variant="h3">Completed!</Typography>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <SummaryStepList StepsData={BRANDSTORMING_STEPS} />
                </Box>

                <Box flexGrow={1} flexDirection={"column"}>
                  <Box
                    sx={{
                      mt: 3,
                    }}
                  >
                    <Typography variant="largeTitle">Summary</Typography>
                  </Box>

                  <Box display={"grid"} sx={{ mt: 1 }}>
                    <Typography variant="subhead1">
                      Here’s a summary of the work you’ve completed in this
                      PowerUP. You can take this as many times as you’d like and
                      edit whatever you’ve already completed. Don’t forget to
                      get feedback on your completed PowerUP.
                    </Typography>
                  </Box>

                  {renderBrandStoryDetails()}

                  <Box sx={{ mt: 3 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="title_medium">Your Deck</Typography>
                      <Box
                        sx={{
                          ml: "auto",
                        }}
                      >
                        <Button
                          onClick={() => {}}
                          variant="outlined"
                          startIcon={<Add />}
                          href={selectedTemplate.fileUrl}
                        >
                          Download As .PPTX
                        </Button>
                      </Box>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                      {selectedTemplate && (
                        <Box>
                          <Typography variant="label_medium">
                            {selectedTemplate.name}
                          </Typography>
                          <Box
                            display={"grid"}
                            gridTemplateColumns="repeat(12, 1fr)"
                            gridAutoRows={"1fr"}
                          >
                            <Box
                              sx={{
                                gridColumn: {
                                  xl: "span 3",
                                  lg: "span 4",
                                  md: "span 4",
                                  sm: "span 10",
                                  xs: "span 10",
                                },
                              }}
                            >
                              <img
                                src={selectedTemplate.imgList[0]}
                                style={{
                                  border: `1px solid ${theme.palette.secondary.light}`,
                                }}
                                height={150}
                                width={200}
                              />
                              <Link
                                size="sm"
                                variant="onOutline"
                                href={
                                  "/workspace/powerup/md-brandstorm/8/unlock-deck"
                                }
                                sx={{ mt: 2, display: "flex" }}
                                underline="none"
                              >
                                <PencilIcon />
                                <Typography sx={{ ml: 1 }} color="primary">
                                  Change Selection
                                </Typography>
                              </Link>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Divider
                    sx={{
                      background: theme => theme.palette.secondary.dark,
                      mt: 6,
                      [theme.breakpoints.down("md")]: {
                        background: theme =>
                          theme.palette.secondary.borderLight,
                      },
                    }}
                  />
                  <Box
                    sx={{
                      mt: 3,
                      display: { sm: "none", xs: "none", md: "flex" },
                    }}
                  >
                    <Box sx={{ ml: "auto" }}>
                      <Button variant="outlined">Rate PowerUp</Button>
                    </Box>
                    <Box sx={{ ml: 2 }}>
                      <Button
                        onClick={() => {
                          history.push("/workspace");
                        }}
                      >
                        Return to Workspace Home
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: { sm: "block", xs: "block", md: "none" },
                      background: theme.palette.secondary.white,
                      borderRadius: "28px",
                      p: 3,
                      mt: 3,
                    }}
                  >
                    <Typography variant="title_medium">
                      Want feedback?
                    </Typography>
                    <Box sx={{ mt: 3 }}>
                      <Typography variant="subhead2">
                        Your StartupOS Advisor is here to help if you would like
                        feedback for this completed PowerUp.
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: "center", mt: 4 }}>
                      <Button>Submit for feedback</Button>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Grid>

            <KnowledgeSidebar currentTab={currentTab} powerUpId={"2"} />

            <Box
              sx={{
                display: {
                  sm: currentTab === "rate" ? "flex" : "none",
                  xs: currentTab === "rate" ? "flex" : "none",
                  md: "none",
                },
                mt: 3,
                ml: 2,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  mr: 2,
                }}
              >
                <GreenTicklIcon />
              </Box>

              <Typography variant="h3">Completed!</Typography>
            </Box>
            <RatingCard currentTab={currentTab} />
          </Grid>
          <Box
            sx={{
              background: theme.palette.secondary.white,
              position: "fixed",
              bottom: 72,
              width: 1,
              textAlign: "center",
              py: 2,
              display: { sm: "block", xs: "block", md: "none" },
            }}
          >
            <Button
              onClick={() => {
                history.push("/workspace");
              }}
            >
              Return to Workspace Home
            </Button>
          </Box>
        </Grid>
      </Stack>
    </>
  );
}

const mapStoreToProps = ({ powerUp }) => ({
  powerUpExcercises: powerUp?.powerUpExcercises,
});

const mapDispatchToProps = dispatch => ({
  setPowerUpExcercises: updatedSteps =>
    dispatch(actions.setPowerUpExcercises(updatedSteps)),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(BrandStormingSummary);
