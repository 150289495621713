import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  useMediaQuery,
  Button,
  Divider,
} from "@mui/material";
import JsxParser from "react-jsx-parser";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PowerUPLayout from "../../../../layout/PowerUP";
import Sidebar from "../../../../components/Workspace/PowerUp/Sidebar";
import BreadcrumbsComp from "./Components/BreadcrumbsComp";
import { getPowerUPData, getSidebarContent } from "./Functions/common";
import { NOTES_ICON } from "../../../../constants/image";
import constants from "../../../../constants";
import Tabs from "../../../../components/Workspace/Tabs";
import CardWrapper from "./Components/CardWrapper";
import theme from "../../../../constants/Theme";
import CompletedInterviews from "../../../../components/Workspace/PowerUp/CompletedInterviews";
import { PROFILE_PLACEHOLDER } from "../../../../constants/image";
import { getCurrentDate } from "../../../../utils/date";
import AggregateNotesPdf from "../../../../components/Workspace/AggregateNotesPdf";

const AggregatedNotes = () => {
  const [powerupDetails, setPowerupDetails] = useState("");
  const [powerupDetailsLoader, setPowerupDetailsLoader] = useState(false);
  const [SIDEBARDATA, setSidebarData] = useState({});
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const tabItems = constants.EXCERCISE_TAB_ITEMS;
  const [currentTab, setCurrentTab] = useState(tabItems[0].key);
  const [isAscending, setIsAscending] = useState(true);
  const history = useHistory();
  const summaryGridRef = useRef(null);
  const powerUpId = "4";

  const [candidateRecords, setCandidateRecords] = useState([]);

  const powerupModuleDetails = useSelector(
    store => store?.powerUp?.powerupDetails
  );
  useEffect(() => {
    let lastStepData = powerupModuleDetails.data.moduleStepResponses.filter(
      item => item.isLastStep
    );

    let answersObj;
    if (lastStepData) {
      answersObj = JSON.parse(lastStepData[0].answers);
    }
    if (answersObj.candidateRecords) {
      answersObj.candidateRecords.map((item, idx) => {
        item.selected = false;
        item.id = idx + 1;
        return item;
      });

      setCandidateRecords(answersObj.candidateRecords);
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setPowerupDetailsLoader(true);
    getPowerUPData(powerUpId).then(res => {
      setPowerupDetails(res);
    });
    getSidebarContent(powerUpId).then(res => {
      setSidebarData(res);
    });
    setPowerupDetailsLoader(false);
  }, []);

  const handleExportClick = type => {
    summaryGridRef.current.handleExport(type);
  };
  const FooterComponent = () => {
    return (
      <>
        <Box
          justifyContent={"center"}
          sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
        >
          <Button
            onClick={() => history.push("/workspace/powerup/4")}
            sx={{ m: 2 }}
          >
            Return to PowerUp Details
          </Button>
        </Box>
        <Divider
          sx={{
            background: `${theme.palette.secondary.outlineXLight}`,
            mt: 3,
            display: { xs: "none", sm: "none", md: "block" },
          }}
        />
        <Box
          justifyContent={"right"}
          sx={{ display: { xs: "none", sm: "none", md: "flex" }, mt: 3 }}
        >
          <Box
            sx={{
              marginLeft: "auto",
              mr: 1,
            }}
          >
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => handleExportClick("pdf")}
              disabled={!candidateRecords.filter(itm => itm.selected)[0]}
            >
              Export to PDF
            </Button>
          </Box>
          <Box
            sx={{
              mr: 1,
            }}
          >
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => handleExportClick("img")}
              disabled={!candidateRecords.filter(itm => itm.selected)[0]}
            >
              Export to PNG
            </Button>
          </Box>
          <Box>
            <Button onClick={() => history.push("/workspace/powerup/4")}>
              Return to PowerUp Details
            </Button>
          </Box>
        </Box>
      </>
    );
  };

  const TabsComponent = () => {
    return (
      <Tabs
        tabItems={tabItems}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        color="primary"
        spacing={0}
        gridColumn={{
          lg: "none",
          md: "none",
          sm: "span 6",
          xs: "span 6",
        }}
        width={1}
      />
    );
  };
  const HandleOnChange = (e, id, list, ascending) => {
    let listData = [...list];
    listData.map(item => {
      if (item.id === id) {
        item.selected = !item.selected;
        return item;
      }
    });
    setIsAscending(ascending);
    setCandidateRecords(listData);
  };

  const MainContent = () => {
    return (
      <Box>
        <Box display={"flex"} alignItems={"center"} sx={{ mt: 3 }}>
          <img src={NOTES_ICON} />
          <Typography variant="title_medium" sx={{ ml: 1 }}>
            Aggregated Notes
          </Typography>
        </Box>
        {isSmallScreen && (
          <Box sx={{ mt: 2 }}>
            <TabsComponent />
          </Box>
        )}
        <Box sx={{ mt: 3 }}>
          <Typography variant="subhead1">
            Bring the gems, verbatims and insights together from all the
            interviews. Look for strong negative and positive patterns and
            highlight the key insights that you can act on.
          </Typography>
        </Box>
        <Box
          justifyContent={"center"}
          sx={{ display: { xs: "flex", sm: "flex", md: "none" }, mt: 3 }}
        >
          <Button
            sx={{
              mr: 1,
            }}
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => handleExportClick("pdf")}
            disabled={!candidateRecords.filter(itm => itm.selected)[0]}
          >
            Export to PDF
          </Button>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={() => handleExportClick("img")}
            disabled={!candidateRecords.filter(itm => itm.selected)[0]}
          >
            Export to PNG
          </Button>
        </Box>
        <Box sx={{ width: 1 }}>
          <AggregateNotesPdf
            interviewList={candidateRecords}
            ref={summaryGridRef}
          />
        </Box>

        <Box
          sx={{
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: "16px",
            p: 3,
            mt: 3,
            [theme.breakpoints.down("md")]: {
              background: theme.palette.text.background,
            },
          }}
        >
          <Box
            sx={{
              [theme.breakpoints.up("md")]: {
                border: `1px solid ${theme.palette.secondary.dark}`,
                borderRadius: "28px",
                p: 3,
              },
            }}
          >
            <Typography variant="title_medium">
              Your Completed Interviews
            </Typography>
            <CompletedInterviews
              completedInterviewList={candidateRecords}
              HandleOnChange={HandleOnChange}
              isAscendingData={isAscending}
            />
          </Box>
          {candidateRecords.map((interview, index) => {
            return interview.selected ? (
              <Box>
                <Box sx={{ display: "flex", alignItems: "center", mt: 5 }}>
                  <Box component="img" src={PROFILE_PLACEHOLDER}></Box>
                  <Box sx={{ ml: 2 }}>
                    <Typography
                      variant="bodyLarge"
                      sx={{ color: "secondary.black" }}
                    >
                      {interview.name}
                    </Typography>
                  </Box>
                  <Box sx={{ py: 1, ml: "auto" }}>
                    <Typography variant="bodyMedium">
                      {getCurrentDate(new Date(interview.scheduledDate))}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ mt: 5 }}>
                  <Typography variant="largeTitle">Notes Recorded</Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="bodyMedium">
                    <JsxParser
                      components={{ Typography }}
                      jsx={
                        interview.records.filter(itm => itm.key === "notes")[0]
                          .value
                      }
                    />
                  </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="title_medium">
                    {"GEMS & VERBATIMS"}
                  </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="bodyMedium">
                    <JsxParser
                      components={{ Typography }}
                      jsx={
                        interview.records.filter(
                          itm => itm.key === "gems&verbatims"
                        )[0].value
                      }
                    />
                  </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="title_medium">
                    {"KEY INSIGHTS"}
                  </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="bodyMedium">
                    <JsxParser
                      components={{ Typography }}
                      jsx={
                        interview.records.filter(
                          itm => itm.key === "key_insights"
                        )[0].value
                      }
                    />
                  </Typography>
                </Box>
                {candidateRecords.length !== 1 &&
                candidateRecords.length - 1 !== index ? (
                  <Divider
                    sx={{
                      background: `${theme.palette.secondary.outlineXLight}`,
                      mt: 3,
                    }}
                  />
                ) : (
                  ""
                )}
              </Box>
            ) : (
              ""
            );
          })}
        </Box>
        <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
          <FooterComponent />
        </Box>
      </Box>
    );
  };

  return (
    <PowerUPLayout
      main={
        <Fragment>
          {powerupDetailsLoader ? (
            <Box justifyContent={"center"} sx={{ display: "flex", mt: 5 }}>
              <CircularProgress />
            </Box>
          ) : (
            <CardWrapper heading={"IDEA VALIDATION INTERVIEW"}>
              <MainContent />
            </CardWrapper>
          )}
        </Fragment>
      }
      sidebar={
        isSmallScreen ? (
          <Box display={"flex"} flexDirection={"column"}>
            <Box sx={{ my: 3 }}>
              <TabsComponent />
            </Box>
            <Sidebar sidebar_content={SIDEBARDATA} />
          </Box>
        ) : (
          <Sidebar sidebar_content={SIDEBARDATA} />
        )
      }
      breadcrumbs={<BreadcrumbsComp powerupDetails={powerupDetails} />}
      tab={currentTab}
      footerComponent={<FooterComponent />}
    />
  );
};

export default AggregatedNotes;
