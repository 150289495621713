import styled from "@emotion/styled";

export const TetxFieldBox = styled.div(({ theme }) => {
  return {
    color: "#1D1B1E",
    fontFamily: "RobotoRegular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
  };
});

export const TitleBox = styled.div(({ theme }) => {
  return {
    [theme.breakpoints.up("md")]: {
      marginTop: "128px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "23px",
    },
  };
});

export const ButtonContainer = styled.div(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      marginTop: "150px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "114px",
    },
  };
});
