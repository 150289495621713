import React, { useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import ReactSpeedometer from "react-d3-speedometer";

import { RadarDarkIcon } from "../../../common/icons";
import { WorkspaceCard } from "../../../components/Workspace/Card/styles";

import { LOCKICON } from "../../../constants";
import theme from "../../../constants/Theme";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../../constants/routes";

const InvestorRadar = ({ id, level }) => {
  const history = useHistory();

  const getColors = value => {
    // themening for filled and non-filled notches
    return new Array(10).fill("#73fac5").map((_, i) => {
      return i % 2 === 0
        ? i < value * 2
          ? "#00CE7D"
          : "#73fac5"
        : theme.palette.secondary.white;
    });
  };

  useEffect(() => {
    if (level < 5) {
      const svg =
        document.getElementsByClassName("pointer")[id === "desktop" ? 0 : 1];
      const newElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "image"
      );
      newElement.setAttribute("href", LOCKICON);
      newElement.setAttribute("id", id);

      newElement.setAttribute("x", "-50");
      newElement.setAttribute("y", "-50");
      newElement.style.width = "100px";
      newElement.style.height = "50px";
      svg.appendChild(newElement);
    }
  });

  const currentStops = (maxValue, totalSteps, stopRadius) => {
    const steps = maxValue / totalSteps;
    const narr = [];

    let curVal = 0;

    new Array(totalSteps).fill(steps).forEach((val, i) => {
      if (i === 0) {
        narr.push(i);
      }
      curVal = curVal + steps;
      narr.push(curVal);
      i < totalSteps - 1 && narr.push(curVal + stopRadius);
    });
    return narr;
  };

  const handleNext = () => {
    history.push(ROUTES_PATH.NETWORK);
  };

  return (
    <WorkspaceCard variant="outlined">
      <Box
        sx={{
          py: 3,
          px: 3,
        }}
      >
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          xs={12}
          display={"flex"}
          sx={[
            theme => ({
              [theme.breakpoints.down("md")]: {
                pb: 5,
              },
            }),
          ]}
        >
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box display="flex" alignItems={"center"}>
              <RadarDarkIcon />
              <Typography variant="h5" sx={{ ml: 1 }}>
                Investor Radar®
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            display={{ sm: "none", xs: "none", md: "block", lg: "block" }}
          >
            <Typography
              variant="h5"
              color="primary"
              align="right"
              sx={{ pb: 7 }}
              onClick={handleNext}
            >
              Go to Network
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            display={"flex"}
            sx={[
              theme => ({
                [theme.breakpoints.down("md")]: {
                  justifyContent: "center",
                },
              }),
            ]}
            justifyContent="start"
          >
            <Box
              sx={
                id === "desktop" && {
                  display: "flex",
                  width: "100%",
                  height: "100%",
                }
              }
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <ReactSpeedometer
                  key={id}
                  forceRender={true}
                  dimensionUnit="%"
                  minValue={0}
                  maxValue={100}
                  value={level}
                  ringWidth={15}
                  valueTextFontSize="0px"
                  textColor="red"
                  labelFontSize="0px"
                  customSegmentStops={currentStops(100, 5, 2)}
                  needleColor="none"
                  fluidWidth={true}
                  segmentColors={getColors(level)}
                  needleHeightRatio={0}
                ></ReactSpeedometer>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography variant="h5" sx={{ fontWeight: 700, pb: 3, pt: 1 }}>
              Keep going
            </Typography>
            <Typography variant="body1">
              Complete 11 PowerUPs and upload your pitch in My Startup profile
              to unlock Investor Radar®
            </Typography>
            <Box
              sx={{ textAlign: "end", display: { xs: "block", md: "none" } }}
            >
              <Button sx={{ mt: 6, width: "auto" }} onClick={handleNext}>
                Go to Network
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </WorkspaceCard>
  );
};

export default InvestorRadar;
