import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Button, Stack } from "@mui/material";
import { connect } from "react-redux";
import ROUTES_PATH from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import OnboardingCard from "../../../common/card/OnboardingCard";
import constants from "../../../constants";
import * as actions from "../../../modules/actions";
import theme from "../../../constants/Theme";

function ExperienceAsFounder({
  setExperienceLevelsService,
  experienceDetails,
}) {
  const [experienceAsFounderData, setExperienceAsFounderData] = useState(
    constants.EXPERIENCE_LEVELS
  );
  const history = useHistory();
  const [answers, setAnswers] = useState([]);

  useEffect(async () => {
    const data = [...experienceAsFounderData];
    data.map((item, idx) => {
      if (item.text === experienceDetails) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      data[idx] = item;
    });
    setExperienceAsFounderData(data);
  }, []);

  const handleSelect = (index, answer) => {
    const data = [...experienceAsFounderData];
    data.map((item, idx) => {
      item = {
        ...item,
        selected: false,
      };
      data[idx] = item;
    });
    setExperienceAsFounderData(data);

    let selectedAnswer = data[index];

    selectedAnswer = {
      ...selectedAnswer,
      selected: true,
    };

    data[index] = selectedAnswer;
    setExperienceAsFounderData(data);

    if (answer) {
      setAnswers([...answers, answer]);
    } else {
      let filtered = answers.filter((answer) => answer !== selectedAnswer.text);
      setAnswers(filtered);
    }
    //Add answers to redux stores
  };
  const handleNext = () => {
    const selectedExperienceLevel = experienceAsFounderData?.find(
      (item) => item.selected === true
    )?.text;

    const payload = {
      experienceDetails: selectedExperienceLevel,
    };

    setExperienceLevelsService(payload);
    history.push(ROUTES_PATH.ONBOARDING_FOUNDER_SUPERPOWER);
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100%" }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 3 }}
      >
        <Typography
          sx={{
            alignItems: "center",
            color: "#0A141B",
            textAlign: "center",
          }}
          variant="h2"
          color="secondary"
        >
          How would you describe your experience as a founder?
        </Typography>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          maxWidth: theme.breakpoints.values.md,
          py: 4,
        }}
      >
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="1fr"
          gap={2}
          width={1}
        >
          {experienceAsFounderData.map((item, index) => {
            return (
              <Box
                sx={{
                  gridColumn: {
                    lg: "span 3",
                    md: "span 3",
                    sm: "span 6",
                    xs: "span 6",
                  },
                }}
              >
                <OnboardingCard
                  cardText={item.text}
                  iconComponent={<item.iconComponent />}
                  selected={item.selected}
                  onSelect={handleSelect}
                  index={index}
                  cardSubText={item.cardSubText}
                />
              </Box>
            );
          })}
        </Box>
      </Grid>
      <Grid container justifyContent="center" sx={{ mt: 7.5, mb: 3 }}>
        <Grid item xs={10} sm={10} md={4} lg={4}>
          <Box textAlign={"center"}>
            <Button
              onClick={() => handleNext()}
              sx={{ fontSize: "14px" }}
              disabled={
                experienceAsFounderData?.filter(
                  (item) => item.selected === true
                ).length === 0
              }
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}
const mapStoreToProps = ({ onboarding }) => ({
  experienceDetails: onboarding?.experienceDetails,
  onboarding: onboarding,
});
const mapDispatchToProps = (dispatch) => ({
  setExperienceLevelsService: (updatedData) =>
    dispatch(actions.setExperienceLevelsService(updatedData)),
});
export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(ExperienceAsFounder);
