import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  Stack,
  Snackbar,
  Alert,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import messages from "../../../constants/messages";
import {
  CenteredPinPlacement,
  Pin,
  MapImageWrapper,
  ButtonWrapper,
} from "./styles";
import ROUTES_PATH from "../../../constants/routes";
import { PINMARKER, PINICON, USAMAP } from "../../../constants/image";
import { geoLocation } from "../../../utils/geoLocation";
import { toastContainer } from "../../../utils/toast";
import services from "../../../services";
import { REGEX } from "../../../constants/regex";
import * as actions from "../../../modules/actions";
import LoadingButton from "../../../common/Loadingbutton";

function SelectHQLocation({ companyLocation, setCompanyLocation }) {
  const [isTypeZipCode, setIsTypeZipCode] = useState(companyLocation?.zipCode);
  const [showState, setShowState] = useState(companyLocation?.state);
  const [zipCode, setZipCode] = useState(companyLocation?.zipCode);
  const [state, setState] = useState(companyLocation?.state);
  const [stateImage, setStateImage] = useState(companyLocation?.stateImage);
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: {
      latitude: companyLocation?.lat,
      longitude: companyLocation?.lng,
    },
  });
  const [locationLoading,setLocationLoading] = useState(false);

  const history = useHistory();

  useEffect(async () => {
    if (location.loaded) {
      if (location.error) {
        toastContainer(location.error.message, "error");
        setIsTypeZipCode(true);
      }

      if (location.coordinates.latitude && location.coordinates.longitude) {
        const coordinates = location.coordinates;
        setLocationLoading(true);
        const data = await services.searchLocationByCoordinates(
          coordinates.latitude,
          coordinates.longitude
        );

        const address = data.data.address;

        if (address.country_code === "us") {
          setLocationLoading(false);
          setState(data.data.address.state);
          setShowState(true);
        } else {
          setLocationLoading(false);
          toastContainer(messages.LOCATION_INVALID, "error");
        }
      }
    }
  }, [location]);

  const handleZipCodeChange = async (e) => {
    const value = e.target.value;
    if (value.match(REGEX.ZIP_CODE)) {
      setZipCode(value);
      if (value.length >= 5) {
        const locationData = await services.searchLocationByZipcode(value);

        if (locationData.data) {
          const usaData = locationData?.data?.filter(
            (loc) => loc?.address?.country_code === "us"
          );
          if (usaData.length > 0) {
            if (usaData[0].address?.state) {
              setState(usaData[0].address.state);
              setShowState(true);
            } else {
              //If state dont found for zipcode api
              const lattitude = usaData[0]?.lat;
              const longitude = usaData[0]?.lon;

              const latLongData = await services.searchLocationByCoordinates(
                lattitude,
                longitude
              );
              const address = latLongData.data.address;

              if (address.country_code === "us") {
                setState(address.state);
                setShowState(true);
              } else {
                toastContainer(messages.LOCATION_INVALID, "error");
              }
            }
          } else {
            toastContainer(messages.LOCATION_INVALID, "error");
            resetState();
          }
        }
      } else {
        resetState();
      }
    } else {
      resetState();
    }
  };

  useEffect(() => {
    setStateImage(
      `${process.env.REACT_APP_CLOUDFRONT_URL}/maps/us/${state}.svg`
    );
  }, [state]);

  const handleCurrentLocation = () => {
    geoLocation(setLocation);
    setShowState(true);
    setZipCode("");
  };

  const resetState = () => {
    setState("");
    setStateImage(USAMAP);
  };

  const handleNext = () => {
    const payload = {
      stateImage: stateImage,
      state: state,
      zipCode: zipCode,
      lat: location?.coordinates?.latitude,
      lng: location?.coordinates?.longitude,
    };

    setCompanyLocation(payload);

    history.push(ROUTES_PATH.ONBOARDING_PRODUCT_SERVICE);
  };

  const isNextButtonvisible = () => showState && state;

  const handleTypeInZipCode = () => {
    setIsTypeZipCode(true);
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100%" }}
    >
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
        minHeight={"100%"}
        paddingBottom="50px"
      >
        <Grid item xs={11} lg={7} md={7} sm={8}>
          {!state ? (
            <MapImageWrapper image={USAMAP} />
          ) : (
            <MapImageWrapper image={stateImage}>
              <CenteredPinPlacement pinMarkerIcon={PINMARKER}>
                <Pin src={PINICON} alt="icon" />
              </CenteredPinPlacement>
            </MapImageWrapper>
          )}

          <Typography
            align="center"
            variant="h2"
            sx={{ mt: 4, fontWeight: 500 }}
          >
            Where are you located?
          </Typography>

          {isTypeZipCode ? (
            <Box textAlign={"center"}>
              <Typography
                align="center"
                color="secondary"
                variant="subtitle1"
                sx={{ mt: 3 }}
              >
                Enter the zip code where you live so we can connect you to to
                regional founders.
              </Typography>
              <TextField
                type="text"
                value={zipCode}
                onChange={(e) => handleZipCodeChange(e)}
                label="Zip Code"
                sx={{ mt: 4, maxWidth: "376px", width: "100%" }}
                autoComplete="off"
              />
            </Box>
          ) : (
            <Box>
              <Typography
                align="center"
                variant="subtitle1"
                color="secondary"
                sx={{ mt: 3, mb: 18 }}
              >
                May we use your current location so we can connect you to a
                regional community?
              </Typography>

              {!state && (
                <ButtonWrapper>
                  <LoadingButton
                    variant="contained"
                    fullWidth={false}
                    onClick={handleCurrentLocation}
                    sx={{ mt: 2 }}
                    loading={locationLoading}
                  >
                    Use my current location
                  </LoadingButton>
                </ButtonWrapper>
              )}

              {!state && (
                <ButtonWrapper>
                  <Button
                    variant="text"
                    fullWidth={false}
                    onClick={() => handleTypeInZipCode()}
                    sx={{ mt: 2 }}
                  >
                    I’d rather type in my zip code
                  </Button>
                </ButtonWrapper>
              )}
            </Box>
          )}

          {isNextButtonvisible() || isTypeZipCode ? (
            <ButtonWrapper>
              <Button
                variant="contained"
                fullWidth={true}
                onClick={() => handleNext()}
                sx={{ mt: 10 }}
                disabled={!isNextButtonvisible()}
              >
                Next
              </Button>
            </ButtonWrapper>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Stack>
  );
}

const mapStoreToProps = ({ onboarding }) => ({
  companyLocation: onboarding?.companyLocation,
});

const mapDispatchToProps = (dispatch) => ({
  setCompanyLocation: (updatedData) =>
    dispatch(actions.setCompanyLocation(updatedData)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(SelectHQLocation);
