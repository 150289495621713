import styled from "@emotion/styled";
import { Switch, Box } from "@mui/material";
import { sosPrimary } from "../../../constants/Theme/colors";
import theme from "../../../constants/Theme";

export const ProfileContainerLayout = styled.div(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "space-between",
  };
});
export const InvestorToggleSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 30,
  height: 15,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 1,
    transitionDuration: "300ms",

    "&.Mui-checked": {
      transform: "translateX(15px)",
      color: "#fff",

      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#000000" : "#000000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "3px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 12,
    height: 12,
    [theme.breakpoints.down("md")]: {
      width: 11,
      height: 11,
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    [theme.breakpoints.down("md")]: {
      backgroundColor: sosPrimary[50],
      height: 13,
      borderRadius: 26 / 2,
      border: "1px solid #fff",
    },
  },
}));
