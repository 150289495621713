import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  useMediaQuery,
  Button,
} from "@mui/material";
import PowerUPLayout from "../../../../layout/PowerUP";
import Sidebar from "../../../../components/Workspace/PowerUp/Sidebar";
import BreadcrumbsComp from "./Components/BreadcrumbsComp";
import {
  getPowerUPData,
  getSidebarContent,
  getCandidatesList,
  getBrowseCandidateModule,
} from "./Functions/common";
import { BROWSE_CANDIDATE_ICON } from "../../../../constants/image";
import constants from "../../../../constants";
import Tabs from "../../../../components/Workspace/Tabs";
import CardWrapper from "./Components/CardWrapper";
import ManageCandidates from "./Components/ManageCandidates";
import theme from "../../../../constants/Theme";
import { useHistory, useParams } from "react-router-dom";
import services from "../../../../services";

const BrowseInterviewCandidates = () => {
  const [powerupDetails, setPowerupDetails] = useState("");
  const [powerupDetailsLoader, setPowerupDetailsLoader] = useState(false);
  const [SIDEBARDATA, setSidebarData] = useState({});
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const tabItems = constants.EXCERCISE_TAB_ITEMS;
  const [currentTab, setCurrentTab] = useState(tabItems[0].key);
  const history = useHistory();
  const [candidates, setCandidates] = useState([]);
  const [currentModuleObject, setCurrentModuleObject] = useState(null);
  const params = useParams();
  const moduleId = params.id;
  const versionId = parseInt(params.version);

  const powerUpId = "4";

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setPowerupDetailsLoader(true);
    getPowerUPData(powerUpId).then(res => {
      setPowerupDetails(res);
    });
    getSidebarContent(powerUpId).then(res => {
      setSidebarData(res);
    });
    setPowerupDetailsLoader(false);
  }, []);

  useEffect(() => {
    //static email address given by backend this 3 candidates will be fetched
    getCandidatesList(
      "bkoloski@startupos.com,mgarrison@startupos.com,paul@startupos.com"
    )
      .then(result => {
        setCandidates(result);
      })
      .catch(err => console.log(err));

    getBrowseCandidateModule(moduleId)
      .then(result => {
        setCurrentModuleObject(result);
      })
      .catch(err => console.log(err));
  }, []);

  const FooterComponent = () => {
    return (
      <Box display={"flex"} justifyContent={"center"}>
        <Button
          fullWidth
          sx={{ my: 2 }}
          onClick={() => history.push("/workspace/powerup/4")}
        >
          Return to PowerUp Details
        </Button>
      </Box>
    );
  };

  const TabsComponent = () => {
    return (
      <Tabs
        tabItems={tabItems}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        color="primary"
        spacing={0}
        gridColumn={{
          lg: "none",
          md: "none",
          sm: "span 6",
          xs: "span 6",
        }}
        width={1}
      />
    );
  };

  const MainContent = () => {
    return (
      <Box>
        <Box display={"flex"} alignItems={"center"} sx={{ mt: 3 }}>
          <img src={BROWSE_CANDIDATE_ICON} />
          <Typography variant="title_medium" sx={{ ml: 2 }}>
            Browse Interview Candidates
          </Typography>
        </Box>

        {isSmallScreen && (
          <Box sx={{ mt: 2 }}>
            <TabsComponent />
          </Box>
        )}

        <Box sx={{ my: 3 }}>
          <Typography variant="subhead2">
            Select and save candidates from a list of StartupOS members or add
            your own external candidates to save. Saved candidates will be
            displayed below in <b>Interview Candidates</b> on the overview page.
          </Typography>
        </Box>

        <ManageCandidates
          candidates={candidates}
          currentModuleObject={currentModuleObject}
        />
      </Box>
    );
  };

  return (
    <PowerUPLayout
      main={
        <Fragment>
          {powerupDetailsLoader ? (
            <Box justifyContent={"center"} sx={{ display: "flex", mt: 5 }}>
              <CircularProgress />
            </Box>
          ) : (
            <CardWrapper heading={"IDEA VALIDATION INTERVIEW"}>
              <MainContent />
            </CardWrapper>
          )}
        </Fragment>
      }
      sidebar={
        isSmallScreen ? (
          <Box display={"flex"} flexDirection={"column"}>
            <Box sx={{ my: 3 }}>
              <TabsComponent />
            </Box>
            <Sidebar sidebar_content={SIDEBARDATA} />
          </Box>
        ) : (
          <Sidebar sidebar_content={SIDEBARDATA} />
        )
      }
      breadcrumbs={
        <BreadcrumbsComp
          powerupDetails={powerupDetails}
          sectionTitle={"Browse Interview Candidates"}
        />
      }
      tab={currentTab}
      footerComponent={<FooterComponent />}
    />
  );
};

export default BrowseInterviewCandidates;
