import { ListItem } from "@mui/material";
import { styled, createTheme } from "@mui/material/styles";
import { sosPrimary } from "../../../../constants/Theme/colors";
import theme from "../../../../constants/Theme";

export const listItemTheme = createTheme(theme, {
  components: {
    PowerUpListItem: {
      styleOverrides: {
        centered: {
          display: "block",
        },
        block: {
          display: "block",
        },
      },
      variants: [
        {
          props: { variant: "outlined" },
        },
        {
          props: { variant: "outlined", selected: true },
          style: {
            backgroundColor: sosPrimary[50],
          },
        },
        {
          props: { variant: "filled" },
          style: {
            "&:hover": {
              border: "5px solid red",
            },
          },
        },
        {
          props: { variant: "nofill" },
          style: {
            "&:hover": {
              border: "5px solid red",
            },
          },
        },
      ],
    },
  },
});

export const PowerUpListItem = styled(ListItem, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop =>
    prop !== "disabled" &&
    prop !== "alignment" &&
    prop !== "variant" &&
    prop !== "selected" /* && prop !== "customProp"  */,
  name: "PowerUpListItem",
  overridesResolver: (props, styles) => [
    props.disabled && styles.disabled,
    props.alignment == "centered" && styles.centered,
  ],
})(({ theme }) => ({
  // Applies to All PowerUpListItem Components
  borderRadius: "28px",
  transition: "all .2s",
  textAlign: "center",
  boxShadow: theme.shadows[0],
}));
