import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {
  SidebarIconCompleted,
  SidebarIconActive,
  SidebarIconInActive,
} from "../../../common/icons";
import { ONBOARDING_STEPS } from "../../../constants/steps";
import { ROADRUNNERLOGO } from "../../../constants/image";
import { useHistory } from "react-router-dom";
import { Container, Typography } from "@mui/material";
import { StartupLogoBox, StepConnectorVariations } from "./styles";

function OnboardingStepper({ onboarding }) {
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();

  const steps = ONBOARDING_STEPS;

  useEffect(() => {
    return history.listen(location => {
      var currentStepIndex = steps.findIndex(
        step => step.path === location.pathname
      );
      setActiveStep(currentStepIndex);
    });
  }, [history]);

  useEffect(() => {
    var currentStepIndex = steps.findIndex(
      step => step.path === window.location.pathname
    );
    setActiveStep(currentStepIndex);
  }, []);

  const StepIcon = props => {
    const { active, step, keys } = props;
    let completed = isStepFilled(keys);

    if (completed && !active) {
      return <SidebarIconCompleted cursor={"Pointer"} />;
    } else if (active) {
      return <SidebarIconActive cursor={"Pointer"} />;
    } else {
      return <SidebarIconInActive cursor={"default"} />;
    }
  };

  const handleStepClick = index => {
    const stepObject = steps.find(step => step.stepNumber === index);
    if (isStepFilled(stepObject.key)) history.push(stepObject.path);
  };

  const isStepFilled = key => {
    switch (key) {
      case "companyName":
        return true;
      case "companyLocation":
        return onboarding[key]?.state?.length > 0;
      case "productService":
        return (
          onboarding[key]?.length > 0 ||
          onboarding.autoCompleteDetails?.companyType
        );
      case "journeyDetails":
        return (
          onboarding[key]?.length > 0 ||
          onboarding.autoCompleteDetails?.journeyDetails
        );
      default:
        return onboarding[key]?.length > 0;
    }
  };

  const renderOnboardingDetails = step => {
    if (step.key === "journeyDetails") {
      var journeyDetails = [...onboarding.journeyDetails];
      var limitJourney;

      if (onboarding.autoCompleteDetails?.journeyDetails) {
        journeyDetails.push(onboarding.autoCompleteDetails?.journeyDetails);
      }

      if (journeyDetails.length >= 2) {
        limitJourney = journeyDetails.slice(0, 2);
      } else {
        limitJourney = journeyDetails;
      }

      return (
        <Typography variant="h5" color="primary" sx={{ cursor: "Pointer" }}>
          {limitJourney
            .map((journey, index) => {
              if (index < 3) {
                if (index === 0) {
                  return journey
                    .replace("I’ve", "have")
                    .replace("I’m", "")
                    .replace("I ", "")
                    .replace("i ", "");
                } else {
                  return journey
                    .replace("I’ve", "")
                    .replace("I’m", "")
                    .replace("I ", "")
                    .replace("i ", "");
                }
              }
            })
            .join(", ")}
          {journeyDetails.length > 2 ? " and more" : ""}
        </Typography>
      );
    } else if (step.key === "companyLocation") {
      return (
        <Typography variant="h5" color="primary" sx={{ cursor: "Pointer" }}>
          {onboarding.companyLocation?.state}
        </Typography>
      );
    } else if (step.key === "productService") {
      return (
        <Typography variant="h5" color="primary" sx={{ cursor: "Pointer" }}>
          {onboarding.productService ||
            onboarding?.autoCompleteDetails?.companyType}
        </Typography>
      );
    } else {
      return (
        <Typography variant="h5" color="primary" sx={{ cursor: "Pointer" }}>
          {onboarding[step.key]}
        </Typography>
      );
    }
  };

  return (
    <>
      <StartupLogoBox>
        <img src={ROADRUNNERLOGO} alt="logo" />
        <Typography variant="h5" sx={{ ml: 0.1 }} noWrap>
          Your startup journey so far...
        </Typography>
      </StartupLogoBox>
      <Container>
        <Box sx={{ width: "100%" }}>
          <Stepper
            sx={{ mt: 7 }}
            activeStep={activeStep}
            orientation="vertical"
            connector={<StepConnectorVariations />}
          >
            {steps.map((step, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step
                  key={step.key}
                  {...stepProps}
                  onClick={() => handleStepClick(index)}
                >
                  <StepLabel
                    StepIconComponent={props => (
                      <StepIcon keys={step.key} {...props} />
                    )}
                    {...labelProps}
                    sx={{ padding: "4px 0" }}
                  >
                    {(activeStep === step.stepNumber ||
                      isStepFilled(step.key)) && (
                      <Typography
                        variant="h5"
                        sx={{
                          cursor:
                            activeStep === step.stepNumber ||
                            isStepFilled(step.key)
                              ? "Pointer"
                              : "",
                        }}
                      >
                        {" "}
                        {step.label}{" "}
                      </Typography>
                    )}

                    {renderOnboardingDetails(step)}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
      </Container>
    </>
  );
}

const mapStoreToProps = ({ onboarding }) => ({
  onboarding: onboarding,
});

export default connect(mapStoreToProps, undefined)(OnboardingStepper);
