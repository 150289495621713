import styled from "@emotion/styled";

export const PageTitleWrapper = styled.div(({ theme }) => {
  return {
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    borderRadius: "28px",
    backgroundColor: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
  };
});
