import React, { useEffect, useState, forwardRef, useRef } from "react";
import {
  Typography,
  Box,
  Grid,
  TextField,
  Divider,
  List,
  ListItem,
} from "@mui/material";
import useSize from "@react-hook/size";

import { Responsive, WidthProvider } from "react-grid-layout";
import RemoveRedEyeSharpIcon from "@mui/icons-material/RemoveRedEyeSharp";
import VisibilityOffSharpIcon from "@mui/icons-material/VisibilityOffSharp";
import { PROFILE_PLACE_HOLDER, LOGOICON } from "../../../constants";
import theme from "../../../constants/Theme";
import services from "../../../services";
import FormSectionHeader from "../../../components/Workspace/PowerUp/FormSectionHeader";
import { CONTACT_ICONS } from "../../../constants/powerup-data";

const getTextFromSelectedIcon = urls => {
  const iconTextMap = {
    Phone: CONTACT_ICONS[0],
    Email: CONTACT_ICONS[1],
    Text: CONTACT_ICONS[2],
    "Social Media": CONTACT_ICONS[3],
    People: CONTACT_ICONS[4],
  };
  let selectedIcons = [];
  for (const key in iconTextMap) {
    if (
      Object.hasOwnProperty.call(iconTextMap, key) &&
      urls.includes(iconTextMap[key])
    ) {
      selectedIcons.push(key);
    }
  }
  return selectedIcons.join(",");
};
const ObserveHeightComponent = ({ children, reportChange }) => {
  const target = useRef(null);

  const [width, height] = useSize(target);

  useEffect(() => {
    reportChange({
      width: width,
      height: height,
    });
  }, [width, height]);

  return (
    <Box sx={{ p: 1, boxSizing: "border-box" }} ref={target}>
      {children}
    </Box>
  );
};
const ResponsiveGridLayout = WidthProvider(Responsive);
const SummaryPdf = forwardRef(
  (
    {
      powerUpExcercises,
      excerciseSteps,
      initialData,
      autoSave,
      triggerSaveAction,
      setTriggerSaveAction,
      moduleId,
      versionId,
    },
    ref
  ) => {
    const gridParams = {
      autoSize: true,
      cols: 12,
      rowHeight: 20,
    };
    const [profileObj, setProfileObj] = useState([
      {
        label: "Name",
        value: initialData?.personaName,
        avatar: initialData?.avatar,
      },
      { label: "Age", value: initialData?.age },
      { label: "Occupation", value: initialData?.jobTitle },
      { label: "Industry", value: initialData?.industry },
      {
        label: "Location",
        value: initialData?.state + "," + initialData?.city,
      },
      {
        label: "Highest Level of Education",
        value: initialData?.educationLevel,
      },
      {
        label: "Preferred communication",
        value: getTextFromSelectedIcon(initialData?.communicationMethods),
      },
    ]);
    const [layout, setLayout] = useState([
      {
        i: "1",
        x: 0,
        y: 2,
        w: 4,
        h: 2,
      },
      {
        i: "2",
        x: 4,
        y: 2,
        w: 4,
        h: 2,
      },
      {
        i: "3",
        x: 8,
        y: 2,
        w: 4,
        h: 2,
      },
      {
        i: "4",
        x: 0,
        y: 4,
        w: 6,
        h: 2,
      },
      {
        i: "5",
        x: 6,
        y: 4,
        w: 6,
        h: 2,
      },
    ]);
    const [cardContent, setCardContent] = useState([
      {
        id: 1,
        hidden: false,
        title: "Goals & Objectives",
        text: initialData?.goals.join("\r\n"),
      },
      {
        id: 2,
        hidden: false,
        title: "Biggest Challenges",
        text: initialData?.challenges.join("\r\n"),
      },
      {
        id: 3,
        hidden: false,
        title: "Job Responsibilities",
        text: initialData?.jobResponsibilities.join("\r\n"),
      },
      {
        id: 4,
        hidden: false,
        title: "Tools Needed",
        text: initialData?.tools.join("\r\n"),
      },
      {
        id: 5,
        hidden: false,
        title: "Gain Information By",
        text: "find a mentor who has been in her shoes before and knows the path she should take get in the room with investors and start building a future for her startup",
      },
    ]);
    const [currentExerciseData, setCurrentExerciseData] = useState(null);

    const [editAvatarModalOpen, setEditAvatarModalOpen] = useState(false);

    useEffect(() => {
      let currentStep = excerciseSteps.find(
        excercise => excercise.stepNo === 4
      );

      const newExcerciseData = powerUpExcercises.find(
        excercise => excercise.stepNo === currentStep.stepNo
      );

      setCurrentExerciseData(newExcerciseData);

      if (newExcerciseData?.answers?.gridData) {
        setLayout(newExcerciseData?.answers?.gridData);
        setCardContent(newExcerciseData?.answers?.contentData);
        setProfileObj(newExcerciseData?.answers?.profileData);
      }
    }, [powerUpExcercises]);

    useEffect(() => {
      //Trigger autosave flag and call save api and again set trigger flag false
      if (triggerSaveAction) {
        powerupApiCall(layout, cardContent, profileObj);
        setTriggerSaveAction(false);
      }
    }, [triggerSaveAction]);

    const onDragStop = (newLayout, layouts) => {
      setLayout(newLayout);
      powerupApiCall(newLayout, cardContent, profileObj);
    };
    const onResizeStop = (newLayout, layouts) => {
      setLayout(newLayout);
      powerupApiCall(newLayout, cardContent, profileObj);
    };
    const handleResize = (size, key) => {
      let element = layout.find(x => !x.hidden && x.i === key);
      if (typeof element !== "undefined" && element.hasOwnProperty("h")) {
        let boxHeight = element.h;
        if (size && size.height && size.height >= boxHeight) {
          let heightFactor = Math.round(size.height / gridParams.rowHeight);
          heightFactor = heightFactor >= 1 ? heightFactor : 1;
          setLayout(
            layout.map(item =>
              item.i === key ? { ...item, h: heightFactor } : item
            )
          );
        }
      }
    };
    const powerupApiCall = (gridData, contentData, profileData) => {
      if (autoSave || triggerSaveAction) {
        let requestBody = {
          ...currentExerciseData.answers,
          gridData: gridData,
          contentData: contentData,
          profileData: profileData,
        };

        services
          .storeModuleStepResponses(moduleId, {
            ...currentExerciseData,
            isLastStep: true,
            answers: JSON.stringify(requestBody),
          })
          .then(response => {
            if (response.data.code === 200) {
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    };

    return (
      <Box
        ref={ref}
        sx={{
          visibility: "hidden",
          maxHeight: 0,
        }}
        id="summaryDivHeader"
      >
        <Box sx={{ padding: "20px" }}>
          <FormSectionHeader heading="INITIAL PERSONA BUILDER" />
          <Divider
            sx={{
              background: theme => theme.palette.secondary.dark,
              [theme.breakpoints.down("md")]: {
                background: theme => theme.palette.secondary.borderLight,
              },
            }}
          />
        </Box>
        <Box sx={{ m: 2 }}>
          <Typography variant="largeTitle">Persona 1</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderRadius: "34px",
          }}
        >
          <Box
            src={(profileObj && profileObj[0]?.avatar) || PROFILE_PLACE_HOLDER}
            component="img"
            sx={{
              p: 10,
              height: "66px",
              width: "66px",
              cursor: "Pointer",
            }}
            id="profile_img"
          ></Box>
          <Box sx={{ height: "100%", py: 2 }}>
            {profileObj &&
              profileObj.map(itm => {
                return (
                  <Grid sx={{ display: "flex", pl: 3, pt: 1 }} container>
                    <Grid item sm={7} md={7}>
                      <Typography variant="label_medium">
                        {itm.label}:
                      </Typography>
                    </Grid>
                    <Grid item sm={5} md={5}>
                      <Typography variant="body_small">{itm.value}</Typography>
                    </Grid>
                  </Grid>
                );
              })}
          </Box>
        </Box>
        <ResponsiveGridLayout
          className="layout"
          layouts={{ lg: layout }}
          onDragStop={onDragStop}
          onResizeStop={onResizeStop}
          rowHeight={gridParams.rowHeight}
          cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
          width={800}
          style={{ margin: "10px" }}
        >
          {layout.map((item, idx) => {
            return !cardContent[idx].hidden ? (
              <Box
                sx={{
                  border: "1px solid #7C757F",
                  borderRadius: "34px",
                  ".eyeIcon": {
                    display: "none",
                  },
                  "&:hover .eyeIcon": {
                    display: "block",
                  },
                }}
                key={item.i}
              >
                <ObserveHeightComponent
                  reportChange={size => handleResize(size, item.i)}
                >
                  {" "}
                  <Box
                    sx={{
                      py: 3,
                      pl: 3,
                      pr: 1,
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      {" "}
                      <Box>
                        <Typography variant="title_small">
                          {" "}
                          {cardContent[idx].title}
                        </Typography>
                      </Box>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                      <Typography
                        variant="caption"
                        sx={{ whiteSpace: "pre-line" }}
                      >
                        {cardContent[idx].text}
                      </Typography>
                    </Box>
                  </Box>
                </ObserveHeightComponent>
              </Box>
            ) : (
              ""
            );
          })}
        </ResponsiveGridLayout>
        <ResponsiveGridLayout
          className="layout"
          layouts={{ lg: layout }}
          rowHeight={gridParams.rowHeight}
          cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
          width={800}
        >
          {layout.map((item, idx) => {
            return cardContent[idx].hidden && idx !== 0 ? (
              <Box
                sx={{
                  border: "1px solid #7C757F",
                  borderRadius: "34px",
                  opacity: "0.5",
                }}
                key={item.i}
              >
                <ObserveHeightComponent
                  reportChange={size => handleResize(size, item.i)}
                >
                  <Box
                    sx={{
                      py: 3,
                      pl: 3,
                      pr: 1,
                      ".eyeIcon": {
                        display: "none",
                      },
                      "&:hover .eyeIcon": {
                        display: "block",
                      },
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <Box>
                        <Typography variant="title_small">
                          {cardContent[idx].title}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Typography
                        variant="caption"
                        sx={{ whiteSpace: "pre-line" }}
                      >
                        {cardContent[idx].text}
                      </Typography>
                    </Box>
                  </Box>
                </ObserveHeightComponent>
              </Box>
            ) : (
              ""
            );
          })}
        </ResponsiveGridLayout>
        <Box style={{ textAlign: "center", padding: "20px" }}>
          <Divider
            sx={{
              background: theme => theme.palette.secondary.dark,
              [theme.breakpoints.down("md")]: {
                background: theme => theme.palette.secondary.borderLight,
              },
              mb: 2,
            }}
          />
          <Box src={LOGOICON} component="img"></Box>
        </Box>
      </Box>
    );
  }
);

export default SummaryPdf;
