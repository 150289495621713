import { PersonSearch, SpeakerNotes } from "@mui/icons-material";
import { Box, Typography, Button, Card, useMediaQuery } from "@mui/material";
import { getByTitle } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import VideoPlayerComponent from "../../../../../common/videoPlayer";
import PrerequisitesSteps from "../../../../../components/Workspace/PowerUp/PrerequisitesSteps";
import * as actions from "../../../../../modules/actions";
import services from "../../../../../services";
import BrowseCandidatesTable from "./BrowseCandidatesTable";

const ComponentButton = ({ icon, text, action, ...buttonProps }) => {
  return (
    <Button
      disabled={buttonProps.disabled}
      variant="outlined"
      sx={{
        width: "100%",
        maxWidth: "unset",
        height: "200px", // Explicit height setting in this case. Does not follow reusable guidelines. -MG
        ...buttonProps.styles,
      }}
      onClick={action}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>{icon}</Box>
        {text}
      </Box>
    </Button>
  );
};

const DesktopView = ({
  powerupDetails,
  getCardDisableStatus,
  handleCardAction,
  getTitle,
  moduleInterview,
  moduleBuildScript,
}) => {
  const [details, setPowerUPData] = useState({});

  useEffect(() => {
    setPowerUPData(powerupDetails);
  }, [powerupDetails]);

  const dispatch = useDispatch();

  return (
    <Card size="large" variant="outlined">
      <Box>
        <VideoPlayerComponent videoUrl={details.videoUrl} />
      </Box>
      <Box
        sx={{
          mt: 3,
        }}
      >
        <Typography variant="headline2" color="text.secondary">
          Overview
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Typography variant="body1">{details.overview}</Typography>
      </Box>

      <Box
        gridTemplateColumns={"repeat(12,1fr)"}
        display="grid"
        gap={2}
        sx={{ my: 3 }}
      >
        {details &&
          details.modules &&
          details.modules.map(module => {
            return (
              <Box gridColumn={"span 6"}>
                <ComponentButton
                  disabled={getCardDisableStatus(module)}
                  icon={
                    module.moduleUuid === "md-build-script" ? (
                      <SpeakerNotes />
                    ) : (
                      <PersonSearch />
                    )
                  }
                  text={getTitle(module)}
                  action={() => handleCardAction(module)}
                />
              </Box>
            );
          })}
      </Box>

      <Box
        gridTemplateColumns={"repeat(12,1fr)"}
        display="grid"
        gap={2}
        sx={{ my: 3 }}
      >
        {" "}
        <Box gridColumn={"span 12"}>
          {moduleBuildScript &&
            moduleBuildScript.moduleStepResponses.length > 1 && (
              <BrowseCandidatesTable
                moduleInterview={moduleInterview}
                handleStartAction={handleCardAction}
                module={details?.modules && details?.modules[1]}
              />
            )}
        </Box>
      </Box>
      <Box sx={{ my: 2 }}>
        <Box sx={{ my: 1 }}>
          <Typography variant="largeTitle" color="text.secondary">
            What you'll need
          </Typography>
        </Box>
      </Box>
      {details.preRequisites && (
        <PrerequisitesSteps
          powerupType={details.powerUpName}
          prerequisitesData={details.preRequisites}
        />
      )}

      <Box sx={{ my: 4 }}>
        <Box sx={{ my: 1 }}>
          <Typography variant="largeTitle" color="text.secondary">
            What you'll learn
          </Typography>
        </Box>
        <Box>
          <Typography>
            Interviews are one of the most flexible and powerful methods in the
            StartupOS framework. They can be used across the entire startup
            journey - to generate fresh insights and uncover unmet needs,
            validate the strength of new ideas, refine a value proposition, test
            an early prototype and pricing scheme.
            <br></br>
            <br></br>
            This exercise is designed to make the interview process efficient
            for you and your team by tying all the elements of the interview
            together: identify your learning goals, craft a succinct script,
            find participants, manage your time, capture and unpack everything
            heard and make it actionable. It starts with a speed questionnaire
            that probes the areas-of-value and ultimately ends with a review of
            the key takeaways or “gems”... hunches, trends, quotes and insights.
          </Typography>
        </Box>
      </Box>
      <Box sx={{ my: 2 }}>
        <Box sx={{ my: 1 }}>
          <Typography variant="largeTitle" color="text.secondary">
            Before you start
          </Typography>
        </Box>
        <Box>
          <Typography>
            Certain elements of this PowerUp are best experienced on desktop, so
            we recommend staying on this device to complete it.
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

const MobileView = ({
  powerupDetails,
  handleCardAction,
  getCardDisableStatus,
  getTitle,
  moduleInterview,
  moduleBuildScript,
}) => {
  const [details, setPowerUPData] = useState({});

  useEffect(() => {
    setPowerUPData(powerupDetails);
  }, [powerupDetails]);

  return (
    <Box>
      <Box sx={{ my: 4 }}>
        <Box>
          <Typography variant="title_xlarge" color="text.secondary">
            Overview
          </Typography>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Typography variant="subhead1">{details.overview}</Typography>
        </Box>
      </Box>
      <Box>
        <VideoPlayerComponent videoUrl={details.videoUrl} />
      </Box>
      <Box sx={{ my: 4, display: "flex", flexDirection: "column", gap: 4 }}>
        {details &&
          details.modules &&
          details.modules.map(module => {
            return (
              <Box gridColumn={"span 6"}>
                <ComponentButton
                  disabled={getCardDisableStatus(module)}
                  icon={
                    module.moduleUuid === "md-build-script" ? (
                      <SpeakerNotes />
                    ) : (
                      <PersonSearch />
                    )
                  }
                  text={getTitle(module)}
                  action={() => handleCardAction(module)}
                />
              </Box>
            );
          })}
      </Box>

      <Box
        gridTemplateColumns={"repeat(12,1fr)"}
        display="grid"
        gap={2}
        sx={{ my: 3 }}
      >
        {" "}
        <Box gridColumn={"span 12"}>
          {moduleBuildScript &&
            moduleBuildScript.moduleStepResponses.length > 1 && (
              <BrowseCandidatesTable
                moduleInterview={moduleInterview}
                handleStartAction={handleCardAction}
                module={details?.modules && details?.modules[1]}
              />
            )}
        </Box>
      </Box>
      <Box sx={{ my: 2 }}>
        <Box sx={{ my: 1 }}>
          <Typography variant="largeTitle" color="text.secondary">
            What you'll need
          </Typography>
        </Box>

        {details.preRequisites && (
          <PrerequisitesSteps
            powerupType={details.powerUpName}
            prerequisitesData={details.preRequisites}
          />
        )}
      </Box>

      {/* //TODO - Somewhere in here we need to pull prereqs. */}

      <Box sx={{ my: 4 }}>
        <Box sx={{ my: 1 }}>
          <Typography variant="largeTitle" color="text.secondary">
            What you'll learn
          </Typography>
        </Box>
        <Box>
          <Typography variant="bodyLarge">
            Interviews are one of the most flexible and powerful methods in the
            StartupOS framework. They can be used across the entire startup
            journey - to generate fresh insights and uncover unmet needs,
            validate the strength of new ideas, refine a value proposition, test
            an early prototype and pricing scheme.
            <br></br>
            <br></br>
            This exercise is designed to make the interview process efficient
            for you and your team by tying all the elements of the interview
            together: identify your learning goals, craft a succinct script,
            find participants, manage your time, capture and unpack everything
            heard and make it actionable. It starts with a speed questionnaire
            that probes the areas-of-value and ultimately ends with a review of
            the key takeaways or “gems”... hunches, trends, quotes and insights.
          </Typography>
        </Box>
      </Box>
      <Box sx={{ my: 2 }}>
        <Box sx={{ my: 1 }}>
          <Typography variant="largeTitle" color="text.secondary">
            Before you start
          </Typography>
        </Box>
        <Box>
          <Typography variant="bodyLarge">
            Certain elements of this PowerUp are best experienced on desktop, so
            we recommend staying on this device to complete it.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const DetailsOverview = ({ powerupDetails }) => {
  const [details, setPowerUPData] = useState({});
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("md"));
  const [moduleBuildScript, setModuleBuildScript] = useState(null);
  const [moduleInterview, setModuleInterview] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setPowerUPData(powerupDetails);
    if (powerupDetails.modules) {
      //get modulestep responses of interview script module
      services
        .getModuleStepResponses(powerupDetails.modules[0].id)
        .then(result => {
          const data = result?.data?.data;
          if (data) {
            setModuleBuildScript(data[0]);
            dispatch(
              actions.setPowerupDetails({ id: "build-script", data: data[0] })
            );
          } else {
            setModuleBuildScript(null);
          }
        })
        .catch(err => console.log(err));

      services
        .getModuleStepResponses(powerupDetails.modules[1].id)
        .then(result => {
          const data = result?.data?.data;
          if (data) {
            //sorting data as order from backend chages
            const moduleData = data[0];
            moduleData.moduleStepResponses.sort((a, b) => {
              return a.id > b.id ? 1 : -1;
            });
            setModuleInterview(moduleData);

            const candidates = JSON.parse(
              moduleData?.moduleStepResponses[0]?.answers
            )?.candidates;

            if (candidates) {
              dispatch(actions.setInterviewCandidates(candidates));
            }
          } else {
            setModuleInterview(null);
          }
        })
        .catch(err => console.log(err));
    }
  }, [powerupDetails]);

  const getCardDisableStatus = module => {
    if (module.moduleUuid === "md-build-script") {
      return false;
    } else {
      if (moduleBuildScript) {
        if (moduleBuildScript.moduleStepResponses?.length > 1) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  const handleCardAction = module => {
    if (module.moduleUuid === "md-build-script") {
      //Apply this when script is completed dispatch(actions.setInterviewScriptDialog(true));

      if (moduleBuildScript) {
        if (moduleBuildScript.moduleStepResponses?.length > 1) {
          //check if answer exists or deleted
          if (
            JSON.parse(moduleBuildScript.moduleStepResponses[1].answers)
              .interviewScript
          ) {
            dispatch(actions.setInterviewScriptDialog(true));
          } else {
            history.push(
              `/workspace/powerup/${"md-ideavalidation"}/${module.id}/${
                moduleBuildScript.id
              }/start`
            );
          }
        } else {
          history.push(
            `/workspace/powerup/${"md-ideavalidation"}/${module.id}/${
              moduleBuildScript.id
            }/start`
          );
        }
      } else {
        services
          .startModule({ moduleId: module.id })
          .then(response => {
            const res = response.data;
            if (res.code === 200) {
              history.push(
                `/workspace/powerup/${"md-ideavalidation"}/${module.id}/${
                  res.data.id
                }/start`
              );
            }
          })
          .catch(error => console.log(error));
      }
    } else {
      if (
        moduleBuildScript &&
        moduleBuildScript.moduleStepResponses.length > 1
      ) {
        if (moduleInterview && moduleInterview.moduleStepResponses.length > 0) {
          history.push(
            `/workspace/powerup/4/browse-candidates/${module.id}/${moduleInterview.id}/browse`
          );
        } else {
          services
            .startModule({ moduleId: module.id })
            .then(response => {
              const res = response.data;
              if (res.code === 200) {
                history.push(
                  `/workspace/powerup/4/browse-candidates/${module.id}/${res.data.id}/browse`
                );
              }
            })
            .catch(error => console.log(error));
        }
      }
    }
  };

  const getTitle = module => {
    if (module.moduleUuid === "md-build-script") {
      if (
        moduleBuildScript &&
        moduleBuildScript.moduleStepResponses.length > 1
      ) {
        if (
          JSON.parse(moduleBuildScript.moduleStepResponses[1].answers)
            .interviewScript
        ) {
          return "View Completed Script";
        } else {
          return "Build a Script";
        }
      } else {
        return "Build a Script";
      }
    } else {
      return module.title;
    }
  };

  return (
    <Box>
      {isSmallScreen ? (
        <MobileView
          powerupDetails={details}
          getCardDisableStatus={getCardDisableStatus}
          handleCardAction={handleCardAction}
          getTitle={getTitle}
          moduleBuildScript={moduleBuildScript}
          moduleInterview={moduleInterview}
        />
      ) : (
        <DesktopView
          powerupDetails={details}
          getCardDisableStatus={getCardDisableStatus}
          handleCardAction={handleCardAction}
          getTitle={getTitle}
          moduleBuildScript={moduleBuildScript}
          moduleInterview={moduleInterview}
        />
      )}
    </Box>
  );
};
