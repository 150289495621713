import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Button, Stack } from "@mui/material";
import { connect } from "react-redux";

import ROUTES_PATH from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import OnboardingCard from "../../../common/card/OnboardingCard";

import constants from "../../../constants";

import * as actions from "../../../modules/actions";
import theme from "../../../constants/Theme";

function ProductServiceOffering({
  fetchedAutoCompleteDetails,
  getAutoCompleteData,
  setProductService,
  autoCompleteDetails,
  productService,
}) {
  const [productServiceData, setProductServiceData] = useState(
    constants.PRODUCT_SERVICE_OFFERING_DATA
  );

  const history = useHistory();

  const [answers, setAnswers] = useState([]);

  useEffect(async () => {
    getAutoCompleteData("companyType");
    const data = [...productServiceData];
    data.map((item, idx) => {
      if (item.text === productService) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      if (item.text === "Other") {
        //if found add it into answers array which holds state of input box's answers
        if (autoCompleteDetails.companyType) {
          setAnswers([autoCompleteDetails.companyType]);
          item.selected = true;
        }
      }
      data[idx] = item;
    });
    setProductServiceData(data);
  }, []);

  const handleSelect = (index, answer) => {
    const data = [...productServiceData];

    data.map((item, idx) => {
      item = {
        ...item,
        selected: false,
      };
      data[idx] = item;
    });
    setProductServiceData(data);

    var selectedAnswer = data[index];
    selectedAnswer = {
      ...selectedAnswer,
      selected: answer ? true : false,
    };
    data[index] = selectedAnswer;
    setProductServiceData(data);
    if (answer) {
      setAnswers([answer]);
    } else {
      let filtered = answers?.filter(
        (answer) => answer !== selectedAnswer.text
      );
      setAnswers(filtered);
    }

    //Add answers to redux stores
  };
  const handleNext = () => {
    const selectedProductService = productServiceData?.find(
      (item) => item.selected === true
    )?.text;
    const payload = {
      productService:
        selectedProductService !== "Other" ? selectedProductService : "",
      autoCompleteData: {
        companyType: selectedProductService === "Other" ? answers[0] : "",
      },
    };

    setProductService(payload);

    history.push(ROUTES_PATH.ONBOARDING_STARTUP_JOURNEY);
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100%" }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ mt: 3 }}
      >
        <Typography
          sx={{
            alignItems: "center",
            color: "#0A141B",
            textAlign: "center",
          }}
          variant="h2"
          color="secondary"
        >
          What type of product/service are you offering?
        </Typography>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          maxWidth: theme.breakpoints.values.md,
          py: 4,
        }}
      >
        <Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          gridAutoRows="1fr"
          gap={2}
          width={1}
        >
          {productServiceData.map((item, index) => {
            return (
              <Box
                sx={{
                  gridColumn: {
                    lg: "span 4",
                    md: "span 4",
                    sm: "span 6",
                    xs: "span 6",
                  },
                }}
              >
                <OnboardingCard
                  cardText={item.text}
                  iconComponent={<item.iconComponent />}
                  selected={item?.selected}
                  onSelect={handleSelect}
                  index={index}
                  isInput={item.isInput}
                  suggestions={fetchedAutoCompleteDetails["companyType"]}
                  inputValue={autoCompleteDetails.companyType}
                  maxInputLength={15}
                />
              </Box>
            );
          })}
        </Box>
      </Grid>
      <Grid container justifyContent="center" sx={{ mt: 7.5, mb: 3 }}>
        <Grid item xs={10} sm={10} md={4} lg={4}>
          <Box textAlign={"center"}>
            <Button
              onClick={() => handleNext()}
              sx={{ fontSize: "14px" }}
              disabled={
                productServiceData?.filter((item) => item.selected === true)
                  .length === 0
              }
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}

const mapStoreToProps = ({ onboarding }) => ({
  fetchedAutoCompleteDetails: onboarding?.fetchedAutoCompleteDetails,
  autoCompleteDetails: onboarding?.autoCompleteDetails,
  productService: onboarding?.productService,
  onboarding: onboarding,
});

const mapDispatchToProps = (dispatch) => ({
  getAutoCompleteData: (questionKey) =>
    dispatch(actions.getAutoCompleteData(questionKey)),
  setProductService: (updatedData) =>
    dispatch(actions.setProductService(updatedData)),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(ProductServiceOffering);
