import { Box, Typography, Button } from "@mui/material";
import React from "react";
import theme from "../../../constants/Theme";
import AddIcon from "@mui/icons-material/Add";

export default function PageTitle({ iconUrl, title }) {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: `${theme.spacing(3)}`,
          backgroundColor: `${theme.palette.background.default}`,
        }}
      >
        <Box
          sx={{
            ml: { xs: 2, md: 4 },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", p: 2 }}>
            <img src={iconUrl} alt="icon" />
          </Box>
          <Typography variant="h3">{title}</Typography>
        </Box>

        <Box sx={{ p: 4, display: { xs: "none", md: "flex" } }}>
          <Button variant="outlined" startIcon={<AddIcon />}>
            Invite Team
          </Button>
        </Box>
      </Box>
    </div>
  );
}
