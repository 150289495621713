import styled from "@emotion/styled";
import { TextField } from "@mui/material";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import TableRow from "@mui/material/TableRow";

export const StyledTextField = styled(TextField)(({ theme }) => ({
  padding: "0px !important",
  Input: {
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
  },
  "& .MuiOutlinedInput-root": {
    color: theme.palette.primary.main,
    padding: "0px !important",
    fontSize: 12,
    fontFamily: "RobotoRegular",
    fontWeight: 700,
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(118, 73, 160, 0.08)",
    color: "black",
    borderWidth: 1,
    borderColor: theme.palette.secondary.borderLight,
    borderStyle: "solid",
    borderTopWidth: 0,

    "&:first-child": {
      borderLeftWidth: 0,
    },

    "&:last-child": {
      borderRightWidth: 0,
    },
  },

  [`&.${tableCellClasses.root}`]: {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    "&:last-child": {
      borderRightWidth: 0,
    },
  },

  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderWidth: 1,
    borderColor: theme.palette.secondary.borderLight,
    borderStyle: "solid",
    borderBottomWidth: 0,

    "&:first-child": {
      borderLeftWidth: 0,
    },

    "&:last-child": {
      borderRightWidth: 0,
    },
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  background: theme.palette.secondary.white,

  "&:first-child": {
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  "&:last-child": {
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderWidth: 10,
  },
}));
