import React from "react";
import { Grid, Typography, Box } from "@mui/material";

import Card from "../../../components/Workspace/Card";

import ProgressCard from "./ProgressCard";
import CurrentStreaks from "./CurrentStreaks";
import InvestorRadar from "./InverstorRadar";
import StartupOSScore from "./StartupOSScore";
import { BlueDashboardIcon } from "../../../common/icons";
import { getCurrentDate } from "../../../utils/date";

const QuickStaView = ({ data }) => {
  return (
    <Card>
      <Box sx={{ display: "flex", alignItems: "center" }} borderRadius="28px">
        <BlueDashboardIcon />
        <Typography variant="h2Medium" sx={{ ml: 2 }}>
          Dashboard
        </Typography>
        <Typography
          sx={{
            marginLeft: "auto",
          }}
          variant="h6"
        >
          {getCurrentDate()}
        </Typography>
      </Box>
      <Typography variant="h6" sx={{ pt: 3, pb: 2 }}>
        Quick Stats View
      </Typography>
      <Grid container spacing={2}>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <ProgressCard data={data} />
        </Grid>

        <Grid item lg={6} md={12} sm={12} xs={12}>
          <StartupOSScore
            id="desktop"
            value={parseInt(data.startUpScore)}
            maxValue={data.startUpMaxScore}
          />
        </Grid>

        <Grid item lg={6} md={12} sm={12} xs={12}>
          <InvestorRadar id="desktop" level={data.radarValue} />
        </Grid>

        <Grid item lg={6} md={12} sm={12} xs={12}>
          <CurrentStreaks data={data.streaks} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default QuickStaView;
