import React, { Component } from "react";
import { Grid, Button, Box, Stack } from "@mui/material";
import "./forgotPassword.css";
import TextInput from "../../common/input/Input";
import ROUTES_PATH from "../../constants/routes";
import { REGEX } from "../../constants/regex";
import {
  saveUserEmail,
  saveVerificationType,
} from "../../utils/sessionStorage";
import { passwordReset } from "../../utils/auth0";
import { toastContainer } from "../../utils/toast";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      email: "",
      emailError: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  ValidateEmail(value) {
    if (REGEX.EMAIL.test(value)) {
      return true;
    }
    return false;
  }
  handleEmailInputChange = event => {
    this.setState({
      email: event,
    });
    if (this.ValidateEmail(event)) {
      this.setState({ emailError: false });
    } else {
      this.setState({ emailError: true });
    }
  };

  handleForgotPassword = () => {
    const { email, emailError } = this.state;

    if (email && !emailError) {
      saveUserEmail(email);

      //call auth0 /change-password api and send the reset-password email to user
      passwordReset({
        email: this.state.email,
      })
        .then(data => {
          saveVerificationType("forgotPassword");
          this.props.history.push(ROUTES_PATH.RESEND_EMAIL);
        })
        .catch(err => {
          console.log(err);
          toastContainer(err?.error_description, "error");
        });
    }
  };

  render() {
    let { email, emailError } = this.state;
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100%" }}
      >
        <Grid
          className="signup-grid-container h-80"
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={10} md={6} lg={6} className="form-container">
            <div className="signup-title">Forgot Your Password?</div>
            <Box sx={{ pt: 2 }} className="signup-sub-text">
              Enter your email address and we will send you instructions to
              reset your password.
            </Box>
            <TextInput
              type="text"
              label="Email address"
              value={email}
              error={emailError ? "Please enter valid email address" : ""}
              onChange={e => this.handleEmailInputChange(e)}
            />

            <Grid item>
              <Box textAlign={"center"}>
                <Button
                  sx={{ mt: 3, maxWidth: "100%" }}
                  onClick={e => this.handleForgotPassword(e)}
                  disabled={!email || emailError}
                >
                  Continue
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    );
  }
}

export default ForgotPassword;
