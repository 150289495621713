import { TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import messages from "../../../../constants/messages";
import { REGEX } from "../../../../constants/regex";
import theme from "../../../../constants/Theme";

export default function TextAreaInput({ userInputs, setUserInputs }) {
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = e => {
    const value = e.target.value;
    setInputValue(value);
    setUserInputs([value]);
  };

  const inputValid = input => {
    if (input.match(REGEX.STORYTELLER_INPUTBOX)) {
      return true;
    } else {
      return false;
    }
  };

  const handleBlur = () => {
    if (inputValid(inputValue)) {
      setErrorMessage("");
    } else {
      setErrorMessage(messages.TEXTBOX_VALIDATION);
    }
  };

  useEffect(() => {
    if (userInputs.length) {
      setInputValue(userInputs[0]);
      setErrorMessage("");
    } else {
      setInputValue("");
    }
  }, [userInputs]);

  return (
    <TextField
      multiline
      minRows={20}
      value={inputValue}
      onChange={e => handleChange(e)}
      onBlur={handleBlur}
      error={!!errorMessage}
      helperText={errorMessage}
      sx={{
        [theme.breakpoints.down("md")]: {
          "& .MuiOutlinedInput-root": {
            backgroundColor: "white",
          },
        },
      }}
    />
  );
}
