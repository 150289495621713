import { Typography, AccordionDetails, Box } from "@mui/material";
import React, { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  accordionTheme,
  PowerUpAccordion,
  PowerUpSummary,
  PowerUpAvatar,
  AccordionWrapper,
} from "./styles";

const PowerupAccordion = ({ valuePropsContents }) => {
  const [expanded, setExpanded] = useState("");
  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : "");
  };
  return (
    <AccordionWrapper>
      {valuePropsContents.map((item, idx) => {
        return (
          <ThemeProvider key={idx} theme={accordionTheme}>
            <PowerUpAccordion
              onChange={handleChange(idx)}
              disableGutters
              expanded={expanded === idx}
            >
              <PowerUpSummary
                sx={{ alignItems: "center" }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                expanded={expanded === idx}
              >
                <PowerUpAvatar expanded={expanded === idx}>
                  <Typography variant="h5">
                    {item.stepNo && item.stepNo <= 9
                      ? "0" + item.stepNo
                      : item.stepNo}
                  </Typography>
                </PowerUpAvatar>

                <Typography variant="h5" sx={{ alignSelf: "center", pl: 1 }}>
                  {item.title}
                </Typography>
              </PowerUpSummary>
              <AccordionDetails>
                <Typography variant="body1">{item.description}</Typography>
              </AccordionDetails>
            </PowerUpAccordion>
          </ThemeProvider>
        );
      })}
    </AccordionWrapper>
  );
};

export default PowerupAccordion;
