import {
  Box,
  TextField,
  Typography,
  FormControl,
  Autocomplete,
  CardContent,
} from "@mui/material";
import React, { useState } from "react";
import { cardTheme, OnboardingCard } from "./styles";
import PropTypes from "prop-types";
import { ThemeProvider } from "@mui/material/styles";
import messages from "../../constants/messages";

const CardComp = ({
  iconComponent,
  cardText,
  selected,
  cardSubText,
  isInput,
  onSelect,
  index,
  suggestions,
  inputValue,
  maxInputLength
}) => {
  const [error, setError] = useState("");
  const handleChange = (event) => {
    var value = event.target.value;
    if (typeof value === "number") {
      value = event.target.innerText;
      onSelect(index, value);
    } else {
      if (!value) {
        setError(null);
        onSelect(index, null);
      } else {
        if (value.length > 0 && (value.length < 2 || value.length > maxInputLength)) {
          setError(messages.AUTOCOMPLETE_INPUT_INVALID.replace("{{maxLength}}",maxInputLength));
          onSelect(index, null);
        } else {
          setError(null);
          onSelect(index, value);
        }
      }
    }
  };

  return (
    <ThemeProvider theme={cardTheme}>
      <OnboardingCard
        alignment={isInput ? "block" : "centered"}
        color="primary"
        variant="outlined"
        selected={selected}
        onClick={() =>
          !isInput ? onSelect(index, !selected ? cardText : null) : null
        }
      >
        <CardContent>
          {!isInput ? (
            <>
              {iconComponent}
              <Typography
                align="center"
                color="primary"
                variant="h4"
                sx={{ mt: 2 }}
              >
                {cardText}
              </Typography>
              {cardSubText && cardSubText.length > 0 && (
                <Typography
                  align="center"
                  color="GrayText"
                  sx={{ mt: 2 }}
                >
                  {cardSubText}
                </Typography>
              )}
            </>
          ) : (
            <>
              <Typography
                align="center"
                color="primary"
                variant="h4"
                sx={{ mt: 3 }}
              >
                {cardText}
              </Typography>
              <FormControl sx={{ mt: 3, mb:2 }} fullWidth>
                <Autocomplete
                  freeSolo
                  disableClearable
                  defaultValue={inputValue}
                  options={suggestions?.map((option) => option)}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        sx={{ backgroundColor: "white", borderRadius: "12px" }}
                        placeholder="Type here"
                        onChange={handleChange}
                      />
                      {error ? (
                        <Typography
                          color={"error"}
                          sx={{
                            fontSize: "6px",
                            maxWidth: "fit-content",
                            textAlign: "left",
                          }}
                        >
                          {error}
                        </Typography>
                      ) : null}
                    </>
                  )}
                  onChange={handleChange}
                />
              </FormControl>
            </>
          )}
        </CardContent>
      </OnboardingCard>
    </ThemeProvider>
  );
};

{
  CardComp.propTypes = {
    cardText: PropTypes.string,
    cardSubText: PropTypes.string,
    iconComponent: PropTypes.func,
    selected: PropTypes.bool,
    isInput: PropTypes.bool,
    onSelect: PropTypes.func,
    index: PropTypes.number,
  };

  CardComp.defaultProps = {
    cardText: "",
    cardSubText: "",
    iconComponent: null,
    selected: false,
    isInput: false,
    onSelect: null,
    index: 0,
  };
}

export default CardComp;
