import React, { Component } from "react";
import { Grid, Button, Box } from "@mui/material";
import "./createPassword.css";
import TextInput from "../../common/input/Input";
import PasswordValidation from "../../common/passwordValidation/PasswordValidation";
import ROUTES_PATH from "../../constants/routes";
import { REGEX } from "../../constants/regex";

class CreatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      confirmPassword: "",
      pasword: "",
      validationErrorArray: [true, true, true, true],
      emailError: false,
      confirm_password_error: "",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  ValidateEmail(value) {
    if (REGEX.EMAIL.test(value)) {
      return true;
    }
    return false;
  }
  handleEmailInputChange = event => {
    this.setState({
      email: event,
    });
    if (this.ValidateEmail(event)) {
      this.setState({ emailError: false });
    } else {
      this.setState({ emailError: true });
    }
  };

  comparePasswords(confirmPassword, password) {
    if (confirmPassword === password) {
      this.setState({ confirm_password_error: "" });
    } else {
      this.setState({ confirm_password_error: "Passwords do not match" });
    }
  }

  handlePasswordInputChange = event => {
    let { confirmPassword } = this.state;

    this.setState({
      password: event,
    });

    this.comparePasswords(event, confirmPassword);
    let validationErrorArray = [...this.state.validationErrorArray];
    if (event.length > 8) {
      validationErrorArray[0] = false;
    } else {
      validationErrorArray[0] = true;
    }
    if (event.match(REGEX.LOWERCASELETTERS)) {
      validationErrorArray[1] = false;
    } else {
      validationErrorArray[1] = true;
    }
    if (event.match(REGEX.UPPERCASELETTERS)) {
      validationErrorArray[2] = false;
    } else {
      validationErrorArray[2] = true;
    }
    if (event.match(REGEX.NUMBERS)) {
      validationErrorArray[3] = false;
    } else {
      validationErrorArray[3] = true;
    }
    this.setState({ validationErrorArray });
  };

  handleConfirmPasswordInputChange = event => {
    let { password } = this.state;
    this.comparePasswords(event, password);

    this.setState({
      confirmPassword: event,
    });
  };

  render() {
    let {
      password,
      validationErrorArray,
      confirmPassword,
      confirm_password_error,
    } = this.state;
    return (
      <Box className="signup-container">
        <Grid
          className="signup-grid-container"
          sx={{ mt: 9 }}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={10} md={6} lg={4} className="form-container">
            <div className="signup-title">Create New Password</div>

            <TextInput
              type="password"
              label="Password"
              value={password}
              onChange={e => this.handlePasswordInputChange(e)}
            />
            <TextInput
              type="password"
              label="Re-enter Password"
              value={confirmPassword}
              onChange={e => this.handleConfirmPasswordInputChange(e)}
            />
            <span className="error-text">{confirm_password_error}</span>
            <PasswordValidation validationErrorArray={validationErrorArray} />

            <Grid item>
              <Box textAlign="center">
                <Button
                  sx={{ mt: 3, maxWidth: "100%" }}
                  disabled={
                    !password ||
                    !confirmPassword ||
                    validationErrorArray.includes(true) ||
                    confirmPassword !== password
                  }
                >
                  Continue
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default CreatePassword;
