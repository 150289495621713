import React, { useRef } from "react";
import { Typography, Box, Grid } from "@mui/material";
import { Responsive, WidthProvider } from "react-grid-layout";
import theme from "../../constants/Theme";

const ObserveHeightComponent = ({ children }) => {
  const target = useRef(null);

  return (
    <Box sx={{ p: 1, boxSizing: "border-box" }} ref={target}>
      {children}
    </Box>
  );
};

const ResponsiveGridLayout = WidthProvider(Responsive);
export default function GridLayoutComp({ gridData, contentData, profileData }) {
  const gridParams = {
    autoSize: true,
    cols: 12,
    rowHeight: 20,
  };

  return (
    <>
      {gridData ? (
        <Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                height: "100%",
                border: `1px solid ${theme.palette.secondary.dark}`,
                borderRadius: "34px",
              }}
            >
              <Box
                src={profileData && profileData[0]?.avatar}
                component="img"
                sx={{
                  height: "66px",
                  width: "66px",
                  cursor: "Pointer",
                  alignSelf: "center",
                  margin: "auto",
                }}
              ></Box>
              <Box
                sx={{
                  height: 1,
                  py: 2,
                }}
              >
                {profileData &&
                  profileData.map(itm => {
                    return (
                      <Grid
                        sx={{
                          display: "flex",
                          pl: 3,
                          pt: 1,
                        }}
                        container
                      >
                        <Grid item sm={7} md={7}>
                          <Typography variant="light_on_surface">
                            {itm.label}:
                          </Typography>
                        </Grid>
                        <Grid item sm={5} md={5}>
                          <Typography variant="body_small">
                            {itm.value}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
              </Box>
            </Box>
          </Box>

          <ResponsiveGridLayout
            className="layout"
            layouts={{ lg: gridData }}
            rowHeight={gridParams.rowHeight}
            cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
            width={800}
          >
            {gridData?.map((item, idx) => {
              return !contentData[idx].hidden ? (
                <Box
                  sx={{
                    border: `1px solid ${theme.palette.secondary.dark}`,
                    borderRadius: "34px",
                  }}
                  key={item.i}
                >
                  <ObserveHeightComponent>
                    <Box
                      sx={{
                        py: 3,
                        pl: 3,
                        pr: 1,
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Typography
                          variant="light_on_surface"
                          sx={{ wordBreak: "break-all" }}
                        >
                          {contentData[idx]?.title}
                        </Typography>
                        <Box
                          sx={{
                            textAlign: "right",
                            cursor: "pointer",
                            ml: "auto",
                          }}
                        ></Box>
                      </Box>

                      <Box sx={{ mt: 2 }}>
                        <Typography
                          variant="body_small"
                          sx={{ wordBreak: "break-all" }}
                        >
                          {contentData[idx]?.text}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          position: "absolute",
                          bottom: theme.spacing(1),
                        }}
                      ></Box>
                    </Box>
                  </ObserveHeightComponent>
                </Box>
              ) : (
                ""
              );
            })}
          </ResponsiveGridLayout>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}
