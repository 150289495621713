import React, { useState, useEffect } from "react";
import MultiValueContainer from "../MultiValueContainer";
import { TextField, Box, InputAdornment, Button, Divider } from "@mui/material";
import PropTypes from "prop-types";
import { REGEX } from "../../../../constants/regex";
import messages from "../../../../constants/messages";
import theme from "../../../../constants/Theme";
const MultivalueInputFormContainer = ({
  userInputs,
  setUserInputs,
  inputLabel,
  outputContainerLabel,
  inputPlaceholder,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [inputText, setInputText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = e => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (inputPlaceholder) {
      setInputText(inputPlaceholder);
    }
  }, [inputPlaceholder]);

  const tagsValid = () => {
    const tagsArray = inputValue.split(",");
    for (let tag of tagsArray) {
      const validTag = inputValidate(tag);
      if (!validTag) {
        return false;
      }
    }
    return true;
  };
  const handleOnClick = () => {
    if (tagsValid()) {
      let inputs = [...userInputs, ...inputValue.split(",")];
      let uniqueInputs = new Set(inputs);
      setUserInputs(Array.from(uniqueInputs));
      setInputValue("");
      setErrorMessage("");
    } else {
      setErrorMessage(messages.MULTITAG_INPUT_INVALID);
    }
  };

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      handleOnClick();
    }
    if (e.which === 188) {
      setInputValue(e.target.value);
    }
  };

  const handleDeleteTags = deleted => {
    const filteredTags = userInputs.filter(value => value !== deleted);
    setUserInputs(filteredTags);
  };

  const handleFocusChange = e => {
    setInputText(inputLabel);
  };

  const handleBlurChange = e => {
    if (!inputValue) {
      setInputText(inputPlaceholder);
    }
  };

  const inputValidate = input => {
    if (input.match(REGEX.POWERUP_INPUT)) {
      return true;
    } else {
      return false;
    }
  };

  const responsiveLabelBackground = {
    [theme.breakpoints.down("md")]: {
      "& .MuiFormLabel-root": {
        background: theme.palette.secondary.backgroundGradient,
      },
    },
    [theme.breakpoints.up("md")]: {
      "& .MuiFormLabel-root": {
        backgroundColor: "secondary.white",
      },
    },
  };

  return (
    <Box sx={{ mt: 1 }} flexGrow={1} display="flex" flexDirection={"column"}>
      <TextField
        fullWidth
        label={inputText}
        value={inputValue}
        onFocus={handleFocusChange}
        onBlur={handleBlurChange}
        error={!!errorMessage}
        helperText={errorMessage}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{ height: "70%", background: "secondary.light" }}
            >
              <Button
                disabled={!inputValue}
                variant="nofill"
                size="small"
                sx={{ m: 0, p: 0 }}
                onClick={handleOnClick}
              >
                + Add
              </Button>
            </InputAdornment>
          ),
        }}
        onChange={e => handleChange(e)}
        onKeyDown={e => handleKeyDown(e)}
        sx={responsiveLabelBackground}
      />
      <Divider sx={{ my: 3, borderColor: "secondary.light" }} />

      <MultiValueContainer
        title={outputContainerLabel}
        tags={userInputs}
        handleDelete={handleDeleteTags}
      />
    </Box>
  );
};

{
  MultivalueInputFormContainer.propTypes = {
    inputTags: PropTypes.array,
    setInputTags: PropTypes.func,
    inputLabel: PropTypes.string,
    outputContainerLabel: PropTypes.string,
    inputPlaceholder: PropTypes.string,
  };

  MultivalueInputFormContainer.defaultProps = {
    inputTags: [],
    setInputTags: null,
    inputLabel: "",
    outputContainerLabel: "",
    inputPlaceholder: "",
  };
}

export default MultivalueInputFormContainer;
