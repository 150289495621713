import React from "react";
import { Button, Grid, Typography, Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import Card from "../../../components/Workspace/Card";
import theme from "../../../constants/Theme";
import { PowerUpIcon } from "../../../common/icons";
import ROUTES_PATH from "../../../constants/routes";

const PowerUp = ({ data }) => {
  const history = useHistory();

  const handleViewAll = () => {
    history.push(ROUTES_PATH.WORKSPACE);
  };

  const handleResume = () => {
    const path =
      data?.excerciseStatus === "STARTED"
        ? `/powerup/${data.id}/start`
        : `/powerup/${data.id}/retake`;

    history.push(ROUTES_PATH.WORKSPACE + path);
  };

  return (
    <Card sx={{ px: 3 }}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{
          maxWidth: theme.breakpoints.values.md,
        }}
      >
        <Grid
          item
          lg={10}
          display="flex"
          alignItems={"center"}
          justifyContent="flex-start"
        >
          <PowerUpIcon />
          <Typography variant="boldFont" sx={{ ml: 1 }}>
            POWER
          </Typography>
          <Typography variant="thinFont">UP</Typography>
        </Grid>
        <Grid
          item
          lg={2}
          display={{
            lg: "block",
            md: "none",
            xs: "none",
            sm: "none",
          }}
        >
          <Typography variant="h5" color={"primary"} onClick={handleViewAll}>
            View all
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          maxWidth: theme.breakpoints.values.md,
          pt: 4,
        }}
        rowGap={2}
      >
        <Grid item lg={12}>
          <Typography variant="h5">Pick up where you left off</Typography>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <img src={data.imageUrl} width={"100%"} height={"100%"} />
        </Grid>

        <Grid item lg={12}>
          <Typography variant="f18">{data.powerUpName}</Typography>
        </Grid>

        <Grid item lg={12}>
          <Typography variant="body1">{data.shortDescription}</Typography>
        </Grid>
        <Box
          sx={{
            marginLeft: "auto",
            display: { xs: "flex", md: "flex", lg: "none" },
          }}
        >
          <Button
            sx={{ mt: 3, width: "auto" }}
            variant="outlined"
            onClick={handleViewAll}
          >
            View all
          </Button>
          <Button
            sx={{ mt: 3, width: "auto", ml: 1.2 }}
            variant="contained"
            onClick={handleResume}
          >
            {data?.excerciseStatus === "STARTED" ? "Resume" : "Retake"}
          </Button>
        </Box>
        <Grid
          container
          justifyContent={"flex-end"}
          sx={{ mt: 1, display: { xs: "none", md: "none", lg: "flex" } }}
          columnGap={2}
        >
          <Grid item lg={3} md={3} sm={3} xs={4} display="flex">
            <Button variant="contained" onClick={handleResume}>
              {data?.excerciseStatus === "STARTED" ? "Resume" : "Retake"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PowerUp;
