import styled from "@emotion/styled";

export const SidebarWrapper = styled.div(({ theme }) => {
  return {
    position: "absolute",
    top: 0,
    border: 0,
    borderRight: 1,
    borderRightColor: theme.palette.secondary.light,
    borderStyle: "solid",
    height: "100%",
    overflowY: "scroll",
    display: "grid",
    gridTemplateRows: "1fr auto",
    maxWidth: "inherit",
  };
});

export const WelcomeSidebarContentWrapper = styled.div(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: theme.spacing(2),
  };
});

export const SidebarBottomLogoWrapper = styled.div(({ theme }) => {
  return {
    position: "relative",
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});
