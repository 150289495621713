import styled from "@emotion/styled";
import { Box } from "@mui/material";
import theme from "../../../constants/Theme";

export const ProgressHeroContainer = styled(props => <Box {...props} />)(
  ({ theme, taskImgUrl }) => ({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    marginTop: theme.spacing(4),
    borderRadius: "28px",
    background: `linear-gradient(0deg, rgba(31, 31, 31, 0.9), rgba(31, 31, 31, 0.9)),
          url(${taskImgUrl ? taskImgUrl : ""})`,
    [theme.breakpoints.down("md")]: {
      borderRadius: "16px 16px 0px 0px",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center",
      paddingLeft: theme.spacing(0),
    },
  })
);

export const StepContainer = styled(Box)({
  display: "flex",
  width: "100%",
  height: "auto",
  padding: theme.spacing(0),
  color: theme.palette.secondary.black,
  border: `1px solid ${theme.palette.containerBorder.light}`,
  boxSizing: "border-box",
  borderRadius: theme.spacing(3),
  background: theme.palette.secondary.white,
  [theme.breakpoints.down("md")]: {
    borderRadius: "46px 46px 15px 15px",
  },
  zIndex: 2,
});

export const CompletedWrapper = styled(Box)({
  display: "flex",
  width: "max-content",
  gap: "8px",
  height: "24px",
  background: "rgba(0, 206, 125, 0.15)",
  border: `1px solid ${theme.palette.text.readOnlyGreen}`,
  color: theme.palette.text.readOnlyGreen,
  boxSizing: "border-box",
  backdropFilter: "blur(8px)",
  borderRadius: "100px",
  alignItems: "center",
});
export const PointsWrapper = styled.div(({ theme }) => {
  return {
    color: theme.palette.secondary.black,
    position: "relative",
    padding: theme.spacing(1),
    display: " flex",
    textAlign: "center",
    justifyContent: "center",

    "&::before": {
      content: '""',
      position: "absolute",
      inset: "0",
      borderRadius: "50px",
      padding: "2px",
      background: `linear-gradient(230.14deg, #00CE7D 23.96%, #478FC8 42.3%, #7B61FF 69.28%, #F32836 83.3%)`,
      "-webkit-mask": `linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)`,
      "-webkit-mask-composite": "xor",
      maskComposite: "exclude",
      pointerEvents: "none",
    },
  };
});

export const ArrowWrapper = styled(Box)({
  display: "flex",
  height: "max-content",
  padding: theme.spacing(2),
  gap: "10px",
  background: "rgba(255, 255, 255, 0.1)",
  border: ` 1px solid  ${theme.palette.button.borderLight}`,
  boxSizing: "border-box",
  backdropFilter: "blur(8px)",
  borderRadius: "100px",
  color: theme.palette.button.main,
  cursor: "pointer",
});
