import React from "react";
import { Box, Grid } from "@mui/material";
import StayOnTrack from "../StayOnTrack";
import RunwayCalculator from "../Runway Calculator";
import PowerUp from "../PowerUp";
import ProgressCard from "../QuickStatView/ProgressCard";

export default function WorkspaceUpdates(props) {
  const { data, stayOnTrackData, children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{ ml: 3, mr: 3, pb: 25 }}
    >
      <Box sx={{ mt: 3, width: "auto" }}>
        <ProgressCard data={data} gradientId="progressMobile" />
      </Box>
      {data?.lastVisitedPowerUp && (
        <Box
          sx={{ mt: 3, width: "auto" }}
          data-tut="reactour__responsive-powerup"
        >
          <PowerUp data={data?.lastVisitedPowerUp} />
        </Box>
      )}
      <Box
        sx={{ mt: 3, width: "auto" }}
        data-tut="reactour__responsive-runwayCalculator"
      >
        <RunwayCalculator data={data?.runawayDetails} />
      </Box>
      <Grid
        container
        sx={{ mt: 3 }}
        backgroundColor="#FCFCFC"
        borderRadius="28px"
        data-tut="reactour__responsive-stayOnTrack"
      >
        <StayOnTrack data={data} stayOnTrackData={stayOnTrackData} />
      </Grid>
    </Box>
  );
}
