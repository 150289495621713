import React, { useState } from "react";
import {
  Typography,
  CardContent,
  Box,
  Card,
  Grid,
  Divider,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { sosPrimary } from "../../../../constants/Theme/colors";
import theme from "../../../../constants/Theme";
import { TableHeader, TableCell, TableRow } from "./styles";
import { Link } from "react-router-dom";
import { getCurrentDate } from "../../../../utils/date";

const PersonaWorkCard = ({ completedWorks }) => {
  const [personaItems, setPersonaItems] = useState(completedWorks);
  const [isAscending, setIsAscending] = useState(true);
  const handelSort = () => {
    setIsAscending(!isAscending);
    sortPersonaItems();
  };

  const sortPersonaItems = () => {
    let data = [...personaItems];
    if (isAscending) {
      data.sort((a, b) => {
        return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
      });
    } else {
      data.sort((a, b) => {
        return a.title.toLowerCase() < b.title.toLowerCase() ? 1 : -1;
      });
    }

    setPersonaItems(data);
  };

  return (
    <Box>
      <Card sx={{ borderRadius: "28px", p: 1 }}>
        <CardContent>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="largeTitle"
              color={theme.palette.text.secondary}
            >
              Completed Work
            </Typography>
            <Typography
              variant="lable_large"
              sx={{ marginLeft: "auto" }}
              color={sosPrimary[500]}
            >
              View all
            </Typography>
          </Box>

          <Box>
            <Grid item xs={12} md={12} xl={12} sx={{ width: "100%", mt: 3 }}>
              <TableHeader>
                <TableCell onClick={handelSort}>
                  <Typography variant="lable_large">Name</Typography>
                  <Box sx={{ ml: 1 }}>
                    {isAscending ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                  </Box>
                </TableCell>

                <TableCell>
                  <Typography variant="lable_large" sx={{ ml: 2 }}>
                    Last modified
                  </Typography>
                </TableCell>
              </TableHeader>
              <Divider
                sx={{
                  mt: 2,
                  background: `${theme.palette.secondary.outlineXLight}`,
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} sx={{ width: 1 }}>
              {personaItems?.map((item, index) => {
                if (index < 11 && item) {
                  return (
                    <Box key={index}>
                      <TableRow>
                        <Box
                          sx={{
                            py: 1,
                          }}
                        >
                          <Link
                            to={`/workspace/persona-builder/${item.moduleId}/${item.versionId}/completed-summary`}
                            style={{ textDecoration: "none" }}
                          >
                            <Typography
                              variant="bodyLarge"
                              sx={{ color: "secondary.black" }}
                            >
                              {item.title}
                            </Typography>
                          </Link>
                        </Box>
                        <Box sx={{ py: 1 }}>
                          <Typography variant="bodyLarge">
                            {getCurrentDate(new Date(item.completionDate))}
                          </Typography>
                        </Box>
                      </TableRow>
                      <Divider
                        sx={{
                          background: `${theme.palette.secondary.outlineXLight}`,
                        }}
                      />
                    </Box>
                  );
                }
              })}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PersonaWorkCard;
