import React from "react";
import { useStore } from "react-redux";
import { Switch, Redirect } from "react-router-dom";
import Route from "./Route";
import SignUp from "../pages/signUp";
import LogIn from "../pages/logIn";
import ROUTES_PATH from "../constants/routes";
import CreateAccount from "../pages/createAccount";
import EmailVerification from "../pages/emailVerification";
import SocialLogin from "../pages/socialLogin";
import ForgotPassword from "../pages/forgotPassword";
import CreatePassword from "../pages/createPassword";
import Authorize from "../pages/authorize";
import AuthorizeSuccess from "../pages/authorize/authorizeSuccess";
import Dashboard from "../containers/Dashboard";
import { createInstanceandInjectStore } from "../axios";
import SelectHQLocation from "../containers/Onboarding/SelectHQLocation";
import StartupJourney from "../containers/Onboarding/StartupJourney";
import CompanyName from "../containers/Onboarding/CompanyName";
import ProductServiceOffering from "../containers/Onboarding/ProductServiceOffering";
import ExperienceAsFounder from "../containers/Onboarding/ExperienceAsFounder";
import FounderSuperPowser from "../containers/Onboarding/FounderSuperPower";
import CommitmentLevels from "../containers/Onboarding/CommitmentLevels";
import StartupWorkspace from "../containers/Workspace";
import MyStartup from "../containers/MyStartup";
import PowerupDetails from "../containers/Workspace/PowerupDetails";
import Network from "../containers/Network";
import StorytellerSummary from "../containers/Workspace/StorytellerSummary";
import RevenueModelerSummary from "../containers/Workspace/PowerupExcercises/PowerUps/RevenueModler/RevenueModelerSummary";

import PowerupExcercises from "../containers/Workspace/PowerupExcercises";
import TAMSAMSOM from "../containers/Workspace/PowerupExcercises/PowerUps/TAMSAMSOM";
import TAMSAMSOMSummary from "../containers/Workspace/PowerupExcercises/PowerUps/TAMSAMSOM/Components/Summary";
import ValuePropSummary from "../containers/Workspace/Summary/ValueProp";
import IdeaValidationDetails from "../containers/Workspace/PowerUP/IdeaValidation/IdeaValidationDetails";
import IdeaValidationExcersice from "../containers/Workspace/PowerUP/IdeaValidation/Excercises";
import PersonaBuilderSummary from "../containers/Workspace/Summary/PersonaBuilderSummary";
import ThemeDemo from "../constants/Theme/themedemo";
import RevenueModler from "../containers/Workspace/PowerupExcercises/PowerUps/RevenueModler";
import PersonaBuilder from "../containers/Workspace/PowerupExcercises/PowerUps/PersonaBuilder";
import Brandstorming from "../containers/Workspace/PowerupExcercises/PowerUps/Brandstorming";
import BrandStormingSummary from "../containers/Workspace/PowerupExcercises/PowerUps/Brandstorming/Summary";
import AggregatedNotes from "../containers/Workspace/PowerUP/IdeaValidation/AggregatedNotes";
import BrowseInterviewCandidates from "../containers/Workspace/PowerUP/IdeaValidation/BrowseInterviewCandidates";
import InterviewingYourCandidate from "../containers/Workspace/PowerUP/IdeaValidation/InterviewingYourCandidate";

export default function Routes() {
  const st = useStore();
  createInstanceandInjectStore(st);

  return (
    <Switch>
      <Route path={ROUTES_PATH.LOG_IN} component={LogIn} />

      <Route path={ROUTES_PATH.CREATE_ACCOUNT} component={CreateAccount} />
      <Route
        path={ROUTES_PATH.EMAIL_VERIFICATION}
        component={EmailVerification}
      />
      <Route path={ROUTES_PATH.SOCIAL_LOGIN} component={SocialLogin} />
      <Route path={ROUTES_PATH.FORGOT_PASSWORD} component={ForgotPassword} />
      <Route path={ROUTES_PATH.CREATE_PASSWORD} component={CreatePassword} />
      <Route path={ROUTES_PATH.RESEND_EMAIL} component={EmailVerification} />
      <Route path={ROUTES_PATH.AUTHORIZE} component={Authorize} />
      <Route
        path={ROUTES_PATH.AUTHORIZE_SUCCESS}
        component={AuthorizeSuccess}
      />

      <Route path={ROUTES_PATH.DASHBOARD} component={Dashboard} isPrivate />
      <Route path={ROUTES_PATH.STARTUP} component={MyStartup} isPrivate />
      <Route
        path={ROUTES_PATH.TAM_SAM_SOM_POWERUP}
        component={TAMSAMSOM}
        isPrivate
      />

      <Route
        path={ROUTES_PATH.INTERVIEWING_YOUR_CANDIDATE}
        component={InterviewingYourCandidate}
        isPrivate
      />

      <Route
        path={ROUTES_PATH.AGGREGATE_NOTES}
        component={AggregatedNotes}
        isPrivate
      />

      <Route
        path={ROUTES_PATH.IDEA_VALIDATION_INTERVIEW_POWERUP}
        component={IdeaValidationExcersice}
        isPrivate
      />
      <Route
        path={ROUTES_PATH.BROWSE_INTERVIEW_CANDIDATES}
        component={BrowseInterviewCandidates}
        isPrivate
      />

      <Route
        path={ROUTES_PATH.IDEA_VALIDATION_INTERVIEW}
        component={IdeaValidationDetails}
        isPrivate
      />
      <Route
        path={ROUTES_PATH.BRANDSTORMING_POWERUP}
        component={Brandstorming}
        isPrivate
      />
      <Route
        path={ROUTES_PATH.REVENUE_MODLER}
        component={RevenueModler}
        isPrivate
      />
      <Route
        path={ROUTES_PATH.INITIAL_PERSONNA_BUILDER_POWERUP}
        component={PersonaBuilder}
        isPrivate
      />

      <Route
        path={ROUTES_PATH.TAM_SAM_SOM_POWERUP}
        component={TAMSAMSOM}
        isPrivate
      />

      <Route
        path={ROUTES_PATH.WORKSPACE_POWERUP_EXCERCISE}
        component={PowerupExcercises}
        isPrivate
      />

      <Route path={"/demo"} component={ThemeDemo} isPrivate />
      <Route
        path={ROUTES_PATH.WORKSPACE_POWERUP_DETAILS}
        component={PowerupDetails}
        isPrivate
      />

      <Route
        path={ROUTES_PATH.STORYTELLER_POWERUP_SUMMARY}
        component={StorytellerSummary}
        isPrivate
      />
      <Route
        path={ROUTES_PATH.VALUEPROP_POWERUP_SUMMARY}
        component={ValuePropSummary}
        isPrivate
      />
      <Route
        path={ROUTES_PATH.TAMSAMSOM_POWERUP_SUMMARY}
        component={TAMSAMSOMSummary}
        isPrivate
      />

      <Route
        path={ROUTES_PATH.REVENUW_MODELER_POWERUP_SUMMARY}
        component={RevenueModelerSummary}
        isPrivate
      />
      <Route
        path={ROUTES_PATH.PERSONA_BUILDER_POWERUP_SUMMARY}
        component={PersonaBuilderSummary}
        isPrivate
      />
      <Route
        path={ROUTES_PATH.BRANDSTORMING_SUMMARY}
        component={BrandStormingSummary}
        isPrivate
      />

      <Route
        path={ROUTES_PATH.WORKSPACE}
        component={StartupWorkspace}
        isPrivate
      />
      {/* <Route
        path={ROUTES_PATH.FRAME_YOUR_STATEMENT}
        component={FrameYourStatement}
        isPrivate
  />*/}
      <Route path={ROUTES_PATH.NETWORK} component={Network} isPrivate />
      <Route path={ROUTES_PATH.SIGN_UP} exact component={SignUp} />
      {/* ONBOARDING ROUTES */}
      <Route
        path={ROUTES_PATH.ONBOARDING_LOCATION}
        component={SelectHQLocation}
      />
      <Route
        path={ROUTES_PATH.ONBOARDING_STARTUP_JOURNEY}
        component={StartupJourney}
      />
      <Route
        path={ROUTES_PATH.ONBOARDING_PRODUCT_SERVICE}
        component={ProductServiceOffering}
      />
      <Route
        path={ROUTES_PATH.ONBOARDING_FOUNDER_EXPERIENCE_LEVEL}
        component={ExperienceAsFounder}
      />
      <Route
        path={ROUTES_PATH.ONBOARDING_FOUNDER_SUPERPOWER}
        component={FounderSuperPowser}
      />
      <Route
        path={ROUTES_PATH.ONBOARDING_TIME_COMMITMENT}
        component={CommitmentLevels}
      />
      <Route path={ROUTES_PATH.ONBOARDING_BASEPATH} component={CompanyName} />

      <Route path={"/demo"} component={ThemeDemo} />

      <Redirect from={"/"} to={ROUTES_PATH.ONBOARDING_BASEPATH} />
    </Switch>
  );
}
