import React, { useState } from "react";
import { Box, Grid, TextField, Typography, Button, Stack, ClickAwayListener } from "@mui/material";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../modules/actions";
import { COMPANYNAME } from "../../../constants/image";
import ROUTES_PATH from "../../../constants/routes";
import { TitleBox, ButtonContainer } from "./styles";
import constants from "../../../constants";
import { REGEX } from "../../../constants/regex";
import messages from "../../../constants/messages";

function CompanyName({ onboardingCompanyName, setOnboardingCompanyName }) {
  const [companyName, setCompanyName] = useState(onboardingCompanyName);
  const [errorText, setErrorText] = useState("");

  const history = useHistory();

  const handleOnChange = (value) => {
    setErrorText('');
    setCompanyName(value);
  };

  const handleClickOutside =(value)=>{
    if(value.length < 2 || value.length > 25){
      setErrorText(messages.COMPANY_NAME_LENGTH_INVALID)
    }else{
      if (isValidCompanyName(value)) {
        setErrorText("");
      } else {
        setErrorText(messages.COMPANY_NAME_CHARACTERS_INVALID);
      }
    }
   
  }

  const handleSkipForNow = () =>{
    setOnboardingCompanyName(constants.DEFAULT_COMPANY_NAME);
    history.push(ROUTES_PATH.ONBOARDING_LOCATION);
  }

  const handleNext = () => {
    if(companyName.length < 2){
      setErrorText(messages.COMPANY_NAME_LENGTH_INVALID);
      return;
    }
    setOnboardingCompanyName(companyName || constants.DEFAULT_COMPANY_NAME);
    history.push(ROUTES_PATH.ONBOARDING_LOCATION);
  };

  const isValidCompanyName = (companyName) => {
    if (companyName.match(REGEX.COMPANY_NAME)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100%" }}

    >
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
        minHeight={"100%"}
        paddingBottom="50px"
      >
        <Grid item xs={11} sm={11} md={9} lg={9} sx={{ textAlign: "center" }}>
          <Box sx={{ width: "100%" }}>
            <img className="w-100" src={COMPANYNAME} alt="company-name" />
          </Box>
        </Grid>
        <TitleBox>
          <Grid container alignItems="center" justifyContent="center">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ textAlign: "center" }}
            >
              <Typography
                sx={{ alignItems: "center", color: "#0A141B" }}
                variant="h2"
                color="secondary"
              >
                What is the name of your startup?
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ mt: 3, textAlign: "center" }}
            >
              <Box>
                <Typography
                  sx={{
                    alignItems: "center",
                    color: "#6A7575",
                    fontWeight: "400",
                    fontFamily: "RobotoRegular",
                    fontSize: "12px",
                  }}
                  variant="h4Roboto"
                  color="secondary"
                >
                  You can always change this later on.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={10} sm={10} md={5} lg={5} sx={{ mt: 3 }}>
              <Box textAlign={"center"}>
                <TextField
                  sx={{
                    maxWidth: "376px",
                    width: "100%",
                    fontSize: "16px",
                  }}
                  value={companyName}
                  onChange={(e) => handleOnChange(e.target.value)}
                  label="Startup Name"
                  inputProps={{ maxLength: 25 }}
                  autoComplete="off"
                  error={!!errorText}
                  helperText={errorText}
                  FormHelperTextProps={{
                    sx: { position: "absolute", left: -10, bottom: -20 },
                  }}
                  onBlur={()=>handleClickOutside(companyName)}
                ></TextField>
              </Box>
            </Grid>
          </Grid>
        </TitleBox>

        <Grid container justifyContent="center" sx={{ mt: 3 }}>
          <Grid item xs={10} sm={10} md={4} lg={4}>
            <ButtonContainer>
              <Button
                onClick={() => handleNext()}
                sx={{ fontSize: "14px" }}
                disabled={!isValidCompanyName(companyName)}
              >
                Next
              </Button>
              <Button
                onClick={() => handleSkipForNow()}
                sx={{ fontSize: "14px", mt: 2, mb: 2 }}
                disabled={companyName}
                variant="nofill"
              >
                Skip for now
              </Button>
            </ButtonContainer>
          </Grid>
        </Grid>
        {/*</Grid>*/}
      </Grid>
    </Stack>
  );
}

const mapStoreToProps = ({ onboarding }) => ({
  onboardingCompanyName: onboarding?.companyName,
});

const mapDispatchToProps = (dispatch) => ({
  setOnboardingCompanyName: (updatedData) =>
    dispatch(actions.setCompanyName(updatedData)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(CompanyName);
