import styled from "@emotion/styled";

export const ContentWrapper = styled.div(({ theme }) => {
  return {
    [theme.breakpoints.up("md")]: {
      backgroundColor: "var(--gray-bg)",
      border: `1px solid ${theme.palette.secondary.dark}`,
      boxSizing: "border-box",
      borderRadius: "28px",
      padding: theme.spacing(3),
    },
  };
});

export const ContentHeader = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
  };
});
