import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import styled from "@emotion/styled";
import { fontFamily } from "@mui/system";

export const StepConnectorVariations = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "var(--stepper-connector-completed-color)",
      borderWidth: "1.5px",
      marginLeft: theme.spacing(1),
      height:"40px"
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "var(--stepper-connector-completed-color)",
      borderWidth: "1.5px",
      marginLeft: theme.spacing(1),
      height:"40px"
    },
  },
  [`&.${stepConnectorClasses.disabled}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      height:"40px",
      borderWidth: "1.5px",
      marginLeft: theme.spacing(1),
    },
  },
}));

export const StartupLogoBox = styled.div(({theme})=>{

  return{
      display:"flex",
      justifyContent:"center",
      marginTop:theme.spacing(3)
  }
})
