import axios from "axios";
import * as url from "../utils/urls";
import {
  PERSONA_BUILDER_SIDEBAR,
  STORY_TELLER_SIDEBAR,
  TAMSAMSOM_SIDEBAR,
  PROBLEM_SOLUTION_SIDEBAR,
  VALUE_PROP_SIDEBAR,
  IDEA_VALIDATION_SIDEBAR,
  REVENUE_MODELER_SIDEBAR,
  BRANDSTORMING_SIDEBAR,
} from "../constants/powerup-data";

export default {
  // login and register user
  login: function (payload) {
    return axios.post(url.loginUrl, payload);
  },

  //update user email
  updateEmail: function (payload) {
    return axios.post(url.updateEmailUrl, payload);
  },

  //resend user email
  resendEmail: function (payload) {
    return axios.post(url.resendEmailUrl, payload);
  },

  //search location by coordinates
  searchLocationByCoordinates: function (lat, lng) {
    return axios.get(url.searchLocationByCoordinatesUrl(lat, lng));
  },

  //search location by postalcode
  searchLocationByZipcode: function (zipcode) {
    return axios.get(url.searchLocationByZipcodeUrl(zipcode));
  },

  //get auto-complete data for onboarding product/services and journey
  autoCompleteData: function (questionKey) {
    return axios.get(url.autoCompleteDataUrl(questionKey));
  },

  addAutoCompleteData: function (data) {
    return axios.post(url.autoCompleteDataUrl(data.key), data);
  },

  //update user flag to not show the flag again after initial skip
  updateUserFlag: function (payload) {
    return axios.put(url.updateUserFlagUrl(), payload);
  },

  //update runway calculator details
  updateRunwayDetails: function (payload) {
    return axios.put(url.updateRunwayDetailsUrl(), payload);
  },

  //get dashboard stats
  getDashboardStats: function () {
    return axios.get(url.getDashboardStatsUrl());
  },

  //get todays top 3
  getTodaysTop: function () {
    return axios.get(url.getTodaysTopUrl());
  },
  // get company profile
  getCompanyProfile: function () {
    return axios.get(url.companyProfile());
  },

  // update company profile
  updateCompanyProfile: function (data) {
    return axios.put(url.companyProfile(), data);
  },

  //get Powerups and poweruppacks for workspace page
  getPowerUpPacksList: function () {
    return axios.get(url.powerUpPacksApiUrl());
  },
  getPowerUpsList: function () {
    return axios.get(url.powerUpsApiUrl());
  },

  storeExcerciseStep: function (data) {
    return axios.post(url.excerciseStepsUrl(), data);
  },

  getPowerupSteps: function (powerUpId) {
    return axios.get(url.excerciseStepsUrl(), {
      params: { powerUpId: powerUpId },
    });
  },

  getModulesSteps: function (moduleId) {
    return axios.get(url.modulesStepUrl(), {
      params: { id: moduleId },
    });
  },

  getExcerciseDetails: function (powerUpId) {
    return axios.get(url.getExcerciseDetailsUrl(), { params: { powerUpId } });
  },

  getModuleStepResponses: function (moduleId) {
    return axios.get(url.modulesStepResponsesUrl(moduleId));
  },

  storeModuleStepResponses: function (moduleId, data) {
    return axios.post(url.modulesStepResponsesUrl(moduleId), data);
  },
  //get to get powerup details
  getPowerupData: function (id) {
    return axios.get(url.powerupDataUrl(id));
  },

  // Update last visited powerup
  updateLastVisitedPowerup: function (payload) {
    return axios.put(url.lastVisitedPowerupDataUrl, payload);
  },

  startPowerup: function (payload) {
    return axios.post(url.startPowerupDataUrl, payload);
  },
  startModule: function (payload) {
    return axios.post(url.startModuleDataUrl, payload);
  },
  uploadFile: function (formData) {
    return axios.post(url.uploadFile, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  updateStreak: function (payload) {
    return axios.put(url.updateStreak, payload);
  },

  verifyEmail: function (token) {
    return axios.get(url.verifyEmail(token));
  },

  getSidebarContent: function (id) {
    switch (id) {
      case "1":
        return VALUE_PROP_SIDEBAR;
      case "2":
        return STORY_TELLER_SIDEBAR;
      case "3":
        return PERSONA_BUILDER_SIDEBAR;
      case "4":
        return IDEA_VALIDATION_SIDEBAR;
      case "5":
        return PROBLEM_SOLUTION_SIDEBAR;
      case "6":
        return BRANDSTORMING_SIDEBAR;
      case "7":
        return TAMSAMSOM_SIDEBAR;
      case "9":
        return REVENUE_MODELER_SIDEBAR;

      default:
        return {};
    }
  },
  // get powerUp packs details
  getPoweupPacksDetails: function (id) {
    return axios.get(url.powerUpPacksDeatils(id));
  },

  //get module details details
  getModuleDetails: function (id) {
    return axios.get(url.moduleDetails(id));
  },

  getInterviewCandidates: function (emails) {
    return axios.get(url.interviewCandidates(), { params: { emails: emails } });
  },

  scheduleCandidateEvent: function (payload) {
    return axios.post(url.scheduleCanidateEvent(), payload);
  },
};
