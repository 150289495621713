import { Box } from "@mui/material";
import React, { useEffect } from "react";
import theme from "../../../constants/Theme";

const ProgressBar = ({ value, gradientId }) => {
  const gradientTransform = `rotate(90)`;

  useEffect(() => {
    var max = -219.99078369140625;
    var ele =
      document.querySelectorAll(".progress-bar")[
        gradientId === "progressMobile" ? 1 : 0
      ];

    var percent = ele.getAttribute("data-progress");

    const fill = ele.querySelector(".fill");

    fill.setAttribute(
      "style",
      "stroke-dashoffset: " + ((100 - percent) / 100) * max
    );

    fill.setAttribute("stroke", `url(#${gradientId})`);

    var textValue = ele.querySelector(".value");

    textValue.innerHTML = percent + "%";

    textValue.setAttribute("fill", `url(#${gradientId})`);

    ele.querySelector(".text").setAttribute("fill", `url(#${gradientId})`);
  }, [value]);

  return (
    <Box style={{ maxWidth: "136px", maxHeight: "136px" }}>
      <svg style={{ height: 0, width: 0 }}>
        <defs>
          <linearGradient id={gradientId} gradientTransform={gradientTransform}>
            <stop
              offset="0%"
              stopColor={theme.palette.secondary.progressGradient1}
            />
            <stop
              offset="100%"
              stopColor={theme.palette.secondary.progressGradient2}
            />
          </linearGradient>
        </defs>
      </svg>

      <svg
        class="progress-bar"
        data-progress={value}
        x="0px"
        y="0px"
        viewBox="0 0 80 80"
      >
        <path class="track" d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0" />
        <path class="fill" d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0" />
        <text
          class="value"
          x="50%"
          y="55%"
          text-anchor="middle"
          font-size="18px"
          font-family="PoppinsSemiBold"
        >
          0%
        </text>
        <text
          class="text"
          x="50%"
          y="70%"
          text-anchor="middle"
          font-size="8px"
          font-family="PoppinsRegular"
        >
          Total
        </text>
      </svg>
    </Box>
  );
};

export default ProgressBar;
