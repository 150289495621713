import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
  powerUpExcercises: [],
  showInterviewScriptDialog: false,
  powerupDetails: {},
  interviewCandidates: [],
};

const powerUp = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_POWERUP_ExcerciseS:
      return updateObject(state, { powerUpExcercises: action.payload });
    case actionTypes.SET_INTERVIEW_SCRIPT_DIALOG:
      return updateObject(state, { showInterviewScriptDialog: action.payload });
    case actionTypes.SET_POWERUP_DETAILS:
      return updateObject(state, { powerupDetails: action.payload });
    case actionTypes.SET_INTERVIEW_CANDIDATES:
      return updateObject(state, { interviewCandidates: action.payload });
    default:
      return state;
  }
};

export default powerUp;
