import { Button, CircularProgress } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const LoadingButton = ({ loaderColor, loading, children, ...props }) => {
  return (
    <Button {...props}>
      {loading ? <CircularProgress size={30} color={loaderColor} /> : children}
    </Button>
  );
};

{
  LoadingButton.propTypes = {
    loading: PropTypes.bool,
    loaderColor: PropTypes.string,
  };

  LoadingButton.defaultProps = {
    loading: false,
    loaderColor: "secondary",
  };
}
export default LoadingButton;
