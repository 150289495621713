import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
export default function CustomizedDatePicker({
  Component,
  label,
  value,
  onChange,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={value}
        onChange={newValue => {
          onChange(newValue);
        }}
        disablePast
        components={{
          OpenPickerIcon: ArrowDropDownIcon,
        }}
        OpenPickerButtonProps={{
          sx: {
            pr: "5px", //adding absolute padding to position the button icon element in picker field. This way element should always be align with other pickers element
          },
        }}
        renderInput={params => <Component {...params} />}
      />
    </LocalizationProvider>
  );
}
