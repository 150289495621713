import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";

import "./App.css";
import "./overrides.css";

import TourComp from "./components/Workspace/Tour";

import { store, persistor } from "./modules";
import Routes from "./routes";

function App() {
  try {
    window.pendo.initialize({ visitor: {} });
  } catch (e) {
    console.log("Encountered an error when trying to init Pendo. " + e);
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router
          getUserConfirmation={() => {
            /* Empty callback to block the default browser prompt */
          }}
        >
          <Routes />

          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={true}
            icon={false}
          />
          <TourComp />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
