import React from "react";
import {
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

export const Tabs = ({
  currentTab,
  setCurrentTab,
  tabItems,
  buttonWidth,
  color,
  spacing,
  width,
  gridColumn,
}) => {
  const handleChange = (event, newValue) => {
    if (newValue) {
      setCurrentTab(newValue);
    }
  };
  return (
    <Box display={"flex"} justifyContent={"center"}>
      <ToggleButtonGroup
        value={currentTab}
        exclusive
        onChange={handleChange}
        disableRipple
        sx={{
          gap: { xs: 0, md: spacing },
          width: width,
          display: gridColumn ? "grid" : "inline-flex",
          gridTemplateColumns: gridColumn ? "repeat(12, 1fr)" : "none",
          gridAutoRows: gridColumn ? "1fr" : "auto",
        }}
        variant="with-gap"
      >
        {tabItems.map((tabItem, index) => {
          return (
            <ToggleButton
              value={tabItem.key}
              key={index}
              sx={{
                width: buttonWidth,
                gridColumn: gridColumn,
              }}
              color={color}
              fullWidth
            >
              <Typography variant="h5">{tabItem.label}</Typography>
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </Box>
  );
};

{
  Tabs.propTypes = {
    currentTab: PropTypes.string,
    setCurrentTab: PropTypes.func,
    tabItems: PropTypes.array,
    buttonWidth: PropTypes.string,
    color: PropTypes.string,
    spacing: PropTypes.number,
    width: PropTypes.string,
    gridColumn: PropTypes.object,
  };
  Tabs.defaultProps = {
    currentTab: "",
    setCurrentTab: null,
    tabItems: [],
    buttonWidth: "auto",
    color: "primary",
    spacing: 0,
    width: "auto",
    gridColumn: null,
  };
}
export default Tabs;
