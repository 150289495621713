import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { Grid, Box } from "@mui/material";
import theme from "../../../../constants/Theme";
import { VALUE_PROP_SIDEBAR } from "../../../../constants/powerup-data";
import services from "../../../../services";

export default function KnowledgeSidebar({ currentTab, powerUpId }) {
  const [SIDEBARDATA, setSidebarData] = useState({});

  useEffect(() => {
    setSidebarData(
      services.getSidebarContent(
        typeof powerUpId !== "undefined" && typeof powerUpId === "number"
          ? powerUpId.toString()
          : typeof powerUpId !== "undefined"
          ? powerUpId
          : ""
      )
    );
  }, []);

  return (
    <Grid
      item
      md={4}
      sm={12}
      sx={{
        pl: 3,
        mt: 4,
        [theme.breakpoints.down("md")]: {
          pl: 0,
          mt: 0,
        },
      }}
    >
      <Box
        sx={{
          display: {
            sm: currentTab === "knowledge" ? "block" : "none",
            xs: currentTab === "knowledge" ? "block" : "none",
            md: "block",
          },
        }}
      >
        <Sidebar sidebar_content={SIDEBARDATA} />
      </Box>
    </Grid>
  );
}
