import React, { useEffect, useState } from "react";
import { Box, Grid, Button, Typography, Stack } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CompletedWrapper, PointsWrapper } from "../styles";
import theme from "../../../../constants/Theme";
import {
  POWERUP_LOGO_WHITE,
  POWERUPLOGO,
  POWERUP_IMAGE,
  DEMO_DAY_LOGO,
  FUSION_LOGO,
  PLUGIN_LOGO,
} from "../../../../constants";
import { useHistory } from "react-router-dom";

export default function ProgressHeroCard(props) {
  const history = useHistory();
  return (
    <Stack sx={{ minHeight: { md: "300px" } }}>
      <Grid container sx={{ display: "flex", flexDirection: "row", height: 1 }}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              width: 1,
              height: 1,
              display: "block",
              objectFit: "cover",
              borderRadius: theme.spacing(2),
            }}
            src={
              props.data?.taskImgUrl ? props.data?.taskImgUrl : POWERUP_IMAGE
            }
            component="img"
          ></Box>
        </Grid>
        <Grid item xs={12} md={8} sx={{ p: 2 }}>
          <Grid container sx={{ pt: 2 }} flexDirection="row">
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  pb: 1,
                }}
              >
                <Box
                  src={
                    props.data?.taskName == "POWER_UP"
                      ? POWERUPLOGO
                      : props.data?.taskName == "PLUGIN"
                      ? PLUGIN_LOGO
                      : props.data?.taskName == "FUSION"
                      ? FUSION_LOGO
                      : DEMO_DAY_LOGO
                  }
                  component="img"
                ></Box>
                {props.data.taskName === "POWER_UP" ||
                props.data.taskName === "PLUGIN" ? (
                  props.data?.companyTask?.taskStatus == "STARTED" ? (
                    <CompletedWrapper sx={{ p: 1 }}>
                      <Typography variant="overlineLarge">
                        In Progress
                      </Typography>
                    </CompletedWrapper>
                  ) : props.data?.companyTask?.taskStatus == "COMPLETED" ? (
                    <CompletedWrapper sx={{ pr: 1 }}>
                      <CheckCircleIcon />
                      <Typography variant="overlineLarge">Complete</Typography>
                    </CompletedWrapper>
                  ) : (
                    <PointsWrapper>
                      <Typography variant="overlineLarge">25 Points</Typography>
                    </PointsWrapper>
                  )
                ) : (
                  ""
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="ph_headline">
                {props.data.taskTitle}
              </Typography>
            </Grid>
            <Grid item xs={12} md={10} sx={{ pt: 1 }}>
              <Typography variant="ph_text">
                {props.data.taskDescription}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: { xs: 6, md: 10 },
                pb: 2,
                bottom: 0,
                right: 0,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  float: "right",
                }}
              >
                {props.data?.companyTask?.taskStatus == "STARTED" ? (
                  <Button
                    onClick={() => props.handleStartPowerUp(props.data.taskId)}
                  >
                    Resume
                  </Button>
                ) : props.data?.companyTask?.taskStatus == "COMPLETED" ? (
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      display: "flex",
                      float: "right",
                    }}
                  >
                    <Grid item xs={12} md={12} lg={4} xl={4}>
                      <Button
                        variant="heroSecondary"
                        sx={{ float: "right" }}
                        onClick={() =>
                          props.handleStartPowerUp(props.data.taskId)
                        }
                      >
                        Revisit
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={12} lg={8} xl={8}>
                      <Button onClick={() => history.push()}>
                        View completed work
                      </Button>
                    </Grid>
                  </Grid>
                ) : props.data.taskName == "POWER_UP" ? (
                  <Button
                    onClick={() => props.handleStartPowerUp(props.data.taskId)}
                  >
                    Start
                  </Button>
                ) : props.data.taskName == "PLUGIN" ? (
                  <Button>Schedule session</Button>
                ) : props.data.taskName == "FUSION" ? (
                  <Grid
                    container
                    spacing={1}
                    sx={{
                      display: "flex",
                      float: "right",
                    }}
                  >
                    <Grid item xs={12} md={12} lg={8} xl={8}>
                      <Button variant="heroSecondary" sx={{ float: "right" }}>
                        View deck
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={12} lg={4} xl={4}>
                      <Button>Start</Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Button>Schedule meeting</Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
}
