import React, { useState } from "react";
import { Box, Divider, Grid, Stack } from "@mui/material";
import PageTitle from "../../components/Workspace/PageTitle";
import { NETWORK_LOGO } from "../../constants/image";
import { PageContentWrapper } from "../../components/Workspace/PageContentWrapper";
import Tabs from "../../components/Workspace/Tabs";
import constants from "../../constants";
import theme from "../../constants/Theme";

export default function Network() {
  const [currentTab, setCurrentTab] = useState(
    constants.WORKSPACE_TAB_ITEMS[0].key
  );

  const tabItems = constants.NETWORK_TAB_ITEMS;

  return (
    <Stack
      sx={{
        minHeight: "100%",
        borderRadius: "28px",
        background:
          "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
        [theme.breakpoints.down("md")]: {
          borderRadius: 0,
        },
      }}
    >
      <Grid
        sx={{
          p: 4,
          [theme.breakpoints.down("sm")]: {
            p: 2,
          },
          maxWidth: theme.breakpoints.values.xl,
          flexGrow: 1,
          minHeight: "100%",
          margin: "auto",
        }}
        flexDirection={"column"}
        container
      >
        <PageTitle iconUrl={NETWORK_LOGO} title="Network"></PageTitle>

        <PageContentWrapper>
          <Grid container alignItems={"center"}>
            <Grid item md={2} display={{ sm: "none", md: "block" }}></Grid>
            <Grid item md={12} sm={12} justifyContent="center">
              <Tabs
                tabItems={tabItems}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                buttonWidth={"200px"}
                color="primary"
                spacing={1}
              />
            </Grid>
          </Grid>

          <Divider sx={{ mt: 3, background: theme.palette.secondary.dark }} />
        </PageContentWrapper>
      </Grid>
    </Stack>
  );
}
