import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { LOGOICON } from "../../constants/image";
import { BACKARROW } from "../../constants/image";

export default function AppHeader({ onBack }) {
  return (
    <Box sx={{ pt: 3 }}>
      <Box sx={{ pl: 4 }}>
        <img
          className="cursor-pointer position-absolute"
          src={BACKARROW}
          onClick={() => onBack()}
          alt="back-arrow"
        />
      </Box>

      <div className="align-center ">
        <img
          className="cursor-pointer"
          onClick={() =>
            (window.location.href = process.env.REACT_APP_HOME_URL)
          }
          src={LOGOICON}
          alt="logo"
        />
      </div>
    </Box>
  );
}

AppHeader.propTypes = {
  onBack: PropTypes.func,
};

AppHeader.defaultProps = {
  onBack: undefined,
};
