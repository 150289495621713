import { Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";

export const NumberContainer = styled(Avatar)(({ theme, bordercolor }) => ({
  width: 32,
  height: 32,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.secondary.white,
  [theme.breakpoints.down("md")]: {
    backgroundColor: theme.palette.secondary.backgroundGradient,
  },
  border: `1px solid ${bordercolor}`,
}));
