import React, { useState, useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import PresentationTemplate from "../../../../../../../components/Workspace/PowerUp/PresentationTemplate";
import { TEMPLATE_TYPES } from "../../../../../../../constants/brandstorming-data";
import PresentationPreview from "../../../../../../../components/Workspace/PowerUp/PresentationPreview";

export default function UnlockYourDeck({ userInputs, setUserInputs }) {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [previewIndex, setPreviewIndex] = useState(0);
  const [selected, setSelected] = useState(-1);

  const handleInputChange = (value, key) => {
    const currentInput = { ...userInputs };
    currentInput[key] = value;
    setUserInputs(currentInput);
  };

  const handleDialog = () => {
    setIsPopUpOpen(!isPopUpOpen);
  };

  return (
    <Box>
      <PresentationPreview
        open={isPopUpOpen}
        close={handleDialog}
        data={TEMPLATE_TYPES[previewIndex]}
      />
      <Grid container spacing={0} sx={{ pt: 6, display: "flex", gap: 4 }}>
        <Grid item xs={12} md={12}>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 2, md: 0 },
            }}
          >
            {TEMPLATE_TYPES.map((item, index) => {
              return (
                <Grid item xs={12} md={6} lg={4}>
                  <PresentationTemplate
                    data={item}
                    handleInputChange={handleInputChange}
                    handlePreview={handleDialog}
                    setPreviewIndex={setPreviewIndex}
                    setSelected={setSelected}
                    selected={selected}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} sx={{ pt: 6 }}>
          <Typography variant="subhead3">Customize with your brand</Typography>
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography variant="subtext_medium">
            Update the template with your logo, font styles, and brand color
            scheme to make it yours.
          </Typography>
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography variant="subtext_medium">
            If you need help choosing any brand related material, your chosen
            deck template will guide you and recommend resources to finalize
            your brand assets and styles.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
