import React, { useEffect, useState } from "react";
import theme from "../../../../../../constants/Theme";
import {
  Box,
  Typography,
  Grid,
  Button,
  Link,
  Dialog,
  TextField,
  MenuItem,
} from "@mui/material";

import ModelTypesDialog from "../Components/ModelTypesDialog";
import {
  REVENUE_MODELER_TYPES,
  MODELS_DATA,
  STAGE_MENU,
} from "../../../../../../constants/revenue-modeler-types";
import InputSelect from "../Components/InputSelect";
import SelectInput from "../../../../../../common/SelectInput";

export default function Evaluate({ userInputs, setUserInputs }) {
  const stageMenu = STAGE_MENU;
  const modelMenu = MODELS_DATA;
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [modelTypes, setModelTypes] = useState(REVENUE_MODELER_TYPES);

  const handleDialog = () => {
    setIsPopUpOpen(!isPopUpOpen);
  };

  const handleInputChange = (event, key) => {
    const value = event.target.value;
    const currentInput = { ...userInputs };
    currentInput[key] = value;
    setUserInputs(currentInput);
  };

  return (
    <Box>
      <ModelTypesDialog
        open={isPopUpOpen}
        close={handleDialog}
        data={modelTypes}
      />
      <Grid container spacing={4} sx={{ pt: { xs: 2, md: 12 } }}>
        <Grid item xs={12} md={12}>
          <Typography variant="revenue_title">
            What stage are you in?
          </Typography>

          <Typography variant="revenue_body">
            For an idea, or a pre-seed or seed stage company, it’s not
            necessarily important to have detailed financial projections.
            Instead, what you need is an understanding of the market size and
            dynamics, and how your product solves a problem in the market. The
            more evolved your business becomes, the more detailed projections
            you’ll want to build.
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} sx={{ pb: 8 }}>
          <SelectInput
            label="Stage"
            fullWidth
            wrapperStyle={{ mt: 2 }}
            onInputChange={handleInputChange}
            selectedValue={userInputs?.stage}
            inputKey="stage"
            options={stageMenu}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            gap: theme.spacing(1),
            flexDirection: "column",
            pb: { xs: 0, md: 10 },
          }}
        >
          <Typography variant="revenue_title">
            What revenue model do you plan on using?
          </Typography>
          <Link
            component="button"
            variant="body2"
            sx={{
              color: theme.palette.button.link,
              display: { xs: "flex", md: "none" },
              pt: 1,
              pb: 3,
            }}
            onClick={handleDialog}
          >
            More on revenue models
          </Link>

          <SelectInput
            label="Model"
            fullWidth
            wrapperStyle={{ mt: 2 }}
            onInputChange={handleInputChange}
            selectedValue={userInputs?.model}
            inputKey="model"
            options={modelMenu}
          />

          <Link
            component="button"
            variant="body2"
            sx={{
              color: theme.palette.button.link,
              display: { xs: "none", md: "flex" },
            }}
            onClick={handleDialog}
          >
            More on revenue models
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}
