import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Tooltip, TextField } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import InputSelect from "../Components/InputSelect";
import InputTextField from "../Components/InputTextField";
import theme from "../../../../../../constants/Theme";
import ToolTipContainer from "../Components/ToolTipContainer";
import {
  PRIMARY_BILL,
  ACQUIRE_CUSTOMERS,
  BUSINESS_TYPE,
} from "../../../../../../constants/revenue-modeler-types";
import { ToolTipBody, DateTextField } from "../styles";
import CustomizedDatePicker from "../../../../../../components/Workspace/PowerUp/Datepicker";
import { StyledTextField } from "../RevenueModelerSummary/Components/SelfServiceSubscriptionsTable/styles";
import numeral from "numeral";
import SelectInput from "../../../../../../common/SelectInput";

export default function AdaptAdjust({ userInputs, setUserInputs }) {
  const primaryBill = PRIMARY_BILL;
  const acquireCustomers = ACQUIRE_CUSTOMERS;
  const businessType = BUSINESS_TYPE;
  const [toolTipOpen, setToolTipOpen] = useState(false);
  const [launchDate, setLaunchDate] = useState("");

  const handleTooltip = () => {
    setToolTipOpen(!toolTipOpen);
  };

  const handleInputChange = (event, key) => {
    let value;
    if (key !== "launchDate") {
      value = event.target.value;
    }
    const currentInput = { ...userInputs };
    if (key == "launchDate") {
      currentInput[key] = event;
    } else {
      currentInput[key] = value;
    }
    setUserInputs(currentInput);
  };

  const handleDateInput = e => {
    const formattedNumber = numeral(e).value();
    const number = numeral(formattedNumber)?.format("0,0");
    setLaunchDate(number);
  };

  return (
    <Box>
      <Grid container sx={{ display: "flex", gap: theme.spacing(2), pt: 2 }}>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              alignItems: { xs: "flex-start", md: "center" },
            }}
          >
            <Typography variant="revenue_title">
              What is the average monthly subscription price in USD?{" "}
            </Typography>
            <ToolTipBody>
              <Tooltip
                title={<ToolTipContainer />}
                onClose={handleTooltip}
                open={toolTipOpen}
              >
                <InfoIcon
                  sx={{ width: 18, cursor: "pointer" }}
                  onClick={handleTooltip}
                />
              </Tooltip>
            </ToolTipBody>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            label={"Subscription Price (USD)"}
            onChange={e => handleInputChange(e, "subscriptionPrice")}
            value={userInputs?.subscriptionPrice}
            sx={{ mt: 2 }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography variant="revenue_title">
            How will you primarily bill your customers?
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectInput
            label="Billing Cadence"
            fullWidth
            wrapperStyle={{ mt: 2 }}
            onInputChange={handleInputChange}
            selectedValue={userInputs?.billingCadence}
            inputKey="billingCadence"
            options={primaryBill}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography variant="revenue_title">
            How will you acquire customers?
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectInput
            label="Primary Source"
            fullWidth
            wrapperStyle={{ mt: 2 }}
            onInputChange={handleInputChange}
            selectedValue={userInputs?.primarySource}
            inputKey="primarySource"
            options={acquireCustomers}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography variant="revenue_title">
            What is your business type?
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <SelectInput
            label="Business Type"
            fullWidth
            wrapperStyle={{ mt: 2 }}
            onInputChange={handleInputChange}
            selectedValue={userInputs?.businessType}
            inputKey="businessType"
            options={businessType}
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <Typography variant="revenue_title">
            When do you expect to launch the product?
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomizedDatePicker
            value={userInputs?.launchDate}
            label="Launch Date"
            onChange={e => {
              handleInputChange(e, "launchDate");
            }}
            Component={params => (
              <DateTextField
                {...params}
                error={false}
                fullWidth
                variant="outlined"
                value={userInputs?.launchDate}
                sx={{
                  width: 1,
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
