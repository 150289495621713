import React, { useState } from "react";
import { TextField, Box, Chip } from "@mui/material";
import PropTypes from "prop-types";
import ClearIcon from "@mui/icons-material/Clear";
const MultiTagsInput = ({
  inputValue,
  params,
  handleInputChange,
  inputKey,
  inputValid,
  placeholder,
  errorMessage,
  typingEnabled,
  label,
  ...props
}) => {
  const [errorText, setErrorText] = useState("");
  const [value, setValue] = useState([]);

  const handleKeyDown = e => {
    if (e.key === "Enter" && typingEnabled) {
      if (inputValid(inputKey, e.target.value)) {
        inputValue.push(e.target.value);
        let inputs = [...inputValue];
        let uniqueInputs = new Set(inputs);
        handleInputChange(
          { target: { value: Array.from(uniqueInputs) } },
          inputKey
        );
        setValue("");
        setErrorText("");
      } else {
        setErrorText(errorMessage);
      }
    }
  };

  const handleDelete = value => {
    const newtags = inputValue.filter(val => val !== value);
    handleInputChange({ target: { value: newtags } }, inputKey);
  };

  const handleChange = e => {
    setValue(e.target.value);
  };

  const handleBlur = e => {
    if (e.target.value) {
      if (inputValid(inputKey, e.target.value)) {
        setErrorText("");
      } else {
        setErrorText(errorMessage);
      }
    }
  };

  return (
    <TextField
      {...params}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      placeholder={inputValue.length ? null : placeholder}
      label={label}
      onBlur={handleBlur}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            {inputValue.map((data, index) => {
              return (
                <Chip
                  label={data}
                  variant="outlined"
                  sx={{ borderRadius: "5px" }}
                  onDelete={() => handleDelete(data)}
                  deleteIcon={<ClearIcon />}
                />
              );
            })}
          </Box>
        ),
      }}
      error={!!errorText}
      helperText={errorText}
      inputProps={{ ...params.inputProps, value: value }}
      {...props}
    />
  );
};

MultiTagsInput.propTypes = {
  inputValue: PropTypes.array,
  params: PropTypes.object,
  handleChange: PropTypes.func,
  handleInputChange: PropTypes.func,
  inputKey: PropTypes.string,
  inputValid: PropTypes.func,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
};

MultiTagsInput.defaultProps = {
  inputValue: [],
  params: { InputProps: {} },
  handleChange: null,
  handleInputChange: null,
  inputKey: "",
  inputValid: "",
  placeholder: "",
  errorMessage: "",
};

export default MultiTagsInput;
