import {
  VALUE_PROPOSITION,
  AVATAR_DEFAULT,
  AVATAR_1,
  AVATAR_2,
  AVATAR_3,
  AVATAR_4,
  AVATAR_5,
  AVATAR_6,
  AVATAR_7,
  AVATAR_8,
  AVATAR_9,
  AVATAR_10,
  AVATAR_11,
  AVATAR_12,
  AVATAR_13,
  AVATAR_14,
  AVATAR_15,
  AVATAR_16,
  AVATAR_17,
  AVATAR_18,
  PHONE_ICON,
  EMAIL_ICON,
  TEXT_ICON,
  LIKE_ICON,
  SMILE_ICON,
} from "./image";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import MoodOutlinedIcon from "@mui/icons-material/MoodOutlined";

export const POWERUP_HEADERS = [
  {
    key: "pitchPerfect",
    label: "Pitch Perfect",
    containerHeading: "Build an Oscar-worthy pitch deck",
    infoText:
      "A pitch deck is a brief presentation that provides investors with an overview of your business. It’s usually focused on showcasing your product, sharing your business model, giving a look into your monetization strategy, and introducing your team. It’s an essential fundraising tool and creating a pitch deck that wins investment is not an easy task.\n\n But don’t worry. You’re about to learn how to nail it.",
  },
  {
    key: "mvp/mlp",
    label: "MVP/MLP",
    containerHeading: "Launch your MVP/MLP (Minimum Lovable Product)",
    infoText:
      "A Minimum Lovable Product (MLP) is an initial offering that users love from the start. It represents the minimum that is required for customers to adore a product, rather than merely tolerating it.\n\n MLP thinking builds upon the concept of the MVP, which aims to create the most basic version of a product with just enough features to be usable. Start these PowerUps to ensure your product is lovable on day one.",
  },
  {
    key: "investorMeeting",
    label: "Investor Meeting",
    containerHeading: "Crush your investor meeting",
    infoText:
      "An investor meeting is a presentation of your business idea to a venture capitalist or angel investor in hopes of obtaining their funding. These meetings are increasingly foundational to startup success. Long after you secure your funding, you can also expect to hold regular meetings with your investors. These meetings typically cover how your company is performing, future plans and projections, and how the invested money is being spent.\n\n We have the perfect plan as you prepare to meet with your potential investor.",
  },
  {
    key: "productHuntDebut",
    label: "Product Hunt Debut",
    containerHeading: "Product Hunt Debut",
    infoText:
      "The Product Hunt community loves new things, so it's a great audience to share your new product with. Posting an upcoming product on Product Hunt is a great way to get valuable feedback which will help you perfect your offering and build awareness in the lead up to your beta and market launch.",
  },
  {
    key: "techCrunchDisrupt",
    label: "TechCrunch Disrupt",
    containerHeading: "Show It (TechCrunch Disrupt)",
    infoText:
      "TechCrunch Disrupt is an annual conference hosted by TechCrunch in San Francisco, New York, Berlin and London. The global startup community comes together to discover insights, collaborate, and celebrate achievements that have defined each founder's journey.\n\n If you plan on attending one of these events, our PowerUps will help you get ready to compete in front of venture capital investors and media to gain coaching and the chance to pitch a panel of top-tier investors on the Disrupt Stage.",
  },
];

export const POWERUP_ITEMS = [
  {
    title: "Value Proposition",
    subText:
      "Create a value proposition (aka an elevator pitch) that summarizes the value of your product in one sentence.",
    image: VALUE_PROPOSITION,
  },
  {
    title: "Storyteller",
    subText:
      "Everybody loves a good story. Start learing the basics of storytelling, and how stories can help in your startup journey.",
    image: VALUE_PROPOSITION,
  },
  {
    title: "Initial Persona Builder",
    subText:
      "Learn about your future users and make a composite persona for them to look back to as you forge ahead.",
    image: VALUE_PROPOSITION,
  },
  {
    title: "Idea Validation Interview",
    subText:
      "Tie all the elements of an interview process together so your focus is on testing your idea.",
    image: VALUE_PROPOSITION,
  },
  {
    title: "Problem Solution",
    subText: "Identify solutions with a higher chance of adoption.",
    image: VALUE_PROPOSITION,
  },
  {
    title: "Brandstorming",
    subText:
      "Create a detailed report on your existing or future brand in the form of a creative brief.",
    image: VALUE_PROPOSITION,
  },
  {
    title: "TAM, SAM, SOM",
    subText:
      "Calculate the market size for your venture and understand where your brand fits in the overall market.",
    image: VALUE_PROPOSITION,
  },
  {
    title: "F&F Fundraiser",
    subText:
      'Learn how to raise and spend "friends & family" money most efficiently.',
    image: VALUE_PROPOSITION,
  },
  {
    title: "Revenue Model",
    subText:
      "Decide how your startup business will generate revenue or income.",
    image: VALUE_PROPOSITION,
  },
];

export const VALUE_PROPOSITION_FORM_FLOW = [
  {
    formField: "customerDescription",
    displayNumber: "01",
    title: "Describe Your Customer",
    description: "",
  },
  {
    formField: "customerJobs",
    displayNumber: "02",
    title: "Customer Jobs",
    description: "",
  },
  {
    formField: "customerPains",
    displayNumber: "03",
    title: "Customer Pains",
    description: "",
  },
  {
    formField: "customerGains",
    displayNumber: "01",
    title: "Customer Gains",
    description: "",
  },
];

export const POWERUP_STATUS = {
  STARTED: "STARTED",
  READY: "READY",
};

export const VALUE_PROP_SIDEBAR = {
  sidebar_id: "string",
  powerup_id: "string",
  steps: [
    { name: "Step One", complete: true, link: "" },
    { name: "Step Two", complete: false, link: "" },
  ],
  tabs: [
    {
      name: "details",
      description:
        "Using this Power Up, you'll create a value proposition (also known as an elevator pitch) that summarizes the value of your product in one sentence.",
      powerup_attributes: [
        {
          id: "powerup_attribute_id_1",
          name: "Time Estimate",
          value: "30 min - 2 hours",
          icon: "time",
        },
        {
          id: "powerup_attribute_id_2",
          name: "Difficulty",
          value: "Easy",
          icon: "steps",
        },
        {
          id: "powerup_attribute_id_3",
          name: "Team Recommended",
          value: "Yes",
          icon: "person",
        },
        {
          id: "powerup_attribute_id_4",
          name: "Feedback Alert!",
          value:
            "After completing this Power Up, it is highly recommended to seek feedback with your matched Founder. If revisions are needed after feedback, you can easily edit the work you have already completed.",
          icon: "info",
        },
      ],
    },
    {
      name: "Power Tips",
      html_content: `
        <p><Typography sx={{mb: 1}}>Using this PowerUp, you’ll create a value proposition (also known as an elevator pitch) that summarizes the value of your product in one sentence.</Typography></p>
        <p><Typography variant="title_small" sx={{my: 1}}>Frequently Common Mistakes</Typography></p>
        <Typography  sx={{mb: 1}}><strong>Trying to alleviate every pain and target every gain:</strong></Typography>
        <Typography>Mediocre or bad outcomes try to address every customer pain and gain they have identified and then often fail to deliver. Great work often focus on a limited number of pain relievers and gain creators and then deliver on those exceptionally well. </Typography>        
        `,
    },
    {
      name: "inspiration",
      media: [
        {
          id: "media_id_1",
          type: "video",
          title: "Video Title 1",
          content_url:
            "https://storage.coverr.co/videos/XTV79I01nRiyAWapsHTQlm8VrSPHXS00nR?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6Ijg3NjdFMzIzRjlGQzEzN0E4QTAyIiwiaWF0IjoxNjUwNjMwODUzfQ.Jn3ncLceelrB7Vef85e4_h0lFpY6Or7rnaII1oeQSLQ",
        },
      ],
    },
    //{
    //  name: "reviews",
    //  reviews: [
    //    //// Todo
    //  ],
    //},
  ],
};

export const STORY_TELLER_STEPS = [
  {
    id: 1,
    title: "Act One - Establishment",
    description:
      "Write down some ideas for how you want to start your story. This has grab your listeners attention! We’d recommend starting with the moment you came up with the idea, the moment you were faced with this problem. ",
    stepNo: 1,
    stepUuid: "",
  },
  {
    id: 2,
    title: "Act Two - Climax",
    description:
      "This is the moment you state your purpose: why people should be interested. Describe your product and how it addresses the problem you stated in Act One. ",
    stepNo: 2,
    stepUuid: "",
  },
  {
    id: 3,
    title: "Act Three - Resolution",
    description:
      "Where is your product going? This is where you can tie your listener in with the story, how can they be a part of your business.",
    stepNo: 3,
    stepUuid: "",
  },
];

export const STORY_TELLER_SIDEBAR = {
  sidebar_id: "string",
  powerup_id: "string",
  tabs: [
    {
      name: "details",
      description:
        "Using this PowerUp, you’ll create a story about your product using a three-act structure that can be used in any setting: business or casual.",
      powerup_attributes: [
        {
          id: "powerup_attribute_id_1",
          name: "Time Estimate",
          value: "30 min - 2 hours",
          icon: "time",
        },
        {
          id: "powerup_attribute_id_2",
          name: "Difficulty",
          value: "Medium",
          icon: "steps",
        },
        {
          id: "powerup_attribute_id_3",
          name: "Team Recommended",
          value: "Optional",
          icon: "person",
        },
        //{
        //  id: "powerup_attribute_id_4",
        //  name: "Feedback Alert!",
        //  value:
        //    "After completing this Power Up, it is highly recommended to seek feedback with your matched Founder. If revisions are needed after feedback, you can easily edit the work you have already completed.",
        //  icon: "info",
        //},
      ],
    },
    {
      name: "Power Tips",
      html_content: "",
    },
    {
      name: "inspiration",
      media: [
        {
          id: "media_id_1",
          type: "video",
          title: "Video Title 1",
          content_url:
            "https://storage.coverr.co/videos/XTV79I01nRiyAWapsHTQlm8VrSPHXS00nR?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6Ijg3NjdFMzIzRjlGQzEzN0E4QTAyIiwiaWF0IjoxNjUwNjMwODUzfQ.Jn3ncLceelrB7Vef85e4_h0lFpY6Or7rnaII1oeQSLQ",
        },
      ],
    },
    //{
    //  name: "reviews",
    //  reviews: [
    //    //// Todo
    //  ],
    //},
  ],
};

export const PERSONA_BUILDER_SIDEBAR = {
  sidebar_id: "string",
  powerup_id: "string",
  tabs: [
    {
      name: "details",
      description:
        "Using this PowerUp, you’ll create user personas that will guide every facet of your startup, from design to marketing. They’re a foundational artifact that will grow and change as your company does.",
      powerup_attributes: [
        {
          id: "powerup_attribute_id_1",
          name: "Time Estimate",
          value: "30 min - 2 hours",
          icon: "time",
        },
        {
          id: "powerup_attribute_id_2",
          name: "Difficulty",
          value: "Medium",
          icon: "steps",
        },
        {
          id: "powerup_attribute_id_3",
          name: "Team Recommended",
          value: "Optional",
          icon: "person",
        },
        //{
        //  id: "powerup_attribute_id_4",
        //  name: "Feedback Alert!",
        //  value:
        //    "After completing this Power Up, it is highly recommended to seek feedback with your matched Founder. If revisions are needed after feedback, you can easily edit the work you have already completed.",
        //  icon: "info",
        //},
      ],
    },
    {
      name: "Power Tips",
      html_content: `
        <p><Typography variant="title_small" sx={{mb: 1}}>Share with Friends & Family</Typography></p>
        <Typography  sx={{mb: 1}}><strong>Any time you complete a new draft of your PowerUp including your first, share it with your friends and family:</strong></Typography>
        <Typography>The more feedback you can receive, the better, and we strongly recommend sharing your work with people in your life.</Typography>
        <p><Typography variant="title_small" sx={{my: 1}}>Frequently Common Mistakes</Typography></p>
        <Typography  sx={{mb: 1}}><strong>Trying to alleviate every pain and target every gain:</strong></Typography>
        <Typography>Mediocre or bad outcomes try to address every customer pain and gain they have identified and then often fail to deliver. Great work often focus on a limited number of pain relievers and gain creators and then deliver on those exceptionally well. </Typography>
        <Typography  sx={{my: 1}}><strong>Analysis paralysis:</strong></Typography>
        <Typography>A great outcome with a great “fit” on paper is just an untested fantasy. Use this PowerUp as a starting point to get out of the building and investigate your assumptions. Ask yourself if you really understand which jobs are important to customers and what the related pains and gains are.</Typography>
        `,
    },
    {
      name: "inspiration",
      media: [
        {
          id: "media_id_1",
          type: "video",
          title: "Video Title 1",
          content_url:
            "https://storage.coverr.co/videos/XTV79I01nRiyAWapsHTQlm8VrSPHXS00nR?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6Ijg3NjdFMzIzRjlGQzEzN0E4QTAyIiwiaWF0IjoxNjUwNjMwODUzfQ.Jn3ncLceelrB7Vef85e4_h0lFpY6Or7rnaII1oeQSLQ",
        },
      ],
    },
    //{
    //  name: "reviews",
    //  reviews: [
    //    //// Todo
    //  ],
    //},
  ],
};

export const TAMSAMSOM_SIDEBAR = {
  sidebar_id: "string",
  powerup_id: "string",
  tabs: [
    {
      name: "details",
      description:
        "Using this PowerUp, you’ll calculate the size of your market, as well as the size of that market that you can realistically capture, and the overall market conditions that will determine your odds of huge success.",
      powerup_attributes: [
        {
          id: "powerup_attribute_id_1",
          name: "Time Estimate",
          value: "2 - 4 hours",
          icon: "time",
        },
        {
          id: "powerup_attribute_id_2",
          name: "Difficulty",
          value: "Hard",
          icon: "steps",
        },
        {
          id: "powerup_attribute_id_3",
          name: "Team Recommended",
          value: "Optional",
          icon: "person",
        },
        //{
        //  id: "powerup_attribute_id_4",
        //  name: "Feedback Alert!",
        //  value:
        //    "After completing this Power Up, it is highly recommended to seek feedback with your matched Founder. If revisions are needed after feedback, you can easily edit the work you have already completed.",
        //  icon: "info",
        //},
      ],
    },
    {
      name: "Power Tips",
      html_content: "",
    },
    {
      name: "inspiration",
      media: [
        {
          id: "media_id_1",
          type: "video",
          title: "Video Title 1",
          content_url:
            "https://storage.coverr.co/videos/XTV79I01nRiyAWapsHTQlm8VrSPHXS00nR?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6Ijg3NjdFMzIzRjlGQzEzN0E4QTAyIiwiaWF0IjoxNjUwNjMwODUzfQ.Jn3ncLceelrB7Vef85e4_h0lFpY6Or7rnaII1oeQSLQ",
        },
      ],
    },
    //{
    //  name: "reviews",
    //  reviews: [
    //    //// Todo
    //  ],
    //},
  ],
};

export const PROBLEM_SOLUTION_SIDEBAR = {
  sidebar_id: "string",
  powerup_id: "string",
  tabs: [
    {
      name: "details",
      description: "[PROBLEM SOLUTION POWERUP PLACEHOLDER]",
      powerup_attributes: [
        {
          id: "powerup_attribute_id_1",
          name: "Time Estimate",
          value: "[PLACEHOLDER]",
          icon: "time",
        },
        {
          id: "powerup_attribute_id_2",
          name: "Difficulty",
          value: "[PLACEHOLDER]",
          icon: "steps",
        },
        {
          id: "powerup_attribute_id_3",
          name: "Team Recommended",
          value: "[PLACEHOLDER]",
          icon: "person",
        },
        //{
        //  id: "powerup_attribute_id_4",
        //  name: "Feedback Alert!",
        //  value:
        //    "After completing this Power Up, it is highly recommended to seek feedback with your matched Founder. If revisions are needed after feedback, you can easily edit the work you have already completed.",
        //  icon: "info",
        //},
      ],
    },
    {
      name: "Power Tips",
      html_content: "",
    },
    {
      name: "inspiration",
      media: [
        {
          id: "media_id_1",
          type: "video",
          title: "Video Title 1",
          content_url:
            "https://storage.coverr.co/videos/XTV79I01nRiyAWapsHTQlm8VrSPHXS00nR?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6Ijg3NjdFMzIzRjlGQzEzN0E4QTAyIiwiaWF0IjoxNjUwNjMwODUzfQ.Jn3ncLceelrB7Vef85e4_h0lFpY6Or7rnaII1oeQSLQ",
        },
      ],
    },
    //{
    //  name: "reviews",
    //  reviews: [
    //    //// Todo
    //  ],
    //},
  ],
};

export const BRANDSTORMING_SIDEBAR = {
  sidebar_id: "string",
  powerup_id: "string",
  tabs: [
    {
      name: "details",
      description: "[BRANDSTORMING POWERUP PLACEHOLDER]",
      powerup_attributes: [
        {
          id: "powerup_attribute_id_1",
          name: "Time Estimate",
          value: "[PLACEHOLDER]",
          icon: "time",
        },
        {
          id: "powerup_attribute_id_2",
          name: "Difficulty",
          value: "[PLACEHOLDER]",
          icon: "steps",
        },
        {
          id: "powerup_attribute_id_3",
          name: "Team Recommended",
          value: "[PLACEHOLDER]",
          icon: "person",
        },
        //{
        //  id: "powerup_attribute_id_4",
        //  name: "Feedback Alert!",
        //  value:
        //    "After completing this Power Up, it is highly recommended to seek feedback with your matched Founder. If revisions are needed after feedback, you can easily edit the work you have already completed.",
        //  icon: "info",
        //},
      ],
    },
    {
      name: "Power Tips",
      html_content: "",
    },
    {
      name: "inspiration",
      media: [
        {
          id: "media_id_1",
          type: "video",
          title: "Video Title 1",
          content_url:
            "https://storage.coverr.co/videos/XTV79I01nRiyAWapsHTQlm8VrSPHXS00nR?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6Ijg3NjdFMzIzRjlGQzEzN0E4QTAyIiwiaWF0IjoxNjUwNjMwODUzfQ.Jn3ncLceelrB7Vef85e4_h0lFpY6Or7rnaII1oeQSLQ",
        },
      ],
    },
    //{
    //  name: "reviews",
    //  reviews: [
    //    //// Todo
    //  ],
    //},
  ],
};

export const REVENUE_MODELER_SIDEBAR = {
  sidebar_id: "string",
  powerup_id: "string",
  tabs: [
    {
      name: "details",
      description: "[REVENUE MODELER POWERUP PLACEHOLDER]",
      powerup_attributes: [
        {
          id: "powerup_attribute_id_1",
          name: "Time Estimate",
          value: "[PLACEHOLDER]",
          icon: "time",
        },
        {
          id: "powerup_attribute_id_2",
          name: "Difficulty",
          value: "[PLACEHOLDER]",
          icon: "steps",
        },
        {
          id: "powerup_attribute_id_3",
          name: "Team Recommended",
          value: "[PLACEHOLDER]",
          icon: "person",
        },
        //{
        //  id: "powerup_attribute_id_4",
        //  name: "Feedback Alert!",
        //  value:
        //    "After completing this Power Up, it is highly recommended to seek feedback with your matched Founder. If revisions are needed after feedback, you can easily edit the work you have already completed.",
        //  icon: "info",
        //},
      ],
    },
    {
      name: "Power Tips",
      html_content: "",
    },
    {
      name: "inspiration",
      media: [
        {
          id: "media_id_1",
          type: "video",
          title: "Video Title 1",
          content_url:
            "https://storage.coverr.co/videos/XTV79I01nRiyAWapsHTQlm8VrSPHXS00nR?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6Ijg3NjdFMzIzRjlGQzEzN0E4QTAyIiwiaWF0IjoxNjUwNjMwODUzfQ.Jn3ncLceelrB7Vef85e4_h0lFpY6Or7rnaII1oeQSLQ",
        },
      ],
    },
    //{
    //  name: "reviews",
    //  reviews: [
    //    //// Todo
    //  ],
    //},
  ],
};

export const IDEA_VALIDATION_SIDEBAR = {
  sidebar_id: "string",
  powerup_id: "string",
  tabs: [
    {
      name: "details",
      description: "[IDEA VALIDATION POWERUP PLACEHOLDER]",
      powerup_attributes: [
        {
          id: "powerup_attribute_id_1",
          name: "Time Estimate",
          value: "[PLACEHOLDER]",
          icon: "time",
        },
        {
          id: "powerup_attribute_id_2",
          name: "Difficulty",
          value: "[PLACEHOLDER]",
          icon: "steps",
        },
        {
          id: "powerup_attribute_id_3",
          name: "Team Recommended",
          value: "[PLACEHOLDER]",
          icon: "person",
        },
        //{
        //  id: "powerup_attribute_id_4",
        //  name: "Feedback Alert!",
        //  value:
        //    "After completing this Power Up, it is highly recommended to seek feedback with your matched Founder. If revisions are needed after feedback, you can easily edit the work you have already completed.",
        //  icon: "info",
        //},
      ],
    },
    {
      name: "Power Tips",
      html_content: "",
    },
    {
      name: "inspiration",
      media: [
        {
          id: "media_id_1",
          type: "video",
          title: "Video Title 1",
          content_url:
            "https://storage.coverr.co/videos/XTV79I01nRiyAWapsHTQlm8VrSPHXS00nR?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6Ijg3NjdFMzIzRjlGQzEzN0E4QTAyIiwiaWF0IjoxNjUwNjMwODUzfQ.Jn3ncLceelrB7Vef85e4_h0lFpY6Or7rnaII1oeQSLQ",
        },
      ],
    },
    //{
    //  name: "reviews",
    //  reviews: [
    //    //// Todo
    //  ],
    //},
  ],
};
export const AVATARS = [
  AVATAR_1,
  AVATAR_2,
  AVATAR_3,
  AVATAR_4,
  AVATAR_5,
  AVATAR_6,
  AVATAR_7,
  AVATAR_8,
  AVATAR_9,
  AVATAR_10,
  AVATAR_11,
  AVATAR_12,
  AVATAR_13,
  AVATAR_14,
  AVATAR_15,
  AVATAR_16,
  AVATAR_17,
  AVATAR_18,
];

export const PERSONA_EDUCATION_LEVELS = [
  "Less than a high school diploma",
  "High school degree or equivalent (e.g. GED)",
  "Some college, no degree",
  "Associate degree (e.g. AA, AS)",
  "Bachelor's degree (e.g. BA, BS)",
  "Master's degree (MA, MS, MEd)",
  "Professional degree (MD, DDS, DVM)",
];

export const PERSONA_INDUSTRY_TYPES = [
  "Banking",
  "Manufacturing",
  "Investment",
  "Retail",
  "Technology",
  "Advertising",
  "Pharmaceutical",
  "Marketing",
  "Trade",
  "Transport",
  "Real Estate",
  "Healthcare",
  "Insurance",
  "Agriculture",
  "Construction",
  "Food & Beverage",
  "Agency",
  "Agriculture/Farming",
  "Aerospace & Defense",
  "Automotive",
  "AEC (Architecture/Construction/Engineering)",
  "Banking & Financial Services",
  "Biotech",
  "Broadcasting",
  "Computing",
  "Consulting/Professional Services",
  "CPG (Consumer Packaged Goods)",
  "Durables",
  "Eco/Recycling/Sustainability",
  "Education",
  "Energy/Renewables/Utilities",
  "Entertainments/Sports",
  "Clothing/Fashion",
  "Food & Food Service",
  "Freight & Logistics",
  "Furniture",
  "Government",
  "Healthcare",
  "Life Sciences",
  "Heavy Equipment",
  "Home Improvement",
  "Hygiene & Wellness",
  "Information Technology",
  "Insurance",
  "Legal Services",
  "Luxury / Upscale Branding",
  "Manufacturing",
  "Materials (Forestry & Mining)",
  "Minerals",
  "News/Media/Publishing",
  "Non-Profits/NGOs",
  "Packaging",
  "Petcare",
  "Pharma",
  "Real Estate",
  "Recreation",
  "Relocation",
  "Retail",
  "Robotics",
  "Space Exploration",
  "Telecom",
  "Toys",
  "Travel & Hospitality",
];

export const STATES = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "American Samoa",
    abbreviation: "AS",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Guam",
    abbreviation: "GU",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Palau",
    abbreviation: "PW",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
];

export const CHALLENGES = [
  "Uncertainty",
  "Collaboration",
  "Communications",
  "Customer services",
  "Digitalization",
  "Diversity & Inclusion",
  "Financial management",
  "Monitoring performance",
  "Regulation & Compliance",
  "Recruiting right talent",
  "Technology",
];

export const TOOLS = [
  "Communication Tools",
  "Project Management Tools",
  "Documentation / Product Management Tools",
  "Scheduling Tools",
  "Team Culture / Team Building Tools",
  "Marketing Tools",
  "Sales Tools",
  "Customer Success Tools",
  "Product / Design Tools",
  "Expense Management Tools",
  "Other",
];

export const CONTACT_ICONS = [
  PHONE_ICON,
  EMAIL_ICON,
  TEXT_ICON,
  LIKE_ICON,
  SMILE_ICON,
];

export const CONTACT_ICONS_MAP = [
  { url: PHONE_ICON, component: LocalPhoneOutlinedIcon, text: "Phone" },
  { url: EMAIL_ICON, component: EmailOutlinedIcon, text: "Email" },
  { url: TEXT_ICON, component: TextsmsOutlinedIcon, text: "Text" },
  { url: LIKE_ICON, component: ThumbUpOutlinedIcon, text: "Social Media" },
  { url: SMILE_ICON, component: MoodOutlinedIcon, text: "In Person" },
];
