import React from "react";
import { Rating } from "@mui/material";
import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import PropTypes from "prop-types";

const RatingComponent = ({ onChange, rating, isReadOnly, iconColor }) => {
  return (
    <Rating
      sx={{ marginLeft: "auto" }}
      value={rating}
      icon={<StarRoundedIcon />}
      emptyIcon={<StarBorderRoundedIcon sx={{ color: `${iconColor}` }} />}
      readOnly={isReadOnly}
      onChange={onChange}
    />
  );
};

{
  RatingComponent.propTypes = {
    isReadOnly: PropTypes.bool,
    onChange: PropTypes.func,
    index: PropTypes.number,
    iconColor: PropTypes.string,
  };

  RatingComponent.defaultProps = {
    isReadOnly: true,
    onChange: null,
    iconColor: null,
  };
}

export default RatingComponent;
