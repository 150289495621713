import React, { useState } from "react";
import {
  Typography,
  CardContent,
  CardActions,
  Box,
  Card,
  Button,
  Divider,
  TextField,
} from "@mui/material";

import theme from "../../../../constants/Theme";
import RatingComponent from "../../../../common/rating";

const RatingCard = ({ currentTab }) => {
  const [review, setReview] = useState(null);
  const [rating, setRating] = useState(null);

  const handleReviewChange = e => {
    setReview(e.target.value);
  };
  const handleRatingChange = e => {
    setRating(e.target.value);
  };
  return (
    <Box
      sx={{
        width: 1,
        mx: 2,
        mt: 3,
        display: {
          sm: currentTab === "rate" ? "block" : "none",
          xs: currentTab === "rate" ? "block" : "none",
          md: "none",
        },
      }}
    >
      <Card sx={{ borderRadius: "28px", p: 1 }}>
        <CardContent>
          <Typography
            variant="medium_title"
            color={theme.palette.text.secondary}
          >
            Rate this PowerUp
          </Typography>
          <Box sx={{ mt: 3 }}>
            <RatingComponent
              isReadOnly={false}
              iconColor={theme.palette.text.primary}
              onChange={handleRatingChange}
            />
          </Box>
          <Divider
            sx={{
              mt: 3,
              background: `${theme.palette.secondary.outlineLight}`,
            }}
          />
          <TextField
            sx={{ width: 1, mt: 3 }}
            variant="outlined"
            placeholder="Tell us how this PowerUp can be improved..."
            multiline
            minRows={5}
            maxRows={5}
            value={review}
            onChange={e => handleReviewChange(e)}
          />
        </CardContent>
        <CardActions sx={{ px: 2, float: "right" }}>
          <Button disabled={!review || !rating} size="small">
            Submit Review
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default RatingCard;
