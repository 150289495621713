import * as React from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Modal,
  Typography,
} from "@mui/material";

import { TopBorder } from "./styles";
import { LOGOICON } from "../../constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  overflowY: "auto",
  outline: "none",
  boxShadow: "0px 1px 2px rgb(0 0 0 / 10%), 0px 4px 15px rgb(0 0 0 / 10%)",
  borderRadius: "16px",
};

export default function WelcomeStartupOSModal({ isOpen, onClose, onNext }) {
  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          style: { backgroundColor: "rgba(196, 196, 196, 0.75)" },
        }}
      >
        <Box sx={style}>
          <TopBorder />

          <Grid
            container
            display={"block"}
            rowSpacing={3}
            columnSpacing={4}
            sx={{ padding: 8, paddingTop: 5 }}
          >
            <Grid item>
              <img src={LOGOICON} style={{ padding: 0 }} />
            </Grid>
            <Grid item>
              <Typography variant="headline2"> Welcome to StartupOS</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Congratulations! You’ve taken the first step to successfully
                launching your startup. Would you like to take a quick guided
                tour to familiarize yourself with the platform?
              </Typography>
            </Grid>

            <Grid item sx={{ marginTop: "16px" }}>
              <Box display={"grid"}>
                <Button
                  variant="contained"
                  sx={{ width: "auto", marginLeft: "initial" }}
                  onClick={() => onNext()}
                >
                  Let's go
                </Button>
                <Button
                  variant="nofill"
                  sx={{
                    width: "auto",
                    marginLeft: "initial",
                    padding: 0,
                    marginTop: "30px",
                  }}
                  onClick={onClose}
                >
                  <Typography variant="subtitle" color="primary">
                    Skip this for now.
                  </Typography>
                </Button>
              </Box>
            </Grid>

            <Grid item>
              <Divider />
            </Grid>

            <Grid item>
              <Typography>
                For any questions, please contact us at{" "}
                <Link underline="none" href="mailto:support@startupos.com">
                  <Typography variant="link">support@startupos.com.</Typography>
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
