import {
  Typography,
  Box,
  Divider,
  Grid,
  Stack,
  Card,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import theme from "../../../constants/Theme";
import WorkspaceBreadCrumbs from "../../../components/Workspace/BreadCrumbs";
import FormSectionHeader from "../../../components/Workspace/PowerUp/FormSectionHeader";
import constants from "../../../constants";
import { GreenTicklIcon, PencilIcon } from "../../../common/icons";
import Tabs from "../../../components/Workspace/Tabs";
import KnowledgeSidebar from "../../../components/Workspace/PowerUp/KnowledgeSidebar";
import { connect } from "react-redux";
import * as actions from "../../../modules/actions";
import RatingCard from "../../../components/Workspace/PowerUp/Rating";

function ValuePropSummary({ powerUpExcercises, setPowerUpExcercises }) {
  const tabItems = constants.SUMMARY_TAB_ITEMS;
  const history = useHistory();
  const params = useParams();
  const moduleId = parseInt(params.moduleId);
  const versionId = parseInt(params.versionId);

  const [currentTab, setCurrentTab] = useState(tabItems[0].key);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setCurrentTab(tabItems[0].key);
  }, []);

  const handleEditAction = () => {
    history.push(
      `/workspace/powerup/md-value-prop/${moduleId}/${versionId}/frame-statement`
    );
  };

  return (
    <>
      <Stack
        sx={{
          minHeight: "100%",
          borderRadius: "28px",
          p: 4,
          [theme.breakpoints.down("md")]: {
            p: 0,
            borderRadius: 0,
          },
          background:
            "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
        }}
      >
        <Grid
          container
          sx={{ maxWidth: theme.breakpoints.values.xl, margin: "auto" }}
          flexGrow={1}
          flexDirection="column"
        >
          <WorkspaceBreadCrumbs subpath="Value Proposition" />
          <Box
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              mt: 3,
              mx: 2,
            }}
          >
            <Tabs
              tabItems={tabItems}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              color="primary"
              spacing={0}
              buttonWidth="50%"
              width="100%"
            />
          </Box>
          <Grid container sx={{ minHeight: "100%", mb: 10 }}>
            <Grid
              item
              md={8}
              sm={12}
              xs={12}
              sx={theme => ({
                [theme.breakpoints.up("md")]: {
                  borderRight: `1px solid ${theme.palette.secondary.main}`,
                  pr: 3,
                  mt: 4,
                },
                [theme.breakpoints.down("md")]: {
                  p: 2,
                },
                display: "flex",
                flexDirection: "column",
                minHeight: "100%",
                display: {
                  sm: currentTab === "excercise" ? "block" : "none",
                  xs: currentTab === "excercise" ? "block" : "none",
                  md: "flex",
                },
              })}
            >
              <Card
                sx={{
                  borderRadius: "28px",
                  border: `1px solid ${theme.palette.secondary.dark}`,
                  p: 3,
                  display: "flex",
                  flexDirection: "column",
                  [theme.breakpoints.down("md")]: {
                    background: "transparent",
                    border: "none",
                    boxShadow: "0",
                    p: 1,
                    mt: 2,
                  },
                }}
              >
                <FormSectionHeader heading="VALUE PROPOSITION" />
                <Divider
                  sx={{
                    background: theme => theme.palette.secondary.dark,
                    [theme.breakpoints.down("md")]: {
                      background: theme => theme.palette.secondary.borderLight,
                    },
                  }}
                />
                <Box flexDirection={"column"}>
                  <Box sx={{ mt: 3 }}>
                    <Typography variant="largeTitle">Summary</Typography>
                  </Box>
                  <Divider sx={{ mt: 3, borderColor: "secondary.light" }} />
                  <Box
                    sx={{
                      mt: 3,
                      display: { sm: "none", xs: "none", md: "flex" },
                    }}
                  >
                    <Typography variant="title_medium">
                      Your Value Proposition
                    </Typography>{" "}
                    <Box sx={{ ml: "auto" }}>
                      <Button
                        onClick={handleEditAction}
                        variant="nofill"
                        startIcon={<PencilIcon />}
                      >
                        Edit Answer
                      </Button>
                    </Box>
                  </Box>
                  <Box display={"grid"} sx={{ mt: 3 }}>
                    <Grid container sx={{ w: 1 }} alignItems={"center"}>
                      <Box
                        sx={{
                          pr: 2,
                          display: { sm: "flex", xs: "flex", md: "none" },
                        }}
                      >
                        <Typography variant="title_medium">
                          Your Value Proposition
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          ml: "auto",
                          display: { sm: "block", xs: "block", md: "none" },
                        }}
                        onClick={handleEditAction}
                      >
                        <PencilIcon />
                      </Box>
                      <Grid item md={12} sm={12} sx={{ px: 3, py: 10 }}>
                        <Typography variant="largeTitle">
                          {`Our ${powerUpExcercises[4].answers[0]?.value
                            ?.trimStart()
                            ?.trimEnd()} helps ${powerUpExcercises[4].answers[1]?.value
                            ?.trimStart()
                            ?.trimEnd()} who want to ${powerUpExcercises[4].answers[2]?.value
                            ?.trimStart()
                            ?.trimEnd()} by ${powerUpExcercises[4].answers[3]?.value
                            ?.trimStart()
                            ?.trimEnd()} and ${powerUpExcercises[4].answers[4]?.value
                            ?.trimStart()
                            ?.trimEnd()}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider sx={{ mt: 3, borderColor: "secondary.light" }} />
                  <Box
                    sx={{
                      display: { sm: "block", xs: "block", md: "none" },
                      background: theme.palette.secondary.white,
                      borderRadius: "28px",
                      p: 3,
                      mt: 3,
                    }}
                  >
                    <Typography variant="title_medium">
                      Want feedback?
                    </Typography>
                    <Box sx={{ mt: 3 }}>
                      <Typography variant="subhead2">
                        Your StartupOS Advisor is here to help if you would like
                        feedback for this completed PowerUp.
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: "center", mt: 4 }}>
                      <Button>Submit for feedback</Button>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mt: 3,
                      display: { sm: "none", xs: "none", md: "flex" },
                    }}
                  >
                    <Box sx={{ ml: "auto" }}>
                      <Button variant="outlined">Rate PowerUp</Button>
                    </Box>
                    <Box sx={{ ml: 2 }}>
                      <Button
                        onClick={() => {
                          history.push("/workspace");
                        }}
                      >
                        Return to Workspace Home
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Grid>
            <KnowledgeSidebar currentTab={currentTab} powerUpId={"1"} />
            <Box
              sx={{
                display: {
                  sm: currentTab === "rate" ? "flex" : "none",
                  xs: currentTab === "rate" ? "flex" : "none",
                  md: "none",
                },
                mt: 3,
                ml: 2,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  mr: 2,
                }}
              >
                <GreenTicklIcon />
              </Box>
              <Typography variant="h3">Completed!</Typography>
            </Box>
            <RatingCard currentTab={currentTab} />
          </Grid>
          <Box
            sx={{
              background: theme.palette.secondary.white,
              position: "fixed",
              bottom: 72,
              width: 1,
              textAlign: "center",
              py: 2,
              display: { sm: "block", xs: "block", md: "none" },
            }}
          >
            <Button
              onClick={() => {
                history.push("/workspace");
              }}
            >
              Return to Workspace Home
            </Button>
          </Box>
        </Grid>
      </Stack>
    </>
  );
}

const mapStoreToProps = ({ powerUp }) => ({
  powerUpExcercises: powerUp?.powerUpExcercises,
});

const mapDispatchToProps = dispatch => ({
  setPowerUpExcercises: updatedSteps =>
    dispatch(actions.setPowerUpExcercises(updatedSteps)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(ValuePropSummary);
