import { useState } from "react";
import {
  SIDEBAR_ACTIVE_ICON,
  SIDEBAR_INACTIVE_ICON,
  SIDEBAR_COMPLETED_ICON,
  BACKARROW,
  CUSTOMERICON,
  LAUNCHICON,
  GLOWBULBICON,
  CHARGEICON,
  FUNDINGICON,
  SMILEICON,
  MARKETANALYSISICON,
  HARDWAREICON,
  SOFTWAREICON,
  MARKETPLACEICON,
  SERVICEICON,
  CONSUMERGOODSICON,
  EGGICON,
  MINIROADRUNNERICON,
  MINIRUNNERICON,
  CHICKICON,
  FLOWERICON,
  GRITICON,
  ENERGYICON,
  EYEVISIONICON,
  COMMUNITYICON,
  HEARTICON,
  EXECUTIONICON,
  OVERTIMECON,
  FULLTIMEICON,
  PARTTIMEICON,
  SIDEHUSTLEICON,
  NETWORKICON,
  NETWORKICON_GREY,
  NOTESICON,
  TASKSICON,
  PROGRESSDARKICON,
  STARTUPOSDARKICON,
  CURRENTSTREAKDARKICON,
  RADARDARKICON,
  LOCKICON,
  BLUEDASHBOARDICON,
  FEATUREDPARTNERICON,
  DASHBOARDICON,
  POWERUP,
  WORKSPACEICON,
  USERSICON,
  POWERUPSCIRCLEICON,
  NAVIGATIONCIRCLEICON,
  CALCULATORCIRCLEICON,
  PARTNERCIRCLEICON,
  TRACKCIRCLEICON,
  QUICKSTATCIRCLEICON,
  STARTUPOSCIRCLESMALLICON,
  STEP_COMPLETED,
  STEP_CURRENT,
  DIFFCULTY_ICON,
  TEAM_RECOMMEND_ICON,
  GREEN_TICK_ICON,
  PENCIL_ICON,
  GREEN_CHECK_ICON,
  DEFAULT_POWER_UP,
  DEFAULT_DEMO_DAY,
  DEFAULT_PLUGIN,
  DEFAULT_FUSION,
  ACTIVE_POWER_UP,
  ACTIVE_DEMODAY,
  ACTIVE_PLUGIN,
  ACTIVE_FUSION,
  COMPLETED_FUSION,
  COMPLETED_PLUGIN,
  COMPLETED_DEFAULT,
  FILLED_POWERUP,
  FILE_DOWNLOAD,
  JOURNEY_SMILE_ICON,
} from "../../constants/image";

import styled from "@emotion/styled";

import { Box, StepButton, StepIcon } from "@mui/material";

export const SidebarIconCompleted = ({ cursor }) => {
  return (
    <img
      src={SIDEBAR_COMPLETED_ICON}
      alt="completed"
      style={{ cursor: cursor }}
    />
  );
};

export const SidebarIconActive = ({ cursor }) => {
  return (
    <img src={SIDEBAR_ACTIVE_ICON} alt="active" style={{ cursor: cursor }} />
  );
};

export const SidebarIconInActive = ({ cursor }) => {
  return (
    <img
      src={SIDEBAR_INACTIVE_ICON}
      alt="inactive"
      style={{ cursor: cursor }}
    />
  );
};

export const BackIcon = () => {
  return <img src={BACKARROW} alt="back-arrow" />;
};

export const CustomerIcon = () => {
  return <img src={CUSTOMERICON} alt="customer-icon" />;
};

export const LaunchIcon = () => {
  return <img src={LAUNCHICON} alt="launch-icon" />;
};
export const GlowBulbIcon = () => {
  return <img src={GLOWBULBICON} alt="glob-bulb" />;
};

export const ChargeIcon = () => {
  return <img src={CHARGEICON} alt="charge" />;
};

export const FundingIcon = () => {
  return <img src={FUNDINGICON} alt="funding" />;
};

export const SmileIcon = () => {
  return <img src={JOURNEY_SMILE_ICON} alt="smile" />;
};

export const MarketAnalysisIcon = () => {
  return <img src={MARKETANALYSISICON} alt="market" />;
};

export const SoftwareIcon = () => {
  return <img src={SOFTWAREICON} alt="software" />;
};

export const HardwareIcon = () => {
  return <img src={HARDWAREICON} alt="hardware" />;
};

export const MarketPlaceIcon = () => {
  return <img src={MARKETPLACEICON} alt="marketplace" />;
};

export const ServiceIcon = () => {
  return <img src={SERVICEICON} alt="service" />;
};

export const ConsumerGoodsIcon = () => {
  return <img src={CONSUMERGOODSICON} alt="consumer" />;
};

export const EggIcon = () => {
  return <img src={EGGICON} alt="egg" />;
};

export const ChickIcon = () => {
  return <img src={CHICKICON} alt="chick" />;
};

export const MiniRunnerIcon = () => {
  return <img src={MINIRUNNERICON} alt="runner" />;
};

export const MiniRoaderRunnerIcon = () => {
  return <img src={MINIROADRUNNERICON} alt="runner" />;
};

export const FlowerIcon = () => {
  return <img src={FLOWERICON} alt="flower" />;
};

export const GritIcon = () => {
  return <img src={GRITICON} alt="grit" />;
};

export const EnergyIcon = () => {
  return <img src={ENERGYICON} alt="energy" />;
};

export const CommunityIcon = () => {
  return <img src={COMMUNITYICON} alt="community" />;
};

export const ExecutionIcon = () => {
  return <img src={EXECUTIONICON} alt="execution" />;
};

export const EyeVisionIcon = () => {
  return <img src={EYEVISIONICON} alt="eyevision" />;
};

export const NetworkIcon = () => {
  return <img src={NETWORKICON} alt="network" />;
};

export const HeartIcon = () => {
  return <img src={HEARTICON} alt="heart" />;
};

export const OverTimeIcon = () => {
  return <img src={OVERTIMECON} alt="overtime" />;
};

export const FullTimeIcon = () => {
  return <img src={FULLTIMEICON} alt="fulltime" />;
};

export const PartTimeIcon = () => {
  return <img src={PARTTIMEICON} alt="parttime" />;
};

export const SideHustleIcon = () => {
  return <img src={SIDEHUSTLEICON} alt="sidehustle" />;
};

export const PowerUpIcon = () => {
  return <img src={POWERUP} alt="powerup" />;
};
export const DashboardIcon = () => {
  return <img src={DASHBOARDICON} alt="dashboard" />;
};
export const WorkspaceIcon = () => {
  return <img src={WORKSPACEICON} alt="workspace" />;
};
export const SidebarNetworkIcon = () => {
  return <img src={NETWORKICON_GREY} alt="newtwork" />;
};
export const UsersIcon = () => {
  return <img src={USERSICON} alt="users" />;
};

export const NotesIcon = () => {
  return <img src={NOTESICON} alt="notes" />;
};

export const TasksIcon = () => {
  return <img src={TASKSICON} alt="tasks" />;
};

export const CurrentStreakIcon = () => {
  return <img src={CURRENTSTREAKDARKICON} alt="currentStreak" />;
};
export const ProgressDarkIcon = () => {
  return <img src={PROGRESSDARKICON} alt="progressDark" />;
};

export const StartuposDarkIcon = () => {
  return <img src={STARTUPOSDARKICON} alt="startupos" />;
};

export const RadarDarkIcon = () => {
  return <img src={RADARDARKICON} alt="radar" />;
};

export const LockIcon = () => {
  return <img src={LOCKICON} alt="lock" />;
};
export const BlueDashboardIcon = () => {
  return <img src={BLUEDASHBOARDICON} alt="dashboard" />;
};
export const FeaturedPartnerIcon = () => {
  return <img src={FEATUREDPARTNERICON} alt="partner" />;
};

export const PowerUpsCircleIcon = () => {
  return <img src={POWERUPSCIRCLEICON} alt="circle" />;
};

export const NavigationCircleIcon = () => {
  return <img src={NAVIGATIONCIRCLEICON} alt="navigation" />;
};

export const CalculatorCircleIcon = () => {
  return <img src={CALCULATORCIRCLEICON} alt="calculator" />;
};

export const PartnerCircleIcon = () => {
  return <img src={PARTNERCIRCLEICON} alt="partner" />;
};

export const TrackCicleIcon = () => {
  return <img src={TRACKCIRCLEICON} alt="track" />;
};

export const QuickStatCircleIcon = () => {
  return <img src={QUICKSTATCIRCLEICON} alt="quick" />;
};

export const StartupOSCircleSmallIcon = () => {
  return <img src={STARTUPOSCIRCLESMALLICON} alt="circle" />;
};

export const WorkspaceStepIcon = ({ active, style }) => {
  return (
    <img
      width={32}
      height={32}
      src={active ? STEP_COMPLETED : STEP_CURRENT}
      style={{ ...style }}
      alt="steps"
    />
  );
};
export const TeamRecommendIcon = () => {
  return <img src={TEAM_RECOMMEND_ICON} alt="team" />;
};

export const DifficultylIcon = () => {
  return <img src={DIFFCULTY_ICON} alt="difficulty" />;
};
export const GreenTicklIcon = () => {
  return <img src={GREEN_TICK_ICON} alt="tick" />;
};

export const PencilIcon = () => {
  return <img src={PENCIL_ICON} alt="pencil" />;
};

export const GreenCheckIcon = () => {
  return <img src={GREEN_CHECK_ICON} alt="check" />;
};
export const CounterCircleWrapperIcon = styled.div(({ theme }) => {
  return {
    height: "32px",
    width: "32px",
    borderRadius: "50px",
    border: `1px solid ${theme.palette.secondary?.dark}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});

export const PowerUpStepIcon = ({ active, style }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Box
      src={active || hovered ? ACTIVE_POWER_UP : DEFAULT_POWER_UP}
      sx={{
        ...style,
        width: 32,
        height: 32,
        fill: "white",
      }}
      alt="powerup"
      component="img"
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    />
  );
};
export const PluginStepIcon = ({ active, style, completed }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Box
      src={
        active || hovered
          ? ACTIVE_PLUGIN
          : completed
          ? COMPLETED_PLUGIN
          : DEFAULT_PLUGIN
      }
      sx={{ ...style, width: 32, height: 32 }}
      component="img"
      alt="plugin"
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    />
  );
};
export const FusionStepIcon = ({ active, style, completed }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Box
      src={
        active || hovered
          ? ACTIVE_FUSION
          : completed
          ? COMPLETED_FUSION
          : DEFAULT_FUSION
      }
      sx={{ ...style, width: 32, height: 32 }}
      component="img"
      alt="fusion"
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    />
  );
};
export const DemoStepIcon = ({ active, style }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Box
      src={active || hovered ? ACTIVE_DEMODAY : DEFAULT_DEMO_DAY}
      sx={{ ...style, width: 32, height: 32 }}
      component="img"
      alt="demo"
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    />
  );
};
export const CompletedIcon = ({ style, number }) => {
  return (
    <Box
      src={COMPLETED_DEFAULT}
      sx={{ ...style, width: 32, height: 32 }}
      component="img"
      alt="completed"
    />
  );
};

export const PowerUpFilledIcon = ({ style }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Box
      src={hovered ? ACTIVE_POWER_UP : FILLED_POWERUP}
      sx={{ ...style, width: 32, height: 32 }}
      component="img"
      alt="filled"
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    />
  );
};

export const FileDownload = () => {
  return <img src={FILE_DOWNLOAD} alt="file_download" />;
};
