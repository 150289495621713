import React, { useState } from "react";
import {
  Grid,
  InputBase,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import FileDownloadDoneOutlinedIcon from "@mui/icons-material/FileDownloadDoneOutlined";
import { EditDialog, FormWrapper } from "../styles";
import theme from "../../../constants/Theme";
import constants from "../../../constants";
import CloseIcon from "@mui/icons-material/Close";

let FileType = {
  img: ".png, .jpg, .jpeg",
  all: ".pdf, .xlsx, .docx, .csv, .ppt, .pptx, .key, .xls, .odt, .txt",
  video: ".mp4, .3gp, .avi, .webm, .ogg",
};

export default function EditForm(props) {
  const [productOfferingData, setProductOfferingData] = useState(
    constants.PRODUCT_SERVICE_OFFERING_DATA
  );
  const handleFileChange = type => event => {
    const file = event.target.files[0];

    if (file === undefined) {
      props.handleUploadAction("", "");
      if (type == "img") {
        props.setCompanyDataError(prevState => ({
          ...prevState,
          logoError: "Please attach a logo",
        }));
      } else if (type == "video") {
        props.setCompanyDataError(prevState => ({
          ...prevState,
          videoError: "Please attach a video",
        }));
      } else {
        props.setCompanyDataError(prevState => ({
          ...prevState,
          pitchDeckError: "Please attach a pitch deck",
        }));
      }
    } else {
      props.handleUploadAction(file, type);
    }
  };

  const uploadButton = (type, handler) => {
    return (
      <Button
        variant="outlined"
        startIcon={
          !props.loading &&
          (props.browseFlag ? (
            <FileDownloadDoneOutlinedIcon />
          ) : (
            <FileUploadOutlinedIcon />
          ))
        }
        sx={{
          width: "100%",
          border: `1px solid ${theme.palette.secondary.dark}`,
        }}
        component="label"
      >
        {props.loading ? (
          <CircularProgress />
        ) : props.browseFlag ? (
          "Uploaded"
        ) : (
          "Browse"
        )}
        <input
          type="file"
          accept={FileType[type]}
          hidden
          multiple={false}
          onChange={handleFileChange(type)}
        />
      </Button>
    );
  };
  return (
    <EditDialog open={props.open} onBackdropClick={props.close}>
      <FormWrapper>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Box sx={{ color: `${theme.palette.text.primary}`, pt: 2 }}>
              <Typography variant="h3">Edit Profile</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>Company Name*</Typography>
            <InputBase
              placeholder="The Roadrunner Corp."
              value={props.companyName}
              autoFocus={true}
              onChange={(e, type) => {
                props.handleChange(e, "companyName");
              }}
              inputProps={{ maxLength: "25", minLength: "2" }}
            />
            {props?.companyDataError?.companyNameError && (
              <Typography variant="error_text">
                {props.companyDataError.companyNameError}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>Tagline</Typography>
            <InputBase
              placeholder="A next-generation lear..."
              value={props.tagline}
              onChange={(e, type) => {
                props.handleChange(e, "tagline");
              }}
              inputProps={{ maxLength: "100" }}
              multiline={true}
              rows={1}
            />
            {props?.companyDataError?.taglineError && (
              <Typography variant="error_text">
                {props.companyDataError.taglineError}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>Product Offering*</Typography>
            {!props.showOther ? (
              <Select
                value={props.productOffering}
                onChange={(e, type) => {
                  props.handleChange(e, "productOffering");
                }}
                sx={{
                  "&:hover": {
                    "&& fieldset": {
                      border: `1px solid ${theme.palette.secondary.textFieldBorder}`,
                    },
                  },
                }}
              >
                {productOfferingData.map((item, index) => {
                  return <MenuItem value={item.text}>{item.text}</MenuItem>;
                })}
              </Select>
            ) : (
              <InputBase
                value={props.productOffering}
                placeholder="Other"
                onChange={(e, type) => {
                  props.handleChange(e, "productOffering");
                }}
                endAdornment={
                  <CloseIcon
                    onClick={() => props.setShowOther(!props.showOther)}
                    sx={{
                      color: `${theme.palette.secondary.textFieldBorder}`,
                      cursor: "pointer",
                    }}
                  />
                }
              />
            )}
            {props?.companyDataError?.productOfferingError && (
              <Typography variant="error_text">
                {props.companyDataError.productOfferingError}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>Location*</Typography>
            <InputBase
              placeholder="Zip Code"
              value={props.compLocation}
              onClick={props.handleClear}
              onChange={(e, type) => {
                props.handleChange(e, "location");
              }}
              inputProps={{ maxLength: "5", minLength: "5" }}
            />
            {props?.companyDataError?.locationError && (
              <Typography variant="error_text">
                {props?.companyDataError?.locationError}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>Startup Size*</Typography>
            <Select
              value={props.startUpSize}
              onChange={(e, type) => {
                props.handleChange(e, "startUpSize");
              }}
              sx={{
                "&:hover": {
                  "&& fieldset": {
                    border: `1px solid ${theme.palette.secondary.textFieldBorder}`,
                  },
                },
              }}
            >
              <MenuItem value={"1-10"}>1-10</MenuItem>
              <MenuItem value={"11-50"}>11-50</MenuItem>
              <MenuItem value={"50-100"}>50-100</MenuItem>
              <MenuItem value={"100+"}>100+</MenuItem>
            </Select>

            {props?.companyDataError?.startupSizeError && (
              <Typography variant="error_text">
                {props.companyDataError.startupSizeError}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>Founding Date</Typography>
            <InputBase
              placeholder="2022"
              value={props.foundingDate}
              onChange={(e, type) => {
                props.handleChange(e, "foundingDate");
              }}
              inputProps={{ maxLength: "4", minLength: "4" }}
            />
            {props?.companyDataError?.foundingDateError && (
              <Typography variant="error_text">
                {props.companyDataError.foundingDateError}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>Company Site</Typography>
            <InputBase
              placeholder="www.roadrunnercorp.c.."
              value={props.companySite}
              onChange={(e, type) => {
                props.handleChange(e, "companySite");
              }}
              inputProps={{ maxLength: "100" }}
            />
            {props?.companyDataError?.companySiteError && (
              <Typography variant="error_text">
                {props.companyDataError.companySiteError}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography>About</Typography>
            <InputBase
              placeholder="The Roadrunner Corp..."
              value={props.about}
              onChange={(e, type) => {
                props.handleChange(e, "about");
              }}
              inputProps={{ maxLength: "500" }}
              multiline={true}
              rows={1}
            />
            {props?.companyDataError?.aboutError && (
              <Typography variant="error_text">
                {props.companyDataError.aboutError}
              </Typography>
            )}
          </Grid>

          <Grid item xs={12} sm={12} lg={12}>
            <Grid container spacing={1}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  p: 1,
                  gap: 1,
                  width: "100%",
                }}
              >
                <Grid item xs={5} md={3}>
                  <Typography>Logo</Typography>
                  {uploadButton("img")}
                  {props?.companyDataError?.logoError && (
                    <Typography variant="error_text">
                      {props.companyDataError.logoError}
                    </Typography>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} lg={12}>
            <Box sx={{ float: "right" }}>
              <Button
                sx={{ width: "max-content" }}
                onClick={props.handleValidations}
                disabled={props.saveDisabled}
              >
                {props.saveLoading ? (
                  <CircularProgress
                    sx={{
                      color: `${theme.palette.secondary.white}`,
                      width: theme.spacing(1),
                    }}
                  />
                ) : (
                  "Save"
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </FormWrapper>
    </EditDialog>
  );
}
