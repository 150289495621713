import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getColorForPercentage } from "../../../../utils/getPercentageColor";

const ScoreProgress = ({ id, maxValue, value }) => {
  useEffect(() => {
    function positionTheDot(percentage) {
      // Get path length
      var path = document.getElementById("theMotionPath");
      var pathLen = path.getTotalLength();

      var path2 = document.getElementById("theMotionPath2");
      var pathLen2 = path2.getTotalLength();

      var pt = path.getPointAtLength(percentage * pathLen);

      var pt2 = path2.getPointAtLength(percentage * pathLen2);

      // Position the red dot at this point
      var dot = document.getElementById("dot");
      dot.setAttribute("transform", "translate(" + pt.x + "," + pt.y + ")");
      dot.setAttribute("stroke", getColorForPercentage(percentage));
      var tri = document.getElementById(id + "-triangle");
      var triShape = document.getElementById(id + "-triangleShape");
      tri.setAttribute("transform", "translate(" + pt2.x + "," + pt2.y + ")");
      triShape.setAttribute(
        "transform",
        "scale(.5) rotate(" + percentage * 100 * 1.8 + ")"
      );
      var svgTextElement = document.getElementById(id + "-dialtext");
      var textNode = svgTextElement.childNodes[0];
      textNode.nodeValue = Math.round(850 * percentage);
    }
    value && maxValue && positionTheDot(value / maxValue);
  }, [value]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "150px",
        maxHeight: "150px",
      }}
    >
      <svg id="mainarc" viewBox="88 80 225 200">
        <defs>
          <linearGradient
            spreadMethod="pad"
            id={`${id}-gradient`}
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop offset="0" stop-color="#F32836" />
            <stop offset=".20" stop-color="#7B61FF" />
            <stop offset=".35" stop-color="#478FC8" />
            <stop offset=".70" stop-color="#00CE7D" />
          </linearGradient>
          <font>
            <font-face font-family="PoppinsSemiBold" />
          </font>
        </defs>
        <g transform="rotate(-40 200 190)">
          <path
            d="M 100 200 A 100 100 180 0 1 300 200"
            stroke={`url(#${id}-gradient)`}
            stroke-width="10"
            fill="none"
            id="theMotionPath"
            stroke-linecap="round"
          />
          <circle
            cx="0"
            cy="0"
            r="8"
            fill="white"
            stroke="red"
            stroke-width="6"
            id="dot"
          />

          <g transform="rotate(40 200 200)">
            <circle
              cx="200"
              cy="200"
              r="70"
              fill="none"
              stroke="rgba(124, 117, 127, 0.16)"
              stroke-width="1"
            />
            <text
              x="200"
              y="218"
              text-anchor="middle"
              id={`${id}-dialtext`}
              font-size="50px"
              font-family="PoppinsSemiBold"
            >
              0
            </text>
          </g>

          <path
            d="M 120 200 A 1 1 180 0 1 280 200"
            stroke="none"
            stroke-width="1"
            fill="none"
            id="theMotionPath2"
          />
          <g id={`${id}-triangle`}>
            <polygon
              points="5.000000000000001,8.660254037844386 -10,1.2246467991473533e-15 4.999999999999993,-8.66025403784439"
              id={`${id}-triangleShape`}
            ></polygon>
          </g>
        </g>
      </svg>
    </Box>
  );
};

export default ScoreProgress;
