import styled from "@emotion/styled";

export const TermsTitle = styled.div(({ theme }) => {
  return {
    fontFamily: "var(--poppins-regular)",
    fontStyle: "var(--font-style-normal)",
    fontWeight: "var(--font-weight-400)",
    fontSize: "var(--font-size-22)",
    lineHeight: "var(--line-height-28)",
    color: "var(--sub-text-color)",
    padding: "24px",
  };
});

export const TermsText = styled.div(({ theme }) => {
  return {
    fontFamily: "var(--roboto-regular)",
    fontStyle: "var(--font-style-normal)",
    fontWeight: "var(--font-weight-400)",
    fontSize: "var(--font-size-14)",
    lineHeight: "var(--line-height-20)",
    color: "var(--black-color)",
    letterSpacing: "var(--terms-sub-text-spacing)",
    padding: "24px",
  };
});

export const TermsSubText = styled.div(({ theme }) => {
  return {
    fontFamily: "var(--roboto-regular)",
    fontStyle: "var(--font-style-normal)",
    fontWeight: "var(--font-weight-400)",
    fontSize: "var(--font-size-14)",
    lineHeight: "var(--line-height-20)",
    color: "var(--black-color)",
    letterSpacing: "var(--terms-sub-text-spacing)",
    padding: "0px 24px 24px 24px",
  };
});

export const TopBorder = styled.div(({ theme, variant }) => {
  let backgroundColor = "linear-gradient(92.58deg, #592C82 0%, #B655CC 100%)";
  if (variant === "warning") {
    backgroundColor = theme.palette.warning.main;
  }
  return {
    background: backgroundColor,
    height: "8px",
    top: "0px",
    marginTop: "0px",
  };
});
