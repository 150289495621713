import React from "react";
import { Typography, Box, Divider, Card } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { themePalette } from "../../../../constants/Theme/colors";
import { DifficultylIcon, TeamRecommendIcon } from "../../../../common/icons";

import { POWERUPLOGO } from "../../../../constants";
import { ContentWrapper, ContentHeader } from "./styles";
import RatingComponent from "../../../../common/rating";
import ContentDetails from "./ContentDetails";

export default function PowerUPHeader({
  valuePropsHeaderData,
  valuePropsDetails,
  powerupApiDetails,
}) {
  const getDifficultyLevel = difficultyLevel => {
    if (difficultyLevel === "EASY") {
      return "Easy";
    } else if (difficultyLevel === "MEDIUM") {
      return "Medium";
    } else {
      return "Hard";
    }
  };
  return (
    <Card size="large" variant="outlined">
      <ContentHeader>
        <Box sx={{ textAlign: "left" }}>
          <img src={POWERUPLOGO} alt="powerup logo" />
        </Box>
        <RatingComponent Readonly={true} rating={3} />
      </ContentHeader>
      <Typography sx={{ mt: 1 }} variant="h1">
        {valuePropsHeaderData.powerUpName}
      </Typography>
      <Box sx={{ mt: 2, display: { xs: "none", sm: "none", md: "block" } }}>
        <Typography variant="subhead1">
          {valuePropsHeaderData.shortDescription}
        </Typography>
      </Box>

      <Divider
        sx={{
          mt: 3,
          background: theme => theme.palette.secondary.borderLight,
          display: { xs: "block", sm: "block", md: "none" },
        }}
      />
      <Box sx={{ display: { xs: "block", sm: "block", md: "none" }, mt: 3 }}>
        <ContentDetails
          icon={
            <AccessTimeIcon sx={{ color: themePalette.palette.text.primary }} />
          }
          title={"Time Estimate"}
          description={
            valuePropsHeaderData && valuePropsHeaderData.timeEstimate
          }
          titlePadding={1}
        />

        <ContentDetails
          icon={<DifficultylIcon />}
          title={"Difficulty"}
          description={
            valuePropsHeaderData &&
            getDifficultyLevel(valuePropsHeaderData.powerUpDifficulty)
          }
          titlePadding={1.8}
        />

        <ContentDetails
          icon={<TeamRecommendIcon />}
          title={"Team Recommended"}
          description={
            valuePropsHeaderData && valuePropsHeaderData.teamRecommended
          }
          titlePadding={1.5}
        />

        <ContentDetails
          icon={
            <ErrorOutlineOutlinedIcon
              sx={{ color: themePalette.palette.text.primary }}
            />
          }
          title="Feedback Alert"
          description={
            valuePropsHeaderData &&
            valuePropsHeaderData.feedbackAlertDescription
          }
          titlePadding={1}
        />
      </Box>
    </Card>
  );
}
