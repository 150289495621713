import styled from "@emotion/styled";
import { Dialog, Box, Stack, Tab, Tabs } from "@mui/material";
import theme from "../../constants/Theme";
import { sosPrimary } from "../../constants/Theme/colors";

export const EditDialog = styled(Dialog)({
  width: "100%",
  height: "100%",

  "& .MuiDialog-paper": {
    borderRadius: "2%",
    "@media (max-width: 769px)": {
      borderRadius: theme.spacing(2),
    },
  },

  "& .MuiInputBase-root": {
    border: `1px solid ${theme.palette.secondary.textFieldBorder}`,
    boxSizing: "border-box",
    paddingLeft: "10px",
    backgroundColor: `${theme.palette.text.background}`,
    width: "100%",
    minHeight: "40px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  "& .MuiOutlinedInput-root": {
    border: "none",
    paddingLeft: "0px",
    height: "40px",
    borderRadius: "0px",
  },

  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${theme.palette.secondary.textFieldBorder}`,
  },
  "& .MuiInputBase-input": {
    borderRadius: "0px !important",
  },
  "& .MuiInputBase-input .MuiInputBase-multiline": {
    borderRadius: "0px !important",
  },
});

export const FormWrapper = styled(Box)({
  display: "flex",
  margin: theme.spacing(2),
  fontSize: "14px",
  color: `${theme.palette.text.formPrimary}`,
  backgroundColor: `${theme.palette.secondary.primary}`,
});

export const TabGroup = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: " rgba(118, 73, 160, 0.12)",
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "center",
  },
});
export const TabContainer = styled(props => <Tab disableRipple {...props} />)(
  ({ theme, type }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    width: "50%",
    color: sosPrimary[500],
    border: `1px solid ${theme.palette.secondary.dark}`,
    boxSizing: "border-box",
    borderRadius: type === "myProfile" ? "8px 0px 0px 8px" : "0px 8px 8px 0px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: sosPrimary[500],
      opacity: 1,
    },
    "&.Mui-selected": {
      color: sosPrimary[500],
      background: "rgba(118, 73, 160, 0.12)",
      fontWeight: theme.typography.fontWeightMedium,
      border: `1pc solid sosPrimary[500]`,
    },
    "&.Mui-focusVisible": {
      backgroundColor: `${theme.palette.focus.main}`,
    },
  })
);
