import styled from "@emotion/styled";

export const TimerBox = styled.div(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "center",
    gap: "16px",
    alignItems: "center",
  };
});
