import styled from "@emotion/styled";

export const PageWrapper = styled.div(({ theme }) => {
  return {
    minHeight: "100vh",
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
  };
});
export const HeaderArea = styled.div(({ theme }) => {
  return {
    background: "var(--white-color)",
  };
});
export const MainArea = styled.div(({ theme }) => {
  return {
    height: "100%",
  };
});
