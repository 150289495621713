import React from "react";
import { WORKSPACE_LEFT_SIDEBAR_ITEMS } from "../../../constants/workspace-sidebar";
import NavItemsList from "../../../common/NavItemsList/NavItemsList";
import { FABICON } from "../../../constants/image";
import { Box } from "@mui/material";

export default function BottomNavbar() {
  return (
    <Box data-tut="reactour__responsive-navigation">
      <NavItemsList listItems={WORKSPACE_LEFT_SIDEBAR_ITEMS} direction="row" />
    </Box>
  );
}
