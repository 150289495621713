import styled from "@emotion/styled";

export const PageWrapper = styled.div(({ theme }) => {
  return {
    //minHeight: "100vh",
    //display: "grid",
    //gridTemplateRows: "auto 1fr auto",
    //background: "var(--white-color)",
    //overflow: "hidden",
  };
});
