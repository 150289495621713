const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;

export const DIFFCULTY_ICON = `${cloudFrontUrl}/Icons/difficulty.svg`;
export const TEAM_RECOMMEND_ICON = `${cloudFrontUrl}/Icons/team-recommend.svg`;
export const GREEN_TICK_ICON = `${cloudFrontUrl}/Icons/green-tick.svg`;
export const PENCIL_ICON = `${cloudFrontUrl}/Icons/pencil-icon.svg`;
export const BACKARROW = `${cloudFrontUrl}/Icons/back-arrow.svg`;
export const LOGOICON = `${cloudFrontUrl}/Images/logo.svg`;
export const GOOGLEICON = `${cloudFrontUrl}/Icons/google-icon.svg`;
export const LINKEDINICON = `${cloudFrontUrl}/Icons/linkedIn-icon.svg`;
export const EYEICON = `${cloudFrontUrl}/Icons/eye-icon.svg`;
export const CLOSEDEYEICON = `${cloudFrontUrl}/Icons/close-eye-icon.svg`;
export const CHECKBOXICON = `${cloudFrontUrl}/Icons/checkbox.svg`;
export const FILLEDCHECKBOXICON = `${cloudFrontUrl}/Icons/filled-checkbox.svg`;
export const SIDEBAR_ACTIVE_ICON = `${cloudFrontUrl}/Icons/onboarding-sidebar-active.svg`;
export const SIDEBAR_COMPLETED_ICON = `${cloudFrontUrl}/Icons/onboarding-sidebar-completed.svg`;
export const SIDEBAR_INACTIVE_ICON = `${cloudFrontUrl}/Icons/onboarding-sidebar-inactive.svg`;
export const ROADRUNNERLOGO = `${cloudFrontUrl}/Icons/Roadrunner.svg`;
export const ROADRUNNERLOGOLARGE = `${cloudFrontUrl}/Icons/RoadrunnerLarge.svg`;
export const EMAILICON = `${cloudFrontUrl}/Icons/email.svg`;
export const TEXAS = `${cloudFrontUrl}/maps/us/Texas.svg`;
export const PINMARKER = `${cloudFrontUrl}/Icons/pin-marker-icon.svg`;
export const PINICON = `${cloudFrontUrl}/Icons/pin-icon.svg`;
export const USAMAP = `${cloudFrontUrl}/Images/US-map.svg`;
export const CUSTOMERICON = `${cloudFrontUrl}/Icons/customer-icon.svg`;
export const GLOWBULBICON = `${cloudFrontUrl}/Icons/blow-idea-icon.svg`;
export const CHARGEICON = `${cloudFrontUrl}/Icons/charge-icon.svg`;
export const FUNDINGICON = `${cloudFrontUrl}/Icons/funding-icon.svg`;
export const LAUNCHICON = `${cloudFrontUrl}/Icons/launch-icon.svg`;
export const SMILEICON = `${cloudFrontUrl}/Icons/smile-icon.svg`;
export const MARKETANALYSISICON = `${cloudFrontUrl}/Icons/market-analysis-icon.svg`;
export const BACKBUTTON = `${cloudFrontUrl}/Icons/back-button.svg`;
export const SOFTWAREICON = `${cloudFrontUrl}/Icons/softwareIcon.svg`;
export const HARDWAREICON = `${cloudFrontUrl}/Icons/hardware-icon.svg`;
export const MARKETPLACEICON = `${cloudFrontUrl}/Icons/markeplace-icon.svg`;
export const CONSUMERGOODSICON = `${cloudFrontUrl}/Icons/consumer-goods-icon.svg`;
export const SERVICEICON = `${cloudFrontUrl}/Icons/service-icon.svg`;
export const EGGICON = `${cloudFrontUrl}/Icons/egg.svg`;
export const CHICKICON = `${cloudFrontUrl}/Icons/chick-icon.svg`;
export const MINIRUNNERICON = `${cloudFrontUrl}/Icons/runnerIcon.svg`;
export const MINIROADRUNNERICON = `${cloudFrontUrl}/Icons/road-runner-icon.svg`;
export const COMPANYNAME = `${cloudFrontUrl}/Images/company-name.svg`;
export const FLOWERICON = `${cloudFrontUrl}/Icons/flower-icon.png`;
export const HEARTICON = `${cloudFrontUrl}/Icons/heart-icon.svg`;
export const GRITICON = `${cloudFrontUrl}/Icons/grit-icon.svg`;
export const NETWORKICON = `${cloudFrontUrl}/Icons/network-icon.svg`;
export const EXECUTIONICON = `${cloudFrontUrl}/Icons/execution-icon.svg`;
export const COMMUNITYICON = `${cloudFrontUrl}/Icons/community-icon.svg`;
export const ENERGYICON = `${cloudFrontUrl}/Icons/energy-icon.svg`;
export const EYEVISIONICON = `${cloudFrontUrl}/Icons/eye-vision-icon.svg`;
export const OVERTIMECON = `${cloudFrontUrl}/Icons/over-time-logo.svg`;
export const FULLTIMEICON = `${cloudFrontUrl}/Icons/full-time-logo.svg`;
export const PARTTIMEICON = `${cloudFrontUrl}/Icons/part-time-logo.svg`;
export const SIDEHUSTLEICON = `${cloudFrontUrl}/Icons/side-hustle-logo.svg`;
export const DASHBOARDICON = `${cloudFrontUrl}/Icons/dashboard-icon.svg`;
export const USERSICON = `${cloudFrontUrl}/Icons/users-icon.svg`;
export const WORKSPACEICON = `${cloudFrontUrl}/Icons/workspace-icon.svg`;
export const STARTUPOSCIRCLEICON = `${cloudFrontUrl}/Icons/startupos-circle-icon.svg`;
export const STARTUPOSICON = `${cloudFrontUrl}/Icons/startupos-minified-logo.svg`;
export const SETTINGICON = `${cloudFrontUrl}/Icons/setting-icon.svg`;
export const DRAWERICON = `${cloudFrontUrl}/Icons/drawer-icon.svg`;
export const TIMERICON = `${cloudFrontUrl}/Icons/timer-icon.svg`;
export const EDITICON = `${cloudFrontUrl}/Icons/edit-icon.svg`;
export const TIMERCOLONICON = `${cloudFrontUrl}/Icons/timer-colon.svg`;
export const POWERUP = `${cloudFrontUrl}/Icons/powerUp.svg`;
export const POWERUPTAM = `${cloudFrontUrl}/Images/powerup-tam.svg`;
export const NETWORKICON_GREY = `${cloudFrontUrl}/Icons/network-icon-grey.svg`;
export const NOTESICON = `${cloudFrontUrl}/Icons/notes-icon.svg`;
export const TASKSICON = `${cloudFrontUrl}/Icons/tasks-icon.svg`;
export const STARTUPOSDARKICON = `${cloudFrontUrl}/Icons/startupos-dark.svg`;
export const RADARDARKICON = `${cloudFrontUrl}/Icons/radar-dark.svg`;
export const CURRENTSTREAKDARKICON = `${cloudFrontUrl}/Icons/current-streak-dark.svg`;
export const PROGRESSDARKICON = `${cloudFrontUrl}/Icons/progress-dark.svg`;
export const LOCKICON = `${cloudFrontUrl}/Images/lock-icon.svg`;
export const BLUEDASHBOARDICON = `${cloudFrontUrl}/Icons/blue-dashboard-icon.svg`;
export const FEATUREDPARTNERICON = `${cloudFrontUrl}/Icons/featured-partner-icon.svg`;
export const VideoSampleImg = `${cloudFrontUrl}/Images/Video.svg`;
export const FABICON = `${cloudFrontUrl}/Icons/fab-icon.svg`;
export const NAVIGATIONCIRCLEICON = `${cloudFrontUrl}/Icons/navigationCircle.svg`;
export const TRACKCIRCLEICON = `${cloudFrontUrl}/Icons/trackCircle.svg`;
export const POWERUPSCIRCLEICON = `${cloudFrontUrl}/Icons/powerupsCircle.svg`;
export const CALCULATORCIRCLEICON = `${cloudFrontUrl}/Icons/calculatorCircle.svg`;
export const QUICKSTATCIRCLEICON = `${cloudFrontUrl}/Icons/quickStatCircle.svg`;
export const PARTNERCIRCLEICON = `${cloudFrontUrl}/Icons/partnerCircle.svg`;
export const STARTUPOSCIRCLESMALLICON = `${cloudFrontUrl}/Icons/startupos-circle-icon-small.svg`;
export const INVESTORSRADAR = `${cloudFrontUrl}/Images/investor-radar-img.png`;
export const STARTUPOSSCORE = `${cloudFrontUrl}/Images/startup-os-score-img.png`;
export const WORKSPACE_CARD_ICON = `${cloudFrontUrl}/Icons/workspace-blue-icon.svg`;
export const POWERUPLOGO = `${cloudFrontUrl}/Images/PowerUp Logo.svg`;
export const STARTUPOS_TRANSPARENT = `${cloudFrontUrl}/Icons/startupos-transparent-icon.svg`;
export const SPONSOREDBY = `${cloudFrontUrl}/Icons/sponsoredBy.svg`;
export const GRIDICON = `${cloudFrontUrl}/Icons/grid-icon.svg`;
export const LISTICON = `${cloudFrontUrl}/Icons/list-icon.svg`;
export const VALUE_PROPOSITION = `${cloudFrontUrl}/Images/media.svg`;
export const POWERUP_EMPTY_ICON = `${cloudFrontUrl}/Icons/powerup-empty.png`;
export const MY_STARTUP_CARD_ICON = `${cloudFrontUrl}/Icons/mystartup-card-icon.svg`;
export const STARTUP_LOGO = `${cloudFrontUrl}/Icons/startup-logo.svg`;
export const VIEW_ICON = `${cloudFrontUrl}/Icons/view-icon.svg`;
export const STEP_CURRENT = `${cloudFrontUrl}/Icons/current-step.svg`;
export const STEP_COMPLETED = `${cloudFrontUrl}/Icons/step-completed.svg`;
export const BLUE_DOT_ICON = `${cloudFrontUrl}/Icons/blue-dot.svg`;
export const NETWORK_LOGO = `${cloudFrontUrl}/Icons/network-logo-icon.svg`;
export const QUESTION_ICON = `${cloudFrontUrl}/Icons/question.svg`;
export const POWERUPKNOWLEDGELOGO = `${cloudFrontUrl}/Images/PowerUP_KnowledgeLogo.png`;
export const GREEN_CHECK_ICON = `${cloudFrontUrl}/Icons/green-check.svg`;
export const FLAG_ICON = `${cloudFrontUrl}/Icons/flag.svg`;

export const POWERUP_LOGO_WHITE = `${cloudFrontUrl}/Icons/powerup-logo-white.svg`;
export const ROADRUNNERFADEDICON = `${cloudFrontUrl}/Icons/roadrunner-faded.svg`;
export const STARTUPOSFADEDICON = `${cloudFrontUrl}/Icons/startupOS-faded.svg`;
export const POWERUP_IMAGE = `${cloudFrontUrl}/Images/dummy_card.svg`;
export const STARTUPOSFILLEDICON = `${cloudFrontUrl}/Icons/startupOS-filled-icon.svg`;
export const MINIROADRUNNERFILLED = `${cloudFrontUrl}/Icons/mini-roadrunner-filled.svg`;

export const ACTIVE_POWER_UP = `${cloudFrontUrl}/Icons/active-powerup-new.svg`;
export const ACTIVE_PLUGIN = `${cloudFrontUrl}/Icons/active-plugin-new.svg`;
export const ACTIVE_FUSION = `${cloudFrontUrl}/Icons/active-fusion.svg`;
export const ACTIVE_DEMODAY = `${cloudFrontUrl}/Icons/active-demoday.svg`;

export const COMPLETED_FUSION = `${cloudFrontUrl}/Icons/completed-fusion.svg`;
export const COMPLETED_PLUGIN = `${cloudFrontUrl}/Icons/completed-plugin.svg`;

export const DEFAULT_POWER_UP = `${cloudFrontUrl}/Icons/default-powerup.svg`;
export const DEFAULT_PLUGIN = `${cloudFrontUrl}/Icons/default-plugin.svg`;
export const DEFAULT_FUSION = `${cloudFrontUrl}/Icons/default-fusion.svg`;
export const DEFAULT_DEMO_DAY = `${cloudFrontUrl}/Icons/default-demoday.svg`;
export const DEMO_DAY_LOGO = `${cloudFrontUrl}/Icons/Demoday_Logo_Black.svg`;
export const FUSION_LOGO = `${cloudFrontUrl}/Icons/Fusion_Logo_Black.svg`;
export const PLUGIN_LOGO = `${cloudFrontUrl}/Icons/PlugIn_Logo_Black.svg`;
export const COMPLETED_DEFAULT = `${cloudFrontUrl}/Icons/completed-default.svg`;
export const FILLED_POWERUP = `${cloudFrontUrl}/Icons/filled-powerup.svg`;
export const PROFILE_PLACE_HOLDER = `${cloudFrontUrl}/Images/profile-placeholder.svg`;
export const AVATAR_HOVER = `${cloudFrontUrl}/Icons/avatar-hover.svg`;
export const AVATAR_FOCUS = `${cloudFrontUrl}/Icons/avatar-focus.svg`;
export const AVATAR_DEFAULT = `${cloudFrontUrl}/Icons/avatar-default.svg`;
export const AVATAR_ENABLED = `${cloudFrontUrl}/Icons/avatar-enabled.svg`;

export const PHONE_ICON = `${cloudFrontUrl}/Icons/phone.svg`;
export const EMAIL_ICON = `${cloudFrontUrl}/Icons/email-icon.svg`;
export const LIKE_ICON = `${cloudFrontUrl}/Icons/like-icon.svg`;
export const TEXT_ICON = `${cloudFrontUrl}/Icons/text-icon.svg`;
export const SMILE_ICON = `${cloudFrontUrl}/Icons/smile-icon.svg`;

export const AVATAR_1 = `${cloudFrontUrl}/Images/avatar1.svg`;
export const AVATAR_2 = `${cloudFrontUrl}/Images/avatar2.svg`;
export const AVATAR_3 = `${cloudFrontUrl}/Images/avatar3.svg`;
export const AVATAR_4 = `${cloudFrontUrl}/Images/avatar4.svg`;
export const AVATAR_5 = `${cloudFrontUrl}/Images/avatar5.svg`;
export const AVATAR_6 = `${cloudFrontUrl}/Images/avatar6.svg`;
export const AVATAR_7 = `${cloudFrontUrl}/Images/avatar7.svg`;
export const AVATAR_8 = `${cloudFrontUrl}/Images/avatar8.svg`;
export const AVATAR_9 = `${cloudFrontUrl}/Images/avatar9.svg`;
export const AVATAR_10 = `${cloudFrontUrl}/Images/avatar10.svg`;
export const AVATAR_11 = `${cloudFrontUrl}/Images/avatar11.svg`;
export const AVATAR_12 = `${cloudFrontUrl}/Images/avatar12.svg`;
export const AVATAR_13 = `${cloudFrontUrl}/Images/avatar13.svg`;
export const AVATAR_14 = `${cloudFrontUrl}/Images/avatar14.svg`;
export const AVATAR_15 = `${cloudFrontUrl}/Images/avatar15.svg`;
export const AVATAR_16 = `${cloudFrontUrl}/Images/avatar16.svg`;
export const AVATAR_17 = `${cloudFrontUrl}/Images/avatar17.svg`;
export const AVATAR_18 = `${cloudFrontUrl}/Images/avatar18.svg`;
export const FILE_DOWNLOAD = `${cloudFrontUrl}/Icons/file_download.svg`;
export const RESIZE_ICON = `${cloudFrontUrl}/Icons/resize-icon.svg`;
export const TEMPLATE_1_HEADER = `${cloudFrontUrl}/Images/Templates/Template1/Template-1-header.svg`;
export const TEMP1_IMG1 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 02.svg`;
export const TEMP1_IMG2 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 03.svg`;
export const TEMP1_IMG3 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 04.svg`;
export const TEMP1_IMG4 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 05.svg`;
export const TEMP1_IMG5 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 06.svg`;
export const TEMP1_IMG6 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 07.svg`;
export const TEMP1_IMG7 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 08.svg`;
export const TEMP1_IMG8 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 09.svg`;
export const TEMP1_IMG9 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 10.svg`;
export const TEMP1_IMG10 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 11.svg`;
export const TEMP1_IMG11 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 12.svg`;
export const TEMP1_IMG12 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 13.svg`;
export const TEMP1_IMG13 = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1 - 14.svg`;
export const TEMPLATE_2_HEADER = `${cloudFrontUrl}/Images/Templates/Template2/Template-2-header.svg`;
export const TEMP2_IMG1 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 02.svg`;
export const TEMP2_IMG2 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 03.svg`;
export const TEMP2_IMG3 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 04.svg`;
export const TEMP2_IMG4 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 05.svg`;
export const TEMP2_IMG5 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 06.svg`;
export const TEMP2_IMG6 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 07.svg`;
export const TEMP2_IMG7 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 08.svg`;
export const TEMP2_IMG8 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 09.svg`;
export const TEMP2_IMG9 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 10.svg`;
export const TEMP2_IMG10 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 11.svg`;
export const TEMP2_IMG11 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 12.svg`;
export const TEMP2_IMG12 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 13.svg`;
export const TEMP2_IMG13 = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2 - 14.svg`;
export const TEMPLATE_3_HEADER = `${cloudFrontUrl}/Images/Templates/Template3/Template-3-header.svg`;
export const TEMP3_IMG1 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 02.svg`;
export const TEMP3_IMG2 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 03.svg`;
export const TEMP3_IMG3 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 04.svg`;
export const TEMP3_IMG4 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 05.svg`;
export const TEMP3_IMG5 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 06.svg`;
export const TEMP3_IMG6 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 07.svg`;
export const TEMP3_IMG7 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 08.svg`;
export const TEMP3_IMG8 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 09.svg`;
export const TEMP3_IMG9 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 10.svg`;
export const TEMP3_IMG10 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 11.svg`;
export const TEMP3_IMG11 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 12.svg`;
export const TEMP3_IMG12 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 13.svg`;
export const TEMP3_IMG13 = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3 - 14.svg`;
export const TEMP1_FILE = `${cloudFrontUrl}/Images/Templates/Template1/StartupOS - Brandstorming Template 1.pptx`;
export const TEMP2_FILE = `${cloudFrontUrl}/Images/Templates/Template2/StartupOS - Brandstorming Template 2.pptx`;
export const TEMP3_FILE = `${cloudFrontUrl}/Images/Templates/Template3/StartupOS - Brandstorming Template 3.pptx`;
export const INTERVIEWING_ICON = `${cloudFrontUrl}/Icons/interviewing.svg`;
export const NOTES_ICON = `${cloudFrontUrl}/Icons/notes.svg`;
export const PROFILE_PLACEHOLDER = `${cloudFrontUrl}/Icons/profile-placeholder.svg`;
export const BROWSE_CANDIDATE_ICON = `${cloudFrontUrl}/Icons/browse-candidate.svg`;
export const JOURNEY_SMILE_ICON = `${cloudFrontUrl}/Icons/journey-smile-icon.svg`;
export const POWERUP_IMG = `${cloudFrontUrl}/Images/Powerup_PNG.png`;
export const PROFILE_PLACEHOLDER_PNG = `${cloudFrontUrl}/Images/profile-placeholder-png.png`;
