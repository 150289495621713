import services from "../../services";
import * as actionTypes from "./actionTypes";

export const setPowerUpExcercises = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_POWERUP_ExcerciseS,
      payload,
    });
  };
};

export const setInterviewScriptDialog = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_INTERVIEW_SCRIPT_DIALOG,
      payload,
    });
  };
};

export const setPowerupDetails = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_POWERUP_DETAILS,
      payload,
    });
  };
};

export const setInterviewCandidates = payload => {
  return dispatch => {
    dispatch({
      type: actionTypes.SET_INTERVIEW_CANDIDATES,
      payload,
    });
  };
};
