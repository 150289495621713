import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import DefaultLayout from "../layout/Default";
import WorkspaceLayout from "../layout/Workspace";
import ROUTES_PATH from "../constants/routes";
import OnboardingLayout from "../layout/Onboarding";
import { useLocation } from "react-router-dom";

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  /**
   * As a non authed user (not logged in / login token not valid)
   * If they try to access a private route (one requiring authentication)
   * Then redirect user to non private route -> ie. 'Home Page', 'Sign In', etc...
   *
   * Ex:
   *    if (isPrivate && !signed) {
   *        return <Redirect to="/" />;
   *    }
   */

  /**
   * As an authed user (already logged in)
   * If they try to access a non private route ie. Homepage or Sign In
   * Redirect them to main page as an authed user -> ie. 'dashboard', 'profile' etc...
   *
   * Ex:
   *    if (!isPrivate && signed) {
   *        return <Redirect to="/dashboard" />;
   *    }
   */

  /**
   * If when we have pages that require use of a different layout other than default.
   * We will implemnt some function to determine which layout file to load.
   * Right now we only have one so it is static set to DefauletLayout.
   *
   * Ex:
   * const Layout = signed ? <<Different Layout File>> : DefaultLayout;
   */

  const authData = useSelector(state => state?.auth);

  const signed = authData?.idToken;

  const location = useLocation();

  if (isPrivate && !signed) {
    return <Redirect to="/login" />;
  }

  if (!isPrivate && authData.authenticatedAppUser && signed) {
    const userData = authData?.userData;

    if (userData?.email && userData?.email_verified)
      try {
        window.pendo.updateOptions({
          account: {
            id:
              typeof userData !== "undefined" && userData.email
                ? userData.email
                : "", // Required if user is logged in
            email:
              typeof userData !== "undefined" && userData.email
                ? userData.email
                : "", // Recommended if using Pendo Feedback, or NPS Email
            name:
              typeof userData !== "undefined" && userData.name
                ? userData.name
                : "", // Recommended if using Pendo Feedback
          },
        });
      } catch (e) {
        console.log("Encountered an error when trying to init Pendo. " + e);
      }
    return <Redirect to={ROUTES_PATH.WORKSPACE} />;
  }

  let Layout = DefaultLayout;

  if (signed) {
    Layout = WorkspaceLayout;
  }

  if (
    location &&
    location.pathname &&
    location.pathname.includes("onboarding")
  ) {
    Layout = OnboardingLayout;
  } else if (location && location.state && location.state.onboarding) {
    Layout = OnboardingLayout;
  }

  if (
    window.location.pathname.includes("authorize") ||
    window.location.pathname.includes("success")
  ) {
    Layout = Component;
  }

  if (
    (location.pathname.includes("email-verification") ||
      location.pathname.includes("social-signup")) &&
    signed
  ) {
    Layout = ({ children }) => (
      <div style={{ height: "100vh" }}>{children}</div>
    );
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};
RouteWrapper.defaultProps = {
  isPrivate: false,
};
