import React, { useState, useEffect } from "react";
import { Typography, Box, Button, Grid, Link } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import theme from "../../../../constants/Theme";

export default function PresentationTemplate({
  data,
  handleInputChange,
  setSelected,
  selected,
  handlePreview,
  setPreviewIndex,
}) {
  const handleTemplateSelection = () => {
    setSelected(data.id);
    handleInputChange(data.id, "template");
  };

  const handlePreviewAction = () => {
    setPreviewIndex(data.id);
    handlePreview();
  };

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "center",
        gap: { xs: 2, md: 0 },
        alignItems: "center",

        p: 1,
      }}
    >
      <Grid item sx={12} md={12}>
        <Box
          sx={{
            maxHeight: "175px",
            width: 1,
            border:
              selected === data.id
                ? "5px solid rgba(118, 73, 160, 0.5);"
                : "1px solid rgba(124, 117, 127, 0.16)",
            borderRadius: 4,
            cursor: "pointer",
            backgroundColor: theme.palette.secondary.white,
            "&:hover": {
              border: "5px solid rgba(118, 73, 160, 0.5);",
              borderRadius: 4,
            },
          }}
        >
          {selected === data.id && (
            <CheckCircleIcon
              sx={{
                display: "flex",
                fill: theme.palette.text.readOnlyGreen,
                float: "right",
                pr: 1,
                pt: 1,
              }}
            />
          )}
          <Box
            component="img"
            src={data.headerImage}
            onClick={handleTemplateSelection}
            sx={{
              maxWidth: 1,
              maxHeight: "125px",
              width: 1,
              borderRadius: 4,
            }}
          ></Box>
        </Box>
      </Grid>
      <Grid
        item
        sx={12}
        md={12}
        sx={{
          display: "flex",
          flexDirection: { md: "row" },
          justifyContent: "space-between",
          pt: 2,
          gap: { xs: 10, md: 0 },
        }}
      >
        <Grid item sx={6} md={6}>
          <Typography variant="button_link">{data.name}</Typography>
        </Grid>
        <Grid item sx={6} md={6} sx={{ textAlign: "right" }}>
          <Link
            component="button"
            variant="button_link"
            underline="none"
            onClick={handlePreviewAction}
          >
            Preview
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
}
