import React from "react";
import { Box, Breadcrumbs, Link, Typography, IconButton } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../../constants/routes";

export default function WorkspaceBreadCrumbs({ subpath }) {
  const history = useHistory();

  const handleWorkspaceNavigation = () => {
    history.push(ROUTES_PATH.WORKSPACE);
  };
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <IconButton aria-label="ArrowBack" color="primary">
        <ArrowBack />
      </IconButton>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon sx={{ ml: 1.5, mr: 1 }} />}
        sx={{ display: { xs: "none", sm: "none", md: "block" } }}
      >
        <Link
          underline="hover"
          color="primary"
          component="button"
          onClick={() => {
            handleWorkspaceNavigation();
          }}
        >
          <Typography color="primary" variant="h5">
            Workspace
          </Typography>
        </Link>
        <Typography color="text.primary" variant="h5">
          {subpath}
        </Typography>
      </Breadcrumbs>
      <Link
        underline="hover"
        color="primary"
        component="button"
        onClick={() => {
          handleWorkspaceNavigation();
        }}
        sx={{
          display: { sm: "block", md: "none", lg: "none", xl: "none" },
          py: 2,
        }}
      >
        <Typography color="primary" variant="h5">
          Workspace
        </Typography>
      </Link>
    </Box>
  );
}
