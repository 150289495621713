import React, { Fragment, useEffect } from "react";
import {
  Box,
  Card,
  CardMedia,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import VideoPlayerComponent from "../../../../../common/videoPlayer";

export const InspirationTab = ({ ...props }) => {
  const [tab, setTab] = React.useState(props.tab);
  useEffect(() => {
    setTab(props.tab);
  }, [props.tab]);
  return (
    <Fragment>
      {tab && tab.name && (
        <>
          <Typography
            variant="title_medium"
            sx={{ textTransform: "capitalize", my: 2 }}
          >
            {tab.name}
          </Typography>
          <Divider />
        </>
      )}
      <Box
        sx={{
          boxSizing: "border-box",
          flexGrow: 1,
          overflow: "scroll",
          my: 2,
          pb: 2,
        }}
      >
        <Box sx={{ mb: 4 }}>
          <Typography variant="title_small">Related Media</Typography>
        </Box>
        <Stack spacing={4}>
          {tab.media.map((media, index) => (
            <Box key={index}>
              {media && media.type && media.type === "image" && (
                <Card
                  sx={{
                    borderRadius: "16px",
                    boxShadow: "none",
                  }}
                >
                  <CardMedia component={"img"} image={media.content_url} />
                </Card>
              )}
              {media && media.type && media.type === "video" && (
                <Box
                  sx={{
                    borderRadius: "16px",
                    boxShadow: "none",
                    overflow: "hidden",
                    "& > div": {
                      display: "flex",
                    },
                  }}
                >
                  <VideoPlayerComponent videoUrl={media.content_url} />
                </Box>
              )}
              <Box sx={{ my: 2 }}>
                <Typography variant="label_medium">{media.title}</Typography>
              </Box>
            </Box>
          ))}
        </Stack>
      </Box>
    </Fragment>
  );
};
