import React, { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import AvatarPicker from "../../../../../../../common/IconPicker";
import messages from "../../../../../../../constants/messages";
import { AVATARS } from "../../../../../../../constants/powerup-data";

export default function NameAndAvatar({
  userInputs,
  setUserInputs,
  nameAvatarInputValid,
}) {
  const [errorMessage, setErrorMessage] = useState({
    personaName: "",
    avatar: "",
  });

  const handleTextInputChange = e => {
    setUserInputs({ ...userInputs, personaName: e.target.value });
  };

  const handleBlur = e => {
    const value = e.target.value;
    if (!nameAvatarInputValid("personaName", value)) {
      setErrorMessage({
        ...errorMessage,
        personaName: messages.PERSONANAME_INPUT_INVALID,
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        personaName: "",
      });
    }
  };

  const selectAvatar = value => {
    setUserInputs({ ...userInputs, avatar: value });
  };

  return (
    <Box>
      <Typography variant="h5">Name your persona</Typography>
      <TextField
        label="Name"
        fullWidth
        sx={{ mt: 2 }}
        onChange={e => handleTextInputChange(e)}
        onBlur={e => handleBlur(e)}
        error={!!errorMessage.personaName}
        value={userInputs?.personaName}
        helperText={errorMessage.personaName}
      />
      <Box sx={{ mt: 10 }}>
        <Typography variant="h5">Pick your avatar</Typography>
        <AvatarPicker
          selectedIcon={userInputs?.avatar}
          setSelectedIcon={selectAvatar}
          icons={AVATARS}
          iconHeight={"72px"}
          iconWidth={"72px"}
          imageStyle={{ borderRadius: "50px" }}
        />
      </Box>
    </Box>
  );
}
