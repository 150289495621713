export default {
  USER_ALREADY_EXISTS: "User already exists",
  EMAIL_ADDRESS_UPDATED: "Email address updated",
  RESET_PASSWORD: "Reset Password email successfully sent",
  VERIFICATION_EMAIL: "Verification email successfully sent",
  COMPANY_NAME_LENGTH_INVALID: "Name should be between 2-25 characters",
  COMPANY_NAME_CHARACTERS_INVALID: "Invalid characters only (,.& ) are allowed",
  AUTOCOMPLETE_INPUT_INVALID:
    "Input should be between 2-{{maxLength}} characters",
  LOCATION_INVALID:
    "StartupOS is currently open to founders building companies headquartered in the United States. Applications for companies based outside of the US is expected to open later this year.",
  UPLOAD_SIZE: "Maximum upload size exceeded.",
  COMPANY_NAME_BLANK: "Please enter company name",
  TAGLINE_BLANK: "Please enter tagline",
  PRODUCT_OFFERING_BLANK: "Please enter product offering",
  LOCATION_BLANK: "Please enter the location",
  STARTUP_SIZE_BLANK: "Please enter startup size",
  FOUNDING_DATE_BLANK: "Please enter valid founding date",
  FOUNDING_DATE_INVALID: "Please enter valid year",
  COMPANY_SITE_BLANK: "Please enter company site",
  ABOUT_BLANK: "Please enter about",
  LOGO_BLANK: "Please attach logo",
  MULTITAG_INPUT_INVALID:
    "Invalid input, should contain 1-30 characters no special characters allowed",
  UNSAVED_CHANGES_PROMPT_MESSAGE:
    "Are you sure you want to discard the changes you made?",
  EMAIL_VERIFIED: "Email verified successfully. Please login again.",
  API_SUBMIT_ERROR: "Something went wrong!",
  EXCERCISE_STORE_ERROR: "Error while storing excercise details",
  TEXTBOX_VALIDATION: "Answer should be between 30-300 characters",
  LOCATION_INVALID: "Please enter valid zip code",
  ACV_INPUT_INVALID: "Invalid Input, expected is $1000 & above",
  TAM_SAM_SOM_TEXTFIELD: "Input should between 4-30 characters",
  PERSONANAME_INPUT_INVALID: "Name should be between 3-300 characters",
  PERSONA_AVATAR_INVALID: "Please select Avatar",
  FILE_UPLOAD_ERROR: "File upload fail, please try again",
  NAME_VALID: "Please enter valid name",
  INVALID_EMAIL: "Please enter valid email",
  EVENT_SCHEDULED_SUCCESS: "Event scheduled successfully",
  EVENT_SCHEDULED_ERROR: "Error scheduling event",
};
