import {
  Typography,
  Box,
  Divider,
  Grid,
  Stack,
  Card,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import _debounce from "lodash/debounce";
import { CSVLink } from "react-csv";
import theme from "../../../../../../constants/Theme";
import WorkspaceBreadCrumbs from "../../../../../../components/Workspace/BreadCrumbs";
import FormSectionHeader from "../../../../../../components/Workspace/PowerUp/FormSectionHeader";
import SummaryStepList from "../../../../../../components/Workspace/PowerUp/SummaryStepList";
import constants from "../../../../../../constants";
import { FileDownload, GreenTicklIcon } from "../../../../../../common/icons";
import Tabs from "../../../../../../components/Workspace/Tabs";
import KnowledgeSidebar from "../../../../../../components/Workspace/PowerUp/KnowledgeSidebar";
import { connect } from "react-redux";
import * as actions from "../../../../../../modules/actions";
import RatingCard from "../../../../../../components/Workspace/PowerUp/Rating";

import SelfServiceSubscriptionsTable from "./Components/SelfServiceSubscriptionsTable";
import { getFormattedDate } from "../../../../../../utils/date";

import numeral from "numeral";
import services from "../../../../../../services";

function RevenueModelerSummary({ powerUpExcercises, setPowerUpExcercises }) {
  const tabItems = constants.SUMMARY_TAB_ITEMS;
  const history = useHistory();
  const params = useParams();
  const moduleId = parseInt(params.moduleId);

  const [currentTab, setCurrentTab] = useState(tabItems[0].key);

  const [currentModule, setCurrentModule] = useState(null);

  const [version, setVersion] = useState(null);

  const [subscription, setSubscription] = useState({
    arpu: 0,
    primarilyBill: "yearly",
    expectedLaunchDate: new Date(),
    churnRate: 2,
    expectedLifetimeValue: 0,
    businessType: "b2b",
  });

  const getModuleStepResponsesApiCall = () => {
    services
      .getModuleStepResponses(moduleId)
      .then(result => {
        const moduleStepsResponses = result.data.data;

        const currentModuleResponse = moduleStepsResponses[0];
        const lastStep = currentModuleResponse.moduleStepResponses.find(
          step => step.moduleStep.stepNo === 2
        );
        setCurrentModule({
          ...lastStep,
          answers: JSON.parse(lastStep.answers),
          responseId: currentModuleResponse.id,
        });

        const currentAnswer = JSON.parse(lastStep.answers).summaryObj;
        setSubscription({
          ...subscription,
          arpu: currentAnswer.subscriptionPrice,
          primarilyBill: currentAnswer.billingCadence,
          expectedLaunchDate: currentAnswer.launchDate || new Date(),
          churnRate:
            currentAnswer.businessType?.toLowerCase() === "b2b" ? 2 : 7,
          businessType: currentAnswer.businessType?.toLowerCase(),
        });
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setCurrentTab(tabItems[0].key);
    getModuleStepResponsesApiCall();
  }, []);

  const handleSave = () => {
    const answer = {
      ...currentModule.answers,
      summaryObj: {
        ...currentModule.answers.stepObj,
        subscriptionPrice: subscription.arpu,
        billingCadence: subscription.primarilyBill,
        launchDate: subscription.expectedLaunchDate,
      },
    };
    const data = {
      moduleResponseId: currentModule.responseId,
      stepNo: 2,
      answers: JSON.stringify(answer),
      isLastStep: true,
      isCompleted: true,
      moduleStepId: currentModule.moduleStep?.id,
    };
    updateModuleStepResponseApiCall(data);
  };

  useEffect(() => {
    let expectedLifetimeValue = 0;

    const formattedArpuValue = parseInt(numeral(subscription.arpu).value());

    if (formattedArpuValue > 0) {
      if (subscription.primarilyBill === "Monthly") {
        expectedLifetimeValue = parseFloat(
          formattedArpuValue / (subscription.churnRate / 100)
        );
      } else if (subscription.primarilyBill === "Quarterly") {
        expectedLifetimeValue = parseFloat(
          formattedArpuValue / (subscription.churnRate / 100 / 4)
        );
      } else if (subscription.primarilyBill === "Yearly") {
        expectedLifetimeValue = parseFloat(
          formattedArpuValue / (subscription.churnRate / 100 / 12)
        );
      }

      if (currentModule) {
        const debouncedSave = _debounce(() => handleSave(), 2000);
        debouncedSave();
      }
    }

    setSubscription({
      ...subscription,
      expectedLifetimeValue: expectedLifetimeValue,
    });
  }, [
    subscription.arpu,
    subscription.primarilyBill,
    subscription.expectedLaunchDate,
  ]);

  const getNotesChurnRate = () => {
    if (subscription.businessType === "b2b") {
      return "Most B2B companies aim for 0-2% monthly churn. We’ve assigned you the average, 2%.";
    } else if (subscription.businessType === "b2c") {
      return "Most B2C companies aim for 6-8% monthly churn. We’ve assigned you the average, 7%.";
    }
  };

  const tableHeaders = [
    { label: "Business Model", key: "businessModel" },
    { label: "Output", key: "output" },
    { label: "Notes", key: "notes" },
  ];

  const tableData = [
    {
      businessModel: "Average Monthly Subscription Price (ARPU)",
      output: "$" + subscription.arpu,
      notes:
        "The average revenue per user, per month. If you have multiple plans, provide an average of the plan price.",
    },

    {
      businessModel: "How you will primarily bill your customers",
      output: subscription.primarilyBill.toUpperCase(),
      notes: "",
    },
    {
      businessModel: "Expected Launch",
      output: getFormattedDate(subscription.expectedLaunchDate, "MM/DD/YYYY"),
      notes: "",
    },
    {
      businessModel: "Expected Yearly Churn Rate",
      output: subscription.churnRate + "%",
      notes: getNotesChurnRate(),
    },
    {
      businessModel: "Expected Lifetime Value",
      output: "$" + subscription.expectedLifetimeValue,
      notes: "ARPU divided by (Expected Yearly Churn Rate divided by 12)",
    },
  ];

  const updateModuleStepResponseApiCall = data => {
    services
      .storeModuleStepResponses(moduleId, data)
      .then(result => {})
      .catch(error => console.log(error));
  };

  return (
    <>
      {" "}
      <Stack
        sx={{
          minHeight: "100%",
          borderRadius: "28px",
          maxWidth: "100vw",
          p: 4,
          [theme.breakpoints.down("md")]: {
            p: 0,
            borderRadius: 0,
          },
          background:
            "linear-gradient(0deg, rgba(118, 73, 160, 0.05), rgba(118, 73, 160, 0.05)), #FCFCFC",
        }}
      >
        <Grid
          container
          sx={{
            maxWidth: theme.breakpoints.values.xl,
            margin: "auto",
          }}
          flexGrow={1}
          flexDirection="column"
        >
          <WorkspaceBreadCrumbs subpath="Revenue Modeler" />
          <Box
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              mt: 3,
              mx: 2,
            }}
          >
            <Tabs
              tabItems={tabItems}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              color="primary"
              spacing={0}
              buttonWidth="50%"
              width="100%"
            />
          </Box>
          <Grid container sx={{ minHeight: "100%", mb: 10 }}>
            <Grid
              item
              md={8}
              sm={12}
              xs={12}
              sx={theme => ({
                [theme.breakpoints.up("md")]: {
                  borderRight: `1px solid ${theme.palette.secondary.main}`,
                  pr: 3,
                  mt: 4,
                },
                [theme.breakpoints.down("md")]: {
                  p: 2,
                },
                flexDirection: "column",
                minHeight: "100%",
                display: {
                  sm: currentTab === "excercise" ? "block" : "none",
                  xs: currentTab === "excercise" ? "block" : "none",
                  md: "flex",
                },
              })}
            >
              <Card
                sx={{
                  borderRadius: "28px",
                  border: `1px solid ${theme.palette.secondary.dark}`,
                  p: 3,
                  display: "flex",
                  flexDirection: "column",
                  [theme.breakpoints.down("md")]: {
                    background: "transparent",
                    border: "none",
                    boxShadow: "0",
                    p: 1,
                    mt: 2,
                  },
                }}
              >
                <FormSectionHeader heading="REVENUE MODELER" />
                <Divider
                  sx={{
                    background: theme => theme.palette.secondary.dark,
                    [theme.breakpoints.down("md")]: {
                      background: theme => theme.palette.secondary.borderLight,
                    },
                  }}
                />
                <Box sx={{ display: "flex", mt: 3, alignItems: "center" }}>
                  <Box
                    sx={{
                      mr: 2,
                    }}
                  >
                    <GreenTicklIcon />
                  </Box>

                  <Typography variant="h3">Completed!</Typography>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <SummaryStepList
                    StepsData={constants.REVENUE_MODELER_STEPS}
                  />
                </Box>

                <Box
                  flexGrow={1}
                  flexDirection={"column"}
                  sx={{ maxWidth: "100%" }}
                >
                  <Box
                    sx={{
                      mt: 3,
                    }}
                  >
                    <Typography variant="largeTitle">Summary</Typography>
                  </Box>

                  <Box display={"grid"} sx={{ mt: 1 }}>
                    <Typography variant="subhead1">
                      Here’s a summary of the work you’ve completed in this
                      PowerUp. You can take this as many times as you’d like and
                      edit whatever you’ve already completed. Don’t forget to
                      get feedback on your completed PowerUp.
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems={"center"} sx={{ mt: 4 }}>
                    <Typography variant="title_medium">
                      Self-Service Subscriptions
                    </Typography>

                    <Box
                      sx={{ ml: "auto", display: { xs: "none", md: "block" } }}
                    >
                      <CSVLink
                        data={tableData}
                        headers={tableHeaders}
                        className="disable-hyperlink"
                        filename="Self-Service Subscriptions.csv"
                      >
                        <Button
                          variant="outlined"
                          color="primary"
                          sx={{
                            borderRadius: 2,
                            border: `1px solid ${theme.palette.secondary.dark}`,
                            width: "max-content",
                            textDecoration: "none",
                          }}
                        >
                          <FileDownload />
                          <Typography
                            variant="title_small"
                            sx={{ pl: 1, textDecoration: "none" }}
                          >
                            Download
                          </Typography>
                        </Button>
                      </CSVLink>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mt: 4,
                    }}
                  >
                    <SelfServiceSubscriptionsTable
                      subscription={subscription}
                      setSubscription={setSubscription}
                      getNotesChurnRate={getNotesChurnRate}
                    />
                  </Box>

                  <Box
                    sx={{
                      mt: 4,
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    <CSVLink
                      data={tableData}
                      headers={tableHeaders}
                      className="disable-hyperlink"
                      filename="Self-Service Subscriptions.csv"
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{
                          borderRadius: 2,
                          border: `1px solid ${theme.palette.secondary.dark}`,
                          width: "max-content",
                          textDecoration: "none",
                        }}
                      >
                        <FileDownload />
                        <Typography
                          variant="title_small"
                          sx={{ paddingLeft: 1, textDecoration: "none" }}
                        >
                          Download
                        </Typography>
                      </Button>
                    </CSVLink>
                  </Box>

                  <Divider
                    sx={{
                      background: theme => theme.palette.secondary.dark,
                      mt: 6,
                      [theme.breakpoints.down("md")]: {
                        background: theme =>
                          theme.palette.secondary.borderLight,
                      },
                    }}
                  />
                  <Box
                    sx={{
                      mt: 3,
                      display: { sm: "none", xs: "none", md: "flex" },
                    }}
                  >
                    <Box sx={{ ml: "auto" }}>
                      <Button variant="outlined">Rate PowerUp</Button>
                    </Box>
                    <Box sx={{ ml: 2 }}>
                      <Button
                        onClick={() => {
                          history.push("/workspace");
                        }}
                      >
                        Return to Workspace Home
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: { sm: "block", xs: "block", md: "none" },
                      background: theme.palette.secondary.white,
                      borderRadius: "28px",
                      p: 3,
                      mt: 3,
                    }}
                  >
                    <Typography variant="title_medium">
                      Want feedback?
                    </Typography>
                    <Box sx={{ mt: 3 }}>
                      <Typography variant="subhead2">
                        Your StartupOS Advisor is here to help if you would like
                        feedback for this completed PowerUp.
                      </Typography>
                    </Box>
                    <Box sx={{ textAlign: "center", mt: 4 }}>
                      <Button>Submit for feedback</Button>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Grid>

            <KnowledgeSidebar currentTab={currentTab} />

            <Box
              sx={{
                display: {
                  sm: currentTab === "rate" ? "flex" : "none",
                  xs: currentTab === "rate" ? "flex" : "none",
                  md: "none",
                },
                mt: 3,
                ml: 2,
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  mr: 2,
                }}
              >
                <GreenTicklIcon />
              </Box>

              <Typography variant="h3">Completed!</Typography>
            </Box>
            <RatingCard currentTab={currentTab} />
          </Grid>
          <Box
            sx={{
              background: theme.palette.secondary.white,
              position: "fixed",
              bottom: 72,
              width: 1,
              textAlign: "center",
              py: 2,
              display: { sm: "block", xs: "block", md: "none" },
            }}
          >
            <Button
              onClick={() => {
                history.push("/workspace");
              }}
            >
              Return to Workspace Home
            </Button>
          </Box>
        </Grid>
      </Stack>
    </>
  );
}

const mapStoreToProps = ({ powerUp }) => ({
  powerUpExcercises: powerUp?.powerUpExcercises,
});

const mapDispatchToProps = dispatch => ({
  setPowerUpExcercises: updatedSteps =>
    dispatch(actions.setPowerUpExcercises(updatedSteps)),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(RevenueModelerSummary);
