import React, { useState, useEffect } from "react";
import theme from "../../../../../constants/Theme";
import { Button, Box } from "@mui/material";
import * as actions from "../../../../../modules/actions";
import { connect } from "react-redux";
import { useParams, useHistory, Prompt } from "react-router-dom";
import services from "../../../../../services";
import RouterPrompt from "../../../../../common/RouterPrompt";
import messages from "../../../../../constants/messages";
import constants from "../../../../../constants";
import { toastContainer } from "../../../../../utils/toast";
import LoadingButton from "../../../../../common/Loadingbutton";
import ExcerciseWrapper from "../../../../../components/Workspace/PowerUp/ExcerciseWrapper";
import ExcerciseSection from "../../../../../components/Workspace/PowerUp/ExcerciseSection";
import KnowledgeSidebar from "../../../../../components/Workspace/PowerUp/KnowledgeSidebar";
import { GraphWrapper } from "./styles";
import Evaluate from "./Evaluate";
import AdaptAdjust from "./AdaptAdjust";
import {
  getExcerciseConfigurations,
  getExcerciseCompletionStatus,
  getDefaultInputStates,
  evaluateInputValid,
  adaptAdjestInputValid,
  buttonDisable,
} from "./functions";
import Header from "./Components/Header";
import { handleButtonDisable } from "../../powerupFunctions";

function RevenueModler({ powerUpExcercises, setPowerUpExcercises }) {
  const [userInputs, setUserInputs] = useState(null);

  const [currentStage, setCurrentStage] = useState();
  const [currentExcercise, setCurrentExcercise] = useState();
  const [excerciseSteps, setExcerciseSteps] = useState([]);
  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const params = useParams();
  const history = useHistory();
  const excerciseParam = params.excercise;
  const [excerciseConfigs, setExcerciseConfigs] = useState();
  const tabItems = constants.EXCERCISE_TAB_ITEMS;
  const [currentTab, setCurrentTab] = useState(tabItems[0].key);
  const [headerExerciseDetails, setHeaderExerciseDetails] = useState([]);
  const moduleId = params.id;
  const [version, setVersion] = useState();

  const handleBackClick = () => {
    if (currentExcercise.stepNo === 1) {
      //send to details page
      history.push(`/workspace/powerup/9`);
    } else {
      setUserInputs([]);
      let currentStep = excerciseSteps.find(
        excercise => excercise.stepNo === currentExcercise.stepNo - 1
      );
      const newExcerciseData = excerciseConfigs.excercises.find(
        excercise => excercise.step_id === currentStep.id
      );
      setCurrentExcercise(syncData(newExcerciseData));
    }
  };
  const updatepowerUpExcercises = data => {
    return new Promise((resolve, reject) => {
      try {
        let existingPowerUpExcercises = [...powerUpExcercises];
        let currentIndex = existingPowerUpExcercises.findIndex(
          excercise => excercise.stepNo === data.stepNo
        );

        // if excercise already exists update
        let requestBody = null;
        if (currentIndex >= 0) {
          var excercise = existingPowerUpExcercises[currentIndex];
          excercise = {
            ...excercise,
            answers: data.answers,
            isLastStep: data.isLastStep,
            isCompleted: data.isCompleted,
          };
          existingPowerUpExcercises[currentIndex] = excercise;
          requestBody = excercise.answers;
        } else {
          //Else insert new one
          existingPowerUpExcercises.push(data);
          requestBody = data.answers;
        }
        services
          .storeModuleStepResponses(moduleId, {
            ...data,
            answers: JSON.stringify(requestBody),
          })
          .then(response => {
            if (response.data.code === 200) {
              setPowerUpExcercises(existingPowerUpExcercises);
              resolve(true);
            }
          })
          .catch(error => {
            reject(error);
            console.log(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  };
  const handleNextClick = () => {
    setNextButtonLoading(true);

    const isLastExcercise =
      currentExcercise.stepNo === excerciseConfigs.excercises.length;
    let data;
    currentExcercise.stepNo === 1
      ? (data = {
          moduleResponseId: version,
          stepNo: currentExcercise.stepNo,
          answers: {
            stepObj: userInputs,
          },
          isLastStep: isLastExcercise,
          isCompleted: getExcerciseCompletionStatus(
            currentExcercise,
            userInputs
          ),
          moduleStepId: currentExcercise.id,
        })
      : (data = {
          moduleResponseId: version,
          stepNo: currentExcercise.stepNo,
          answers: {
            stepObj: userInputs,
            summaryObj: userInputs,
          },
          isLastStep: isLastExcercise,
          isCompleted: getExcerciseCompletionStatus(
            currentExcercise,
            userInputs
          ),
          moduleStepId: currentExcercise.id,
        });
    //let data = {
    //  moduleResponseId: version,
    //  stepNo: currentExcercise.stepNo,
    //  answers: userInputs,
    //  isLastStep: isLastExcercise,
    //  isCompleted: getExcerciseCompletionStatus(currentExcercise, userInputs),
    //  moduleStepId: currentExcercise.id,
    //};

    updatepowerUpExcercises(data)
      .then(response => {
        if (response) {
          setUserInputs(null);
          setNextButtonLoading(false);
          if (!data.isLastStep) {
            let currentStep = excerciseSteps.find(
              excercise => excercise.stepNo === currentExcercise.stepNo + 1
            );
            const newExcerciseData = excerciseConfigs.excercises.find(
              excercise => excercise.step_id === currentStep.id
            );
            setCurrentExcercise(syncData(newExcerciseData));
          } else {
            history.push(`/workspace/revenuemodeler/summary/${moduleId}`);
          }
        }
      })
      .catch(error => {
        console.log(error);
        setNextButtonLoading(false);
        toastContainer(messages.EXCERCISE_STORE_ERROR, "error");
      });
  };

  const shouldShowPrompt = () => {
    if (userInputs && powerUpExcercises && currentExcercise) {
      const currentSavedStep = powerUpExcercises.find(
        ex => ex.stepNo === currentExcercise.stepNo
      );
      if (currentSavedStep) {
        if (
          JSON.stringify(currentSavedStep.answers.value) ===
          JSON.stringify(userInputs)
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        if (Object.keys(userInputs).length === 0) {
          return false;
        } else {
          return true;
        }
      }
    }
  };
  const BackButton = () => {
    return (
      <Button
        variant="outlined"
        color="primary"
        sx={{ mx: 1 }}
        onClick={handleBackClick}
      >
        Back
      </Button>
    );
  };

  const NextButton = () => {
    return (
      <LoadingButton
        variant="contained"
        color="primary"
        sx={{ mx: 1 }}
        disabled={buttonDisable(userInputs, currentExcercise?.component)}
        loading={nextButtonLoading}
        onClick={handleNextClick}
      >
        Next
      </LoadingButton>
    );
  };

  const syncData = jsonData => {
    const stepDetailsData = excerciseSteps.find(
      step => step.id === jsonData.step_id
    );
    return { ...jsonData, ...stepDetailsData };
  };

  const getLastExcercise = excercises => {
    excercises.sort((a, b) => (a.stepNo > b.stepNo ? 1 : -1));
    return excercises[excercises.length - 1].moduleStepId;
  };

  useEffect(() => {
    if (excerciseConfigs) {
      services
        .getModulesSteps("md-revenue")
        .then(result => {
          if (result.data?.data) {
            const steps = result.data.data;
            steps.sort((a, b) => (a.stepNo > b.stepNo ? 1 : -1));
            setExcerciseSteps(steps);
          }
        })
        .catch(err => console.log(err));
    }
  }, [excerciseConfigs]);

  useEffect(() => {
    if (excerciseConfigs && excerciseSteps && excerciseSteps.length) {
      services
        .getModuleStepResponses(moduleId)
        .then(result => {
          const tempExcerciseDetails = result.data?.data[0];
          if (tempExcerciseDetails) {
            const excerciseDetails = tempExcerciseDetails.moduleStepResponses;
            setVersion(tempExcerciseDetails.id);
            if (!excerciseDetails || !excerciseDetails.length) {
              setPowerUpExcercises([]);
              setCurrentExcercise(syncData(excerciseConfigs.excercises[0]));
            } else {
              const mappedExcerciseDetails = excerciseDetails.map(
                excercise => ({
                  moduleResponseId: tempExcerciseDetails.id,
                  moduleStepId: excercise.moduleStep.id,
                  answers: JSON.parse(excercise.answers),
                  stepNo: excercise.moduleStep.stepNo,
                })
              );

              setPowerUpExcercises(mappedExcerciseDetails);
              if (excerciseParam === "start") {
                const lastExcerciseStepId = getLastExcercise(
                  mappedExcerciseDetails
                );
                const latestExcercise = excerciseConfigs.excercises.find(
                  excercise => excercise.step_id === lastExcerciseStepId + 1
                );

                if (latestExcercise) {
                  setCurrentExcercise(syncData(latestExcercise));
                } else {
                  history.push(excerciseConfigs.summary_path);
                }
              } else if (excerciseParam === "retake") {
                setPowerUpExcercises([]);
                setCurrentExcercise(syncData(excerciseConfigs.excercises[0]));
              } else {
                setCurrentExcercise(
                  syncData(
                    excerciseConfigs.excercises.find(
                      excercise => excercise.path === excerciseParam
                    )
                  )
                );
              }
            }
          }
        })
        .catch(err => console.log(err));
    }
  }, [excerciseSteps]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    if (currentExcercise) {
      const currentItem = powerUpExcercises.find(
        excercise => excercise.stepNo === currentExcercise.stepNo
      );
      //Check if there is previously filled step and see its input
      if (currentItem) {
        if (currentItem.answers && Object.keys(currentItem.answers).length) {
          setUserInputs(currentItem.answers.stepObj);
        } else {
          setUserInputs(getDefaultInputStates(currentExcercise.component));
        }
      } else {
        setUserInputs(getDefaultInputStates(currentExcercise.component));
      }
      setCurrentTab(tabItems[0].key);
      history.push(currentExcercise.path);
    }
  }, [currentExcercise]);

  useEffect(() => {
    setExcerciseConfigs(getExcerciseConfigurations(moduleId));
    fetchGraphData();
  }, []);

  const handleStepperStepChange = stepId => {
    setUserInputs([]);
    const changedStep = excerciseConfigs.excercises.find(
      excercise => excercise.step_id === stepId
    );
    if (changedStep) {
      setCurrentExcercise(syncData(changedStep));
    }
  };
  const fetchGraphData = () => {
    //get tam/sam/som details
    services
      .getExcerciseDetails(7)
      .then(result => {
        const excerciseDetails = result.data?.data?.powerUpStepResponses;
        if (!excerciseDetails || !excerciseDetails.length) {
        } else {
          const mappedExcerciseDetails = excerciseDetails.map(excercise => ({
            powerUpId: result.data.data.powerUpId,
            powerUpStepId: excercise.powerUpStepId,
            answers: JSON.parse(excercise.answers),
            stepNo: excerciseSteps.find(x => x.id === excercise.powerUpStepId)
              ?.stepNo,
          }));
          setHeaderExerciseDetails(mappedExcerciseDetails);
        }
      })
      .catch(err => console.log(err));
  };

  return (
    <>
      <RouterPrompt
        title={messages.UNSAVED_CHANGES_PROMPT_MESSAGE}
        when={shouldShowPrompt}
        onOK={() => true}
        onCancel={() => false}
        okText={"Ok"}
        cancelText={"Cancel"}
        stepPath={`/workspace/new-powerup/${moduleId}/${currentExcercise?.path}`}
      />
      <ExcerciseWrapper
        NextButton={NextButton}
        BackButton={BackButton}
        excerciseConfigs={excerciseConfigs}
        currentExcercise={currentExcercise}
      >
        {excerciseConfigs && (
          <ExcerciseSection
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            excerciseConfigurations={excerciseConfigs}
            currentExcercise={currentExcercise}
            excerciseSteps={excerciseSteps}
            NextButton={NextButton}
            BackButton={BackButton}
            powerUpExcercises={powerUpExcercises}
            handleStepperStepChange={handleStepperStepChange}
          >
            {excerciseSteps && excerciseSteps.length > 0 && (
              <Header
                excerciseSteps={excerciseSteps}
                step={currentExcercise?.stepNo}
                powerUpExcercises={headerExerciseDetails}
              />
            )}

            {currentExcercise &&
              (currentExcercise.component === "Evaluate" ? (
                <Evaluate
                  userInputs={userInputs}
                  setUserInputs={setUserInputs}
                />
              ) : currentExcercise.component === "AdaptAdjust" ? (
                <AdaptAdjust
                  userInputs={userInputs}
                  setUserInputs={setUserInputs}
                />
              ) : null)}
          </ExcerciseSection>
        )}

        <KnowledgeSidebar currentTab={currentTab} />
      </ExcerciseWrapper>
    </>
  );
}

const mapStoreToProps = ({ powerUp }) => ({
  powerUpExcercises: powerUp?.powerUpExcercises,
});

const mapDispatchToProps = dispatch => ({
  setPowerUpExcercises: updatedSteps =>
    dispatch(actions.setPowerUpExcercises(updatedSteps)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(RevenueModler);
