import * as React from "react";
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";
import SAMPLEDATA from "../../constants/text";
import { TermsSubText, TermsText, TermsTitle } from "./styles";
import { Close } from "@mui/icons-material";
import theme from "../../constants/Theme";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  height: "90%",
  bgcolor: "background.paper",
  overflowY: "auto",
  outline: "none",
  border: "1px solid #E6DFEC",
  boxSizing: "border-box",
  boxShadow: "0px 1px 2px rgb(0 0 0 / 10%), 0px 4px 15px rgb(0 0 0 / 10%)",
  borderRadius: "4px",
};

export default function TermsAndServiceModal({ ...props }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Typography
        onClick={handleOpen}
        variant="span"
        color={props && props.color ? props.color : "black"}
        sx={{ cursor: "pointer" }}
      >
        {" "}
        &nbsp;terms of service
      </Typography>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display={"grid"} gridTemplateColumns={"1fr auto"}>
            <TermsTitle>
              Terms and Conditions <div className="color-red">(DRAFT)</div>
            </TermsTitle>
            <IconButton sx={{ p: 3 }} onClick={handleClose}>
              <Close sx={{ fontSize: theme.typography.h2 }} />
            </IconButton>
          </Box>

          <hr />
          <TermsText>DRAFT BEING PREPARED BY ORRICK</TermsText>
          <TermsSubText>{SAMPLEDATA.TERMSANDSERVICETEXT}</TermsSubText>
        </Box>
      </Modal>
    </>
  );
}
