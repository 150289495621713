import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import theme from "../../../../../constants/Theme";
import InterviewScript from "../Components/InterviewScript";
import { getDefaultScriptData } from "../Functions/common";
import { useEffect } from "react";
import services from "../../../../../services";

const BuildInterviewScript = ({ userInputs, handleSave }) => {
  const [scriptData, setScriptData] = useState();
  const [defaultData, setDefaultData] = useState(getDefaultScriptData({}));

  const storeItems = useSelector(store => store.powerUp?.powerUpExcercises);

  useEffect(() => {
    const currentItem = storeItems.find(
      excercise => excercise.stepNo === 2
    )?.answers;

    if (currentItem?.interviewScript) {
      setScriptData(currentItem?.interviewScript);
    } else {
      services
        .getCompanyProfile()
        .then(response => {
          const resData = response.data.data;
          let questions = [];
          if (userInputs?.questionsList) {
            userInputs?.questionsList?.map(question => {
              if (question.selected) {
                questions.push(question?.goal);
              }
            });
          }
          if (resData) {
            if (userInputs) {
              if (currentItem?.interviewScript) {
                setScriptData(currentItem?.interviewScript);
              } else {
                setScriptData(
                  getDefaultScriptData({
                    ...resData,
                    questions: questions,
                  })
                );
              }
            }
            setDefaultData(
              getDefaultScriptData({
                ...resData,
                questions: questions,
              })
            );
          }
        })
        .catch(err => console.log(err));
    }
  }, []);

  const handleSaveAction = updatedScriptData => {
    handleSave({ interviewScript: [...updatedScriptData] });
  };

  const resetAction = () => {
    setScriptData(defaultData);
  };

  return (
    <Box>
      <Box
        sx={theme => ({
          border: `1px solid ${theme.palette.secondary.dark}`,
          borderRadius: 3,
          py: 2,
          [theme.breakpoints.up("md")]: {
            px: 3,
          },
          [theme.breakpoints.down("md")]: {
            px: 2,
            background: theme.palette.text.background,
          },
        })}
      >
        <InterviewScript
          scriptConfigData={scriptData || defaultData}
          defaultData={defaultData}
          handleSaveAction={handleSaveAction}
          resetAction={resetAction}
        />
      </Box>
      <Box sx={{ pt: 3 }}>
        <Typography variant="subhead1">
          If you’re satisfied with the script, you can begin searching for
          candidates that match your target profile.
        </Typography>
      </Box>

      <Button
        variant="nofill"
        sx={{
          display: { sm: "flex", md: "none" },
          justifyContent: "center",
          mt: 4,
        }}
      >
        Return to PowerUp Details
      </Button>
    </Box>
  );
};

export default BuildInterviewScript;
