import React from "react";
import { Grid, Box } from "@mui/material";
import { LOGOICON } from "../../../constants/image";
import WelcomeSideBar from "./WelcomeSideBar";
import Stepper from "../Stepper";
import { useLocation } from "react-router-dom";
import ROUTES_PATH from "../../../constants/routes";
import { SidebarBottomLogoWrapper, SidebarWrapper } from "./styles";

export default function Sidebar() {
  const location = useLocation();

  return (
    <SidebarWrapper>
      <Box
        sx={{
          height: "100%",
          overflowY: "auto",
        }}
      >
        {location.pathname === ROUTES_PATH.ONBOARDING_BASEPATH ? (
          <WelcomeSideBar />
        ) : (
          <Stepper />
        )}
      </Box>

      <SidebarBottomLogoWrapper>
        <a href={ROUTES_PATH.HOME_PAGE_URL} draggable={false}>
          <img
            draggable={false}
            src={LOGOICON}
            alt="logo"
            sx={{
              maxWidth: "100%",
              mt: 3,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </a>
      </SidebarBottomLogoWrapper>
    </SidebarWrapper>
  );
}
