import {
  EggIcon,
  ChickIcon,
  MiniRunnerIcon,
  MiniRoaderRunnerIcon,
  SoftwareIcon,
  HardwareIcon,
  ServiceIcon,
  MarketPlaceIcon,
  ConsumerGoodsIcon,
  EyeVisionIcon,
  HeartIcon,
  EnergyIcon,
  FlowerIcon,
  CommunityIcon,
  GritIcon,
  NetworkIcon,
  ExecutionIcon,
  SideHustleIcon,
  PartTimeIcon,
  FullTimeIcon,
  OverTimeIcon,
  CustomerIcon,
  MarketAnalysisIcon,
  ChargeIcon,
  GlowBulbIcon,
  SmileIcon,
  FundingIcon,
  LaunchIcon,
} from "../common/icons";
export * from "./image";
export * as routePath from "./text";
export * as sampleData from "./text";
export * from "./messages";

export default {
  EXPERIENCE_LEVELS: [
    {
      text: "Total newbie",
      cardSubText: "Eager to learn how to start",
      iconComponent: EggIcon,
      selected: false,
    },
    {
      text: "First-time founder",
      cardSubText: "I’m starting my company",
      iconComponent: ChickIcon,
      selected: false,
    },
    {
      text: "Second-time founder",
      cardSubText: "This is my second time launching a startup",
      iconComponent: MiniRunnerIcon,
      selected: false,
    },
    {
      text: "Serial entrepreneur",
      cardSubText: "I can’t stop building",
      iconComponent: MiniRoaderRunnerIcon,
      selected: false,
    },
  ],
  PRODUCT_SERVICE_OFFERING_DATA: [
    {
      text: "Software",
      iconComponent: SoftwareIcon,
      selected: false,
    },
    {
      text: "Hardware",
      iconComponent: HardwareIcon,
      selected: false,
    },
    {
      text: "Marketplace",
      iconComponent: MarketPlaceIcon,
      selected: false,
    },
    {
      text: "Consumer Goods",
      iconComponent: ConsumerGoodsIcon,
      selected: false,
    },
    {
      text: "Service",
      iconComponent: ServiceIcon,
      selected: false,
    },

    {
      text: "Other",
      selected: false,
      iconComponent: ServiceIcon,
      isInput: true,
    },
  ],
  FOUNDER_SUPER_POWER_DATA: [
    {
      text: "Super vision",
      iconComponent: EyeVisionIcon,
      selected: false,
    },
    {
      text: "Expert network",
      iconComponent: NetworkIcon,
      selected: false,
    },
    {
      text: "Execution",
      iconComponent: ExecutionIcon,
      selected: false,
    },
    {
      text: "Luck",
      iconComponent: FlowerIcon,
      selected: false,
    },
    {
      text: "Grit",
      iconComponent: GritIcon,
      selected: false,
    },
    {
      text: "Leadership",
      iconComponent: CommunityIcon,
      selected: false,
    },
    {
      text: "Empathy",
      iconComponent: HeartIcon,
      selected: false,
    },
    {
      text: "Energy",
      iconComponent: EnergyIcon,
      selected: false,
    },
  ],

  COMMITMENT_LEVELS: [
    {
      text: "Side-hustle",
      cardSubText: "I commit as much time as I can outside of work",
      iconComponent: SideHustleIcon,
      selected: false,
    },
    {
      text: "Part-time",
      cardSubText: "I commit half my energy to my startup",
      iconComponent: PartTimeIcon,
      selected: false,
    },
    {
      text: "Full-time",
      cardSubText: "I commit nearly all my time to my startup",
      iconComponent: FullTimeIcon,
      selected: false,
    },
    {
      text: "Overtime",
      cardSubText: "I live, eat, sleep and breath my startup",
      iconComponent: OverTimeIcon,
      selected: false,
    },
  ],

  STARTUP_JOURNEY_DATA: [
    {
      text: "I’ve got an idea",
      iconComponent: GlowBulbIcon,
      selected: false,
    },
    {
      text: "I’ve received some kind of funding",
      iconComponent: FundingIcon,
      selected: false,
    },
    {
      text: "I know my customer",
      iconComponent: CustomerIcon,
      selected: false,
    },
    {
      text: "I’ve sized my market",
      iconComponent: MarketAnalysisIcon,
      selected: false,
    },
    {
      text: "I’ve started hiring",
      iconComponent: SmileIcon,
      selected: false,
    },
    {
      text: "I’m building a brand",
      iconComponent: ChargeIcon,
      selected: false,
    },
    {
      text: "I’ve launched a product",
      iconComponent: LaunchIcon,
      selected: false,
    },
    {
      text: "Other",
      iconComponent: LaunchIcon,
      selected: false,
      isInput: true,
    },
  ],

  NEXT_KEY_MILESTONES: [
    {
      text: "First MLP iteration",
      subText: "Product Market Fit",
      date: "",
    },
    {
      text: "First post-launch MLP iteration",
      subText: "Product Market Fit",
      date: "",
    },
    {
      text: "Traunch 1",
      subText: "Product Market Fit",
      date: "",
    },
  ],
  CURRENT_STREAKS: [
    {
      id: "loginStreaks",
      text: "Log in at least once a day ",
      boldText: "",
      count: 17,
      type: "LOGIN",
    },
    {
      id: "todaysTopStreak",
      text: "Complete ",
      boldText: " Today’s Top 3",
      count: 17,
      type: "TODAYS_TOP",
    },
    {
      id: "powerUpsStreak",
      text: "Complete at least one ",
      boldText: " PowerUP",
      count: 17,
      type: "POWER_UP",
    },
  ],

  STORYTELLER_CONTENTS: [
    {
      index: "01",
      title: "Act One - Establishment",
      description:
        "Write down some ideas for how you want to start your story. This has grab your listeners attention! We’d recommend starting with the moment you came up with the idea, the moment you were faced with this problem.",
    },
    {
      index: "02",
      title: "Act Two - Climax",
      description:
        "This is the moment you state your purpose: why people should be interested. Describe your product and how it addresses the problem you stated in Act One.",
    },
    {
      index: "03",
      title: "Act Three - Resolution",
      description:
        "Where is your product going? This is where you can tie your listener in with the story, how can they be a part of your business.",
    },
  ],
  VALUE_PROP_RECAP_DATA: [
    {
      index: 1,
      title: "My Customers",
    },
    {
      index: 2,
      title: "Customer Jobs",
    },
    {
      index: 3,
      title: "Customer Pains",
    },
    {
      index: 4,
      title: "Customer Gains",
    },
  ],

  DEFAULT_COMPANY_NAME: "My New Startup",

  WORKSPACE_TAB_ITEMS: [
    {
      key: "poweupPacks",
      label: "PowerUP Packs",
    },
    {
      key: "powerups",
      label: "PowerUPs",
    },
  ],

  POWERUP_TAB_ITEMS: [
    {
      key: "poweupPacks",
      label: "PowerUp Packs",
    },
    {
      key: "powerups",
      label: "PowerUps - Coming Soon!",
    },
  ],

  MY_STARTUP_TAB_ITEMS: [
    {
      key: "myProfile",
      label: "My Profile",
    },
    {
      key: "myWork",
      label: "My Work",
    },
  ],
  VALUE_PROP_DETAILS: {
    title: "Overview",
    videoUrl:
      "https://startupos-dev-assets.s3.amazonaws.com/Video/sample_video.mp4",
    text: "Using this Power Up, you’ll create a value proposition (also known as an elevator pitch) that summarizes the value of your product in one sentence.",
    subTitle1: "Before you begin",
    subText1:
      "To get the most out of this module, you should be familiar with the market you’re looking to get into and your expected audience. It’s recommended you complete the Discovery and Market routes before starting your value proposition for best results. Team collaboration brings in different perspectives and is also recommended.",
    subTitle2: "What you’ll learn",
    subText2:
      "A value proposition is a sentence that sums up why a customer would choose your product. It proposes a challenge and specific call-outs as to how it can be solved. It’s also known as an elevator pitch. The value proposition allows you to describe your Value Propositions and the target Customer Segments in more detail and evaluate the “fit” between the value you intend to create and the expectations your customers have.",
    subText3:
      " You can use this excercise before, during and after developing an in-depth knowledge of your customers. If you use it before, it will highlight what you need to learn about customers and test about value propositions. If you use it after, it will help you analyze and evaluate “fit”.",
    subText4:
      " The Value Proposition Canvas can be applied to new and existing value propositions and customer segments alike. In both cases it will help you structure your thinking and make your ideas more tangible.",
  },

  VALUE_PROP_DETAILS_SIDEBAR: [
    {
      key: "30 min",
      label: "Time Estimate",
    },
    {
      key: "2 hours",
      label: "Time Estimate",
    },
    {
      key: "Easy",
      label: "Difficulty",
    },
    {
      key: "Yes",
      label: "Team Recommended",
    },
    {
      key: "After completing this Power Up, it is highly recommended to seek feedback with your matched Founder. If revisions are needed after feedback, you can easily edit the work you have already completed.",
      label: "Feedback Alert!",
    },
    {
      key: "0",
      label: "Ready to start this PowerUP?",
    },
    {
      key: "",
      label: "Courtesy of StartupOS",
    },
  ],
  VALUE_PROP_HEADER: {
    title: "Value Proposition",
    text: "Using this Power Up, you’ll create a value proposition (also known as an elevator pitch) that summarizes the value of your product in one sentence.",
  },

  NETWORK_TAB_ITEMS: [
    {
      key: "community",
      label: "Community",
    },
    {
      key: "expertFeedback",
      label: "Expert Feedback ",
    },
    {
      key: "partners",
      label: "Partners ",
    },
    {
      key: "investorSearch",
      label: "Investor Search ",
    },
  ],

  RUNWAY_CALCULATOR_TAB_ITEMS: [
    {
      key: "actual",
      label: "Actual",
    },
    {
      key: "whatIf",
      label: "What If?",
    },
  ],

  PRO_TIPS: [
    "Prior to raising any capital outside of founder capital, it is important for you to determine your individual product North Star. This should be a product metric that is a leading indicator of revenue. For DocuSign it was signed envelopes, for Twilio it was API calls on initial messaging, for Zapier it was activations. For your company it could be these, monthly active users (MAUs), or something else altogether.",
    "Before approaching venture capital firms or even angel investors, founders often look to their friends and family for startup capital. At the earliest stages of a company’s journey, and maybe after you and your cofounders have put in your own cash, a relatively easy and fast way to secure a small amount of working capital is to approach your close friends and selected members of your family (that is, if people in your network have the means to contribute such funding).",
    "Now it is time to look for seed funding. In general, you want to raise enough money to give yourself 12 to 24 months of runway, since that's typically the amount of time it takes to move from one round of funding to the next.",
  ],

  EXCERCISE_TAB_ITEMS: [
    {
      key: "excercise",
      label: "Exercise",
    },
    {
      key: "knowledge",
      label: "Knowledge",
    },
  ],
  SUMMARY_TAB_ITEMS: [
    {
      key: "excercise",
      label: "Exercise",
    },
    {
      key: "rate",
      label: "Rate PowerUP",
    },
  ],

  STORYTELLER_STEPS: [
    {
      index: "01",
      label: "Act One - Establishment",
      description:
        "Growing up, my father was an ice road trucker. After weeks of him being away he’d return home and tell us perilous stories about terrbile road conditions, unfair treatment by the higher ups, all the funny characters he’d meet going cross country, it was a really interesting lifestyle. When I was old enough I got to go on a few trips myself! I grew up in the trucking industry, and I grew up wanting to make the industry beter for the truckers who work tirelessly to keep this country running.",
    },
    {
      index: "02",
      label: "Act Two - Climax",
      description:
        "The Roadrunner Corp. is designing an all-in-one platform for truckers, so they can worry less about the road ahead, and keep the days on end of driving completely stress-free. We’ll give them alerts for road conditions, a full, constantly updated map of stops with crowd-sourced reviews, and content to keep them focused on their drive. It’s a place to share their stories, and to keep them active in sharing them as well.",
    },
    {
      index: "03",
      label: "Act Three - Resolution",
      description:
        "We’ve been working with some brilliant designers to build out a full prototype of the product, and we’ve been putting this in the hands of a least a dozen different truckers every week, gathering quantitative and qualitative data and iterating on our prototype. We’re aiming on gathering another 500,000 dollars in pre-seed over the next 30 days, and in 45 days, we’ll have an MVP that’ll be in the hands of 1,200 truckers nationwide.",
    },
  ],

  PERSONA_BUILDER_STEPS: [
    {
      index: "01",
      label: "Name & Avatar",
      description:
        "Use this user persona template to create a profile on your ideal customer and visualize how your product will work for them.",
    },
    {
      index: "02",
      label: "Describe Your Persona",
      description:
        "Use this user persona template to create a profile on your ideal customer and visualize how your product will work for them.",
    },
    {
      index: "03",
      label: "Job Responsibilities",
      description:
        "Use this user persona template to create a profile on your ideal customer and visualize how your product will work for them.",
    },
    {
      index: "04",
      label: "Job Needs",
      description:
        "Use this user persona template to create a profile on your ideal customer and visualize how your product will work for them.",
    },
  ],
  PROGRESS_TITLES: [
    "You’re behind on your progress",
    "You’re right on track",
    "You’re ahead of the pack",
  ],
  PREREQUISITE_STEPS: [
    {
      id: 1,
      label: "Initial Persona Builder",
      exerciseStatus: "COMPLETED",
    },
    {
      id: 2,
      label: "Value Proposition",
      exerciseStatus: "START",
    },
    {
      id: 3,
      label: "TAM/SAM/SOM",
      exerciseStatus: "COMPLETED",
    },
  ],
  TAMSAMSOM_COMPLETED_STEPS: [
    {
      index: "01",
      label: "TAM",
    },
    {
      index: "02",
      label: "SAM",
    },
    {
      index: "03",
      label: "SOM",
    },
    {
      index: "04",
      label: "Market Temperature",
    },
  ],

  REVENUE_MODELER_STEPS: [
    {
      index: "01",
      label: "Evaluate revenue model options",
    },
    {
      index: "02",
      label: "Adapt and adjust",
    },
  ],
  BRANDSTORMING_RECAP_DATA: [
    {
      index: 1,
      title: "Brand Descriptors",
    },
    {
      index: 2,
      title: "Brand Detractors",
    },
    {
      index: 3,
      title: "Ideal Customer",
    },
  ],
  QUESTIONS_DATA: [
    {
      id: 1,
      statement: "Determine initial desirability of solution.",
      goal: "Do people care about my solution?",
      selected: false,
    },
    {
      id: 2,
      statement: "Gauge importance of need.",
      goal: "Understand how large or important the need for my offering is.",
      selected: false,
    },
    {
      id: 3,
      statement: "Identify substitutes.",
      goal: "Determine alternative or workaround is available and learn whether it is having a positive or negative impact on customer.",
      selected: false,
    },
    {
      id: 4,
      statement: "Screen early adaptors.",
      goal: "Identify potential early adaptors who would be willing to try my offering out and provide feedback.",
      selected: false,
    },
    {
      id: 5,
      statement: "Delighters and detractors.",
      goal: "Learn which aspects of my offering are most appealing and unappealing and why.",
      selected: false,
    },
    {
      id: 6,
      statement: "Customers willingness to pay.",
      goal: "Determine if/how much the subject would be is willingness to pay for my offering.",
      selected: false,
    },
    {
      id: 7,
      statement: "Identify missing ingredients.",
      goal: "Determine whether there’s anything I might be missing from my offering.",
      selected: false,
    },
    {
      id: 8,
      statement: "What is most critical to making a sale.",
      goal: "Understand how the customer might make a buying decisions.",
      selected: false,
    },
    {
      id: 9,
      statement: "How would people talk about it.",
      goal: "How would they describe my offering to others?",
      selected: false,
    },
    {
      id: 10,
      statement: "Identify niches.",
      goal: " Do the participants fall into distinct groups with similar characteristics, needs or behaviors?",
      selected: false,
    },
  ],
  INTERVIEW_STATUS: [
    "Scheduled",
    "Completed",
    "Incomplete",
    "Cancelled",
    "No Show",
  ],
};
