import { styled } from "@mui/material/styles";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    background:
      "linear-gradient(92.58deg, rgba(233, 115, 0, 0.15) 0%, rgba(255, 209, 65, 0.15) 100%)",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: `linear-gradient(92.58deg, ${theme.palette.secondary.progressGradient2} 0%, ${theme.palette.secondary.progressGradient1} 100%)`,
  },
}));
