import React, { Component } from "react";
import { Grid, Button, Box, Stack } from "@mui/material";
import { connect } from "react-redux";

import "./createAccount.css";
import TextInput from "../../common/input/Input";
//import Button from "../../common/button/Button";
import PasswordValidation from "../../common/passwordValidation/PasswordValidation";
import ROUTES_PATH from "../../constants/routes";
import { REGEX } from "../../constants/regex";
import {
  getUserEmail,
  saveUserEmail,
  saveUserId,
  saveVerificationType,
} from "../../utils/sessionStorage";
import { createUser } from "../../utils/auth0";
import services from "../../services";
import { toastContainer } from "../../utils/toast";
import messages from "../../constants/messages";
import LoadingButton from "../../common/Loadingbutton";

class CreateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      email: "",
      pasword: "",
      validationErrorArray: [true, true, true, true, true],
      emailError: false,
      signupLoading: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ email: getUserEmail() });
  }
  ValidateEmail(value) {
    if (REGEX.EMAIL.test(value)) {
      return true;
    }
    return false;
  }
  handleEmailInputChange = event => {
    this.setState({
      email: event,
    });
    if (this.ValidateEmail(event)) {
      this.setState({ emailError: false });
    } else {
      this.setState({ emailError: true });
    }
  };
  handlePasswordInputChange = event => {
    this.setState({
      password: event,
    });

    let validationErrorArray = [...this.state.validationErrorArray];
    if (event.length >= 8) {
      validationErrorArray[0] = false;
    } else {
      validationErrorArray[0] = true;
    }
    if (event.match(REGEX.LOWERCASELETTERS)) {
      validationErrorArray[1] = false;
    } else {
      validationErrorArray[1] = true;
    }
    if (event.match(REGEX.UPPERCASELETTERS)) {
      validationErrorArray[2] = false;
    } else {
      validationErrorArray[2] = true;
    }
    if (event.match(REGEX.NUMBERS)) {
      validationErrorArray[3] = false;
    } else {
      validationErrorArray[3] = true;
    }
    if (event.match(REGEX.SPECIAL_CHARACTERS)) {
      validationErrorArray[4] = false;
    } else {
      validationErrorArray[4] = true;
    }
    this.setState({ validationErrorArray });
  };

  handleContinue = async () => {
    const { email, password } = this.state;
    this.setState({ signupLoading: true });
    createUser({ email, password })
      .then(result => {
        let payload = {};

        const onboardingData = this.getOnboardingData();

        if (!this.isEmpty(onboardingData)) {
          payload = {
            id: result.Id,
            token: null,
            onboardingDetails: onboardingData,
          };
        } else {
          payload = {
            id: result.Id,
            token: null,
          };
        }

        services
          .login(payload)
          .then(() => {
            this.setState({ signupLoading: false });
            saveUserEmail(email);
            saveUserId(result.Id);
            saveVerificationType("signUp");
            this.props.history.push(ROUTES_PATH.EMAIL_VERIFICATION);
          })
          .catch(err => {
            this.setState({ signupLoading: false });
            console.log(err);
            toastContainer(err?.error_description, "error");
          });
      })
      .catch(err => {
        this.setState({ signupLoading: false });
        if (err?.code === "invalid_signup") {
          toastContainer(messages.USER_ALREADY_EXISTS, "error");
        } else {
          console.log(err);
          toastContainer(err?.error_description, "error");
        }
      });
  };

  getOnboardingData = () => {
    const onboarding = this.props.onboarding;
    let journeyDetailsString = onboarding?.journeyDetails?.join();
    if (onboarding?.autoCompleteDetails?.journeyDetails) {
      journeyDetailsString +=
        "," + onboarding.autoCompleteDetails.journeyDetails;
    }
    const onboardingDetails = {
      companyName: onboarding?.companyName,
      companyType: onboarding?.autoCompleteDetails?.companyType
        ? onboarding.autoCompleteDetails.companyType
        : onboarding?.productService,
      journeyDetails: journeyDetailsString,
      experienceDetails: onboarding?.experienceDetails,
      founderSuperpower: onboarding?.founderSuperpower,
      currentCommitment: onboarding?.currentCommitment,
      state: onboarding?.companyLocation?.state,
    };

    return onboardingDetails;
  };

  isEmpty = o => Object.entries(o).every(([k, v]) => v === "");

  render() {
    let { email, password, validationErrorArray, emailError } = this.state;
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100%" }}
      >
        <Grid
          className="signup-grid-container"
          sx={{ mt: 9 }}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={10} md={6} lg={6} className="form-container">
            <div className="signup-title">Create Your Account</div>
            <TextInput
              type="text"
              label="Email address"
              value={email}
              error={emailError ? "Please enter valid email address" : ""}
              onChange={e => this.handleEmailInputChange(e)}
            />
            <TextInput
              type="password"
              label="Password"
              value={password}
              onChange={e => this.handlePasswordInputChange(e)}
            />
            <PasswordValidation validationErrorArray={validationErrorArray} />
            <Grid item>
              <Box textAlign="center">
                <LoadingButton
                  sx={{ mt: 3, maxWidth: "100%" }}
                  disabled={
                    !email || emailError || validationErrorArray.includes(true)
                  }
                  onClick={e => this.handleContinue(e)}
                  loading={this.state.signupLoading}
                >
                  Continue
                </LoadingButton>
              </Box>
            </Grid>
            <Box sx={{ py: 2 }} className="signup-sub-text align-center">
              Have an account? &nbsp;
              <span
                onClick={() => {
                  this.props.history.push(ROUTES_PATH.LOG_IN);
                }}
              >
                Log in.
              </span>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    );
  }
}

const mapStoreToProps = ({ auth, onboarding }) => ({
  onboarding: onboarding,
});

export default connect(mapStoreToProps, undefined)(CreateAccount);
