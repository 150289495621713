import { Grid } from "@mui/material";
import React from "react";

import { MainArea, HeaderArea } from "./styles";
import RightSidebar from "../../components/Workspace/Sidebar/RightSidebar";
import LeftSidebar from "../../components/Workspace/Sidebar/LeftSidebar";
import BottomNavbar from "../../components/Workspace/BottomNavbar";
import WorkspaceHeader from "../../components/Workspace/Header";
import ChatIcons from "../../components/Workspace/ChatIcons";

export default function LayoutWrapper({ children, onLogout, userData }) {
  return (
    <Grid container height={"100vh"}>
      <Grid
        item
        md={0.8}
        lg={0.8}
        xl={0.5}
        display={{ xs: "none", sm: "none", md: "block" }}
      >
        <LeftSidebar />
      </Grid>
      <Grid item sm={12} xs={12} xl={11.1} lg={10.7} md={10.7}>
        <HeaderArea>
          <WorkspaceHeader userData={userData} onLogout={onLogout} />
        </HeaderArea>
        <MainArea>{children}</MainArea>
        <ChatIcons />
        <Grid container sx={{ position: "fixed", bottom: 0 }}>
          <Grid
            item
            xs={12}
            display={{ sm: "block", md: "none", lg: "none", xl: "none" }}
          >
            <BottomNavbar />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        md={0.5}
        lg={0.5}
        xl={0.4}
        display={{ sm: "none", md: "block", xs: "none" }}
      >
        <RightSidebar onLogout={onLogout} userData={userData} />
      </Grid>
    </Grid>
  );
}
