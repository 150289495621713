import styled from "@emotion/styled";

export const ItemIcon = styled.div(({ theme, selected }) => {
  return {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "24px",
    backgroundColor: selected ? "#EDDDF6" : "var(--white-color)",
    padding: theme.spacing(1),
    "&:hover":{
      backgroundColor:theme.palette.grey[200]
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(0.5),
    },
  };
});

export const RightSidebarWrapper = styled.div(({ theme }) => {
  return {
    backgroundColor: "var(--white-color)",
    height: "100%",
    maxWidth:theme.spacing(7),
  };
});



