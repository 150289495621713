import React, { useState, useEffect } from "react";
import { Box, Slide } from "@mui/material";
import { useHistory } from "react-router-dom";
import Header from "../../components/Onboarding/Header";
import SliderTransitionWrapper from "../../components/Onboarding/SliderTransitionWrapper";
import styled from "@emotion/styled";

const OnboardingContent = styled.div(({ theme }) => {
  return {
    height: "100%",
    display: "grid",
    gridTemplateRows: "auto 1fr",
    overflow: "hidden",
  };
});

const ScrollingContent = styled.div(({ theme }) => {
  return {
    overflowY: "scroll",
    paddingInline: theme.spacing(2),
  };
});

export default function ContentWrapper({ children }) {
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setAnimate(true);
    }, 200);

    return () => {
      setAnimate(false);
    };
  }, []);

  const history = useHistory();
  const onBackHandler = () => {
    const pathname = window.location.pathname;
    pathname.split("/")[1] === "onboarding" && pathname.split("/").length == 2
      ? (window.location.href = process.env.REACT_APP_HOME_URL)
      : history.goBack();
  };
  return (
    <OnboardingContent>
      <Box>
        <Header onBack={onBackHandler} />
      </Box>
      <Slide
        direction="left"
        in={animate}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 200, exit: 10 }}
      >
        <ScrollingContent>{children}</ScrollingContent>
      </Slide>
    </OnboardingContent>
  );
}
