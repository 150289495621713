import React, { useCallback, useEffect, useState } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { connect } from "react-redux";

import {
  getIdToken,
  getToken,
  getUser,
  handleCallback,
  logout,
} from "../../utils/auth0-spa";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../constants/routes";

import services from "../../services";

import { toastContainer } from "../../utils/toast";
import { authWebLogout } from "../../utils/auth0";
import { saveUserEmail } from "../../utils/sessionStorage";

import * as actions from "../../modules/actions";
import messages from "../../constants/messages";

const AuthorizeSuccess = ({ setAuthData, onboarding }) => {
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);

    const token = queryParams.get("token");

    if (token) {
      services
        .verifyEmail(token) // Performing email verification in case of user email not found while social login
        .then(result => {
          if (result.data.code === 200) {
            toastContainer(messages.EMAIL_VERIFIED, "success");
          }
        })
        .catch(err => {
          toastContainer("Error occured while verifying email " + err, "error");
        });

      setTimeout(() => {
        logout();
        authWebLogout();
      }, 5000);
    }
  }, []);

  useEffect(async () => {
    await handleCallback().catch(error => {
      if (error.message === "Permissions error") {
        logout();
        authWebLogout();
      }
    });

    //call auth0 /token api
    const accessToken = await getToken({
      detailedResponse: false,
    });

    const idToken = await getIdToken();
    const userData = await getUser();

    setAuthData({ accessToken, idToken, userData });

    saveUserEmail(userData?.email);
    let payload = {};

    const onboardingData = getOnboardingData();

    if (!isEmpty(onboardingData)) {
      payload = {
        id: null,
        token: idToken,
        onboardingDetails: onboardingData,
      };
    } else {
      payload = {
        id: null,
        token: idToken,
      };
    }

    //verify and get user entity from DB
    services
      .login(payload)
      .then(result => {
        if (result.data.code === 200) {
          const resData = result.data.data;

          if (!resData.email) {
            history.push(ROUTES_PATH.SOCIAL_LOGIN);
          } else if (!resData.emailVerified) {
            history.push(ROUTES_PATH.EMAIL_VERIFICATION);
          } else {
            if (!!resData.isTourCompleted === false) {
              setAuthData({ showModal: true });
            }
            setAuthData({ companyName: resData.companyName });
            services
              .updateStreak({
                streakName: "LOGIN",
              })
              .then()
              .catch();

            setAuthData({ authenticatedAppUser: true }); // mark user authenticated on App backend
            history.push(ROUTES_PATH.WORKSPACE);
          }
          setLoading(false);
        }
      })
      .catch(err => {
        if (err.response.status === 301) {
          // Executed this block if user trying to create account without onboarding details, show message to user and redirect them to onboarding

          toastContainer(err.response?.data?.message, "error");
          setTimeout(() => {
            setLoading(false);
            logout();
            authWebLogout(process.env.REACT_APP_REDIRECTION_ONBOARDING_URL);
          }, 3000);
        } else {
          console.log(err);
          toastContainer(err?.error_description, "error");
          setTimeout(() => {
            setLoading(false);
            logout();
            authWebLogout();
          }, 3000);
        }
      });
  }, []);

  const getOnboardingData = () => {
    let journeyDetailsString = onboarding?.journeyDetails?.join();
    if (onboarding?.autoCompleteDetails?.journeyDetails) {
      journeyDetailsString +=
        "," + onboarding.autoCompleteDetails.journeyDetails;
    }
    const onboardingDetails = {
      companyName: onboarding?.companyName,
      companyType: onboarding?.autoCompleteDetails?.companyType
        ? onboarding.autoCompleteDetails.companyType
        : onboarding?.productService,
      journeyDetails: journeyDetailsString,
      experienceDetails: onboarding?.experienceDetails,
      founderSuperpower: onboarding?.founderSuperpower,
      currentCommitment: onboarding?.currentCommitment,
      state: onboarding?.companyLocation?.state,
    };

    return onboardingDetails;
  };

  const isEmpty = o => Object.entries(o).every(([k, v]) => v === "");

  return loading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <></>
  );
};

const mapStoreToProps = ({ auth, onboarding }) => ({
  idToken: auth.idToken,
  userData: auth.userData,
  auth: auth,
  onboarding: onboarding,
});

const mapDispatchToProps = dispatch => ({
  setAuthData: updatedData => dispatch(actions.setAuthData(updatedData)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(AuthorizeSuccess);
