import { Typography, Box } from "@mui/material";
import React from "react";
import { NumberContainer } from "./styles";
import { GreenCheckIcon } from "../../../../common/icons";
import theme from "../../../../constants/Theme";

const SummaryStepList = ({ StepsData }) => {
  return (
    <Box>
      {StepsData.map((item, idx) => {
        return (
          <Box sx={{ display: "flex", mt: 3, alignItems: "center" }} key={idx}>
            <NumberContainer bordercolor={theme.palette.text.primary}>
              <Typography variant="label_medium"> {item.index}</Typography>
            </NumberContainer>
            <Typography
              variant="label_medium"
              sx={{ alignSelf: "center", pl: 1 }}
            >
              {item.label}
            </Typography>
            <Box sx={{ marginLeft: "auto", mr: 3 }}>
              <GreenCheckIcon />
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default SummaryStepList;
