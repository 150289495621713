import { combineReducers } from "redux";
import onboarding from "./onboarding";
import auth from "./auth";
import powerUp from "./powerUp"

const rootReducer = combineReducers({
  onboarding,
  auth,
  powerUp
});

//eslint-disable-next-line
export default rootReducer;
