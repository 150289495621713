import React, { forwardRef, useImperativeHandle } from "react";
import { Typography, Box, Divider } from "@mui/material";
import JsxParser from "react-jsx-parser";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import theme from "../../../constants/Theme";
import {
  PROFILE_PLACEHOLDER,
  PROFILE_PLACEHOLDER_PNG,
} from "../../../constants/image";
import { getCurrentDate } from "../../../utils/date";
import { LOGOICON, POWERUP_IMG } from "../../../constants/image";

import FormSectionHeader from "../PowerUp/FormSectionHeader";

const AggregateNotesPdf = forwardRef(({ interviewList }, ref) => {
  useImperativeHandle(ref, () => ({
    async handleExport(type) {
      const element = document.getElementById("aggregateNotes");
      const canvas = await html2canvas(element, {
        useCORS: true,
        proxy: "http://d3rm35snnwgfg3.cloudfront.net",
        logging: true,
        onclone: clonedDoc => {
          clonedDoc.getElementById("aggregateNotes").style.display = "block";
          clonedDoc.getElementById("aggregateNotes").style.maxHeight =
            "inherit";
        },
      });
      const data = canvas.toDataURL("image/png");
      if (type === "img") {
        const link = document.createElement("a");
        link.href = data;
        link.download = "Aggregated Notes.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        function footer() {
          doc.line(10, 280, 200, 280);
          var img = new Image();
          img.src =
            "https://d3rm35snnwgfg3.cloudfront.net/Images/StartupOS_Logo_PNG.png";
          doc.addImage(img, "png", 90, 285);
        }

        function setText(textContent, fontSize, x, y) {
          doc.setFontSize(fontSize);
          doc.text(x, y, textContent);
        }

        var doc = new jsPDF();

        var img = new Image();
        img.src = `${POWERUP_IMG}`;
        doc.addImage(img, "png", 10, 7);

        setText("IDEA VALIDATION INTERVIEW", 12, 140, 10);

        doc.setTextColor(`${theme.palette.secondary.main}`);

        doc.line(10, 20, 200, 20);

        setText("Aggregated Notes", 22, 10, 35);

        doc.setTextColor(`${theme.palette.text.primary}`);

        doc.line(10, 40, 200, 40);
        interviewList
          .filter(interview => interview.selected)
          .map((interviewItem, idx) => {
            let profileImage = new Image();
            profileImage.src = `${PROFILE_PLACEHOLDER_PNG}`;
            if (idx > 0) {
              doc.addPage();
            }
            doc.addImage(profileImage, "png", 10, 50);

            setText(interviewItem.name, 16, 26, 57);

            setText(
              getCurrentDate(new Date(interviewItem.scheduledDate)),
              14,
              167,
              57
            );

            setText("Notes Recorded", 16, 10, 80);

            let lines = doc.splitTextToSize(
              interviewItem.records.filter(itm => itm.key === "notes")[0].value,
              200
            );

            setText(lines, 14, 10, 90);

            setText("GEMS & VERBATIMS", 14, 10, 130);

            let lines1 = doc.splitTextToSize(
              interviewItem.records.filter(
                itm => itm.key === "gems&verbatims"
              )[0].value,
              200
            );

            setText(lines1, 14, 10, 140);

            setText("KEY INSIGHTS", 14, 10, 175);

            let lines2 = doc.splitTextToSize(
              interviewItem.records.filter(itm => itm.key === "key_insights")[0]
                .value,
              200
            );

            setText(lines2, 14, 10, 185);
            footer();
          });

        doc.save("Aggregated Notes.pdf");
      }
    },
  }));

  return (
    <Box
      ref={ref}
      id="aggregateNotes"
      sx={{
        display: "none",
        maxHeight: 0,
        p: 5,
      }}
    >
      <Box>
        <FormSectionHeader heading="Idea Validation Interview" />
        <Divider
          sx={{
            background: theme => theme.palette.secondary.dark,
            [theme.breakpoints.down("md")]: {
              background: theme => theme.palette.secondary.borderLight,
            },
          }}
        />
      </Box>
      {interviewList.map((interview, index) => {
        return interview.selected ? (
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", mt: 5 }}>
              <Box
                component="img"
                id="profile_img"
                src={PROFILE_PLACEHOLDER}
              ></Box>
              <Box sx={{ ml: 2 }}>
                <Typography
                  variant="bodyLarge"
                  sx={{ color: "secondary.black" }}
                >
                  {interview.name}
                </Typography>
              </Box>
              <Box sx={{ py: 1, ml: "auto" }}>
                <Typography variant="bodyMedium">
                  {getCurrentDate(new Date(interview.scheduledDate))}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ mt: 5 }}>
              <Typography variant="largeTitle">Notes Recorded</Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="bodyMedium">
                <JsxParser
                  components={{ Typography }}
                  jsx={
                    interview.records.filter(itm => itm.key === "notes")[0]
                      .value
                  }
                />
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="title_medium">
                {"GEMS & VERBATIMS"}
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="bodyMedium">
                <JsxParser
                  components={{ Typography }}
                  jsx={
                    interview.records.filter(
                      itm => itm.key === "gems&verbatims"
                    )[0].value
                  }
                />
              </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="title_medium">{"KEY INSIGHTS"}</Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant="bodyMedium">
                <JsxParser
                  components={{ Typography }}
                  jsx={
                    interview.records.filter(
                      itm => itm.key === "key_insights"
                    )[0].value
                  }
                />
              </Typography>
            </Box>
            {interviewList.length !== 1 &&
            interviewList.length - 1 !== index ? (
              <Divider
                sx={{
                  background: `${theme.palette.secondary.outlineXLight}`,
                  mt: 3,
                }}
              />
            ) : (
              ""
            )}
          </Box>
        ) : (
          ""
        );
      })}
      <Box style={{ textAlign: "center", padding: "20px" }}>
        <Box src={LOGOICON} component="img"></Box>
      </Box>
    </Box>
  );
});

export default AggregateNotesPdf;
