import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { stateToMarkdown } from "draft-js-export-markdown"; // using module to convert draft-js state data to markdown as draft-js don't provide any conversion solution for rawData to markdown

import TextEditorComponent from "../Components/TextEditor";

const InterviewScript = ({
  readOnly = false,
  hideRestoreOption = false,
  scriptConfigData,
  containerStyle,
  handleSaveAction,
  resetAction,
  customSaveButton = false,
  getChildData,
  getChildEventData,
}) => {
  const [currentData, setCurrentData] = useState({ key: "", value: "" });

  const [scriptData, setScriptData] = useState(scriptConfigData);

  const [version, setVersion] = useState(1); // maintaining version to re-render the script component after Reset to default action
  useEffect(() => {
    if (scriptConfigData) {
      setVersion(() => version + 1);
    }
  }, [scriptConfigData]);

  useEffect(() => {
    if (currentData.value === "") return;

    let rte_markdown = stateToMarkdown(currentData.value.getCurrentContent());

    const objIdx = scriptData.findIndex(obj => obj.key === currentData.key);

    if (objIdx !== -1) {
      const updatedScriptData = [...scriptData];
      updatedScriptData[objIdx] = {
        ...updatedScriptData[objIdx],
        value: rte_markdown,
      };

      setScriptData(updatedScriptData);
    }
  }, [currentData]);

  useEffect(() => {
    if (getChildData) getChildData(scriptData);
  }, [getChildEventData]);

  return (
    <>
      <Box display={"flex"} sx={{ width: "max-content", ml: "auto" }}>
        {!hideRestoreOption && (
          <Button
            variant="noOutline"
            onClick={() => {
              resetAction();
              setVersion(version + 1);
            }}
            sx={{ width: "max-content", mr: 2 }}
          >
            Reset to default
          </Button>
        )}

        {customSaveButton ? (
          <></>
        ) : (
          !readOnly && (
            <Button
              variant="containedDark"
              sx={{ width: "max-content" }}
              onClick={() => {
                handleSaveAction(scriptData);
              }}
            >
              Save Changes
            </Button>
          )
        )}
      </Box>
      <Box sx={{ py: 2, ...containerStyle }}>
        {scriptConfigData.map(ele => (
          <Box sx={{ pb: 2 }}>
            <TextEditorComponent
              version={version}
              id={ele.key}
              label={ele.label}
              initialValue={ele.value}
              onChange={data => setCurrentData(data)}
              readOnly={readOnly}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default InterviewScript;
