import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import theme from "../../../constants/Theme";

import Card from "../../../components/Workspace/Card";
import constants, { SPONSOREDBY, STARTUPOSICON } from "../../../constants";
import { InlineSecondaryTile } from "./style";
import InlinePrimaryTileComponent from "./InlinePrimaryTile";
import Tabs from "../../../components/Workspace/Tabs";
import services from "../../../services";

const RunwayCalculator = ({ data }) => {
  const [currentTab, setCurrentTab] = useState("actual");

  const [actualData, setActualData] = useState({
    cash: 0,
    burnRate: 0,
    month: 0,
  });
  const [whatIfData, setWhatIfData] = useState({
    cash: 0,
    burnRate: 0,
    month: 0,
  });

  const calculateRunwayMonth = (cash, burnRate) => {
    if (cash > 0 && burnRate > 0) {
      return Math.floor(parseFloat(cash / burnRate));
    }
    return 0;
  };

  useEffect(() => {
    const actualGetData = data?.find(
      runwayData => runwayData.runawayType === "ACTUAL"
    );
    const whatIfGetData = data?.find(
      runwayData => runwayData.runawayType === "WHAT_IF"
    );

    if (actualGetData) {
      const actualCash = parseInt(actualGetData.cash);
      const actualBurnRate = parseInt(actualGetData.burnRate);

      const calculateActualMonth = calculateRunwayMonth(
        actualCash,
        actualBurnRate
      );

      setActualData({
        cash: actualCash,
        burnRate: actualBurnRate,
        month: calculateActualMonth,
      });
    }

    if (whatIfGetData) {
      const whatIfCash = parseInt(whatIfGetData.cash);
      const whatIfBurnRate = parseInt(whatIfGetData.burnRate);

      const calculateWhatIfMonth = calculateRunwayMonth(
        whatIfCash,
        whatIfBurnRate
      );

      setWhatIfData({
        cash: whatIfCash,
        burnRate: whatIfBurnRate,
        month: calculateWhatIfMonth,
      });
    }
  }, [data]);

  const onRunwayDataSave = (cash, burnRate) => {
    let runwayMonths = calculateRunwayMonth(cash, burnRate);

    currentTab === "actual"
      ? setActualData({ cash, burnRate, month: runwayMonths })
      : setWhatIfData({ cash, burnRate, month: runwayMonths });

    const runwayType = currentTab === "actual" ? "ACTUAL" : "WHAT_IF";

    const runwayObject = {
      burnRate: burnRate.toString(),
      cash: cash.toString(),
      runawayType: runwayType,
    };

    const payload = [
      currentTab === "actual"
        ? runwayObject
        : {
            burnRate: actualData.burnRate.toString(),
            cash: actualData.cash.toString(),
            runawayType: "ACTUAL",
          },
      currentTab === "whatIf"
        ? runwayObject
        : {
            burnRate: whatIfData.burnRate.toString(),
            cash: whatIfData.cash.toString(),
            runawayType: "WHAT_IF",
          },
    ];

    services
      .updateRunwayDetails(payload)
      .then()
      .catch(err => {
        console.log(err);
      });
  };

  const getProTip = () => {
    const proTips = constants.PRO_TIPS;

    const cash = actualData.cash;

    if (cash >= 0 && cash <= 25000) return proTips[0];
    else if (cash > 25000 && cash < 100000) return proTips[1];
    else return proTips[2];
  };

  return (
    <Card sx={{ px: 3 }}>
      <Typography variant="h3" color="textPrimary">
        Runway Calculator
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pt: 3,
        }}
      >
        <Tabs
          tabItems={constants.RUNWAY_CALCULATOR_TAB_ITEMS}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          buttonWidth={"82px"}
          color="primary"
        />
      </Box>

      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          maxWidth: theme.breakpoints.values.md,
          pt: 4,
        }}
        spacing={1}
      >
        <InlinePrimaryTileComponent
          currentTabData={currentTab === "actual" ? actualData : whatIfData}
          currentTab={currentTab}
          onRunwayDataSave={onRunwayDataSave}
        />

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <InlineSecondaryTile currentTab={currentTab}>
            <Box overflow={"hidden"}>
              <Typography variant="h6" textTransform={"uppercase"}>
                RUNWAY
              </Typography>
              <Typography
                variant="h1"
                textOverflow={"ellipsis"}
                overflow="hidden"
              >
                {currentTab === "actual"
                  ? actualData?.month
                  : whatIfData?.month}{" "}
                Months
              </Typography>
            </Box>
          </InlineSecondaryTile>
        </Grid>

        <Grid item lg={12} md={12} sx={{ mt: 2 }}>
          <Card variant="outlined">
            <Box sx={{ display: "flex" }}>
              <img src={STARTUPOSICON} />
              <Typography variant="h4" sx={{ ml: 1 }}>
                Pro Tips:
              </Typography>
            </Box>
            <Typography variant="body1" sx={{ mt: 2 }}>
              {getProTip()}
            </Typography>
          </Card>
        </Grid>

        <Grid
          item
          lg={12}
          md={12}
          sx={{ mt: 2, ml: "auto" }}
          display="flex"
          justifyContent={"flex-end"}
        >
          <Typography align="right" sx={{ mr: 1 }}>
            Sponsored by
          </Typography>
          <img src={SPONSOREDBY} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default RunwayCalculator;
