import React, { useEffect, useState } from "react";
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import { ItemIcon, NavListWrapper } from "./styles";
import { STARTUPOSICON } from "../../constants/image";
import { useHistory, useLocation } from "react-router-dom";
import theme from "../../constants/Theme";
function NavItemsList({ direction, listItems }) {
  const [active, setActive] = useState();
  const history = useHistory();
  const location = useLocation();

  const handleListItemClick = listItem => {
    // setActive(listItem.key);
    history.push(listItem.link);
  };

  useEffect(() => {
    return history.listen(location => {
      setActive(location.pathname.split("/")[1]);
    });
  }, [history]);

  useEffect(() => {
    setActive(location.pathname.split("/")[1]);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down("md")]: {
          display: "block",
        },
      }}
    >
      <NavListWrapper direction={direction} data-tut="reactour__navigation">
        <List
          sx={{
            display: "flex",
            flexDirection: direction,
            justifyContent: "center",
          }}
        >
          {direction === "column" ? (
            <ListItem
              disablePadding
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                pt: 1,
                pb: 3,
              }}
            >
              <img src={STARTUPOSICON} width="32px" height="32px" />
            </ListItem>
          ) : null}

          {listItems.map((listItem, index) => (
            <ListItem
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "Pointer",
              }}
              onClick={() => handleListItemClick(listItem)}
              disableRipple
              disableTouchRipple
              disablePadding
            >
              <ItemIcon selected={active === listItem.key}>
                <img
                  src={listItem.icon}
                  style={{
                    filter:
                      active === listItem.key
                        ? "brightness(0%)"
                        : "brightness(100%)",
                  }}
                />
              </ItemIcon>
              <ListItemText>
                <Typography variant="subtitle2">{listItem.label}</Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </NavListWrapper>
    </Box>
  );
}

export default NavItemsList;
