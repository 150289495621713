import React from "react";
import { Popover, Typography, Switch, Grid, Box } from "@mui/material";
import PropTypes from "prop-types";
import { ToggleSwitch, RoadRunnerGrid } from "./styles";
import {
  MINIROADRUNNERICON,
  SETTINGICON,
  LOGOICON,
} from "../../../constants/image";
import WorkspaceUser from "../WorkspaceUser";
import { connect } from "react-redux";
import theme from "../../../constants/Theme";
function Header({ onLogout, userData, companyName }) {
  return (
    <Grid
      container
      alignItems={"center"}
      sx={{
        height: "64px",
        [theme.breakpoints.down("md")]: {
          background: theme.palette.secondary.backgroundGradient,
          boxShadow:"0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)"
        },
      }}
    >
      {/* Visible grids for desktop */}

      <Grid
        item
        md={4}
        lg={3}
        xl={3}
        display={{ sm: "none", xs: "none", md: "block" }}
      >
        <Typography variant="h4" sx={{ ml: 4 }}>
          {companyName ? companyName : "The Roadrunner.corp"}
        </Typography>
      </Grid>
      <Grid
        item
        md={4.5}
        lg={5}
        xl={6}
        display={{ sm: "none", xs: "none", md: "block" }}
      ></Grid>

      <Grid
        item
        md={3.5}
        lg={4}
        xl={3}
        display={{ sm: "none", xs: "none", md: "block" }}
      >
        <Grid container alignItems={"center"} spacing={4}>
          <Grid item md={12}>
            <RoadRunnerGrid>
              <img src={MINIROADRUNNERICON} alt="logo" />
              <Typography variant="h6">Roadrunner mode</Typography>
              <Box sx={{ ml: 1 }}>
                <ToggleSwitch />
              </Box>
              <Box sx={{ ml: 4.5, mr: 3, mt: 0.4 }}>
                <img src={SETTINGICON} />
              </Box>
            </RoadRunnerGrid>
          </Grid>
        </Grid>
      </Grid>

      {/* Visible grids for mobile */}

      <Grid
        item
        xs={10}
        sm={10}
        display={{
          xs: "block",
          sm: "block",
          md: "none",
          lg: "none",
          xl: "none",
        }}
        justifyContent="flex-start"
      >
        <Box sx={{ ml: 2 }}>
          <img draggable={false} src={LOGOICON} alt="Logo" />
        </Box>
      </Grid>

      <Grid
        item
        xs={2}
        sm={2}
        display={{
          xs: "block",
          sm: "block",
          md: "none",
          lg: "none",
          xl: "none",
        }}
        justifyContent="flex-start"
      >
        <WorkspaceUser onLogout={onLogout} userData={userData} />
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  onLogout: PropTypes.func,
  userData: PropTypes.object,
};

Header.defaultProps = {
  onBack: undefined,
  userData: undefined,
};

const mapStoreToProps = ({ auth }) => ({
  companyName: auth?.companyName,
});

export default connect(mapStoreToProps, undefined)(Header);
