//Auth Actions
export const SET_AUTH_DATA = "SET_AUTH_DATA";

//Onboarding Actions
export const SET_ONBOARDING_DATA = "SET_ONBOARDING_DATA";
export const SET_COMPANY_NAME = "SET_COMPANY_NAME";
export const SET_COMPANY_LOCATION = "SET_COMPANY_LOCATION";
export const SET_AUTOCOMPLETE_DATA = "SET_AUTOCOMPLETE_DATA";
export const SET_PRODUCT_SERVICE = "SET_PRODUCT_SERVICE";
export const SET_EXPERIENCE_LEVELS = "SET_EXPERIENCE_LEVELS";
export const SET_FOUNDER_SUPER_POWER = "SET_FOUNDER_SUPER_POWER";
export const SET_COMMITMENT_LEVELS = "SET_COMMITMENT_LEVELS";
export const SET_STARTUP_JOURNEY = "SET_STARTUP_JOURNEY";
export const SET_POWERUP_ExcerciseS = "SET_POWERUP_ExcerciseS";
export const SET_INTERVIEW_SCRIPT_DIALOG = "SET_INTERVIEW_SCRIPT_DIALOG";
export const SET_POWERUP_DETAILS = "SET_POWERUP_DETAILS";
export const SET_INTERVIEW_CANDIDATES = "SET_INTERVIEW_CANDIDATES";
