import {
  DialogTitle,
  Dialog,
  IconButton,
  Typography,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import theme from "../../../../../constants/Theme";

export default function DialogComponent({
  isOpen,
  onClose,
  title,
  children,
  dialogAction,
  titleVariant,
  contentStyle,
  footerStyle,
  ...props
}) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} {...props}>
      <DialogTitle
        sx={{
          borderBottom: `1px solid ${theme.palette.secondary.dark}`,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant={titleVariant}>{title}</Typography>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: theme.palette.secondary.dark,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          ...contentStyle,
        }}
      >
        {children}
      </DialogContent>
      <DialogActions
        sx={{
          borderTop: `1px solid ${theme.palette.secondary.dark}`,
          ...footerStyle,
        }}
      >
        {dialogAction}
      </DialogActions>
    </Dialog>
  );
}
