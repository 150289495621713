import React, { useEffect } from "react";

import { Box } from "@mui/material";
import { REGEX } from "../../../../../../../constants/regex";
import { useState } from "react";
import messages from "../../../../../../../constants/messages";
import {
  InputFieldsWrapper,
  InputLabelField,
  TextInpuField,
} from "../../styles";

export default function Som({ userInputs, setUserInputs, somInputValid }) {
  const [errorMessages, setErrorMessages] = useState({
    noFocusOn: "",
    percentageOfSam: "",
  });

  const handleInputChange = (event, key) => {
    const value = event.target.value;
    const currentInput = { ...userInputs };
    currentInput[key] = value;

    setUserInputs(currentInput);
  };

  const handleBlur = (event, key) => {
    const value = event.target.value;

    switch (key) {
      case "percentageOfSam":
        if (somInputValid(key, value)) {
          setErrorMessages({ ...errorMessages, percentageOfSam: "" });
        } else {
          setErrorMessages({
            ...errorMessages,
            percentageOfSam: "Invalid",
          });
        }
        break;
      case "noFocusOn":
        if (somInputValid(key, value)) {
          setErrorMessages({ ...errorMessages, noFocusOn: "" });
        } else {
          setErrorMessages({
            ...errorMessages,
            noFocusOn: messages.TAM_SAM_SOM_TEXTFIELD,
          });
        }
        break;
    }
  };

  return (
    <Box>
      <Box sx={{ my: 2 }}>
        <InputLabelField wrap={true}>
          Due to market conditions, competition, and scaling challenges, I will
          not be able
        </InputLabelField>
      </Box>
      <InputFieldsWrapper>
        <InputLabelField>to focus on</InputLabelField>
        <TextInpuField
          placeholder="union truckers"
          value={userInputs?.noFocusOn}
          onChange={e => handleInputChange(e, "noFocusOn")}
          error={!!errorMessages.noFocusOn}
          onBlur={e => handleBlur(e, "noFocusOn")}
          helperText={errorMessages.noFocusOn}
        />
        <InputLabelField> which excludes</InputLabelField>
        <TextInpuField
          placeholder="#"
          isNumber={true}
          value={userInputs?.percentageOfSam}
          onChange={e => handleInputChange(e, "percentageOfSam")}
          error={!!errorMessages.percentageOfSam}
          onBlur={e => handleBlur(e, "percentageOfSam")}
        />
        <InputLabelField>percentage of the SAM.</InputLabelField>
      </InputFieldsWrapper>
    </Box>
  );
}
