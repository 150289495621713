import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import MultiTagsInput from "../MultiTagsInput";

export default function AutocompleteInput({
  placeholder,
  inputValid,
  errorMessage,
  typingEnabled,
  inputValue,
  handleInputChange,
  inputKey,
  autoCompleteData,
  label,
  multiple,
  ...props
}) {
  const handleChange = e => {
    if (!multiple) {
      handleInputChange(e, inputKey);
    } else {
      if (e.target.value === 0 && e.target.innerText) {
        inputValue.push(e.target.value || e.target.innerText);
        let inputs = [...inputValue];
        let uniqueInputs = new Set(inputs);
        handleInputChange(
          { target: { value: Array.from(uniqueInputs) } },
          inputKey
        );
      }
    }
  };
  return (
    <Autocomplete
      freeSolo
      disableClearable
      value={inputValue}
      options={autoCompleteData?.map(option => option)}
      renderInput={params => (
        <>
          {multiple ? (
            <MultiTagsInput
              inputValue={inputValue}
              params={params}
              handleInputChange={handleInputChange}
              inputKey={inputKey}
              placeholder={placeholder}
              errorMessage={errorMessage}
              inputValid={inputValid}
              typingEnabled={typingEnabled}
              label={label}
            />
          ) : (
            <TextField {...params} label={label} onChange={handleChange} />
          )}
        </>
      )}
      onChange={handleChange}
      {...props}
    />
  );
}
