import services from "../../../../../services";
import { AVATAR_DEFAULT } from "../../../../../constants/image";
import { getCurrentDate } from "../../../../../utils/date";

//service call to update last visited powerup status
export const updateLastVisited = async id => {
  services
    .updateLastVisitedPowerup({
      powerUpId: id,
    })
    .then(response => {})
    .catch(error => {});
};

//service call to get sidebar details
export const getSidebarContent = async id => {
  let res = await services.getSidebarContent(id);
  return res;
};

//service call to get powerup details
export const getPowerUPData = async id => {
  return services
    .getPowerupData(id)
    .then(response => {
      const res = response.data;

      if (res.code === 200) {
        //service call to update last visited powerup status
        services
          .updateLastVisitedPowerup({
            powerUpId: id,
          })
          .then(response => {})
          .catch(error => {});
        return res.data;
        //getPowerupStepsApiCall();
      }
    })
    .catch(error => {
      return {};
    });
};

export const getInterviewCandidates = () => {
  //Currently sending static data as candidates created in next steps will replace this with api modules call
  return [
    {
      avatar: AVATAR_DEFAULT,
      name: "John doe",
      selected: true,
      email: "john@gmail.com",
      interviewDate: getCurrentDate(new Date()),
    },
    {
      avatar: AVATAR_DEFAULT,
      name: "Maxson Culhane",
      selected: false,
      email: "maxon@gmail.com",
      interviewDate: "--",
    },
    {
      avatar: AVATAR_DEFAULT,
      name: "Alexa",
      selected: true,
      email: "alexa@gmail.com",
      interviewDate: getCurrentDate(new Date()),
    },
    {
      avatar: AVATAR_DEFAULT,
      name: "Steve Smith",
      selected: false,
      email: "steve@gmail.com",
      interviewDate: "--",
    },
    {
      avatar: AVATAR_DEFAULT,
      name: "James Peter",
      selected: false,
      email: "clare@gmail.com",
      interviewDate: "--",
    },
  ];
};

export const getDefaultScriptData = companyData => {
  return [
    {
      key: "intro",
      label: "INTRO",
      value: `Hi <first name>. My name is <interviewers name> and I'm with ${
        companyData?.onboardingDetail?.companyName || "<Company name>"
      }. Thanks for making time for me today.`,
    },
    {
      key: "context",
      label: "CONTEXT",
      value: `${
        companyData?.onboardingDetail?.companyName || "<Company name>"
      } is based in ${
        companyData?.onboardingDetail?.state || "<company headquarters>"
      } and was founded by <founder name> in ${
        companyData?.onboardingDetail?.foundedYear || "<date founded>"
      }.`,
    },
    {
      key: "purpose",
      label: "PURPOSE",
      value: `The reason we’re reaching out to you today is to gauge the appetite for a solution like ours before we invest further. Your feedback will help us determine whether our idea has legs.. There are no right or wrong answers... we will value ANY and ALL feedback -- good or bad. Be as honest as you can be and please-please don’t worry about hurting my feelings..
      
    Before we dig in though, I’d like to spend a few minutes learning a little more about you.. I have a few starter questions but feel free to go down a different path. That often takes us to unexpected places and some great insights that will help us too.
          
    But before we get started, would you mind if I record our session? I’d like to review it with our product team later – and want to make sure we don’t miss anything.
          
    If you’re OK with that, I’ll go ahead and press record now`,
    },
    {
      key: "questions",
      label: "QUESTIONS",
      value:
        companyData?.questions?.length > 0
          ? [...companyData?.questions]
          : [
              "What are the challenges you encounter when doing",
              "How do you currently accomplish",
              "What brands do you turn to in order to complete",
              "What do you like about each brand",
            ],
    },
    {
      key: "closeandthanks",
      label: "CLOSE & THANKS",
      value: "",
    },
  ];
};

export const getNotesScriptData = () => {
  return [
    {
      key: "notes",
      value: "",
    },
    {
      key: "gems&verbatims",
      label: "GEMS & VERBATIMS",
      value: "",
    },
    {
      key: "key_insights",
      label: "KEY INSIGHTS",
      value: "",
    },
  ];
};

const getCandidateName = email => {
  switch (email) {
    case "bkoloski@startupos.com":
      return "Ben Koloski";

    case "mgarrison@startupos.com":
      return "Michael Garrison";

    case "paul@startupos.com":
      return "Paul Pluschkell";

    default:
      return "John doe";
  }
};

export const getCandidatesList = emails => {
  return services
    .getInterviewCandidates(emails)
    .then(response => {
      const res = response.data;
      if (res.code === 200) {
        const mappedData = res.data.map(candidate => ({
          email: candidate.email,
          //using static name logic as backend api didnt provides name
          name: getCandidateName(candidate.email),
          selected: false,
          avatar: AVATAR_DEFAULT,
        }));

        return mappedData;
      }
    })
    .catch(err => {
      return err;
    });
};

export const getBrowseCandidateModule = moduleId => {
  return services
    .getModuleStepResponses(moduleId)
    .then(result => {
      return result.data.data[0];
    })
    .catch(error => console.log(error));
};
