import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Divider,
  CircularProgress,
  useTheme,
  Button,
} from "@mui/material";
import { connect } from "react-redux";

import PowerUPLayout from "../../../../layout/PowerUP";
import Sidebar from "../../../../components/Workspace/PowerUp/Sidebar";

import BreadcrumbsComp from "./Components/BreadcrumbsComp";
import {
  getDefaultScriptData,
  getPowerUPData,
  getSidebarContent,
} from "./Functions/common";
import PowerUPHeader from "../../../../components/PowerUP/Details/Header";
import { DetailsOverview } from "./Components/DetailsOverview";
import DialogComponent from "./Components/Dialog";
import * as actions from "../../../../modules/actions";

import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InterviewScript from "./Components/InterviewScript";
import MessageHandlerModal from "../../../../common/modal/MessageHandler";
import services from "../../../../services";

const MainContent = ({ powerupDetails, sidebar_content }) => {
  const theme = useTheme();
  const [details, setPowerUPData] = useState({});

  useEffect(() => {
    setPowerUPData(powerupDetails);
  }, [powerupDetails]);

  return (
    <Box>
      <PowerUPHeader valuePropsHeaderData={details} />
      <Divider
        color={theme.palette.secondary.light}
        sx={{ mt: 3, display: { xs: "none", md: "block" } }}
      />
      <Box sx={{ mt: 3 }}>
        <DetailsOverview powerupDetails={details} />
      </Box>
    </Box>
  );
};

const IdeaValidationDetails = ({
  showInterviewScriptDialog,
  setInterviewScriptDialog,
  powerupScriptDetails,
  setPowerupScriptDetails,
}) => {
  const [powerupDetails, setPowerupDetails] = useState("");
  const [powerupDetailsLoader, setPowerupDetailsLoader] = useState(false);
  const [powerupStepsData, setPowerupStepsData] = useState([]);
  const [SIDEBARDATA, setSidebarData] = useState({});

  const [discardConfirmDialog, setDiscardConfirmDialog] = useState(false);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);

  const [scriptData, setScriptData] = useState(getDefaultScriptData({}));
  const [currentModule, setCurrentModule] = useState();
  const [updatePowerupDetails, setUpdatePowerupDetails] = useState(false);
  const [getChildScriptData, setChildScriptData] = useState(false);

  const powerUpId = "4";

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setPowerupDetailsLoader(true);
    getPowerUPData(powerUpId).then(res => {
      setPowerupDetails(res);
    });
    getSidebarContent(powerUpId).then(res => {
      setSidebarData(res);
    });
    setPowerupDetailsLoader(false);
  }, [updatePowerupDetails]);

  useEffect(() => {
    const fetchedPowerupScriptDetails =
      powerupScriptDetails?.data?.moduleStepResponses?.find(
        moduleStep => moduleStep?.isLastStep === true
      );

    if (fetchedPowerupScriptDetails) {
      setCurrentModule({
        ...fetchedPowerupScriptDetails,
        responseId: powerupScriptDetails?.data?.id,
      });

      if (fetchedPowerupScriptDetails?.answers) {
        setScriptData(
          JSON.parse(fetchedPowerupScriptDetails?.answers)?.interviewScript
        );
      }
    }
  }, [powerupScriptDetails]);

  const handleSaveAction = updatedScriptData => {
    const data = {
      moduleResponseId: currentModule.responseId,
      stepNo: 2,
      answers: JSON.stringify({
        interviewScript: updatedScriptData || scriptData,
      }),
      isLastStep: true,
      isCompleted: true,
      moduleStepId: currentModule.moduleStep?.id,
    };
    updateModuleStepResponseApiCall(currentModule.responseId, data);
  };

  const handleDeleteAction = (cb = () => {}) => {
    const data = {
      moduleResponseId: currentModule.responseId,
      stepNo: 2,
      answers: null,
      isLastStep: true,
      isCompleted: true,
      moduleStepId: currentModule.moduleStep?.id,
    };
    updateModuleStepResponseApiCall(currentModule.responseId, data, cb);
  };

  const updateModuleStepResponseApiCall = (moduleId, data, cb = () => {}) => {
    services
      .storeModuleStepResponses(moduleId, data)
      .then(result => {
        setPowerupScriptDetails({
          id: "build-script",
          data: result?.data?.data,
        });
        cb();
      })
      .catch(error => console.log(error));
  };

  const FooterComponent = () => {
    return (
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        sx={{ width: 1, px: 2 }}
      >
        <Box sx={{ width: 1 }}>
          <Button
            variant="noOutline"
            sx={{ width: "max-content" }}
            startIcon={<DeleteOutlineOutlinedIcon />}
            onClick={() => setDeleteConfirmDialog(true)}
          >
            Delete
          </Button>
        </Box>
        <Box>
          <Button
            sx={{ width: "max-content" }}
            onClick={() => setChildScriptData(!getChildScriptData)}
          >
            Save
          </Button>
        </Box>
      </Box>
    );
  };

  const discardSuccessDialogHandler = () => {
    setDiscardConfirmDialog(false);
    setInterviewScriptDialog(false);
  };

  const discardCancelDialogHandler = () => {
    setDiscardConfirmDialog(false);
  };

  const deleteSuccessDialogHandler = () => {
    handleDeleteAction(() => setUpdatePowerupDetails(true));
    setDeleteConfirmDialog(false);
    setInterviewScriptDialog(false);
  };

  const deleteCancelDialogHandler = () => {
    setDeleteConfirmDialog(false);
  };
  const getChildData = data => {
    handleSaveAction(data);
  };

  const resetAction = () => {
    setScriptData(getDefaultScriptData({}));
  };

  return (
    <>
      <MessageHandlerModal
        isOpen={discardConfirmDialog}
        text={"Are you sure you want to discard the changes you made?"}
        messageType={"primary"}
        cancelText={"No thanks"}
        okText={"Discard"}
        style={{
          width: "450px",
        }}
        onOk={discardSuccessDialogHandler}
        onCancel={discardCancelDialogHandler}
      />

      <MessageHandlerModal
        isOpen={deleteConfirmDialog}
        heading="Delete"
        text={"Are you sure you want to delete this?"}
        messageType={"primary"}
        cancelText={"No thanks"}
        okText={"Delete"}
        style={{
          width: "450px",
        }}
        onOk={deleteSuccessDialogHandler}
        onCancel={deleteCancelDialogHandler}
      />

      <DialogComponent
        isOpen={showInterviewScriptDialog}
        onClose={() => setDiscardConfirmDialog(true)}
        title={"Your Interview Script"}
        dialogAction={<FooterComponent />}
        titleVariant="largeTitle"
        sx={{
          display: "flex",
          width: 1,
          flexDirection: "column",
          borderRadius: "50px",
          "& .MuiDialog-paper": {
            width: "fit-content",
            maxWidth: "736px",
            borderRadius: 3,
          },
        }}
        contentStyle={{
          mt: 2,
        }}
        footerStyle={{
          py: 2,
        }}
      >
        <Box>
          <Box
            sx={[
              theme => ({
                [theme.breakpoints.up("md")]: {
                  border: `1px solid ${theme.palette.secondary.dark}`,
                  py: 2,
                  px: 3,
                },
                borderRadius: 3,
              }),
            ]}
          >
            <InterviewScript
              scriptConfigData={scriptData}
              customSaveButton={true}
              resetAction={resetAction}
              getChildData={getChildData}
              getChildEventData={getChildScriptData}
            />
          </Box>
        </Box>
      </DialogComponent>
      <PowerUPLayout
        main={
          <Fragment>
            {powerupDetailsLoader ? (
              <Box justifyContent={"center"} sx={{ display: "flex", mt: 18 }}>
                <CircularProgress />
              </Box>
            ) : (
              <MainContent
                powerupDetails={powerupDetails}
                sidebar_content={SIDEBARDATA}
              />
            )}
          </Fragment>
        }
        sidebar={<Sidebar sidebar_content={SIDEBARDATA} />}
        breadcrumbs={<BreadcrumbsComp powerupDetails={powerupDetails} />}
      />
    </>
  );
};

const mapStoreToProps = ({ powerUp }) => ({
  showInterviewScriptDialog: powerUp.showInterviewScriptDialog,
  powerupScriptDetails: powerUp.powerupDetails,
});

const mapDispatchToProps = dispatch => ({
  setInterviewScriptDialog: updatedData =>
    dispatch(actions.setInterviewScriptDialog(updatedData)),
  setPowerupScriptDetails: updatedData =>
    dispatch(actions.setPowerupDetails(updatedData)),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps
)(IdeaValidationDetails);
