import React, { useState } from "react";
import { Grid, Box, Divider, Stack, Typography } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { VIEW_ICON, LOGOICON } from "../../../constants/image";
import theme from "../../../constants/Theme";
import ProfileContainer from "./Profile";
import AboutContainer from "./About";
import StartupOSScore from "../../Dashboard/QuickStatView/StartupOSScore";
export default function MyProfile(props) {
  return (
    <Grid container>
      <Box
        sx={{
          width: "100%",
          gridColumn: {
            lg: "span 12",
            md: "span 12",
            sm: "span 12",
            xs: "span 12",
          },
          mt: 5,
        }}
      >
        <ProfileContainer
          handleEditForm={props.handleEditForm}
          investerToggle={props.investerToggle}
          profileArr={props.profileArr}
        />
        <Divider
          sx={{
            mt: 3,
            background: `${theme.palette.secondary.outlineLight}`,
            display: { xs: "none", md: "block" },
          }}
        />
        <AboutContainer
          handleEditForm={props.handleEditForm}
          profileArr={props.profileArr}
          productOffering={props.productOffering}
        />
        <Divider
          sx={{ mt: 3, background: `${theme.palette.secondary.outlineLight}` }}
        />
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            mt: 3,
            pl: { xs: 1, md: 3 },
          }}
        >
          <Grid
            container
            sx={{
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                alignContent: "center",
                justifyContent: { xs: "center", md: "start" },
              }}
            >
              <img src={LOGOICON} alt="investor-view" />
              <Typography variant="h3">Insights</Typography>
            </Grid>
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                alignContent: "center",
                justifyContent: { xs: "start", md: "end" },
                pb: { xs: 2, md: 0 },
                cursor: "pointer",
              }}
              onClick={() => props.setShowInsights(!props.showInsights)}
            >
              {props.showInsights ? <VisibilityIcon /> : <VisibilityOffIcon />}
              <Box
                sx={{
                  lineHeight: theme.spacing(0),
                  color: `${theme.palette.secondary.dark}`,
                }}
              >
                <Typography variant="h7">
                  {props.showInsights
                    ? "Open for Investor View"
                    : "Close for Investor View"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={4} md={6} sm={12} xs={12} justifyContent={"center"}>
          <Box
            sx={{
              border: `1px solid ${theme.palette.secondary.dark}`,
              borderRadius: theme.spacing(3),
              ml: { xs: 0, md: 2 },
              mt: 2,
            }}
          >
            <StartupOSScore id="desktop" value={250} maxValue={850} />
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
}
