export const geoLocation = setLocation => {
  const onSuccess = location => {
    setLocation({
      loaded: true,
      coordinates: {
        latitude: location.coords.latitude.toFixed(10),
        longitude: location.coords.longitude.toFixed(10),
      },
    });
  };

  const setError = error => {
    setLocation({
      loaded: true,
      error: {
        code: error.code,
        message: error.message,
      },
    });

    return error;
  };

  const onError = error => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setError({
          code: error.code,
          message: "User denied the request for Geolocation.",
        });
        break;
      case error.POSITION_UNAVAILABLE:
        setError({
          code: error.code,
          message: "Location information is unavailable.",
        });
        break;
      case error.TIMEOUT:
        setError({
          code: error.code,
          message: "The request to get user location timed out",
        });
        break;
      case error.UNKNOWN_ERROR:
        setError({
          code: error.code,
          message: "An unknown error occurred.",
        });
        break;
      default:
        setError({
          code: 0,
          message: "An unknown error occurred.",
        });
    }

    return error;
  };

  if (!("geolocation" in navigator)) {
    onError({
      code: 0,
      message: "Geolocation not supported",
    });
  }

  navigator.geolocation.getCurrentPosition(onSuccess, onError, {
    enableHighAccuracy: true,
    timeout: 500000,
    maximumAge: 0,
  });
};
