import createAuth0Client from "@auth0/auth0-spa-js";
import { saveSocialSignupType } from "./sessionStorage";
let auth0Client;

async function initClient() {
  try {
    if (!auth0Client) {
      auth0Client = await createAuth0Client({
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        client_id: process.env.REACT_APP_AUTH0_CLIENT,
        redirect_uri: process.env.REACT_APP_REDIRECTION_SUCCESS,
        useRefreshTokens: true,
        scope: "openid profile email offline_access",
        audience: process.env.REACT_APP_AUTH0_USER_INFO,
      });
    }

    return auth0Client;
  } catch (e) {
    console.error(e);
  }
}
initClient().then();

export async function authLogin(params) {
  const auth0 = await initClient();

  await auth0.loginWithRedirect(params);
}

export async function passwordlesslogin(params) {
  const auth0 = await initClient();

  await auth0.passwordlessStart(params);
}

export async function loginGoogle(params) {
  const auth0 = await initClient();
  saveSocialSignupType("google");
  await auth0.loginWithRedirect({
    connection: "google-oauth2",
    redirect_uri: process.env.REACT_APP_REDIRECTION_SUCCESS,
  });
}

export async function loginLinkedIn(params) {
  const auth0 = await initClient();
  saveSocialSignupType("linkedin");

  await auth0.loginWithRedirect({
    connection: "linkedin",
    redirect_uri: process.env.REACT_APP_REDIRECTION_SUCCESS,
  });
}

export async function getToken(params) {
  const auth0 = await initClient();
  let token;

  try {
    token = await auth0.getTokenSilently(params);
    return token;
  } catch (e) {
    console.log(e);
  }
}

export async function getIdToken(params) {
  const auth0 = await initClient();
  let token;

  try {
    token = await auth0.getIdTokenClaims();
    return token.__raw;
  } catch (e) {
    console.log(e);
  }
}

export async function getUser(params) {
  const auth0 = await initClient();
  const user = await auth0.getUser();
  return user;
}

export async function logout() {
  const auth0 = await initClient();
  await auth0.logout({
    returnTo: process.env.REACT_APP_REDIRECTION_SIGNOUT_URL,
  });
}

export async function handleCallback() {
  const auth0 = await initClient();
  return await auth0.handleRedirectCallback();
}

export default {
  getToken,
  getIdToken,
  logout,
  authLogin,
  getUser,
  loginGoogle,
};
