import {
  DASHBOARDICON,
  NETWORKICON_GREY,
  NOTESICON,
  QUESTION_ICON,
  USERSICON,
  WORKSPACEICON,
} from "./image";
import ROUTES_PATH from "./routes";

export const WORKSPACE_LEFT_SIDEBAR_ITEMS = [
  {
    icon: WORKSPACEICON,
    label: "Workspace",
    key: "workspace",
    link: ROUTES_PATH.WORKSPACE,
  },
  {
    icon: DASHBOARDICON,
    label: "Dashboard",
    key: "dashboard",
    link: ROUTES_PATH.DASHBOARD,
  },
  {
    icon: NETWORKICON_GREY,
    label: "Network",
    key: "network",
    link: ROUTES_PATH.NETWORK,
  },
  {
    icon: USERSICON,
    label: "My Startup",
    key: "mystartup",
    link: ROUTES_PATH.STARTUP,
  },
];

export const WORKSPACE_RIGHT_SIDEBAR_ITEMS = [
  {
    icon: NOTESICON,
    label: "Notes",
    key: "notes",
  },
  {
    icon: QUESTION_ICON,
    label: "Q's",
    key: "qs",
  },
];
