import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import MessageHandlerModal from "../modal/MessageHandler";
import PropTypes from "prop-types";

const RouterPrompt = ({
  when,
  onOK,
  onCancel,
  title,
  okText,
  cancelText,
  stepPath,
}) => {
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    if (when()) {
      history.block(prompt => {
        if (prompt.pathname !== stepPath) {
          setCurrentPath(prompt.pathname);
          setShowPrompt(true);
          //need to return something to block route
          return "true";
        }
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return (
    <MessageHandlerModal
      text={title}
      isOpen={showPrompt}
      onOk={handleOK}
      okText={"Discard"}
      heading={"Discard changes"}
      onCancel={handleCancel}
      cancelText={"No thanks"}
      messageType="primary"
    ></MessageHandlerModal>
  );
};

{
  RouterPrompt.propTypes = {
    when: PropTypes.bool,
    title: PropTypes.string,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    showPrompt: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
  };

  RouterPrompt.defaultProps = {
    when: false,
    title: "",
    okText: "Ok",
    cancelText: "Cancel",
    isOpen: false,
    onCancel: null,
    onOk: null,
  };
}

export default RouterPrompt;
