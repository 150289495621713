import styled from "@emotion/styled";
import { sosPrimary } from "../../../constants/Theme/colors";

export const PageContentWrapper = styled.div(
  ({ theme, borderWidth, padding, marginTop }) => {
    return {
      marginTop: marginTop || theme.spacing(3),
      borderRadius: "28px",
      flexGrow: 1,
      borderWidth: borderWidth || 0,
      borderColor: theme.palette.secondary.dark,
      borderStyle: "solid",
      padding: padding || theme.spacing(3),
      display: "flex",
      flexDirection: "column",

      [theme.breakpoints.down("md")]: {
        backgroundColor: theme.palette.secondary.backgroundGradient,
      },
      [theme.breakpoints.up("md")]: {
        backgroundColor: theme.palette.background.default,
      },
    };
  }
);

export const WorkContentWrapper = styled.div(({ theme, borderWidth }) => {
  return {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
      borderRadius: "28px",
      flexGrow: 1,
      borderWidth: borderWidth || 0,
      borderColor: theme.palette.secondary.dark,
      borderStyle: "solid",
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
    },
  };
});

export const ProfileContentWrapper = styled.div(({ theme, borderWidth }) => {
  return {
    marginTop: theme.spacing(3),
    borderRadius: "28px",
    flexGrow: 1,
    borderWidth: borderWidth || 0,
    borderColor: theme.palette.secondary.dark,
    borderStyle: "solid",
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      backgroundColor: "none",
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.background.default,
    },
  };
});
