import React, { useEffect, useState } from "react";
import { Grid, Button, Box, Stack, Typography } from "@mui/material";
import "./signUp.css";
import TextInput from "../../common/input/Input";
import ROUTES_PATH from "../../constants/routes";
import { REGEX } from "../../constants/regex";
import TermsAndServiceModal from "../../common/modal/TermsAndServiceModal";
import { loginGoogle, loginLinkedIn } from "../../utils/auth0-spa";
import { saveUserEmail } from "../../utils/sessionStorage";
import { useLocation } from "react-router-dom";
import { Google, LinkedIn } from "@mui/icons-material";
import { Separator } from "../../common/separator";
import { themePalette } from "../../constants/Theme/colors";

const SignUp = ({ ...props }) => {
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [onboarding, setOnboarding] = useState(false);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location && location.state && location.state.onboarding) {
      setOnboarding(true);
    }
  }, []);

  function ValidateEmail(value) {
    if (REGEX.EMAIL.test(value)) {
      return true;
    }
    return false;
  }
  const handleEmailInputChange = event => {
    setEmail(event);

    if (ValidateEmail(event)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };

  function handleSignUp() {
    saveUserEmail(email);
    props.history.push({
      pathname: ROUTES_PATH.CREATE_ACCOUNT,
      state: { onboarding: true },
    });
  }

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100%" }}
    >
      <Grid
        className="signup-grid-container"
        sx={{ mt: 9 }}
        container
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={6}
          lg={6}
          xl={4}
          className="form-container"
        >
          {onboarding ? (
            <>
              <Typography variant="h2" textAlign={"center"} sx={{ my: 1 }}>
                You're all set!
              </Typography>
              <Typography variant="h2" textAlign={"center"} sx={{ my: 1 }}>
                We're excited to join you in your startup journey.
              </Typography>
              <Typography textAlign={"center"} sx={{ my: 2 }}>
                Enter your email below to start creating an account, then you'll
                get a tour of your workplace.
              </Typography>
            </>
          ) : (
            <div className="signup-title">Sign Up</div>
          )}
          <Box sx={{ my: 4 }}>
            <TextInput
              type="text"
              label="Email address"
              value={email}
              error={emailError ? "Please enter valid email address" : ""}
              onChange={e => handleEmailInputChange(e)}
            />

            <Button
              sx={{ mt: 3, width: 1, maxWidth: "100%" }}
              onClick={e => handleSignUp(e)}
              disabled={!email || emailError}
            >
              Continue
            </Button>
            <Box sx={{ my: 2 }}>
              <Separator
                color={`${themePalette.palette.secondary.light}`}
                content={
                  <Typography
                    sx={{ px: 2 }}
                    color={themePalette.palette.secondary.main}
                  >
                    OR
                  </Typography>
                }
              ></Separator>
            </Box>

            <Box sx={{ mt: 2 }} textAlign={"center"}>
              <Button
                onClick={loginGoogle}
                sx={{
                  my: 1,
                  maxWidth: "100%",
                  backgroundColor: "#2d3748",
                }}
              >
                <Google />
                &nbsp; Continue with Google
              </Button>
            </Box>

            <Box sx={{ mt: 2 }} textAlign={"center"}>
              <Button
                onClick={loginLinkedIn}
                sx={{
                  my: 1,
                  maxWidth: "100%",
                  backgroundColor: "#2867b2",
                }}
              >
                <LinkedIn />
                &nbsp; Continue with LinkedIn
              </Button>
            </Box>
            <Box sx={{ my: 2 }} textAlign={"center"}>
              <Typography variant="span">
                By signing up, you agree to our
                <span>
                  <TermsAndServiceModal color="primary" />
                </span>
              </Typography>
            </Box>
            {/*<Box sx={{ my: 3 }} textAlign={"center"}>
              <Typography variant="span">
                Have an account? &nbsp;
                <Typography
                  variant="span"
                  color={"primary"}
                  onClick={() => {
                    props.history.push(ROUTES_PATH.LOG_IN);
                  }}
                >
                  Log in.
                </Typography>
              </Typography>
            </Box>*/}
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
};

//class SignUp extends Component {
//  constructor(props) {
//    super(props);
//    this.state = {
//      disabled: true,
//      email: "",
//      emailError: false,
//    };
//  }
//componentDidMount() {
//  window.scrollTo(0, 0);
//  const location = useLocation();
//  if (location && location.state && location.state.onboarding) {
//    alert("you came from onboarding!");
//  }
//}
//ValidateEmail(value) {
//  if (REGEX.EMAIL.test(value)) {
//    return true;
//  }
//  return false;
//}
//handleEmailInputChange = event => {
//  this.setState({
//    email: event,
//  });
//  if (this.ValidateEmail(event)) {
//    this.setState({ emailError: false });
//  } else {
//    this.setState({ emailError: true });
//  }
//};

//handleSignUp() {
//  saveUserEmail(this.state.email);
//  this.props.history.push(ROUTES_PATH.CREATE_ACCOUNT);
//}

//  render() {
//    let { email, emailError } = this.state;
//return (
//  <Stack
//    alignItems="center"
//    justifyContent="center"
//    sx={{ minHeight: "100%" }}
//  >
//    <Grid
//      className="signup-grid-container"
//      sx={{ mt: 9 }}
//      container
//      alignItems="center"
//      justifyContent="center"
//    >
//      <Grid item xs={12} sm={10} md={6} lg={6} className="form-container">
//        <div className="signup-title">Sign Up</div>
//        <Box sx={{ pt: 2 }} className="signup-sub-text d-flex">
//          By signing up, you agree to our
//          <span>
//            <TermsAndServiceModal />
//          </span>
//        </Box>
//        <TextInput
//          type="text"
//          placeholder="Email address"
//          value={email}
//          error={emailError ? "Please enter valid email address" : ""}
//          onChange={e => this.handleEmailInputChange(e)}
//        />

//        <Grid item>
//          <Button
//            sx={{ mt: 3 }}
//            onClick={e => this.handleSignUp(e)}
//            disabled={!email || emailError}
//          >
//            Continue
//          </Button>
//        </Grid>
//        <Box sx={{ pt: 3 }} className="or-text-div">
//          <span className="or-text">OR</span>
//        </Box>
//        <SocialButton
//          className="google-social-btn social-btn"
//          label="Continue with Google"
//          icon={GOOGLEICON}
//          onClick={loginGoogle}
//        />
//        <SocialButton
//          className="linkedin-social-btn social-btn"
//          label="Continue with LinkedIn"
//          icon={LINKEDINICON}
//          onClick={loginLinkedIn}
//        />
//        <Box sx={{ pt: 2 }} className="signup-sub-text align-center">
//          Have an account? &nbsp;
//          <span
//            onClick={() => {
//              this.props.history.push(ROUTES_PATH.LOG_IN);
//            }}
//          >
//            Log in.
//          </span>
//        </Box>
//      </Grid>
//    </Grid>
//  </Stack>
//);
//  }
//}

export default SignUp;
