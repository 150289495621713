import React, { Component } from "react";
import { connect } from "react-redux";

import { Grid, Box, Button, Stack, Link } from "@mui/material";
import "./emailVerification.css";
import ROUTES_PATH from "../../constants/routes";
import { EMAILICON } from "../../constants/image";
import sampleData from "../../constants/text";
import {
  getUserEmail,
  getUserId,
  getVerificationType,
} from "../../utils/sessionStorage";
import { authWebLogout, passwordReset } from "../../utils/auth0";
import services from "../../services";

import { toastContainer } from "../../utils/toast";
import messages from "../../constants/messages";

import * as actions from "../../modules/actions";
import { logout } from "../../utils/auth0-spa";
import LoadingButton from "../../common/Loadingbutton";
class EmailVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: "",
      email: "",
      isAuthenticated: false,
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({
      currentPath: this.props.location.pathname.split("/")[1],
      email: this.props.userData?.email || sessionStorage.getItem("email"),
      isAuthenticated: !!this.props.idToken,
    });

    window.scrollTo(0, 0);
  }

  handleBackClick() {
    let { currentPath } = this.state;

    currentPath === "resend-email"
      ? this.props.history.push(ROUTES_PATH.FORGOT_PASSWORD)
      : this.props.history.push(ROUTES_PATH.CREATE_ACCOUNT);
  }

  handleConfirm = async () => {
    const { email, isAuthenticated } = this.state;
    this.setState({ loading: true });
    if (isAuthenticated) {
      const data = this.props.userData;
      services
        .resendEmail({ email: data?.email, userId: data?.sub })
        .then(() => {
          this.setState({ loading: false });
          toastContainer(messages.VERIFICATION_EMAIL, "success");
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
    } else if (getVerificationType() === "forgotPassword") {
      passwordReset({
        email,
      })
        .then(() => {
          this.setState({ loading: false });
          toastContainer(messages.RESET_PASSWORD, "success");
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
    } else {
      //resend verification email to userId using auth0 management API
      const userId = "auth0|" + getUserId();

      services
        .resendEmail({ email, userId })
        .then(() => {
          this.setState({ loading: false });
          toastContainer(messages.VERIFICATION_EMAIL, "success");
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
    }
  };

  onLgoutHandler = async () => {
    await logout();
    await authWebLogout();
  };

  render() {
    let { currentPath, email, isAuthenticated } = this.state;
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100%" }}
      >
        <Grid
          className="signup-grid-container h-80"
          container
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            sm={10}
            md={6}
            lg={this.state.isAuthenticated ? 4 : 6}
            className="form-container"
          >
            {currentPath === "resend-email" ? (
              <img src={EMAILICON} alt="email" />
            ) : (
              ""
            )}
            <div className="signup-title">
              {currentPath === "email-verification"
                ? "Verify Your Email"
                : "Check Your Email"}
            </div>
            <Box sx={{ pt: 2 }} className="verification-sub-text ">
              {currentPath === "resend-email"
                ? sampleData.ResendEmailDescription[0]
                : sampleData.EmailVerificationDescription[0]}
              <span
                className={
                  currentPath === "resend-email"
                    ? "resend-email-text"
                    : "signup-email-text"
                }
              >
                {email}
              </span>
              {currentPath === "resend-email"
                ? sampleData.ResendEmailDescription[1]
                : sampleData.EmailVerificationDescription[1]}
            </Box>

            <Grid item>
              <Box textAlign={"center"}>
                <LoadingButton
                  sx={{
                    mt: 3,
                    maxWidth: "100%",
                  }}
                  onClick={() => this.handleConfirm()}
                  loading={this.state.loading}
                >
                  Resend Email
                </LoadingButton>
              </Box>
            </Grid>

            {this.state.isAuthenticated && (
              <Grid item sx={{ mt: 3 }} align="center">
                <Link
                  component="button"
                  variant="bodyLarge"
                  sx={{ ml: 1, textAlign: "start" }}
                  onClick={this.onLgoutHandler}
                >
                  Logout
                </Link>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Stack>
    );
  }
}

const mapStoreToProps = ({ auth }) => ({
  idToken: auth.idToken,
  userData: auth.userData,
  auth: auth,
});

const mapDispatchToProps = (dispatch) => ({
  setAuthData: (updatedData) => dispatch(actions.setAuthData(updatedData)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(EmailVerification);
